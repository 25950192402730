import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography, TableCell, tableCellClasses, Paper } from "@mui/material";
import { SwapHorizontalCircle, Download, Upload, AccountBalanceWallet, MoreHoriz, HttpsOutlined, Payments, Insights } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useAccount } from "wagmi";
import { useSnackbar } from "../../Contexts/SnackbarContext";
// import ConnectWallet from "../../Web3/ConnectWallet";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { formatNumber } from "../../Components/Common/USFormat";
import { checkBalance } from "../../Components/SmartContract/checkBalance";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#05131B',
    color: '#94A3B8',
    borderBottom: '0px',
    '&:first-of-type': {
      borderRadius: '16px 0px 0px 16px',
    },
    '&:last-of-type': {
      borderRadius: '0px 16px 16px 0px',
    },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.white,
    borderBottom: '0px'
  },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ }) => ({
}));

const DashboardWallet = () => {

  const { address } = useAccount();
  const { showSnackbar } = useSnackbar();
  const [walletBalance, setWalletBalance] = useState(0);
  const userData = getUserDataFromLocalStorage();

  console.log('address', address);
  // Project list array
  let assetData = [
    //   {
    //   project_name: 'Real Estate Audit for Houston Project',
    //   type: 'Custom Order',
    //   date: 'April 8, 2020',
    //   date: 'April 8, 2020',
    //   amount: 'SGD $125.86',
    //   invoice: '#ON123475967'
    // }
  ]

  // getting user wallet balance
  useEffect(() => {
    if (address) {
      async function getBalance() {
        const balanceOf = await checkBalance(address);
        setWalletBalance(formatNumber(parseFloat(Number(balanceOf) / 1e18)))
      }
      getBalance();
    }
  }, [address])

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully 
   * @param {string} acc 
   */
  const walletLogin = async (acc) => {
    if (acc) {
      // dispatch(update({ wallet_address: acc?.toLowerCase() }));
      showSnackbar('Wallet connected successfully', 'success');
    }
  }
  //Wallet connection code end

  return (
    <Box className="dashboard-overview-main dashboard-post-project-container service-proposer-wallet">
      <Box className="dashboard-overview-right">
        <Box className="wallet-container">
          <Box className="wallet-heading">
            <Typography component="h4" className="title">
              Member ID: {userData?.member_id}
            </Typography>
            <Typography component="p" className="sub-title">
              Hi, {userData?.first_name} {userData?.last_name} 👋🏼
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, mt: 2 }} className="">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Box className="connect-wallet-div">
                  <Box className="wallet-div-left">
                    <AccountBalanceWallet className="account-balance" />
                    <Typography component="p" className="title">
                      {address ? address : ""}
                      {/* "0x71C7656EC7ab88b098defB751B7401B5f6d8976F" */}
                    </Typography>
                  </Box>
                  {address ?
                    <Box className="wallet-div-right">
                      <Button>Connected</Button>
                    </Box>
                    :
                    <Button className="sp-wallet-connect-btn btn-rounded" onClick={handleClickOpen}>
                      Connect Wallet
                    </Button>
                  }
                </Box>
                <Typography className="sp-wallet-balance-text">
                  Balance: {walletBalance} RT
                </Typography>
                <Box className="your-rewards-container">
                  <Box className="top-view">
                    <Typography className="title" component="h4">
                      Your Rewards
                    </Typography>
                    <MoreHoriz />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <Payments className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">{walletBalance} RT</Box>
                        <Box className="payment-card-subtitle">
                          Wallet Balance
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <HttpsOutlined className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">0 RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Staked
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <Insights className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">0 RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Earnings
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <SwapHorizontalCircle className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">0 RT</Box>
                        <Box className="payment-card-subtitle">
                          Withdrawable
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="right-section">
                  <Typography>30-Day Wallet Statistics</Typography>
                  <Box className="list-data">
                    <Box className="icons">
                      <Download />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      In
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "0%" }} />
                      <Box
                        className="progress-empty"
                        style={{ width: `100%` }}
                      />
                    </Box>
                    <Box className="sp-in-out-amount">
                      <Typography>0 RT</Typography>
                      <Typography>0%</Typography>
                    </Box>
                  </Box>
                  <Box className="list-data">
                    <Box className="icons">
                      <Upload />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      Out
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "0%" }} />
                      <Box
                        className="progress-empty"
                        style={{ width: `100%` }}
                      />
                    </Box>
                    <Box className="sp-in-out-amount">
                      <Typography>0 RT</Typography>
                      <Typography>0%</Typography>
                    </Box>
                  </Box>
                  {/* <Box className="show-more-btn">
                    <Button
                      className="btn-rounded"
                      color="warning"
                      sx={{ backgroundColor: "#FFF8F3", maxWidth: "123px" }}
                    >
                      {false ? "Show Less" : "Show More"}
                    </Button>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Render projects */}
      <Box mt={2}>
        <TableContainer component={Paper} className='TableContainer'>
          <Box sx={{ padding: "10px 12px 4px 16px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
          </Box>
          <Table aria-label="customized table">
            <TableHead className='TableHeader sp-wallet-section '>
              <TableRow>
                <StyledTableCell style={{ maxWidth: '40%' }}>Project Name</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left">Order Date</StyledTableCell>
                <StyledTableCell align="left">Due On</StyledTableCell>
                <StyledTableCell align="left">Total</StyledTableCell>
                <StyledTableCell align="left">View Invoice</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetData?.length ? assetData?.map((row, i) => (
                <StyledTableRow className='TableRow' key={`${row.project_name}${i}`}>
                  <StyledTableCell component="th" scope="row" style={{ maxWidth: '40%' }}>{row.project_name}</StyledTableCell>
                  <StyledTableCell align="left">
                    <Chip
                      sx={{ marginTop: 2, marginBottom: 2, color: "#FFFFFF", bgcolor: "#398CD1", fontSize: "12px", borderRadius: "8px", }}
                      label={row.type}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.date}</StyledTableCell>
                  <StyledTableCell align="left">{row.date}</StyledTableCell>
                  <StyledTableCell align="left">{row.amount}</StyledTableCell>
                  <StyledTableCell align="left" className="sp-invoice-underline">{row.invoice}</StyledTableCell>
                </StyledTableRow>
              )) :
                <Typography className="dic-funded-no-project">No project found</Typography>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Wallet connect */}
      {
        walletDialogOpen ?
          <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={false} />
          : null
      }
    </Box>
  );
};

export default DashboardWallet;
