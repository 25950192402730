import React, { useMemo } from "react";
import { Box, Chip, Typography } from "@mui/material";
import "./RaceProjectCard.css";
import { LocalFireDepartment } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const RaceProjectCard = ({ project, focused, navigate }) => {
  const getFromAmount = useMemo(() => {
    if(project?.loan_amount){
      return project?.loan_amount;
    }
    if(project?.sale_price){
      return project?.sale_price;
    }
    if(project?.loan_amount){
      return project?.loan_amount;
    }
    return 0;
  },[project]);

  const navigateToRaceProject = () => {
    if(navigate !== false){
      window.open(`/project-details/${project?.id}`, '_blank', 'noopener,noreferrer');
    }
  }
  return (
    <Box className={`race-project-card-container ${ focused ? focused : ''}`}>
      <Box className="card-img-box cursor-pointer" >
        <Box className="img-div" onClick={()=> navigateToRaceProject()}>
          <img src={`${process.env.REACT_APP_IMAGE_URL}${project?.asset_gallery && project?.asset_gallery.length > 0 ? project?.asset_gallery[0]?.images?.path : ''}`} />
        </Box>
        <Box className="card-chip">
          <Chip icon={<LocalFireDepartment />} label={"Popular"} />
        </Box>
      </Box>
      <Box mt={3}>
        <Typography
          component={'h4'}
          className="subtitle"
        >
          {project?.asset_title}
        </Typography>
        <Typography className="font-12 text-blueGray-300">
          From ${getFromAmount}
        </Typography>
      </Box>
    </Box>
  );
};

export default RaceProjectCard;
