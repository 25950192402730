import React, { useRef, useState, useEffect, createRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Business, Payments, Soap, AttachMoney } from "@mui/icons-material";
import TokenIcon from '@mui/icons-material/Token';
import { Box, Card, Typography, Grid, Stack } from "@mui/material";
import { GetApi } from "../../Api/Api";
import CardSkeleton from "../../Components/Cards/CardSkeleton";
import Charts from "./Charts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Portfolio.css";
import PortfolioCard from "../../Components/Cards/PortfolioCard";
import { useAccount } from "wagmi";
import { spaceXFundTokenBalanceOf } from "../../Components/SmartContract/spaceXFundTokenBalanceOf";
import { viewListing } from "../../Components/SmartContract/viewListing"
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import { formatNumber } from "../../Components/Common/USFormat"
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

const Portfolio = () => {
  const navigate = useNavigate();

  const [elRefs, setElRefs] = React.useState([]);
  const searchBarRef = useRef(null); //search bar
  const sidebarOpen = useOutletContext();
  let userData = JSON.parse(localStorage.getItem("user_data"));
  const [assetData, setAssetData] = useState([]); //used to save original data from api
  const [filteredData, setFilteredData] = useState([]); //this is the data which is displayed and altered by filters
  const [filterValues, setFilterValues] = useState({
    category_id: 0,
    investment_type_id: 0,
    investment_val: null,
  });
  const [searchString, setSearchString] = useState("");
  const [totalInvestments, setTotalInvestment] = useState({});
  const [totalSpaceXToken, setTotalSpaceXToken] = useState(0);
  const [sortBy, setSortBy] = useState("Default");
  const [categoryData, setCategoryData] = useState();
  const [investmentTypeData, setInvestmenTypeData] = useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null); //sort
  const open = Boolean(anchorElSort); //sort
  const [loading, setLoading] = useState(false);
  const { address } = useAccount()

  useEffect(() => {

    async function getMyInvestments() {
      try {
        const res = await GetApi(
          `/user/getMyInvestment?user_id=${userData && userData?.id}`
        );
        if (res?.data?.data) {
          let myInvestmentData = res?.data?.data;
          setAssetData(myInvestmentData);
          setFilteredData(myInvestmentData);
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function getTotalInvestmentData() {
      try {
        setLoading(true);
        const res = await GetApi(
          `/user/getInvestmentData?user_id=${userData && userData?.id}`
        );
        if (res?.data?.data) {
          setLoading(false);
          let myInvestmentsData = res?.data?.data;
          setTotalInvestment(myInvestmentsData);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function getInvestMentTypeData() {
      try {
        const res = await GetApi("/proposer/investmentType/");
        setInvestmenTypeData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    let timer = setTimeout(() => {
      getMyInvestments();
      getCategoryData();
      getInvestMentTypeData();
      getTotalInvestmentData();
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [sidebarOpen]);

  /**
   * searches the String in [title] and sets the filtered data
   * @param {string} value  search string
   * @returns {void}
   */
  const suggestionSearch = async (value) => {
    setSearchString(value);

    //minimum 2 character to search resets the data to original if search string is less than 2
    if (value.length < 2) {
      const filterData = assetData.filter((item) => {
        if (
          filterValues.category_id > 0 &&
          filterValues.investment_type_id > 0
        ) {
          return (
            filterValues.category_id === item?.asset_data?.assets_category.id &&
            filterValues.investment_type_id ===
            item?.asset_data?.investment_type.id
          );
        } else if (filterValues.category_id > 0) {
          return (
            filterValues.category_id === item?.asset_data?.assets_category.id
          );
        } else if (filterValues.investment_type_id > 0) {
          return (
            filterValues.investment_type_id ===
            item?.asset_data?.investment_type.id
          );
        } else {
          return true;
        }
      });
      setFilteredData(filterData);
      return;
    } //minimum 2 character to search

    const dataFromSearch = assetData.filter((item) => {
      if (filterValues.category_id > 0 && filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id &&
          filterValues.investment_type_id ===
          item?.asset_data?.investment_type.id
        );
      } else if (filterValues.category_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.category_id === item?.asset_data?.assets_category.id
        );
      } else if (filterValues.investment_type_id > 0) {
        return (
          item?.asset_data?.asset_title
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          filterValues.investment_type_id ===
          item?.asset_data?.investment_type.id
        );
      } else {
        return item?.asset_data?.asset_title
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    });

    setFilteredData(dataFromSearch);
  };

  const handleChangeCategory = (event) => {
    setFilterValues({
      ...filterValues,
      category_id: event.target.value,
    });
  };

  const handleChangeInvestmentType = (event) => {
    setFilterValues({
      ...filterValues,
      investment_type_id: event.target.value,
    });
  };

  useEffect(() => {
    if (
      filterValues.category_id === 0 &&
      filterValues.investment_type_id === 0
    ) {
      setFilteredData(assetData);
      return;
    }

    const dataFromFilter = assetData.filter((item) => {
      return (
        (filterValues.category_id === 0 ||
          item?.asset_data?.assets_category?.id === filterValues.category_id) &&
        (filterValues.investment_type_id === 0 ||
          item?.asset_data?.investment_type?.id ===
          filterValues.investment_type_id)
      );
    });

    setFilteredData(dataFromFilter);
  }, [filterValues]);

  const handleClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElSort(null);
  };

  const handleSortChange = (sortBy) => {
    setSortBy(sortBy);
    if (assetData || assetData.length !== 0) {
      if (sortBy === "Name (A-Z)") {
        let filtered = [...assetData]?.sort((a, b) =>
          a?.asset_data?.asset_title?.localeCompare(b?.asset_data?.asset_title)
        );
        setFilteredData(filtered);
      } else if (sortBy === "Name (Z-A)") {
        let filtered = [...assetData]?.sort((a, b) =>
          b?.asset_data?.asset_title?.localeCompare(a?.asset_data?.asset_title)
        );
        setFilteredData(filtered);
      } else if (sortBy === "Default") {
        setFilteredData(assetData);
      }
    }
  };

  useEffect(() => {
    // add or remove refs
    if (assetData?.length) {
      setElRefs((elRefs) =>
        Array(assetData?.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef())
      );
    }
  }, [assetData]);


  const getSpaceXToken = async (address, data) => {
    const testData = data?.filter((item) => item?.asset_data?.asset_sub_category_info?.id === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && item?.asset_data?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS)

    const groupData = testData?.reduce((acc, item) => {
      const listingId = item?.asset_data?.listing_id;

      if (!listingId) return acc;

      // Check if the listing ID already exists in the accumulator
      if (!acc[listingId]) {
        acc[listingId] = [];
      }

      // Push the current item to the corresponding listing ID group
      acc[listingId].push(item);

      return acc;
    }, {})

    // Convert the object into an array of arrays
    const transactionsArray = Object.values(groupData);

    let totalToken = 0;
    for (let i = 0; i < transactionsArray.length; i++) {
      const data = transactionsArray[i];
      if (data?.length) {
        let newData = data[0]?.asset_data;
        let listingData = await viewListing(address, {
          listing_id: newData?.listing_id,
          category_id: newData?.assets_category?.id,
          asset_category_id: newData?.asset_sub_category_info?.id,
        })
        if (listingData) {
          let spaceXToken = await spaceXFundTokenBalanceOf(address, listingData?.fundType?.tokenAddress)
          if (spaceXToken) {
            totalToken += spaceXToken
          }
        }
      }
    }
    setTotalSpaceXToken(totalToken)
  }

  useEffect(() => {
    if (assetData && userData?.wallet_address)
      getSpaceXToken(userData?.wallet_address, assetData)
  }, [assetData, userData?.wallet_address])


  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box className="pf-page">
        {/*<Box className='pf-name-id'>
               <Typography className='pf-id'>Member ID: {userData?.member_id ? userData?.member_id : ''}</Typography>
               <Typography className='pf-name'>
                  Hi {userData?.first_name} 👋🏼
               </Typography>
            </Box>
            */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Box className="pfInfo">
              <Box className="pfInfo-flex">
                <Box className="pfIf">Information</Box>
                {/* <Box className='pfSa'>
                           See all
                        </Box> */}
              </Box>
              <Grid container spacing={1}>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Business />
                      </Box>
                    </Box>
                    <Box className="pfIf-card-title">
                      {totalInvestments?.totalOfferAmount?.length ? formatNumber(parseFloat(totalInvestments?.totalOfferAmount[0]?.total_project)) : 0}
                    </Box>
                    <Box className="pfIf-card-subtitle">Projects Invested</Box>
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Payments />
                      </Box>
                    </Box>
                    <Box className="pfIf-card-title">
                      ${totalInvestments?.totalOfferAmount?.length ? formatNumber(parseFloat(totalInvestments?.totalOfferAmount[0]?.total_offer_amount)) : 0}
                    </Box>
                    <Box className="pfIf-card-subtitle">Total Investments</Box>
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <Soap />
                      </Box>
                    </Box>
                    <Box className="pfIf-card-title">
                      ${totalInvestments?.totalLoanAmount ? formatNumber(parseFloat(totalInvestments?.totalLoanAmount)) : 0}
                    </Box>
                    <Box className="pfIf-card-subtitle">Loans Provided</Box>
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Box className="pfIf-card">
                    <Box className="pfIf-card-icon-box">
                      <Box className="pfIf-card-icon">
                        <AttachMoney />
                      </Box>
                    </Box>
                    <Box className="pfIf-card-title">
                      ${totalInvestments?.totalReceivedAmount ? formatNumber(parseFloat(totalInvestments?.totalReceivedAmount?.total_received_amount)) : 0}
                    </Box>
                    <Box className="pfIf-card-subtitle">Total Returns</Box>
                  </Box>
                </Grid>
                {totalSpaceXToken ?
                  <Grid item md={3} sm={6} xs={12}>
                    <Box className="pfIf-card">
                      <Box className="pfIf-card-icon-box">
                        <Box className="pfIf-card-icon">
                          <TokenIcon />
                        </Box>
                      </Box>
                      <Box className="pfIf-card-title">
                        {formatNumber(parseFloat(totalSpaceXToken))}
                      </Box>
                      <Box className="pfIf-card-subtitle">Space X Token</Box>
                    </Box>
                  </Grid>
                  : null
                }
              </Grid>
            </Box>
          </Grid>
          {/*  <Grid item xs={12} sm={12} md={4}>
                  <Box
                     sx={{
                        background: '#032744',
                        display: "block",
                        boxSizing: "border-box",
                        width: "100%",
                        height: "100%", // Set the height to 100%
                        maxHeight: '355px', // Set a maximum height if needed
                        borderRadius: '12px',
                        margin: "auto", // Center the chart within the Grid item
                        overflow: "hidden", // Adjust overflow as needed
                     }}
                  >
                     <Charts />
                  </Box>
               </Grid>
            */}
        </Grid>
        {/* <Box sx={{ marginTop: "35px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                sx={{
                  background: "#032744",
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%", // Set the height to 100%
                  maxHeight: "355px", // Set a maximum height if needed
                  borderRadius: "12px",
                  margin: "auto", // Center the chart within the Grid item
                  overflow: "hidden", // Adjust overflow as needed
                  padding: "18px",
                }}
              >
                <Charts />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Box sx={{ margin: "20px 0px" }}>
          <Card className="project-card">
            <Grid container></Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                useFlexGap
                flexWrap="wrap"
                width={"100%"}
                spacing={{ xs: 1.5 }}
              ></Stack>
            </Grid>
          </Card>
        </Box>
        {/* Search bar filter */}
        {/* <Box className="filters-wrap" mb={5.5}>
          <Grid spacing={1} container>
            <Grid item xs={12} sm={6} md={4}>
              <Box className="filterSearch-form">
                <Box component={"span"} className="search-icon">
                  <Search />
                </Box>
                <InputBase
                  ref={searchBarRef}
                  className="filterSearch"
                  placeholder="Search Investments"
                  value={searchString}
                  onChange={(e) => suggestionSearch(e.target.value)}
                  inputProps={{ "aria-label": "search transactions" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Box className="filterCategory-menu-wrap">
                <Box className="filters-group-wrap">
                  {/* Category filter */}
        {/*<Box className="select-group" ml={1.5}>
                    <FormControl
                      className="select-rounded"
                      sx={{ minWidth: "150px" }}
                      size="small"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Category
                        <ViewComfy />
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.category_id}
                        onChange={handleChangeCategory}
                        label="Category"
                        variant="outlined"
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {categoryData?.map((value, ind) => {
                          return (
                            <MenuItem key={ind} value={value.id}>
                              <ListItemText>{value.title}</ListItemText>
                              <Typography className="menu-icon">
                                <ImageListItem>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                    srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                    alt={value.category_icon?.original_name}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Investment Type Filter */}
        {/*<Box className="select-group" ml={1.5}>
                    <FormControl
                      className="select-rounded"
                      sx={{ minWidth: 160 }}
                      size="small"
                    >
                      <InputLabel id="demo-controlled-open-select-label">
                        Investment Type
                        <BarChart />
                      </InputLabel>

                      <Select
                        labelId="demo-controlled-open-select-label"
                        value={filterValues.investment_type_id}
                        label="Investment Type"
                        onChange={handleChangeInvestmentType}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        {investmentTypeData?.map((value, ind) => {
                          return (
                            <MenuItem key={ind} value={value.id}>
                              {value.investment_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Sorting  */}
        {/* <Box className="dropdown-menu-wrap" ml={1.5}>
                    <Button
                      endIcon={<SwapVert />}
                      // onClick={() => sortByName()}
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="btn-rounded btn-outline"
                    >
                      Sort By: {sortBy}
                    </Button>
                    <Menu
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorElSort}
                      open={open}
                      onClose={handleClose}
                      className="dropdown-menu filterCategory-menu"
                    >
                      {sortByOptions.map((value, ind) => {
                        return (
                          <MenuItem
                            key={ind}
                            selected={sortBy === value ? true : false}
                            onClick={() => {
                              handleSortChange(value);
                              setAnchorElSort(null); // done seperately to close the menu after click
                            }}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        <Grid container spacing={{ xs: 1, sm: 1, md: 2 }}>
          {!loading &&
            filteredData &&
            filteredData?.length > 0 &&
            elRefs?.length
            ? filteredData.map((asset, ind) => {
              const galleryimg = [];
              if (asset?.asset_data?.investment_type?.id === 3) {
                // Adding Cover Photo into slider for art parcel only
                if (asset?.asset_data?.asset_gallery) {
                  galleryimg.push(
                    `${process.env.REACT_APP_IMAGE_URL}${asset?.asset_data?.asset_gallery[0]?.images.path}`
                  );
                }

                // Inserting gellery Images Into Slider
                asset?.asset_data?.parcelData?.forEach((val, ind) => {
                  if (val.is_sold && val.investor_id === userData?.id) {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.imagess.path}`
                    );
                  }
                });
              } else {
                // Inserting gellery Images Into Slider
                asset?.asset_data?.asset_gallery.map((val, ind) => {
                  if (val.title === "gellary") {
                    galleryimg.push(
                      `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                    );
                  }
                });
              }

              let newParcelTitle = "";
              let matchedParcels = [];
              // Creating a new title for the art parcels
              if (asset?.asset_data?.investment_type?.id === 3) {
                if (asset?.asset_data?.parcelData?.length) {
                  let parcels = asset?.asset_data?.parcelData;
                  // let parcelIdsToMatch = asset?.parcel_ids;
                  matchedParcels = parcels.filter(
                    (item) =>
                      item.investor_id === userData?.id &&
                      item.is_sold === true
                  );
                  let newParcelIds = [];
                  if (matchedParcels?.length) {
                    matchedParcels?.forEach((parcel) => {
                      let parcelNo = parcel.title.split("#")[1];
                      newParcelIds.push(parcelNo);
                    });
                  }
                  // Using map to create an array of elements
                  const resultElements = newParcelIds.map((id) => `#${id}`);

                  // Using join to concatenate the array elements into a string
                  newParcelTitle = resultElements.join(", ");
                }
              }
              return (
                <Grid key={ind} item xs={12} sm={6} md={4} lg={4}>
                  <PortfolioCard
                    id={asset?.asset_data?.id}
                    title={
                      newParcelTitle
                        ? `Parcel ${newParcelTitle}`
                        : asset?.asset_data?.asset_title
                    }
                    subTitle={
                      newParcelTitle
                        ? asset?.asset_data?.asset_title
                        : asset?.asset_data?.asset_address
                    }
                    investType={
                      asset?.asset_data?.investment_type?.investment_name
                    }
                    investment_type_id={
                      asset?.asset_data?.investment_type?.id
                    }
                    isLoan={asset?.asset_data?.assets_category?.title}
                    imgSrc={galleryimg}
                    link={`/project-details/${asset?.asset_data?.id}`}
                    portfolio={true}
                    matchedParcels={matchedParcels}
                    assetData={asset?.asset_data}
                    ref={elRefs[ind]}
                    index={ind}
                    sidebarOpen={sidebarOpen}
                  />
                </Grid>
              );
            })
            : loading
              ? Array(3)
                .fill()
                .map((item, ind) => (
                  <Grid key={ind} item xs={12} sm={6} md={4} lg={4}>
                    <CardSkeleton />
                  </Grid>
                ))
              : assetData?.length === 0 && (
                <Typography className="title" component={"h4"} ml={3}>
                  No Projects Found
                </Typography>
              )}
        </Grid>
      </Box>

      {/* full page loader */}
      <CommonBackdropLoader loading={loading} />
    </Box>
  );
};

export default Portfolio;
