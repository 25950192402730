import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  Modal,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileViewer from "react-file-viewer";
import { deleteApi, GetApi } from "../../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ArrowDropDown } from "@mui/icons-material";

export default function Documents({
  documentsData,
  descriptionData,
  ownerId,
  assetId,
  editable,
  section_title,
}) {
  const [showMore, setShowMore] = useState(false);

  // Merge the two arrays
  let mergedArray = documentsData?.length ? documentsData : [];
  if (descriptionData?.length > 0) {
    const documentOnly = descriptionData.filter(
      (item) =>
        item?.images &&
        item?.type === "document" &&
        item?.images?.type !== "mp4"
    );
    if (documentOnly?.length > 0) {
      mergedArray = mergedArray.concat(documentOnly);
    }
  }

  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(mergedArray);

  /**
   * Function to view the PDF 
   * @param {*} documentdata 
   */
  const viewDoc = async (documentdata) => {
    setOpenModal(true);
    setView(true);
    if (documentdata?.images.path) {
      const fileName = await fetch(
        `${process.env.REACT_APP_IMAGE_URL}${documentdata.images.path ? documentdata.images.path : ""
        }`
      );
      const templateBuffer = await fileName.arrayBuffer();
      const mimeType = 'application/pdf'; // Since viewDoc is only for PDFs

      const templateBlob = new Blob([templateBuffer], { type: mimeType });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      setType(documentdata.images.type);
      setView(true);
      setOpenModal(true);
    }
  };

  /**
   * Function to download the document
   * @param {*} documentData 
   */
  const downloadDoc = async (documentData) => {
    setOpenModal(false);
    if (documentData?.images?.path) {
      const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentData.images.path}`);
      const templateBuffer = await fileName.arrayBuffer();

      const mimeType = documentData.images.type === "doc" ? 'application/msword'
        : documentData.images.type === "docx" ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          : documentData.images.type === "xls" ? 'application/vnd.ms-excel'
            : documentData.images.type === "xlsx" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              : '';

      const templateBlob = new Blob([templateBuffer], { type: mimeType });
      const templateUrl = URL.createObjectURL(templateBlob);

      const link = document.createElement('a');
      link.href = templateUrl;
      link.download = `document.${documentData.images.type}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  const handleClose = () => setOpenModal(false);

  const handleView = () => {
    setView(!view);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // handle show more button
  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <>
      <Box className="pd-sidebar-documents" mb={3}>
        <Stack
          className="pd-sidebar-heading"
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="title" component={"h4"}>
            {section_title}
          </Typography>
        </Stack>

        {/* Dynamically rendring documents with viewer */}

        {data && data.length > 0 ? (
          <>
            {data?.map((val, ind) => {
              if (ind < 5 || showMore) {
                return (
                  // <Card key={ind} className="pd-card">
                  <Card className="pd-action-card" key={ind}>
                    <CardHeader
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className="bg-blue-900"
                          variant="square"
                          sx={{
                            width: "48px",
                            height: "48px",
                            borderRadius: "12px",
                          }}
                        >
                          <AssignmentIcon />
                        </Avatar>
                      }
                      action={
                        <>
                          <Box className="btn-wrap">
                            <Stack
                              direction="row"
                              spacing={1.5}
                              alignItems={"center"}
                            >
                             {val?.images?.type === "pdf" ? (
                                  <IconButton
                                    aria-label="View"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => viewDoc(val)}
                                  >
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="Download"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => downloadDoc(val)}
                                  >
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                )}
                            </Stack>
                          </Box>
                        </>
                      }
                      onClick={() => {val?.images?.type === "pdf" ? viewDoc(val) : downloadDoc(val) }}
                      subheader={ val.type}
                      title={<Tooltip title={val.images.original_name}>
                      <span>{val.images.original_name}</span>
                    </Tooltip>}
                    />
                  </Card>
                );
              } else {
                return null;
              }
            })}

            {showMore === false && data?.length > 5 && (
              <Stack flexDirection={"row"} mt={2} justifyContent={"flex-end"}>
                <Stack flexDirection={"row"} alignItems="center">
                  <Typography>More</Typography>
                </Stack>
                <Box sx={{ cursor: "pointer" }} onClick={handleShowMore}>
                  <ArrowDropDown />
                </Box>
              </Stack>
            )}
          </>
        ) : (
          <Typography textAlign={"center"}>
            No documents availiable to view
          </Typography>
        )}
      </Box>

      <Grid>
        {/* {view &&
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent defer
                style={{ width: '100%', height: '100%' }}
              >
                <Box className="raj">
                  {<FileViewer fileType={type} filePath={file} />}
                </Box>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        } */}
        {view && type && file && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
              <Box sx={styleModal}>
              {type?.includes("pdf") ? (
                <object
                  data={file}
                  type="application/pdf"
                  style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    Your browser does not support viewing PDFs.{' '}
                    <a href={file} target="_blank" style={{ color: 'lightgreen' }}>
                      Download the PDF
                    </a>.
                  </p>
                </object>
              ) : type?.includes("wordprocessingml.document") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Word documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }))} download="document.docx" style={{ color: 'lightgreen' }}>
                    Download the Word document
                  </a>
                </div>
              ) : type?.includes("spreadsheetml.sheet") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Excel documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))} download="document.xlsx" style={{ color: 'lightgreen' }}>
                    Download the Excel document
                  </a>
                </div>
              ) : (
                <p>Unsupported file type.</p>
              )}
            </Box>
          </Modal>
        )}
      </Grid>
    </>
  );
}
