import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Typography, Button, Avatar, Tooltip } from "@mui/material";
import { KeyboardArrowRight, Cancel, Upload } from '@mui/icons-material';

// Import custom components for this page
import { CircleCheckIcon, NewLogo } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"

export default function UploadDocuments(props) {
  // Destructuring props to get functions and data required for the component
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, EditMode } = props;

  // Retrieve current proposal data and progress from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize hooks with default values for managing uploaded, deleted, and file documents
  const [uploadedImage, setUploadedImage] = useState(propData?.asset_document ? propData?.asset_document : []);
  const [deletedDoc, setDeletedDoc] = useState(propData?.asset_document_ids ? propData?.asset_document_ids : []);
  const [docFiles, setDocFiles] = useState(propData?.asset_document_file ? propData?.asset_document_file : []);

  /**
   * Function to handle uploaded documents. Filters only PDF files and updates the 'uploadedImage' state.
   * @param {Array} acceptedFiles - Array of accepted files.
   */
  // const onDrop = useCallback(acceptedFiles => {
  //   const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
  //   let dataDetail = [...uploadedImage, ...pdfFilesArray];
  //   setUploadedImage(dataDetail);
  // }, [uploadedImage]);


  const onDrop = useCallback(
    (acceptedFiles) => {

      // Filter files by type (PDF, Word, Excel)
      const supportedFilesArray = acceptedFiles.filter(
        (file) =>
          file.type === "application/pdf" ||
          file.type === "application/msword" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
  
      // Combine the newly uploaded files with the existing documents
      const updatedDocuments = [...uploadedImage, ...supportedFilesArray];
  
      // Update the state with the new set of documents
      setUploadedImage(updatedDocuments);
    },
    [uploadedImage]
  );
  

  // // useDropzone hook configuration to handle file drop
  // const { getRootProps, open, getInputProps } = useDropzone({
  //   noClick: true,
  //   noKeyboard: true,
  //   accept: {
  //     "application/pdf": [".pdf"],
  //   },
  //   onDrop
  // });

      /**
   * Uploading supported file types document using useDropzone
   */
      const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: {
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        },
        onDrop,
      });
  

  /**
  * Function to handle deleted documents. If in edit mode, updates the 'deletedDoc' state for existing documents, and updates 'docFiles'.
  * @param {Object} file - The file to be deleted.
  */
  const handleDeleteImage = (file) => {
    if (EditMode && file) {
      const newDocFile = docFiles.filter((docFile) => {
        if (docFile.images == file) {
          setDeletedDoc([...deletedDoc, docFile.id]);
        }
        return docFile.images !== file;
      });
      setDocFiles(newDocFile);
    }

    // Filter out the deleted image from the 'uploadedImage' state
    const newUploadedImages = uploadedImage.filter((image) => image.path !== file.path);
    setUploadedImage(newUploadedImages);
  };

  /**
   * Function to handle the "Next" button click. Updates 'propData' with the current state of uploaded and deleted documents, and proceeds to the next step.
   */
  const handleClick = () => {
    // Update 'propData' with the current state of uploaded and deleted documents
    propData.asset_document = uploadedImage;
    if (EditMode) {
      propData.asset_document_ids = deletedDoc;
    }

    // Update the main page and proceed to the next step
    updateProposalData(propData);
    handleNext();
  }

  /**
  * Function to handle the "Save and Exit" button click. Updates 'propData' with the current state of uploaded and deleted documents, and saves the proposal data.
  */
  const SaveAndExit = () => {
    // Update 'propData' with the current state of uploaded and deleted documents
    propData.asset_document = uploadedImage;
    if (EditMode) {
      propData.asset_document_ids = deletedDoc;
    }

    // Update the main page and save the proposal data
    updateProposalData(propData);
    saveProposal();
  }

  /**
  * Function to handle the "Back" button click. Updates 'propData' with the current state of uploaded documents and goes back to the previous step.
  */
  const handleBack = () => {
    // Update 'propData' with the current state of uploaded documents
    propData.asset_document = uploadedImage;

    // Update the main page and go back to the previous step
    updateProposalData(propData);
    handleback();
  }

  // preparing to show uploaded docs
  const files = uploadedImage?.map((file) => (
    <Box className="showUploadedDocuments">
      <Box key={file.path} className="showUploadedDocuments-wrap">
        <Box className="fileName">
          {file?.path?.includes("proposer/uploads/") || file?.path?.includes("https://race25-live-uploads.s3.eu-central-2.amazonaws.com/") || file?.path?.includes("https://race25-uploads-docs.s3.eu-central-2.amazonaws.com/") || file?.path?.includes('https://local-racetest-images.s3.eu-central-2.amazonaws.com/') ? file.original_name : file.name}
        </Box>
        <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
      </Box>
    </Box >
  ))

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Some documents to upload
                </Typography >
              </Box >
              {/* Logo */}
              <Box className="auth-logo" >
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box >
            </Box >
          </Grid >
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} disabled={(uploadedImage.length) ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box my={4} className="Upload-documents">
                  <Box className='upload-box' sx={{
                    background: "#F1F5F9",
                    borderRadius: "10px"
                  }}>
                    <Box  {...getRootProps({
                      className: "dropzone",
                    })}>
                      <input {...getInputProps()} />
                      <Box className='upload-box-box-ts'>
                        <Box sx={{ padding: '15px 0px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Upload className='upload-box-box-icon' />
                            </Box>
                            <Box className=''>
                              <Box className='upload-box-box-title'>Drag File here to upload</Box>
                              <Box className='upload-box-box-subtitle'>or click the button to browse</Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ marginRight: '10px' }}>
                          <Button className='btn-rounded btn-blueGray-200' onClick={open} startIcon={<Upload />}>
                            Browse Files
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box className='upload-box-box-fm'>
                      <Box className='upload-box-box-doc'>Upload Required Documents</Box>
                      {propData?.selectedCategory == 'funds' ?
                        <Box className='upload-box-box-type'>
                          Pitch Deck, PPM, Subscription Documents, Special purpose Vehicle (SPV) information
                        </Box> :
                        <Box className='upload-box-box-type'>
                          Financial Statement
                          Architecture Diagram
                          <br /><br />
                          You need to upload at least 1 document to submit the project. You will be contacted subsequently to furnish the remaining documents.
                        </Box>
                      }
                    </Box>
                    {files !== "" ? (
                      <Box
                        sx={{
                          paddingBottom: "16px",
                          overflow: "hidden",
                          margin: "10px 16px 10px 16px"
                        }}
                      >
                        {console.log("files...",files)}
                        {files?.map((fileName, ind) => {
                          return (<Box display={"flex"} alignItems={"center"} mt={1} key={ind}>
                            <Avatar
                              src={CircleCheckIcon}
                              alt="Icon"
                              sx={{ width: 24, height: 24 }}
                            />
                            <Box
                              className="dD-file-name text-black"
                              ml={1}
                            >
                              {fileName}
                            </Box>
                          </Box>
                          )
                        })
                        }
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box >
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleBack}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Tooltip title={(uploadedImage[0]) ? "" : "Please upload some documents to proceed."} className="p-flow-next-btn-tooltip">
                      <span>
                        <Button disabled={(uploadedImage.length) ? false : true} onClick={handleClick} endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box >
            </Box >
          </Grid >
        </Grid >
      </Box >
    </Box >
  );
}




