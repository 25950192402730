import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { GetApiParam } from "../../Api/Api";
import { PROJECT_STATUS } from "../../constants";
import Cards from "../../Components/Cards/Cards";
import "../Home/Home.css";

const ProjectList = () => {
    const location = useLocation();
    const state = location.state;

    // State variable for the dynamic inputs
    const [assetData, setAssetData] = useState();
    const [serverTime, setServerTime] = useState();
    const [serverDate, setServerDate] = useState();
    const [bookmark, setBookmark] = useState() // for Bookmark

    // fetching data from local
    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

    // useEffect to scroll to the top of the page
    useEffect(() => {
        scrollToTop();
    }, []);

    // useEffect for fetching bookmark data
    useEffect(() => {
        if (localData?.id) {
            // Api to get InvestmentTypeData
            async function getBookmarkData() {
                try {
                    const payloadData = {
                        "user_id": localData.id
                    }
                    const res = await GetApiParam("/proposer/getBookmark/", payloadData);
                    setBookmark(res?.data?.data ? res?.data?.data : [])
                    // setInvestmentTypeData(res?.data?.data ? res?.data?.data : []);

                } catch (error) {
                    console.log(error);
                }
            }
            const timer = setTimeout(() => {
                getBookmarkData()
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [])

    // useEffect for fetching asset data on component mount and when 'state' changes
    useEffect(() => {
        // useEffect for calling api's
        async function getAssetData() {
            try {
                const payload = state
                const res = await GetApiParam("/proposer/getAssetsList", payload)
                setAssetData(res.data?.data);
                setServerTime(res.data?.server_time);
                setServerDate(res.data?.server_date);
            } catch (error) {
                console.log(error);
            }
        }
        const timer = setTimeout(() => {
            getAssetData()
        }, 100);
        return () => clearTimeout(timer);
    }, [state])

    // useEffect to scroll to the top of the page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    // Function to check if an asset is bookmarked
    const bookmarkVal = (asset_id) => {
        return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);
    }

    return (
        <Box>
            {/*All projects Explore start */}
            <Box className="HomeExplore-section" sx={{ mb: 6, mt: 3 }}>
                <Box className="container">
                    <Box className="filter-panel">
                        <Box className="fp-left">
                            <Box className="heading-left">
                                <Typography component={"h2"} >All {state && state.status === PROJECT_STATUS.DRAFT ? 'Drafts' : "Submitted"} Projects</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="HomeExplore-wrap">
                        <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
                            {(() => {
                                const topAssets = [];
                                for (
                                    let i = 0;
                                    i < (assetData?.length >= 1 ? assetData.length : "");
                                    i++
                                ) {
                                    if (!assetData[i].other_category) {
                                        const galleryimg = [];
                                        // Adding Cover Photo into slider
                                        if (assetData[i]?.asset_coverphoto) {
                                            galleryimg.push(
                                                `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                                            );
                                        }
                                        // Inserting gellery Images Into Slider
                                        assetData[i]?.asset_gallery.map((val, ind) => {
                                            if (val.title === "gellary") {
                                                galleryimg.push(
                                                    `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                                                );
                                            }
                                        });
                                        topAssets.push(
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Cards
                                                    id={assetData[i]?.id}
                                                    title={assetData[i]?.asset_title}
                                                    subTitle={assetData[i]?.asset_address}
                                                    address={assetData[i]?.owner_information?.owner_address}
                                                    investType={
                                                        assetData[i]?.investment_type?.investment_name
                                                    }
                                                    isLoan={assetData[i]?.assets_category?.title}
                                                    isDraft={state && state.status === PROJECT_STATUS.DRAFT ? true : false}
                                                    isClock={state && state.status === PROJECT_STATUS.DRAFT ? false : true}
                                                    isPending={
                                                        assetData[i]?.status === "Pending" || assetData[i]?.status ===
                                                            "inreview" ? true : false
                                                    }
                                                    isDelete={
                                                        state && state.status === PROJECT_STATUS.DRAFT ? false : assetData[i]?.status === "Rejected" ? true : false
                                                    }
                                                    isSaved={false}
                                                    imgSrc={galleryimg}
                                                    isSaveDeal={false}
                                                    server_date={serverDate}
                                                    server_time={serverTime}
                                                    link={`/project-details/${assetData[i]?.id}`}
                                                    ownerName={assetData[i]?.user_details?.first_name}
                                                    projectProgress={state && state?.status === PROJECT_STATUS.DRAFT && assetData[i]?.project_process}
                                                    bookMarkStatus={state && state?.status === PROJECT_STATUS.SUBMITTED && bookmarkVal(assetData[i]?.id)}
                                                />
                                            </Grid>
                                        );
                                    }
                                }
                                return topAssets;
                            })()}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default ProjectList;
