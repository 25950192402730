// cancelOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Call cancelOffer method for investor to cancel the offer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function cancelOffer(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // cancel Offer by investor

        let cancelOfferArgs = {};
        if (assetData?.offerDetails?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            cancelOfferArgs = {
                address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        }
        else if (assetData?.offerDetails?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            cancelOfferArgs = {
                address: CONTRACTS.ART_SALE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
            cancelOfferArgs = {
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
            cancelOfferArgs = {
                address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        }
        // cancel offer by investor
        const { request: cancelOffer } = await prepareWriteContract(cancelOfferArgs);

        const { hash } = await writeContract(cancelOffer);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling cancel offer:', error);
        throw error; // propagate the error
    }
}

export { cancelOffer };