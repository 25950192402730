import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Button, Chip, Grid, Tab, Tabs, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import "./ServiceProviderDashboard.css";

import Icon2 from "../../Assets/Images/service-provider/Icon2.png";
import Icon3 from "../../Assets/Images/service-provider/Icon3.png";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import Bookmarks from "@mui/icons-material/Bookmarks";
import { GetApiParam, postApi } from "../../Api/Api";
import { Delete } from "../../Components/Images/Images";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import {
  calculateNewSinceCreation,
  calculateDaysSinceCreation,
} from "../../Utils/utils";

const ServiceProviderOverview = () => {
  const [value, setValue] = React.useState(0);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [showViewMore, setShowViewMore] = useState([]);
  const navigate = useNavigate();

  const descriptionRefs = useRef([]);

  // fetching data from local
  const localData = getUserDataFromLocalStorage();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getProjects = useCallback(async () => {
    const payload = {
      status: 1,
      user_id: localData.id,
    };
    try {
      const res = await GetApiParam("/services/getSimilarProjects", payload);
      setServiceProvider(res?.data?.data?.length ? res.data.data : []);
    } catch (error) {
      console.log(error);
    }
  }, [localData.id]);

  useEffect(() => {
    // Api to get projectData
    getProjects();
  }, []);

  useEffect(() => {
    if (serviceProvider.length > 0) {
      const newExpandedStates = serviceProvider.map(() => false);
      const newShowViewMore = serviceProvider.map(() => false);
      setExpandedStates(newExpandedStates);
      setShowViewMore(newShowViewMore);
    }
  }, [serviceProvider]);

  const handleViewMore = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
    setShowViewMore((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
  };

  const handleViewLess = (ind) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = false;
      return newStates;
    });
    setShowViewMore((prevStates) => {
      const newStates = [...prevStates];
      newStates[ind] = true;
      return newStates;
    });
  };

  const handleResize = (ind) => {
    const maxHeight = descriptionRefs.current[ind]?.clientHeight;
    if (maxHeight > 80) {
      setShowViewMore((prevStates) => {
        const newStates = [...prevStates];
        newStates[ind] = true;
        return newStates;
      });
    }
  };

  const handleSeeMore = (details) => {
    navigate(`/service-provider/view-single-request/${details?.id}`);
  };

  const handleClick = () => {
    navigate("/service-provider-dashboard/listservice");
  };

  const handleClickBookmark = async (item) => {
    const payloadData = {
      project_id: item.id,
      user_id: localData.id,
      is_bookmark: item.is_bookmark ? 0 : 1,
    };

    if (localData.id) {
      const bookmarkResponce = await postApi(
        "/services/isBookmark",
        payloadData
      );
      if (bookmarkResponce?.data?.code) {
        getProjects();
      }
    }
  };

  return (
    <Box className="service-provider-dashboard-overview">
      <Box className="dashboard-overview-right-topview">
        <Box className="dashboard-overview-topview-left">
          <Box className="dob-overview-title-left">
            <Typography className="font-24" mt={0.6}>
              Your Workspace
            </Typography>
          </Box>
          <Box className="dob-tabs-main">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="View your services" {...a11yProps(0)} />
                  <Tab 
                  onClick={() => navigate('/service-provider-dashboard/my-jobs')}
                  label="All contracts" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="dashboard-overview-topview-right">
          <Box className="post-project-btn">
            <Button
              className="btn"
              startIcon={<AddIcon />}
              onClick={() => handleClick()}
            >
              <span className="btn-span">List a service</span>
            </Button>
          </Box>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box className="dasboard-overview-card-main">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={12}>
              <Box className="dashboard-overview-card">
                <Box className="dob-top">
                  <Box className="dob-left">
                    <Typography component="h5" className="title">
                      Service Title
                    </Typography>
                    <Typography component="p" className="sub-title">
                      Service description
                    </Typography>
                  </Box>
                  <Box className="dop-right">
                    <img src={Delete} alt="" />
                  </Box>
                </Box>
                <Box className="draft-project-btn">
                  <Button variant="contained">Draft service listing</Button>
                </Box>
                <Box className="card-discription">
                  <Typography component="p" className="text">
                    Add details to your draft
                  </Typography>
                </Box>
                <Box className="fill-draft-btn">
                  <Button variant="outlined">
                    <span>Fill in draft</span>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <Box className="dashboard-overview-card">
                <Box className="dop-card2">
                  <Typography component="p" className="title">
                    Required to hire
                  </Typography>
                  <Typography component="h3" className="sub-title">
                    <span>Connect a wallet.</span> There’s <br />
                    no cost until you hire.
                  </Typography>
                </Box>

                <Box className="fill-draft-btn connect-wallet">
                  <Button variant="outlined">Connect Wallet</Button>
                </Box>
                <Box className="account-bal-icon">
                  <img src={Icon2} className="icon" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <Box className="dashboard-overview-card">
                <Box className="dop-card2">
                  <Typography component="p" className="title">
                    Required to hire
                  </Typography>
                  <Typography component="h3" className="sub-title">
                    <span>Verify your email address.</span>
                  </Typography>
                </Box>

                <Box className="fill-draft-btn verify-now">
                  <Button variant="outlined">Verified</Button>
                </Box>
                <Box className="account-bal-icon">
                  <img src={Icon3} className="icon" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel> */}
      <Box className="find-service-container">
        <Box className="find-service-title">
          <Typography component="h4" className="title font-22">
            Latest Requests related to your expertise
          </Typography>
        </Box>
        {/* Browse Services by Category */}
        <Box className="categoryCard" py={3}>
          {/* Explore similer project section */}
          <Box className="explore-job-container" mt={3}>
            <Box mt={3}>
              <Grid container spacing={2}>
                {serviceProvider &&
                  serviceProvider?.slice(0, 2)?.map((serviceVal, ind) => {
                    return (
                      <Grid item key={ind} xs={12} md={6} sm={12}>
                        <Box className="card-box">
                          <Box className="top-header">
                            <Box className="top-left">
                              <Box className="pc-chip-wrep-browse">
                                {calculateNewSinceCreation(
                                  serviceVal?.createdAt
                                ) === "new" ? (
                                  <Chip className="pc-chip" label={"New"} />
                                ) : (
                                  ""
                                )}
                              </Box>
                              <Box className="top-sub-left">
                                <Typography
                                  component="h4"
                                  className="font-16 title"
                                >
                                  {serviceVal.project_title}
                                </Typography>
                                <Typography
                                  component="p"
                                  className="font-12 sub-title"
                                >
                                  {serviceVal?.budget === "Project Budget"
                                    ? "Fixed-price"
                                    : serviceVal?.budget}{" "}
                                  | Posted{" "}
                                  {calculateDaysSinceCreation(
                                    serviceVal?.createdAt
                                  )}{" "}
                                  ago
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="top-right cursor-pointer">
                              {serviceVal?.is_bookmark ? (
                                <Bookmarks
                                  onClick={() =>
                                    handleClickBookmark(serviceVal)
                                  }
                                  className="bookmark-icon"
                                />
                              ) : (
                                <BookmarkBorderIcon
                                  onClick={() =>
                                    handleClickBookmark(serviceVal)
                                  }
                                  className="bookmark-icon"
                                />
                              )}
                            </Box>
                          </Box>
                          <Box className="card-body">
                            <Box className="top-view">
                              <Box className="left-section">
                                <Typography className="title font-12">
                                  {serviceVal?.budget === "Project Budget"
                                    ? "Fixed-price"
                                    : serviceVal?.budget}
                                </Typography>
                                <Typography
                                  component="p"
                                  className="sub-title font-16"
                                >
                                  ${serviceVal?.budget_from} - $
                                  {serviceVal?.budget_to}
                                </Typography>
                              </Box>
                              <Box className="right-section">
                                <Typography
                                  component="h4"
                                  className="title font-12"
                                >
                                  Experience Level
                                </Typography>
                                <Typography
                                  component="p"
                                  className="sub-title font-16"
                                >
                                  {serviceVal?.level_of_experience}
                                </Typography>
                              </Box>
                            </Box>

                            <Box className="card-body-discription">
                              <Box
                                className="hidden-div"
                                ref={(el) => {
                                  descriptionRefs.current[ind] = el;
                                }}
                                onResize={() => handleResize(ind)}
                              ></Box>
                              {expandedStates[ind] ? (
                                <>
                                  <Typography
                                    component="p"
                                    className="title font-14"
                                  >
                                    {serviceVal?.describe_services}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    component="p"
                                    className="title font-14"
                                    sx={{
                                      mt: "12px",
                                      color: "#FFFFFF",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical",
                                    }}
                                  >
                                    {serviceVal?.describe_services}
                                  </Typography>
                                  {showViewMore[ind] && (
                                    <Link onClick={() => handleViewMore(ind)}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#398CD1",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          textDecoration: "underline",
                                          textDecorationColor: "#398CD1",
                                        }}
                                        mt={1}
                                      >
                                        View More
                                      </Typography>
                                    </Link>
                                  )}
                                </>
                              )}
                            </Box>
                            <Box className="button-list">
                              {serviceVal?.skillData?.map((skillVal, index) => (
                                <Button key={index}>
                                  {skillVal.skill_name}
                                </Button>
                              ))}
                            </Box>
                            <Box className="see-more-btn">
                              <Link
                                to={`/service-provider/view-single-request/${serviceVal?.id}`}
                              >
                                <Button
                                  variant="contained"
                                  startIcon={<AddIcon />}
                                >
                                  See more
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box textAlign={"end"}>
          <Box className="post-project-btn">
            <Button
              onClick={() => navigate("/service-provider/browser-request")}
              className="btn"
              startIcon={<VisibilityIcon />}
            >
              <span className="btn-span">View All Open Requests</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceProviderOverview;
