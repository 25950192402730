import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid, Paper, Modal, IconButton,
  Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack
} from "@mui/material";
import { useSnackbar } from "../../../../Contexts/SnackbarContext";
import { GetApi, updateApi, postApi } from "../../../../Api/Api";
import { useParams, Link, useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import MessageIcon from '@mui/icons-material/Message';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import audiList from "../../../../Assets/Images/audi-img.png";
import "./ViewOfferContract.css";
import config from '../../../../Config/Config';
import ConfirmModal from "../../../../Components/Modal/Confirm/Confirm";
import { formatDate, ccyFormat } from "../../../../Utils/utils";
import { getUserDataFromLocalStorage } from "../../../../Services/localstorage.service";
import ContactSupportModal from "../../../../ServiceProvider/ServiceProviderDashBoard/MyJobs/ContactSupportModal/ContactSupportModal";
import ModifyContractOfferModal from "../ModifyContractOfferModal/ModifyContractOfferModal";

const ViewOfferContract = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { contractId } = useParams();
  const [ contractData, setContractData ] = useState({});
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModifyOfferModal, setOpenModifyOfferModal ] = useState(false);
  const [ milestoneData, setMilestoneData ] = useState([]);
  const [isOpenContactSupport, setIsOpenContactSupport] = useState(false);
  const localData = getUserDataFromLocalStorage();

   // Contact Support Modal
   const handleContactSupport = () => {
    handleOpenContactModal()
  }

  /**
    * Function to open Contact Support Modal
    */
  const handleOpenContactModal = () => {
    setIsOpenContactSupport(true);
  };

  /**
 * Function to close Contact Support Modal
 */
  const handleCloseContactModal = () => {
    setIsOpenContactSupport(false);
  };

  const fetchData = useCallback(()=>{
    const url = `/services/getContract?id=${contractId}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setContractData(res.data.data[0]);
          if(res.data.data[0]?.milestoneData?.length > 0)setMilestoneData(res.data.data[0]?.milestoneData);
          else setMilestoneData([]);
        }
      }
    })
  }, [contractId]);

  useEffect(()=> {
    fetchData();
  }, [fetchData]);

  const handleAction = (action) => {
    let status = '';
    if(action === 'confirm'){
      status = 3;// accepted by client
    }else{
      status = 7;// Cancelled
    }
    updateApi(`/services/contractStatusUpdate/${contractId}`, { status, client_id: contractData.client_id }).then((res) => {
      if (res?.status === 200) {
        if(res.data.code === 200){
          showSnackbar(res?.data?.message, 'success');
          setOpenModal(false);
          if(action === 'confirm')navigate(`/service-proposer-dashboard/myorder`);
          else navigate(`/service-proposer-dashboard/myorder?tab=4`);
        }else{
          showSnackbar(res?.data?.message, 'error');
        }
      }
    })
  }

  const handleActionModifyOffer = (action) => {
    let status = 0;
    if(action === 'confirm'){
      status = 1;
    }
    const payload = {
      status,
      id: contractData?.modifyOfferReqData?.id, 
      requested_amount:  contractData?.modifyOfferReqData?.requested_amount,
      milestoneData
    }
    console.log("payload---",payload);
    updateApi(`/services/accRejRequestForContractOfferAmount/${contractId}`, payload).then((res) => {
      if (res?.status === 200) {
        if(res.data.code === 200){
          showSnackbar(res?.data?.message, 'success');
          setOpenModifyOfferModal(false);
        }else{
          showSnackbar(res?.data?.message, 'error');
        }
      }
      fetchData();
    })
  }

  const handleChat = async () => {
    try {
        const payload = {
            userID: localData?.id,
            toUserID: contractData?.spv_id,
            contract_id: contractId,
            chatName: contractData?.title
        }
        const res = await postApi(`/chat/spAddChat`, payload);
        if(res?.data && res?.data?.data){
            navigate("/service-proposer-dashboard/chats", {
                state: {
                    user_id: contractData?.spv_id,
                    chat_id: res?.data?.data?.id
                },
            });
        }
    } catch (error) {
        console.log("dddd", error);
    }
  };

  return (
    <>
      <Box className="offer-view-contract">
        <Box className="individual-job-activity-container">
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={8} sm={12} pr={2}>
                <Box className="post-project-heading">
                  <Typography component="h4" className="title">
                    View Offer
                  </Typography>
                </Box>
                <Box className="post-project-container">
                  <Box className="card-container" >
                    <Grid container spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Status</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{config.CONTRACT_STATUS[contractData.status]}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Offer Date</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{contractData?.createdAt ? formatDate(contractData?.createdAt, 'MM/dd/yy - hh:mm a') : 'NA'}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Service / From</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{contractData?.serviceProviderData?.first_name} {contractData?.serviceProviderData?.last_name}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Button className="btn-rounded btn-grey"> Contract ID: {contractData?.contract_id}</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  </Box>
                  <Box className="details-job-container">
                    <Box className="details-job-main">
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6} sm={6}>
                          <Box className="left-section">
                            <Typography className="font-16">
                              Auditing For {contractData?.title}
                            </Typography>
                            <Typography className="font-12 text-blueGray-300">
                              Service From <span className="usman-text">{contractData?.serviceProviderData?.first_name} {contractData?.serviceProviderData?.last_name}</span> |
                              Delivery date{" "}
                              <span className="date-time-text">{contractData?.delivery_date ? formatDate(contractData?.delivery_date, 'MMM d, yyyy') : 'NA'}</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                          <Box className="right-section">
                            <Typography className="font-14 text-blueGray-400">
                              TOTAL PRICE
                            </Typography>
                            <Typography className="text-white font-22">
                              $ {contractData?.total_fees}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Typography className="font-12 text-blueGray-300">
                          {contractData?.describe_project}
                        </Typography>
                      </Box>
                      <Box className="table-view-container">
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6} sm={6}>
                            <Box className="your-order-div">
                              <Typography className="text-white font-16" ml={2}>
                                Client Messages
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={6} sm={6} sx={{textAlign: "end"}}>
                            <Link className="text-golden font-12 text-underline view-invoice-text">
                              Learn more about contracts and offers
                            </Link>
                          </Grid>
                        </Grid>
              
                        <Box mt={1} className="item-table-main">
                          <TableContainer sx={{overflowY: "scroll", width:"100%",height:"190px"}} >
                            <Table aria-label="spanning table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>{contractData?.client_message}</TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                      {contractData?.milestoneData?.length > 0 &&
                      <Box mt={2} className="table-view-container">
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6} sm={6}>
                            <Box className="your-order-div">
                              <Typography className="text-white font-16">
                                Milestone Details
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={6} md={6} sm={6} sx={{textAlign: "end"}}>
                            <Link className="text-golden font-12 text-underline view-invoice-text">
                              Learn more about contracts and offers
                            </Link>
                          </Grid> */}
                        </Grid>
                        <Box mt={1} className="item-table-main">
                          <TableContainer component={Paper}>
                            <Table sx={{width:'100%' }} aria-label="spanning table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>ITEM</TableCell>
                                  <TableCell align="center">Due Date</TableCell>
                                  <TableCell align="right">PRICE</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {contractData?.milestoneData?.length > 0 && contractData?.milestoneData.map((i) =>
                                  <TableRow>
                                    <TableCell>{i?.description}</TableCell>
                                    <TableCell align="center">{i?.dueDate ? formatDate(i?.dueDate, 'MMM d, yyyy') : 'NA'}</TableCell>
                                    <TableCell align="right">
                                      {/* project fee (project fee)*/}
                                      ${ccyFormat(i?.Amount)}
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow className="sub-total-div">
                                  <TableCell>SUBTOTAL</TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="right">
                                    ${ccyFormat(contractData?.project_fees)}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="sub-total-div">
                                  <TableCell>SERVICE FEE</TableCell>
                                  <TableCell align="right"></TableCell>
                                  <TableCell align="right">
                                    ${ccyFormat(contractData?.service_fees ?? 0)}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="sub-total-div">
                                  <TableCell className="text-white">
                                    TOTAL 
                                    <Typography className="text-blueGray-300 font-12">(You will pay)</Typography>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="right" className="text-white">
                                    ${ccyFormat(Number(contractData?.project_fees ?? 0) + Number(contractData?.service_fees ?? 0))}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>}
                      <Box>
                          <Typography className="text-blueGray-400 font-12 footer-text" textAlign={'center'} pt={2}>
                            If something appears to be missing or incorrect, please contact
                            our <spap className="text-underline">Customer Support Specialists</spap>.
                          </Typography>
                        </Box>
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} md={4} sm={12}>
                <Box className="activity-right-section">
                  <Box>
                    <Typography className="font-16">Job Details</Typography>
                  </Box>
                  <Box className="job-offer-details-box">
                    <Box>
                      <img src={audiList} />
                    </Box>
                    <Box className="right-section">
                      <Typography className="font-16">
                        Auditing For {contractData?.projectDetails?.project_title ? contractData?.projectDetails?.project_title : contractData?.title}
                      </Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {config.CONTRACT_STATUS[contractData.status]}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="service-box">
                    <Box className="service-container">
                      <Typography className="font-12">Service From</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.serviceProviderData?.first_name} {contractData?.serviceProviderData?.last_name}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Delivery Date</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.delivery_date ? formatDate(contractData?.delivery_date, 'MMM d, yyyy') : 'NA'}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Total Price</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        $ {contractData?.total_fees}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Job Category</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.projectDetails?.categoryDetails?.title ? contractData?.projectDetails?.categoryDetails?.title : ''}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Project Term</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.project_term === 'milestone' && 'Milestone'}
                        {contractData?.project_term === 'project' && 'Project'}
                        {contractData?.project_term === 'hourly' && 'Hourly'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="action-div">
                    <Typography className="font-16">Actions</Typography>
                    <Box>
                      <ErrorIcon />
                    </Box>
                  </Box>
                  {contractData?.status === 1 && (
                    <Box className="deliver-order-box">
                      <Typography className="font-14">Pending</Typography>
                      <CheckCircleIcon />
                    </Box>
                  )}
                  {(contractData?.status === 1 && contractData?.modifyOfferReqData?.requested_amount) && (
                    <Box className="deliver-order-box cursor-pointer" onClick={()=> {
                      setOpenModifyOfferModal(true);
                    }}>
                      <Typography className="font-14">Confirm Modify Request</Typography>
                      <CheckCircleIcon />
                    </Box>
                  )}
                  {contractData?.status === 2 && (
                    <Box className="deliver-order-box" onClick={()=> setOpenModal(true)}>
                      <Typography className="font-14">Confirm Order</Typography>
                      <CheckCircleIcon />
                    </Box>
                  )}
                  <Box className="upload-files-box cursor-pointer" onClick={()=>handleChat()} >
                    <Typography className="font-14">Messages</Typography>
                    <MessageIcon />
                  </Box>
                  {/* <Box className="upload-files-box">
                    <Typography className="font-14">Modify Offer</Typography>
                    <EditIcon />
                  </Box> */}

                  <Box className="action-div">
                    <Typography className="font-16">Support</Typography>
                  </Box>
                  <Box className="faq-support-box">
                    <Stack direction={'row'}>
                      <ContactSupportIcon />&nbsp;&nbsp;&nbsp;
                      <Stack direction={'row'}>
                      <Box>
                        <Typography className="font-14">FAQ</Typography>
                        <Typography className="font-12">Have a Question?</Typography>
                      </Box>
                      </Stack>
                    </Stack>
                    <ChevronRightIcon />
                  </Box>
                  <Box className="upload-files-box" onClick={handleContactSupport}>
                    <Stack direction={'row'}> 
                      <SupportAgentIcon />&nbsp;&nbsp;&nbsp;
                      <Box >
                        <Typography className="font-14">Contact Support</Typography>
                        <Typography className="font-12">Resolve Order issues?</Typography>
                      </Box>
                    </Stack>
                    <ChevronRightIcon />
                  </Box>
                  {isOpenContactSupport && <ContactSupportModal onOpen={isOpenContactSupport} handleClose={handleCloseContactModal} contractId={contractId} />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ConfirmModal title={'Confirm Order'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction}/>
      <ModifyContractOfferModal 
        isOpen={openModifyOfferModal} 
        setIsOpenModal={setOpenModifyOfferModal} 
        requested_amount={contractData?.modifyOfferReqData?.requested_amount} 
        milestoneData={milestoneData}
        setMilestoneData={setMilestoneData}
        handleAction={handleActionModifyOffer}
      />
      {/* <ConfirmModal title={'Confirm Request'} description={`Request Of Modify Offer Amount ($${contractData?.modifyOfferReqData?.requested_amount}), Do you want to confitm IT.`} isOpen={openModifyOfferModal} setIsOpenModal={setOpenModifyOfferModal} handleAction={handleActionModifyOffer}/> */}
    </>
  );
};

export default ViewOfferContract;