import React, { useEffect, useState } from 'react'
import "./ServiceProviderSearch.css"
import { Box, IconButton, Stack, TextField, Typography, styled, Badge, Menu, MenuItem, Tab, Tabs } from '@mui/material'
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom';

/**
 * Style for the Search text field
 */
const SearchTextField = styled(TextField)({
    width: '662px',
    height: '48px',
    '& .MuiOutlinedInput-root': {
        '& input': {
            padding: '12px 24px',
        },
        '& fieldset': {
            borderRadius: '48px',
        },

    },
});

const SearchButton = styled(IconButton)({
    '& .MuiIconButton-root': {
        padding: '10px',
        backgroundColor: '#398CD1',
        borderRadius: '48px',
        '& .MuiSvgIcon-root': {
            color: '#ffffff',
            height: '12px',
            width: '12px',
        }
    },

    // disable hover
    '&:hover': {
        backgroundColor: '#398CD1',
        '& .MuiSvgIcon-root': {
            color: '#ffffff',
        }
    }


});

function ServiceProviderSearch({ page, showSearchBar, handleSearchResults, pageTitle, categoryData, footer, fetchProjectAccordingToCategory }) {
    const location = useLocation();
    const [inputText, setInputText] = useState('')

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    useEffect(() => {
        if (location.pathname) {
            setAnchorEl(null)
        }
    }, [location.pathname])

    const handleClick = (event) => {
        if (Boolean(anchorEl)) {
            setAnchorEl(null)
        }
        else { setAnchorEl(event.currentTarget); }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLinkClick = (data) => {
        fetchProjectAccordingToCategory(data);
    }

    return (
        <Box className="service-search-wrap" >
            <Stack direction="row" spacing={2}>
                {pageTitle && <Box className="service-search-item" style={{ borderRight: "3px solid #000" }}>
                    <Typography variant="subtitle2" className='tab-title'>
                        {pageTitle === "Service Catalog" ? <Link to={"/service-proposer/service-catalog"}>
                            { pageTitle }
                        </Link>
                            : { pageTitle }
                        }
                    </Typography>
                </Box>
                }
                {categoryData?.slice(0, 4).map((category, index) => (
                    <Box key={index} className="service-search-item">
                        <Box onClick={() => handleLinkClick(category)} sx={{cursor: 'pointer'}}>
                            {page === 'findWork' ? 
                                <Badge badgeContent={category?.projectsCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            : null}
                            {page === 'categoryServices' ? 
                                <Badge badgeContent={category?.servicesCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            : null}
                            {page === 'findServiceProvider' ? 
                                <Badge badgeContent={category?.serviceProviderCount} >
                                    <Typography variant="subtitle2" className="tab-title">
                                        {category.title === 'Technology Developers & Software Services' ? 'Development & IT' : category.title}
                                    </Typography>
                                </Badge>
                            : null}
                        </Box>
                    </Box>
                ))}
                {categoryData?.length > 4 &&
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        id="fade-button"
                        aria-controls={open ? "fade-menu-item" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <Typography sx={{cursor: 'pointer'}}>
                            More
                        </Typography>
                        <KeyboardArrowDown className="icon" sx={{cursor: 'pointer'}}/>
                        <Menu
                            id="fade-menu-item"
                            MenuListProps={{
                                "aria-labelledby": "fade-button",
                            }}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            className="dropdown-menu InvesterHeader-dropdown"
                            mt={8}
                        >
                            {categoryData?.slice(4).map((item, index) => (
                                <Box onClick={() => handleLinkClick(item)}>
                                    <MenuItem key={index} onClick={handleClose}>
                                        <Box className='service-search-item'>
                                            {page === 'findWork' && 
                                                <Badge badgeContent={item?.projectsCount} >
                                                    <Typography className='text-title'> {item.title}</Typography>
                                                </Badge>
                                            }
                                            {page === 'categoryServices' && 
                                                <Badge badgeContent={item?.servicesCount} >
                                                    <Typography className='text-title'> {item.title}</Typography>
                                                </Badge>
                                            }
                                            {page === 'findServiceProvider' && 
                                                <Badge badgeContent={item?.serviceProviderCount} >
                                                    <Typography className='text-title'> {item.title}</Typography>
                                                </Badge>
                                            }
                                        </Box>
                                    </MenuItem>
                                </Box>
                            ))}
                        </Menu>
                    </Box>
                }
            </Stack>
           
            {
                showSearchBar &&
                <Box className={(pageTitle === 'Service Catalog') ? "catalog-search" : pageTitle === 'client-category-results' ? "client-category-results" : "service-search-item"} mt={3}>
                    <SearchTextField variant='outlined' placeholder="Search Services" onChange={(e) => setInputText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => handleSearchResults(inputText)} >
                                    <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                                </SearchButton>
                            ),
                        }}
                    />
                </Box>
            }
        </Box>
    )
}

export default  ServiceProviderSearch

//props types default and declaration
ServiceProviderSearch.defaultProps = {
    showSearchBar: false,
}

ServiceProviderSearch.propTypes = {
    showSearchBar: PropTypes.bool,
    pageTitle: PropTypes.any, // Indicates that pageTitle is optional and can be any type
}
