import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  IconButton,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from "@mui/icons-material/Delete";
import AddRequirementPopup from "./AddRequirementPopup/AddRequirementPopup";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FileViewer from "react-file-viewer";

const Requirements = ({ onDataUpdate, onNext, onBack, stepThreePreviousData }) => {
  const [formData, setFormData] = useState({ requirementDocument: '' });
  const [validationErrors, setValidationErrors] = useState({});
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [requirementDetails, setRequirementDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [view, setView] = useState(false);
  const [file, setFile] = useState();
  const [type, setType] = useState("");
  const [textCount, setTextCount] = useState(5000);

  /**
* Function to open Add GalleryModal
*/
  const handleOpenPopupRequirement = () => {
    setIsPopupOpen(true);
  };

  /**
 * Function to close Add Gallery Modal
 */
  const handleClosePopupRequirement = () => {
    setIsPopupOpen(false);
  };

  const handleView = () => {
    setView(!view);
  };

  const handleClose = () => setOpenModal(false);

  const handleDelete = (val) => {
    if (val) {
      const updatedMediaFiles = requirementDetails.filter(file => file.id !== val.id);
      setRequirementDetails(updatedMediaFiles);
    }
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const handleSaveRequirementDetail = (newDetails) => {
    // Add the new details to the existing list
    setRequirementDetails([...requirementDetails, newDetails?.requirement_image]);
  };

  useEffect(() => {
    if (stepThreePreviousData?.requirementDocument) {
      setFormData({
        "requirementDocument": stepThreePreviousData?.requirementDocument,
      });
      setRequirementDetails(stepThreePreviousData?.document)
    }
  }, [stepThreePreviousData]);

  const validateForm = () => {
    const errors = {};

    // Validate description
    if (!formData.requirementDocument || formData.requirementDocument.trim() === '') {
      errors.requirementDocument = 'Description is required';
    }

    if (formData?.requirementDocument?.length < 500) {
      errors.requirementDocument = 'Please enter a description of at least 500 characters.';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no validation errors
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === 'requirementDocument') {
      // Check if description length is within the limit
      if (value.length > 5000) {
        updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
      }
      setTextCount(5000 - updatedValue.length); // Update character count
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));

    // Construct the data object to send to the parent component
    const dataToSend = {
      ...formData,
      [name]: updatedValue // Update the specific field with the new value
    };
    // Pass data to parent component
    onDataUpdate(dataToSend);

    // Clear the error message when user starts typing
    setValidationErrors({
      ...validationErrors,
      [name]: ''
    });
  };

  useEffect(() => {
    // Check for validation errors when form data changes
    if (finishButtonClicked) {
      validateForm();
    }
  }, [finishButtonClicked]);

  const handleFormSubmit = () => {
    setFinishButtonClicked(true);
    if (validateForm()) {
      {
        const dataToSend = {
          requirementDocument: formData.requirementDocument,
          document: requirementDetails
        };
        // Pass data to parent component
        onDataUpdate(dataToSend);
        onNext();
      }
    }
  };
  const viewDoc = (requirementDetails) => {
    setOpenModal(true);
    handleView();
    setFile(requirementDetails);
    setType('pdf');
  };

  return (
    <Box className="list-service-requirement-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">
            Requirements for the client
          </Typography>
        </Box>
        <Box>
          <Box className="upload-img">
            <Button variant="outlined" endIcon={<AddCircleOutlineIcon />} className="btn-rounded btn-large" onClick={handleOpenPopupRequirement}>
              Add a requirement</Button>
          </Box>
        </Box>
      </Box>
      <Box className="requirment-container">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography className="font-22">
                Tell the client what you need to get started
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box>
              <TextField
                id="description"
                name="requirementDocument"
                variant="outlined"
                value={formData.requirementDocument}
                onChange={handleInputChange}
                className="onboarding-input-text"
                multiline // Enable multiline
                rows={4} // Set the number of rows to display
                // error={Boolean(errors.description)}
                // helperText={errors.description}
                style={{ minHeight: "80px" }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}>
              {validationErrors.requirementDocument ? <Typography paddingLeft={"16px"} variant="body2" color="error">{validationErrors.requirementDocument}</Typography> : <Typography color={'#94A3B8'} paddingLeft={"32px"} className='font-12'>  Enter at least 500 characters</Typography>}
              <Typography paddingRight={"16px"} color={'#94A3B8'} className='font-12'> {textCount} characters left</Typography>
            </Box>
            <Box className="pd-sidebar-documents" mb={3}>
              <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Typography className="title" component={'h4'}>Document</Typography>
              </Stack>

              {/* Dynamically rendring documents with viewer */}

              {requirementDetails && requirementDetails.length > 0 ? (<>
                {
                  requirementDetails[0]?.map((val, ind) => {
                    return (
                      <Card className="pd-action-card" key={ind}>
                        <CardHeader
                          avatar={
                            <Avatar aria-label="recipe" className="bg-blue-900" variant="square" sx={{ width: '48px', height: '48px', borderRadius: '12px' }}>
                              <AssignmentIcon />
                            </Avatar>
                          }
                          action={
                            <>
                              <Box className="btn-wrap">
                                <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => handleDelete(val)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton aria-label="View" size="large" className='square-icon-btn' onClick={() => viewDoc(val)} >
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                </Stack>
                              </Box>
                            </>
                          }
                          title={val.path}
                          subheader={
                            <Tooltip title={val?.name}>
                              <span>{val?.name}</span>
                            </Tooltip>
                          }
                        />
                      </Card>
                    );
                  })
                }
              </>
              ) : (
                <Typography textAlign={'center'}>No documents availiable to view</Typography>
              )}
            </Box>
            {/* <Box className="mandatory-reuirement-checkbox" mt={3}>
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="Mandatory Requirement"
                labelPlacement="end"
              />
            </Box>
            <Box className="mandatory-reuirement-checkbox">
              <Button variant="contained" className="btn-rounded btn-blue-800 ">Add</Button>
            </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-content-div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className="go-back-btn">
            <Button startIcon={<ArrowBackIcon />} onClick={onBack}>Go Back</Button>
          </Box>
          <Box className="continue-btn">
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              className="btn-rounded btn-large"
              style={{ background: "var(--golden-gradient)" }}
              onClick={handleFormSubmit}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
      {isPopupOpen && <AddRequirementPopup onOpen={isPopupOpen} handleClose={handleClosePopupRequirement} onSave={handleSaveRequirementDetail} />}
      {view &&
        <Modal
          className="projectDocumentsModal"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth={true}
        >
          <Box sx={styleModal}>
            <OverlayScrollbarsComponent defer
              style={{ width: '100%', height: '100%' }}
            >
                {/* <FileViewer fileType={type} filePath={URL.createObjectURL(file)} /> */}
                <object
                    data={URL.createObjectURL(file)}
                    type="application/pdf"
                    style={{ width: '100%', height: '100%' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                  </p>
                </object>
            </OverlayScrollbarsComponent>
          </Box>
        </Modal>
      }
    </Box>
  );
};

export default Requirements;
