import React, { useState, useCallback, useEffect } from "react";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MessageIcon from '@mui/icons-material/Message';
import audiList from "../../../../Assets/Images/audi-img.png";
import "./IndividualJobs.css";
import { GetApi, postApi } from "../../../../Api/Api";
import config from "../../../../Config/Config";
import { ccyFormat, checkNumberValue, formatDate } from "../../../../Utils/utils";
import DetailsJob from "./DetailsJob";
import JobActivity from "./JobActivity";
import DeliveryJob from "./DeliveryJob";
import RequirementsJob from "./RequirementsJob";
import { getUserDataFromLocalStorage } from "../../../../Services/localstorage.service";
import ContactSupportModal from "../../../ServiceProviderDashBoard/MyJobs/ContactSupportModal/ContactSupportModal";

const IndividualJobActivity = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [contractData, setContractData] = useState({});
  const [isOpenContactSupport, setIsOpenContactSupport] = useState(false);
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);

  const localData = getUserDataFromLocalStorage();

   // Contact Support Modal
   const handleContactSupport = () => {
    handleOpenContactModal()
  }

  /**
    * Function to open Contact Support Modal
    */
  const handleOpenContactModal = () => {
    setIsOpenContactSupport(true);
  };

  /**
 * Function to close Contact Support Modal
 */
  const handleCloseContactModal = () => {
    setIsOpenContactSupport(false);
  };

  const handleFaq =()=> {
    navigate("/service-proposer-dashboard/faq")
  }

  const getContractData = useCallback(() => {
    const url = `/services/getContract?id=${jobId}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setContractData(res.data.data[0]);
        }
      }
    })
  }, [jobId]);

  useEffect(() => {
    getContractData();
  }, [getContractData]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, pl: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChat = async () => {
    try {
      const payload = {
        userID: localData?.id,
        toUserID: contractData?.spv_id,
        contract_id: jobId,
        chatName: contractData?.title
      }
      const res = await postApi(`/chat/spAddChat`, payload);
      if (res?.data && res?.data?.data) {
        navigate("/service-proposer-dashboard/chats", {
          state: {
            user_id: contractData?.client_id,
            chat_id: res?.data?.data?.id
          },
        });
      }
    } catch (error) {
      console.log("dddd", error);
    }
  };

  return (
    <Box className="individual-job-activity-container">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={12}>
            <Box sx={{ width: "100%" }}>
              <Box className="gradient-tab-text" sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Activity" {...a11yProps(0)} className={value === 0 ? "st-active" : "st-inactive"} />
                  <Tab label="Details" {...a11yProps(1)} className={value === 1 ? "st-active" : "st-inactive"} />
                  <Tab label="Requirements" {...a11yProps(2)} className={value === 2 ? "st-active" : "st-inactive"} />
                  <Tab label="Delivery" {...a11yProps(3)} className={value === 3 ? "st-active" : "st-inactive"} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <JobActivity contractData={contractData} getContractData={getContractData}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <DetailsJob contractData={contractData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <RequirementsJob contractData={contractData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DeliveryJob contractData={contractData} getContractData={getContractData}/>
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid itme xs={12} md={4} sm={12}>
            <Box className="activity-right-section">
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className="font-16 fw-500">Order Details</Typography>
                <Box className="text-blueGray-300 cursor-pointer">
                  <MoreVertIcon />
                </Box>
              </Stack>
              <Box className="order-list-box">
                <Box>
                  <img src={audiList} />
                </Box>
                <Box className="right-section">
                  <Typography className="font-16">
                    Auditing for {contractData?.title}
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {config.CONTRACT_STATUS[contractData?.status]}
                  </Typography>
                </Box>
              </Box>
              <Box className="service-box">
                <Box className="service-container">
                  <Typography className="font-12">Service from</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.serviceProviderData?.first_name} {contractData?.serviceProviderData?.last_name}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Delivery Date</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.delivery_date ? formatDate(new Date(contractData?.delivery_date), 'MMM d, yyyy') : ' NA'}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Total Price</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    $ {ccyFormat(checkNumberValue(ccyFormat(contractData?.project_fees))+checkNumberValue(ccyFormat(contractData?.service_fees)))}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Order Number</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.contract_id}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Project Term</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.project_term === 'milestone' && 'Milestone'}
                    {contractData?.project_term === 'project' && 'Project'}
                    {contractData?.project_term === 'hourly' && 'Hourly'}
                  </Typography>
                </Box>
              </Box>
              <Box className="upload-files-box" onClick={() => handleChat()} style={{ cursor: "pointer" }}>
                <Typography className="font-14">Messages</Typography>
                <MessageIcon />
              </Box>
              <Box className="action-div">
                <Typography className="font-16">Support</Typography>
                <Box className="text-blueGray-300">
                </Box>
              </Box>
              <Link to={`/service-proposer-dashboard/faq`}>
                <Box className="faq-support-box cursor-pointer">
                  <Stack direction={'row'}>
                    <ContactSupportIcon />&nbsp;&nbsp;&nbsp;
                    <Stack direction={'row'}>
                      <Box>
                        <Typography className="font-14">FAQ</Typography>
                        <Typography className="font-12">Have a Question?</Typography>
                      </Box>
                    </Stack>
                  </Stack>
                  <ChevronRightIcon />
                </Box>
              </Link>
              <Box className="upload-files-box cursor-pointer" onClick={handleContactSupport}>
                <Stack direction={'row'}>
                  <SupportAgentIcon />&nbsp;&nbsp;&nbsp;
                  <Box>
                    <Typography className="font-14">Contact Support</Typography>
                    <Typography className="font-12">Resolve Order issues?</Typography>
                  </Box>
                </Stack>
                <ChevronRightIcon />
              </Box>
              {isOpenContactSupport && <ContactSupportModal onOpen={isOpenContactSupport} handleClose={handleCloseContactModal} contractId={jobId}/>}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IndividualJobActivity;
