import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import "./PostProject.css";
import { ArrowForward } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from "react-router-dom";
import { postApi, updateApi } from '../../../Api/Api';
import { useSnackbar } from "../../../Contexts/SnackbarContext";

const PostProject = (data) => {
    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""
    
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [draftLoading, setDraftLoading] = useState(false);
    const propData = data?.data?.data;

    const handleBack = () => {
        const previewData = data
        navigate("/service-proposer-dashboard/postProject", { state: { previewData, form: "PostProject" } })
    }

    /**
     * Function to handle Save Draft
     */
    const handleSaveDraft = async () => {
        
        let formData = new FormData();
        formData.append("user_id", localData.id);
        formData.append("sp_category_id", propData?.CategoryId);
        formData.append("project_title", propData?.Title);
        formData.append("project_size", propData?.ProjectSize);
        formData.append("work_time", propData?.ProjectTime);
        formData.append("level_of_experience", propData?.ExperienceLevel);
        formData.append("budget", propData.projectBudget === "hourlyRate" ? "Hourly Rate" : "Project Budget");
        formData.append("budget_from", propData?.HourlyRateFrom ? propData?.HourlyRateFrom : propData?.ProjectBudgetFrom);
        formData.append("budget_to", propData?.HourlyRateTo ? propData?.HourlyRateTo : propData?.ProjectBudgetTo);
        formData.append("describe_services", propData?.Description);
        formData.append("status", 0);
        // Append Skill Ids as an array
        const skillIds = propData.Skills.map(skill => skill.id);
        formData.append("skill", JSON.stringify(skillIds));

        // Append documents here
        propData?.Document?.forEach((val) => {
            formData.append("document_file", val);
        });
        propData?.newprojectId?.id &&
            formData.append("race_project_id", propData?.newprojectId?.id);

            if(propData?.existingFiles && propData?.existingFiles.length > 0){
                let existingFile = propData?.existingFiles.map((item) => {
                    return {
                        id: item?.id,
                        mimetype: item.type,
                        location: item.path,
                        originalname: item.original_name,
                        key: item.filename,
                        isDeleted: item?.isDeleted
                    }
                })
                formData.append(`existingFiles`, JSON.stringify(existingFile));
            }
        if (propData?.from === "MyPorjectPosting" && propData?.projectId) {
            setDraftLoading(true);
            try {
                const res = await updateApi(`/services/sp_projectRequirement/${propData?.projectId}`, formData);
                if (res.data.code === 200) {
                    setDraftLoading(false);
                    setTimeout(() => {
                        showSnackbar('Updated Project Successfully', 'success')
                        navigate("/service-proposer-dashboard/projectposting")
                    }, 1000)
                }
            } catch (error) {
                setDraftLoading(false);
            }
        } else {
            setDraftLoading(true);
            try {
                const res = await postApi("/services/sp_projectRequirement", formData);
                if (res.data.code === 200) {
                    setDraftLoading(false);
                    setTimeout(() => {
                        showSnackbar('Project Drafted Successfully', 'success')
                        navigate("/service-proposer-dashboard/projectposting")
                    }, 1000)
                }
            } catch (error) {
                setDraftLoading(false);
            }
        }
    }

    /**
    * Function to handle post project
     */
    const handlePostProject = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append("user_id", localData.id);
        formData.append("sp_category_id", propData?.CategoryId);
        formData.append("project_title", propData?.Title);
        formData.append("project_size", propData?.ProjectSize);
        formData.append("work_time", propData?.ProjectTime);
        formData.append("level_of_experience", propData?.ExperienceLevel);
        formData.append("budget", propData.projectBudget === "hourlyRate" ? "Hourly Rate" : "Project Budget");
        formData.append("budget_from", propData?.HourlyRateFrom ? propData?.HourlyRateFrom : propData?.ProjectBudgetFrom);
        formData.append("budget_to", propData?.HourlyRateTo ? propData?.HourlyRateTo : propData?.ProjectBudgetTo);
        formData.append("describe_services", propData?.Description);
        formData.append("status", 1);
        // Append Skill Ids as an array
        const skillIds = propData.Skills.map(skill => skill.id);
        formData.append("skill", JSON.stringify(skillIds));
        // Append documents here
        propData?.Document?.forEach((val) => {
            formData.append("document_file", val);
        });
        propData?.newprojectId?.id &&
            formData.append("race_project_id", propData?.newprojectId?.id)

        if(propData?.existingFiles && propData?.existingFiles.length > 0){
            let existingFile = propData?.existingFiles.map((item) => {
                return {
                    id: item?.id,
                    mimetype: item.type,
                    location: item.path,
                    originalname: item.original_name,
                    key: item.filename,
                    isDeleted: item?.isDeleted
                }
            })
            formData.append(`existingFiles`, JSON.stringify(existingFile));
        }
        if (propData?.from === "MyPorjectPosting" && propData?.projectId) {
            try {
                const res = await updateApi(`/services/sp_projectRequirement/${propData?.projectId}`, formData);
                if (res.data.code === 200) {
                    setLoading(false);
                    showSnackbar('Project Updated Successfullly', 'success')
                    setTimeout(() => {
                        navigate("/service-proposer-dashboard/projectposting")
                    }, 1000)
                }
            } catch (error) {
                setLoading(false);
                showSnackbar('There was some error', 'error')
            }
        } else {
            try {
                const res = await postApi("/services/sp_projectRequirement", formData);
                if (res.data.code === 200) {
                    setLoading(false);
                    showSnackbar('Project Created Successfullly', 'success')
                    setTimeout(() => {
                        navigate("/service-proposer-dashboard/projectposting")
                    }, 1000)
                }
            } catch (error) {
                setLoading(false);
                showSnackbar('There was some error', 'error')
            }
        }
    }

    return (
        <>
            <Grid spacing={2}>
                <Grid item xs={12}>
                    <Box className="post-projct-main">
                        <Box>
                            <Typography component="h4" className='title font-22'>Project Details</Typography>
                        </Box>
                        <Box className="project-title">
                            <Typography component="h4" className='title font-16'>{propData?.Title}</Typography>
                            <Typography component="h5" className='sub-title font-12'>{propData?.Description}</Typography>

                        </Box>
                        <Box className="post-project-category">
                            <Typography component="h4" className='title font-16'>Category</Typography>
                            <Typography component="h5" className='sub-title font-12'>{propData?.Category}</Typography>
                        </Box>
                        <Box className="post-project-skill">
                            <Typography component="h4" className='title font-16'>Skills</Typography>
                            <Box className="button-div">
                                {propData?.Skills?.map((skill) => {
                                    return <Button variant="outlined" className='btn btn-rounded' startIcon={<AddIcon />}>
                                        {skill?.skill_name}
                                    </Button>
                                })}
                            </Box>
                        </Box>
                        <Box className="post-project-scope">
                            <Typography component="h4" className='title font-16'>Scope</Typography>
                            <Typography component="h5" className='sub-title font-12'>{propData?.ProjectSize}, {propData?.ProjectTime} months, {propData?.ExperienceLevel}</Typography>
                        </Box>
                        <Box className="post-project-budget">
                            <Typography component="h4" className='title font-16'>Budget</Typography>
                            {propData?.HourlyRateFrom ?
                                <Typography component="h5" className='sub-title font-12'>${propData?.HourlyRateFrom} - ${propData?.HourlyRateTo} /hr</Typography>
                                :
                                <Typography component="h5" className='sub-title font-12'>${propData?.ProjectBudgetFrom} - ${propData?.ProjectBudgetTo}</Typography>
                            }
                        </Box>

                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={2} mx={3}>
                        <Box>
                            <Button className='btn-rounded-black btn-outline-sp' onClick={() => handleBack()}>
                                Back
                            </Button>
                        </Box>
                        <Box>
                            {/* <Button className='btn-rounded-black' onClick={() => handleSaveDraft()}>
                                Save as draft
                            </Button> */}
                            <LoadingButton
                                onClick={() => handleSaveDraft()}
                                startIcon={<AddOutlinedIcon />}
                                loading={draftLoading}
                                loadingPosition="start"
                                type="submit"
                                className='save-as-draft-btn'
                                style={{ color: "#398CD1" }}
                            >
                                Save as draft
                            </LoadingButton>
                            &nbsp;&nbsp;
                            <LoadingButton
                                onClick={() => handlePostProject()}
                                startIcon={<ArrowForward />}
                                style={{ background: "var(--golden-gradient)" }}
                                loading={loading}
                                loadingPosition="start"
                                type="submit"
                                className="btn-rounded"
                            >
                                Post Project
                            </LoadingButton>
                        </Box>
                    </Box>
                </Grid>
                {/* <Grid item xs={4}>
                    <Box >
                        <RightProjectSection projects={propData?.newprojectId}/>
                    </Box>
                </Grid> */}

            </Grid>
        </>
    )
}

export default PostProject