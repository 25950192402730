import React, { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Upload } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import "./AddGalleryPopup.css"

const AddGalleryPopup = ({ open, handleClose, onSave }) => {
    const [formValues, setFormValues] = useState({
        image: null,
    });
    const [errors, setErrors] = useState({
        image: "",
    });

    const [AdditonalDetailsLocal, setAdditionalDetailsLocal] = useState([]); // state to store
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";

        const handleChange = (e) => {
            const { name, files } = e.target;
            if (files.length > 0) {
                const file = files[0];
                const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
                const allowedVideoTypes = ["video/mp4", "video/x-m4v", "video/*"];
                const allowedTypes = [...allowedImageTypes, ...allowedVideoTypes];
        
                if (allowedTypes.some(type => file.type.match(type))) {
                    const MAX_SIZE_MB = 10;
                    const MAX_DIMENSION = 4000;
        
                    if ((file.size / 1024 / 1024) > MAX_SIZE_MB) {
                        setErrors({
                            ...errors,
                            [name]: `File size exceeds ${MAX_SIZE_MB} MB. Please upload a smaller file.`,
                        });
                        return;
                    }
        
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        if (allowedImageTypes.includes(file.type)) {
                            const img = new Image();
                            img.onload = () => {
                                const width = img.width;
                                const height = img.height;
        
                                if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
                                    setErrors({
                                        ...errors,
                                        [name]: `Image dimensions exceed ${MAX_DIMENSION}px. Please upload a smaller image.`,
                                    });
                                } else {
                                    setFormValues({
                                        ...formValues,
                                        [name]: file,
                                    });
                                    setErrors({
                                        ...errors,
                                        [name]: "", // Resetting errors for the current field
                                    });
                                }
                            };
                            img.src = event.target.result;
                        } else {
                            // For videos, no dimension validation, just set the file
                            setFormValues({
                                ...formValues,
                                [name]: file,
                            });
                            setErrors({
                                ...errors,
                                [name]: "", // Resetting errors for the current field
                            });
                        }
                    };
                    fileReader.readAsDataURL(file);
                } else {
                    setErrors({
                        ...errors,
                        [name]: "Unsupported file type. Please upload an image (JPEG, PNG, GIF) or video (MP4, M4V).",
                    });
                }
            }
        };

    const handleSubmit = (e) => {
        e.preventDefault();
            // Update the educationDetailsLocal state with the new form values directly
            setAdditionalDetailsLocal([...AdditonalDetailsLocal, formValues]);
            onSave({ gallery_image: formValues?.image, id: uuidv4()});
            setFormValues({
                image: null,
            });
            handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-main"
        >
            <Box className="popup-main">
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Box className="close-icon-main cursor-pointer">
                            <CloseIcon className="" onClick={handleClose} />
                        </Box>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            mt={2}
                            className="heading font-28"
                            sx={{ textAlign: "center" }}
                        >
                            Add Images/Videos
                        </Typography>

                        <Box mt={3}>
                            <Box className="upload-section">
                                <input
                                    accept="image/*,video/mp4,video/x-m4v,video/*"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                />
                                <Box mt={2}>
                                    {formValues.image && (
                                        <>
                                            {formValues.image.type.startsWith('image/') && (
                                                <img
                                                    src={URL.createObjectURL(formValues.image)}
                                                    alt="Uploaded"
                                                    className="uploaded-image"
                                                />
                                            )}
                                            {formValues.image.type.startsWith('video/') && (
                                                <video
                                                    src={URL.createObjectURL(formValues.image)}
                                                    controls
                                                    className="uploaded-video"
                                                    alt="Uploaded"
                                                    style={{height:"250px", width: "250px"}}
                                                />
                                            )}
                                        </>
                                    )}
                                </Box>
                                <label htmlFor="upload-button" className="upload-img-btn">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<Upload />}
                                        className="upload-img"
                                    >
                                        Upload 
                                    </Button>
                                </label>
                                <br />
                                {errors.image && (
                                    <Typography variant="caption" className="font 12" color="error">
                                        {errors.image}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: "center" }} mt={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                sx={{
                                    background: "var(--golden-gradient)",
                                    color: "var(--blueGray-900)",
                                }}
                                disabled ={!formValues.image}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default AddGalleryPopup;
