import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from "@mui/icons-material/Save";
import "./EditSkillPopup.css";
import { GetApi, updateApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const EditSkillPopup = ({ open, handleClose, profileData, getProfileData }) => {
  const { showSnackbar } = useSnackbar();
  const [selectedCategorySkillData, setSelectedCategorySkillData] = useState([]);
  const [stepSkills, setStepSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setStepSkills(inputValue);

    // Remove only the deselected skill from selectedSkills
    if (inputValue.trim() !== '') {
        const inputSkills = inputValue.split(',').map(skill => skill.trim());
        const removedSkill = selectedSkills.find(skill => !inputSkills.includes(skill.skill_name));
        if (removedSkill) {
            const updatedSelectedSkills = selectedSkills.filter(skill => skill.id !== removedSkill.id);
            setSelectedSkills(updatedSelectedSkills);
        }
    }
  };

  /**
       * FUnction to select Multiple Skills 
       * @param {*} skill 
       */
  const handleSkillSelection = (skill) => {
    const skillId = skill.id;
    const skillName = skill.skill_name;

    // Check if the skill is already selected
    if (!selectedSkills.some((selectedSkill) => selectedSkill.id === skillId)) {
        setSelectedSkills([...selectedSkills, { id: skillId, skill_name: skillName }]);
        setStepSkills((prevSkills) => {
            const newSkills = prevSkills && prevSkills.length > 0 ? `${prevSkills}, ${skillName} ` : skillName;
            return newSkills;
        });
    }else{
        // If skill is already selected, remove it from the array
        setSelectedSkills(
            selectedSkills.filter((selectedSkill) => selectedSkill.id !== skillId)
        );
        setStepSkills((prevSkills) => {
            // Remove the skill from the comma-separated string
            const skillArray = prevSkills.split(', ').filter(skill => skill.trim() !== skillName.trim());
            return skillArray.join(', ');
        });
    }
  };

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
        try {
          console.log("profileData---",profileData);
          const res = await GetApi(`/services/spCategory?id=${profileData?.sp_category_id}`);
          console.log("res---",res);
          if(res?.data && res?.data?.data?.length > 0){
            setSelectedCategorySkillData(res?.data?.data[0]?.skillData);
          }else{
            setSelectedCategorySkillData([]);
          }
        } catch (error) {
            console.log(error);
        }
    }
    getCategoryData();
}, [])

  useEffect(() => {
    if(profileData && profileData?.skillData && profileData?.skillData.length > 0){
      setSelectedSkills(profileData?.skillData);
      setStepSkills(profileData?.skillData.map((i)=> i?.skill_name)?.join(', '))
    }else{
      setSelectedSkills([]);
      setStepSkills('');
    }
  }, [profileData])

  const handleSubmit = (event) => {
    event.preventDefault();
    if(selectedSkills && selectedSkills.length > 0){
      let updateData = new FormData();
      // Append Skill Ids as an array
      const skillIds = selectedSkills?.map((skill) => skill.id);
      updateData.append("skill_ids",JSON.stringify(skillIds));
      updateApi(`/services/updateProfile/${profileData.id}`, updateData).then((res) => {
        if (res?.status === 200) {
          showSnackbar('Updated Successfully', 'success');
          getProfileData()
          handleClose()
        } else { // something went wrong user or backend side
          showSnackbar(res?.data?.message, 'error');
          handleClose()
        }
      })
    }
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
                <CloseIcon className="cross-icon-modal"/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit Skill
          </Typography>
          {/* <form onSubmit={handleSubmit}> */}
            {/* Skill Section */}
            <Box className="proff-info-box search-lang" mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Skills
                        </Typography>
                        <Typography className='font-12 sub-title'>Your skills show clients what you can offer, and help us choose which jobs to recommend to you</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9}>
                        <Box className="input-box">
                            <TextField
                                id="outlined-basic"
                                label="Select skills"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder='E.g. Auditor needed for real estate project'
                                value={stepSkills}
                                // onChange={(e) => setStepSkills(e.target.value)}
                                onChange={handleInputChange}
                                disabled
                            />
                        </Box>
                        <Box className="button-div-bg-none" mt={2}>
                            {selectedCategorySkillData &&
                                selectedCategorySkillData.map((skill) => (
                                    <Button
                                        key={skill.id}
                                        variant="outlined"
                                        className={selectedSkills && selectedSkills.find((itm) => itm?.id === skill.id) ? "skill-button" : ""}
                                        startIcon={<AddIcon />}
                                        onClick={() => handleSkillSelection(skill)}
                                    >
                                        {skill.skill_name}
                                    </Button>
                                ))}
                        </Box>
                    </Grid>
                </Grid>
                {selectedSkills.length === 0  && <Typography variant="body2" color="error">Skills are required.</Typography>}
            </Box>

            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{
                      background: "var(--golden-gradient)",
                      color: "var(--blueGray-900)",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          {/* </form> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default EditSkillPopup;
