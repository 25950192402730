import DealTerms from "../DealTerms";
import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import Offers from "../Offers";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import Templates from "../Templates";
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import { formatNumber } from "../../Components/Common/USFormat";
import CapitalInfoInvestor from "../../Pages/Investor/MicroComponents/CapitalInfoInvestor";

export default class Funds extends BaseDetailsAsset {

  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {
    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Funds data is required in Art Details class"
      );
    }

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name,
      },
      {
        title: "Fund Type",
        value: _data.asset_sub_category_info?.title ?? "",
      },
      {
        title: "Owned By",
        value: _data.owner_information
          ? _data.owner_information.owner_type
          : "",
      },
    ];

    this.dealData = [
      {
        title: 'Total Raise',
        value: `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
      },
      // {
      //   title: 'Minimum Investment',
      //   value: `$${_data?.fractionalize_minimum_investment}`,
      // },
      {
        title: `${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Initial Project Term" : 'Duration of Project'}`,
        value: `${_data?.fractionalize_duration_of_project
          } ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
      },
      // {
      //   title: "No. of Share Classes",
      //   value: _data?.capital_type,
      //   num_of_class: _data?.number_of_shared
      // }
    ];
    if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
      this.dealData = [...this.dealData,
      {
        title: "No. of Share Classes",
        value: _data?.capital_type,
        num_of_class: _data?.number_of_shared
      }
      ]
    } else if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
      this.dealData = [...this.dealData,
      {
        title: 'Fund Raising Period',
        value: `${_data?.investment_duration
          } ${_data?.investment_duration > 1 ? "Days" : "Day"}`,
      },
      {
        title: 'Minimum Investment',
        value: `$${formatNumber(parseFloat(_data?.fractionalize_minimum_investment))}`,
      },
      {
        title: 'Management Fees',
        value: `${formatNumber(parseFloat(_data?.management_fees))}%`,
      },
      // {
      //   title: 'Per Year Fees',
      //   value: `${formatNumber(parseFloat(_data?.management_fees / _data?.fractionalize_duration_of_project))}%`,
      // },
      {
        title: 'Carried Interest',
        value: `${formatNumber(parseFloat(_data?.carried_interest))}%`,
      }
      ]
    }

    this.fromClass = "fundDetails";
    this.docs = _data.asset_document;
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.investment_type_id = _data.investment_type_id
    this.category_id = _data.category_id
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.location = _data?.asset_location?.split(",");
    this.galleryImages = _data?.asset_gallery;
    this.is_onboard = _data?.is_onboard
    this.nft_id = _data?.nft_id
    this.assetData = _data
    this.member_id = _data?.user_details?.member_id

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.coverPhoto = _data?.asset_coverphoto;
    this.descriptionData = {
      title: _data?.asset_title,
      subtitle: _data?.asset_address,
      investmentType: _data?.investment_type?.investment_name,
      address: _data?.owner_information?.owner_address,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
    };

    if (this.isFraction) {
      if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
        this.prices = {
          'Total Raise': `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
          // 'Minimum Investment': `$${_data?.fractionalize_minimum_investment}`,
          'Initial Project Term': `${_data?.fractionalize_duration_of_project
            }  ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
        }
      }
      else {
        this.prices = {
          'Total Raise': `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
          // 'Minimum Investment': `$${_data?.fractionalize_minimum_investment}`,
          'Project Duration': `${_data?.fractionalize_duration_of_project
            }  ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
        }
      }
    }

    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} isOnboard={this.is_onboard} nft_id={this.nft_id} isFundFraction={this.isFraction} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        address={this.descriptionData.address}
        assetSubtype={this.descriptionData.assetSubtype}
        userId={this.user_id}
        assetId={this.asset_Id}
        category_id={CATEGORY_TYPE_ID.FUNDS}
      />
    );
  };

  setRightPanelComponents = () => {
    if (!this.isOwner) { // only show below code block components if user is not owner
      this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    } else {
      this.rightPanel.push(<Offers asset_Id={this.asset_Id} category_id={this.category_id} />);
    }
    this.rightPanel.push(<PriceOverview prices={this.prices} isFundFraction={this.isFraction} assetData={this.assetData} capitalInfo={this.capitalInfo} isOfferAccepted={this.isOfferAccepted} assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOnboard={this.is_onboard} isOwner={this.isOwner} />);
    this.rightPanel.push(<CapitalInfoInvestor assetId={this.asset_Id} assetData={this.data} capitalInfo={this.capitalInfo} selectedCapitalId={1} detailsFor={"proposer"} />)
    // if (!this.isOwner) this.rightPanel.push(<InvestorButtons isOfferAccepted={this.isOfferAccepted} assetId={this.asset_Id} btnTitle={this.descriptionData.title} listingType={this.descriptionData.investmentType} isOnboard={this.is_onboard} />);
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} isFundFraction={this.isFraction} />);
    this.rightPanel.push(<DealTerms dealTerms={this.dealData} fromPage={this.fromClass} editable={this.isOwner} isFundFraction={this.isFraction} />);
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Asset Documents" document_name="asset_document" investment_type_id={this.investment_type_id} isFundFraction={this.isFraction} />);
    this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} projectStatus={this.projectStatus} section_title="Agreement Documents" document_name="asset_signing_document" investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isFundFraction={this.isFraction} />);
    this.rightPanel.push(<Templates documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} projectStatus={this.projectStatus} investment_type_id={this.investment_type_id} selected_templates={this.selected_templates} isFundFraction={this.isFraction} />);
  };
}
