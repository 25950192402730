import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { tableCellClasses } from '@mui/material/TableCell';
import { PaymentsOutlined } from '@mui/icons-material';
import {
    Chip, TableCell, Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography,
    Avatar, Modal, TextField
} from '@mui/material';
import { CAPITAL_TYPE_DISPLAY, CATEGORY_TYPE, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS, SUB_CATEGORY_TYPE_ID } from '../../constants.js'
import { GetApiParam } from '../../Api/Api.js';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import SelectCurrencyType from './SelectCurrencyType.jsx';
import { Close, Done, EditSharp, Visibility } from '@mui/icons-material';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { NewLogo } from "../../Components/Images/Images";
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ }) => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

/**
 * @param {string} date  Date String
 * @returns  {string} Formatted Date String in MM/DD/YYYY format
 */
const formatDate = (date) => {
    let d = new Date(date);
    let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    return formattedDate;
}

const FundsPanel = () => {

    const navigate = useNavigate();
    const [offersData, setOffersData] = useState([])
    const [openModalRemainingPayment, setOpenModalRemainingPayment] = useState(false);
    const [propData, setPropData] = useState();
    const [modalOpenCurrency, setModalOpenCurrency] = useState(false);
    const [remainingPaymentData, setRemainingPaymentData] = useState({
        feeTokenType: 'USDT'
    });

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    /**
     * function to get offers
     */
    const getOffers = async (param) => {
        let res = await GetApiParam('/invest/offer', param);
        if (res?.data?.data) {
            setOffersData(res.data.data)
        }
    }

    useEffect(() => {
        getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
    }, [])

    /**
    * function handle make payment button click
    */
    const handlePayRemainingPayment = (assetId, investment_type_id, listing_id, deposit_amount, offerAmount, blockchainOfferId, asset_sub_category) => {
        setModalOpenCurrency(true)
        setRemainingPaymentData({ assetId, investment_type_id, listing_id, category_id: CATEGORY_TYPE_ID.FUNDS, remaining_amount: parseFloat(offerAmount), blockchainOfferId, asset_sub_category })
    }


    /**
    * Function to handle modal close
    */
    const handleModalCloseRemainingPayment = (data) => {
        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalRemainingPayment(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmRemainingPayment = () => {
        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * function to handle status
     * @param {Object} row 
     * @returns 
     */
    const getStatusComponent = (row) => {
        const getStatusProperties = (status) => {
            switch (status) {
                case OFFER_STATUS.PENDING:
                    return {
                        label: 'Pending',
                        sx: { color: 'black', bgcolor: '#F6CFB7', borderRadius: '8px' },
                    };
                case OFFER_STATUS.ACCEPTED:
                    if (row?.class) {
                        return {
                            label: 'Complete Payment',
                            sx: { color: 'black', bgcolor: '#F6CFB7', borderRadius: '8px', width: '180px' },
                            onClick: () =>
                                handlePayRemainingPayment(
                                    row.asset_data?.id,
                                    row.investment_type_id,
                                    row.listing_id,
                                    row.deposit_amount,
                                    row.offer_amount,
                                    row.blockchainOfferId,
                                    row.asset_data?.asset_sub_category_info?.id
                                ),
                            startIcon: <PaymentsOutlined />,
                        }
                    } else {
                        return {
                            label: 'Accepted',
                            sx: { color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' },
                        }
                    }
                case OFFER_STATUS.REJECTED:
                    return {
                        label: 'Rejected',
                        sx: { color: 'white', bgcolor: '#EF4444', borderRadius: '8px' },
                    };
                case OFFER_STATUS.COMPLETED:
                    return {
                        label: 'Completed',
                        sx: { color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' },
                    };
                case OFFER_STATUS.EXPIRED:
                    return {
                        label: 'Expired',
                        sx: { color: 'white', bgcolor: 'darkgray', borderRadius: '8px' },
                    };
                case OFFER_STATUS.CANCELLED:
                    return {
                        label: 'Cancelled',
                        sx: { color: 'black', bgcolor: '#888888', borderRadius: '8px' },
                    };
                default:
                    return {
                        label: 'Hold',
                        sx: { color: 'black', bgcolor: '#FFFFED', borderRadius: '8px' },
                    };
            }
        };

        const statusProperties = getStatusProperties((row?.investment_type_id === INVESTMENT_TYPE_ID.SALE && row?.full_payment || (row?.class && row?.is_commitment == true)) ? OFFER_STATUS.COMPLETED : row.status);

        if (statusProperties.onClick) {
            return (
                <Button
                    className="status-button"
                    onClick={statusProperties.onClick}
                    startIcon={statusProperties.startIcon}
                    sx={statusProperties.sx}
                >
                    {statusProperties.label}
                </Button>
            );
        } else {
            return <Chip label={statusProperties.label} sx={statusProperties.sx} />;
        }
    };

    // handle modal close
    const handleCloseCurrency = () => {
        setModalOpenCurrency(false)
    }

    const handleFeeTokenType = (currencyType) => {
        setPropData({ ...remainingPaymentData, feeTokenType: currencyType });
        setOpenModalRemainingPayment(true);
        handleCloseCurrency(true);
    }

    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Investment Made
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Asset Type</StyledTableCell>
                            <StyledTableCell align="left">Investment Amount</StyledTableCell>
                            <StyledTableCell align="center">Capital Type</StyledTableCell>
                            {/* <StyledTableCell align="center">Fund Type</StyledTableCell> */}
                            <StyledTableCell align="center">Return Received</StyledTableCell>
                            {/* <StyledTableCell align="center">Created Date</StyledTableCell> */}
                            {/* <StyledTableCell align="center">Funded Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offersData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_data?.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">
                                    <Link to={`/project-details/${row.asset_data.id}`}>{row.asset_data?.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.asset_data?.assets_category?.title}</StyledTableCell>
                                <StyledTableCell align="left">${formatNumber(parseFloat(row.offer_amount))}</StyledTableCell>
                                <StyledTableCell align="center">{row?.capital_type_id ? CAPITAL_TYPE_DISPLAY[row?.capital_type_id] : '-'}</StyledTableCell>
                                <StyledTableCell align="center">{row?.return_recived ? row?.return_recived : `$${0}`}</StyledTableCell>
                                {/* <StyledTableCell align="center">{row.asset_data?.asset_sub_category_info?.title ? row.asset_data?.asset_sub_category_info?.title : '-'}</StyledTableCell> */}
                                {/* <StyledTableCell align="center">{row.return_recived?.asset_sub_category_info?.id === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS ? (row.class == '1' ? 'Class A' : 'Class B') : "-"}</StyledTableCell> */}
                                {/* <StyledTableCell align="center">{formatDate(row.createdAt)}</StyledTableCell> */}
                                {/* <StyledTableCell align="center">{row.status == OFFER_STATUS.COMPLETED ? formatDate(row.updatedAt) : '-'}</StyledTableCell>
                                <StyledTableCell align="center">{
                                    getStatusComponent(row)
                                }</StyledTableCell> */}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {modalOpenCurrency && <SelectCurrencyType open={modalOpenCurrency} onClose={handleCloseCurrency} handleFeeTokenType={handleFeeTokenType} />}

            {/* code for pay remaining payment smart contract modal */}
            {openModalRemainingPayment ? <TransactionProgressModal identity={'remaining-payment'} confirmStake={confirmRemainingPayment} propData={propData} handleModalClose={handleModalCloseRemainingPayment} openTransactionModal={openModalRemainingPayment} /> : null}
        </>
    )
}

export default FundsPanel
