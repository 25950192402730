import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import SnackbarAlert from '../Components/Common/SnackbarAlert';
import { INVESTMENT_TYPE } from '../constants';
import { GetApi } from '../Api/Api';

export default function InvestorButtons({ listingType, btnTitle, assetId, isOfferAccepted, isOnboard, DataFromInvestor, isClosed }) {
    const navigate = useNavigate()
    const [buyerId, setBuyerId] = useState({ user_id: null });
    const userData = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null;

    var primaryBtnText = 'Invest';
    var secondaryBtnText = 'How it works';

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    switch (listingType) {
        case 'Loan':
            primaryBtnText = 'Lend'
            break;
        case 'Sale':
            primaryBtnText = `Invest in ${btnTitle}`
            break;
        case 'Parcelling':
            primaryBtnText = 'Buy Parcel'
            // secondaryBtnText = 'Bid'
            break;
        case 'Fractionalize':
            primaryBtnText = `Invest in ${btnTitle}`
            break;
        default:
            break;
    }

    // Setting Sold/Purchased for ART-SALE
    useEffect(() => {
        const checkInvestor = async (assetId) => {
            try {
                const res = await GetApi(`/user/getInvestor?asset_id=${assetId}`);
                if (res?.data?.data)
                    setBuyerId(res.data.data);
            } catch (error) {
                console.error('Error fetching investor:', error);
            }
        };

        if (assetId && listingType === 'Sale') {
            checkInvestor(assetId);
        }
    }, [assetId, listingType]);


    const handleClick = () => {
        let login = localStorage.getItem('login');
        let user = localStorage.getItem('user_data');
        if (login && user) {
            navigate(`/investor-payment/${listingType}`, { state: { assetId: assetId } })
        } else { //toaster error
            navigate(`/login`)
            setOpenSnackbar(true);
        }
    }

    const handleHowItWorkClick = () => {
        DataFromInvestor(true)
    }


    return (
        <>
            <Box mt={2}>
                {isClosed ?
                    <>
                        <Button sx={{ cursor: 'not-allowed' }} className='rc-invest-btn-sold' variant='contained' color='primary' disableRipple>Closed</Button>
                    </> :
                    (isOfferAccepted === true ?
                        <Button sx={{ cursor: 'not-allowed' }} className='rc-invest-btn-sold' variant='contained' color='primary' disableRipple>{(listingType === INVESTMENT_TYPE.SALE) ? (buyerId?.user_id === userData?.id ? 'Purchased' : "Sold") : (listingType === INVESTMENT_TYPE.PARCEL) ? 'Sold' : listingType === INVESTMENT_TYPE.LOAN ? 'Loan Issued' : listingType === INVESTMENT_TYPE.FRACTION ? 'Funded' : ''} </Button>
                        :
                        <Button onClick={handleClick} className='rc-invest-btn' variant='contained' disabled={!isOnboard} color='primary' startIcon={<AttachMoneyIcon />}> {primaryBtnText} </Button>)
                }
                <Button className='rc-invest-btn' sx={{ bgcolor: '#064165 !important' }} variant='contained' color='secondary' startIcon={<InfoIcon />} onClick={handleHowItWorkClick} >{secondaryBtnText}</Button>
            </Box>
            <SnackbarAlert open={openSnackbar} message={'Please Login to proceed further'} severity={'error'} onClose={handleCloseSnackbar} />
        </>
    )
}

InvestorButtons.propTypes = {
    listingType: PropTypes.string.isRequired,
    btnTitle: PropTypes.string,
    assetId: PropTypes.number
}
