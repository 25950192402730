import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./ListService.css";
import { GetApi, GetApiParam } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";

const ServiceOverview = ({ onDataUpdate, onNext, onBack, previousData }) => {

  const [formData, setFormData] = useState({ description: '' });
  const [serviceTitle, setServiceTitle] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [stepSkills, setStepSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategorySkillData, setSelectedCategorySkillData] = useState([]);
  const [previousPageData, setPreviousPageData] = useState([])
  const [serviceData, setServicesData] = useState([]);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [textCount, setTextCount] = useState(5000);
  const userData = getUserDataFromLocalStorage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let payloadService = { login_user_id : userData?.id};
        const [categoryRes, servicesRes, serviceProviderRes] =
          await Promise.all([
            GetApi("/services/spCategory"),
            GetApiParam("/services/getServices", payloadService),
            GetApi("/services/getProfiles"),
          ]);
        setCategories(categoryRes?.data?.data || []);
        setServicesData(
          servicesRes?.data?.data?.length ? servicesRes.data.data : []
        );
        setServiceProvider(
          serviceProviderRes?.data?.data?.length
            ? serviceProviderRes.data.data
            : []
        );
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory !== null) {
      const fetchSubCategories = async () => {
        try {
          const subCategoryRes = await GetApi(`/services/spSubCategory?service_category_id=${selectedCategory}`);
          setSubCategories(subCategoryRes?.data?.data || []);
        } catch (error) {
          setSubCategories([]);
        }
      };
      fetchSubCategories();
    } else {
      setSubCategories([]);
    }
  }, [selectedCategory]);

  /**
     * Setting Skill sets according to the previous stape when user comes back from the step 2
     */
  useEffect(() => {
    if (previousData.length > 0 || previousData !== undefined) {
      setPreviousPageData(previousData)
    }

    // if (previousData.selectedSkills) {
    //   setSelectedSkills(previousData.selectedSkills);
      // setStepSkills(!stepSkills ? previousData.selectedSkills.map(skill => skill.skill_name).join(', ') : previousData?.stepSkill);
    // }
  }, [previousData])

  useEffect(() => {
    const selectedCategoryObject = categories?.find(category => category.id === previousData?.selectedCategory);

    if (previousData) {
      setFormData({
        "description": previousData.description,
      });
      setServiceTitle(previousData?.serviceTitle)
      setSelectedCategory(previousData.selectedCategory)
      setSelectedSubCategory(previousData.selectedSubCategory)
      setSelectedSkills(previousData.selectedSkills);
      if(previousData?.selectedSkills?.length > 0){
        const skills = previousData?.selectedSkills?.map((skill) => skill.skill_name);
        setStepSkills(skills.join(', '));
      }
      
      setSelectedCategorySkillData(selectedCategoryObject?.skillData)
    }
  }, [previousData, categories]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    if (name === 'description') {
      // Check if description length is within the limit
      if (value.length > 5000) {
        updatedValue = value.substring(0, 5000); // Trim the description to 5000 characters
      }
      setTextCount(5000 - updatedValue.length); // Update character count
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));

    // Construct the data object to send to the parent component
    const dataToSend = {
      ...formData,
      [name]: updatedValue // Update the specific field with the new value
    };
    // Pass data to parent component
    onDataUpdate(dataToSend);

    // Clear the error message when user starts typing
    setValidationErrors({
      ...validationErrors,
      [name]: ''
    });
  };

  const handleSkillChange = (e) => {
    const inputValue = e.target.value;
    setStepSkills(inputValue);

    // Remove only the deselected skill from selectedSkills
    if (inputValue.trim() !== '') {
      setValidationErrors(prevErrors => {
        const { stepSkills: stepSkillsError, ...otherErrors } = prevErrors;
        return otherErrors;
      });
      const inputSkills = inputValue.split(',').map(skill => skill.trim());
      const removedSkill = selectedSkills.find(skill => !inputSkills.includes(skill.skill_name));
      if (removedSkill) {
        const updatedSelectedSkills = selectedSkills.filter(skill => skill.id !== removedSkill.id);
        setSelectedSkills(updatedSelectedSkills);
      }
    }
  };

  /**
  * Function to extract skill data from category and handlechange.
  * @param {*} event
  */
  const handleCategoryChange = async (event) => {
    const categoryTitle = event.target.value;
    setSelectedCategory(categoryTitle);
    setSelectedSubCategory('')
    setStepSkills([]);
    setSelectedSkills([]);
    // Find the selected category object
    const selectedCategoryObject = categories?.find(
      (category) => category.id === categoryTitle
    );

    // Extract skillData from the selected category object
    if (selectedCategoryObject) {
      setSelectedCategorySkillData(selectedCategoryObject.skillData);
      setcategoryName(selectedCategoryObject.title);

      // Show subcategories only if the main category is selected
      if (selectedCategoryObject.subcategories) {
        setSubCategories(selectedCategoryObject.subcategories);
      } else {
        setSubCategories([]); // Clear subcategories if none exist
      }
    } else {
      setSelectedCategorySkillData([]);
      setcategoryName('');
      setSubCategories([]); // Clear subcategories if no category is selected
    }
  };

  const handleSubCategoryChange = (event) => {
    const subCategoryId = event.target.value;
    setSelectedSubCategory(subCategoryId);
  };

  /**
 * FUnction to select Multiple Skills
 * @param {*} skill
 */
  const handleSkillSelection = (skill) => {
    const skillId = skill.id;
    const skillName = skill.skill_name;

    // Check if the skill is already selected
    if (!selectedSkills.some((selectedSkill) => selectedSkill.id === skillId)) {
      setSelectedSkills([
        ...selectedSkills,
        { id: skillId, skill_name: skillName },
      ]);
      setStepSkills((prevSkills) => {
        const newSkills = prevSkills && prevSkills.length > 0
          ? `${prevSkills}, ${skillName} `
          : skillName;
        return newSkills;
      });
      // setStepSkills([...stepSkills, { id: skillId, name: skillName }]);
    }else{
      // If skill is already selected, remove it from the array
      setSelectedSkills(
        selectedSkills.filter((selectedSkill) => selectedSkill.id !== skillId)
      );
      setStepSkills((prevSkills) => {
        // Remove the skill from the comma-separated string
        const skillArray = prevSkills.split(', ').filter(skill => skill.trim() !== skillName.trim());
        return skillArray.join(', ');
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    // Validate Service Title
    if (!serviceTitle) {
      errors.serviceTitle = "Title is required.";
    }

    // Category
    if (!selectedCategory) {
      errors.selectedCategory = "Category is required.";
    }

    // Skills
    if (
      (selectedSkills?.length === 0 && (typeof stepSkills !== 'string' || !stepSkills)) ||
      (!selectedSkills?.length === 0 && typeof stepSkills === 'string' && stepSkills)
    ) {
      errors.stepSkills = "Skills are required.";
    }

    // Validate description
    if (!formData.description || formData.description.trim() === '') {
      errors.description = 'Description is required';
    }

    if (formData?.description?.length < 500) {
      errors.description = 'Please enter a description of at least 500 characters.';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no validation errors
  };

  useEffect(() => {
    // Check for validation errors when form data changes
    if (finishButtonClicked) {
      validateForm();
    }
  }, [serviceTitle, selectedCategory, selectedSkills, finishButtonClicked]);

  const handleFormSubmit = () => {
    setFinishButtonClicked(true);
    if (validateForm()) {
      {
        const dataToSend = {
          serviceTitle: serviceTitle,
          description: formData.description,
          selectedCategory: selectedCategory,
          selectedSubCategory: selectedSubCategory,
          selectedSkills: selectedSkills,
          stepSkill: stepSkills,
        };
        // Pass data to parent component
        onDataUpdate(dataToSend);
        onNext();
      }
    }
  };

  return (
    <Box className="list-service-overview-container">
      <Typography className="font-36">Service Overview</Typography>
      <Box className="service-title-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-title-left">
              <Typography className="font-22">Service Title</Typography>
              <ul>
                <li className="text-blueGray-300 font-12">
                  Make sure that your title is short, clear, and to the point
                </li>
                <li className="text-blueGray-300 font-12">
                  Clients should understand the type of service that you offer
                  immediately
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-title-right">
              <TextField
                id="outlined-basic"
                label="Service Title"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                placeholder="I will..."
                className='input-text'
                value={serviceTitle || ''} // Use empty string as default if service Title is undefined
                onChange={(e) => setServiceTitle(e.target.value)}
              />
              {validationErrors.serviceTitle && <Typography variant="body2" color="error">{validationErrors.serviceTitle}</Typography>}

            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="service-category-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-category-left">
              <Typography className="font-22">Service Category</Typography>
              <Typography className="font-12 text-blueGray-300">
                Select the appropriate Category and Sub-category for your
                service in the dropdown menu. You won’t be able to change this
                once the service is published.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-category-right">
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={6}>
                  <Box className="service-cat-sub-left">
                    <FormControl>
                      <InputLabel id="select-label">Category</InputLabel>
                      <Select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        displayEmpty
                        fullWidth
                        labelId="select-label"
                        placeholder="Please Select Category"
                        renderValue={(selected) => {
                          if (!selected) {
                            return <span>Select one from the dropdown below</span>;
                          }
                          return categories.find(category => category.id === selected)?.title || '';
                        }}
                      >
                        {categories?.map(category => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {validationErrors.selectedCategory && <Typography variant="body2" color="error">{validationErrors.selectedCategory}</Typography>}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={6}>
                  <Box className="service-cat-sub-left">
                    <FormControl fullWidth disabled={!selectedCategory}>
                      <InputLabel shrink htmlFor="demo-simple-select" id="select-label">Sub-category</InputLabel>
                      <Select
                        value={selectedSubCategory}
                        onChange={handleSubCategoryChange}
                        displayEmpty
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id="demo-simple-select"
                        label="Select Sub-Category"
                        placeholder="Please Select Sub-Category"
                        labelId="select-label"
                        renderValue={(selected) => {
                          if (!selected) {
                            return <span>Select one from the dropdown below</span>;
                          }
                          return subCategories.find(subCategory => subCategory.id === selected)?.title || '';
                        }}
                      >
                        {subCategories.map(subCategory => (
                          <MenuItem key={subCategory.id} value={subCategory.id}>{subCategory.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="service-search-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="service-search-left">
              <Typography className="font-22">Search Tags</Typography>
              <Typography className="font-12 text-blueGray-300">
                Add up to 5 words or phrases that best describes your service.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="service-search-right">
              <TextField
                id="outlined-basic"
                // label="Search skills or add your own"
                label="Add Skills"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="E.g. Web Development"
                value={stepSkills}
                onChange={handleSkillChange}
                disabled
              />
            {validationErrors.stepSkills && <Typography variant="body2" color="error">{validationErrors.stepSkills}</Typography>}
            </Box>
            <Box className="most-popular-services">
              <Typography className="font-16">Most popular services</Typography>
              <Box className="button-div-bg-none" sx={{ marginTop: 1.5 }}>
                {selectedCategorySkillData &&
                  selectedCategorySkillData.map((skill) => (
                    <Button
                      key={skill.id}
                      className={selectedSkills && selectedSkills.find((itm) => itm?.id === skill.id) ? "skill-button" : ""}
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => handleSkillSelection(skill)}
                    >
                      {skill.skill_name}
                    </Button>
                  ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="discribe-service-container">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="discribe-service-left">
              <Typography className="font-22">Describe the Service</Typography>
              <Typography className="font-12 text-blueGray-300">
                Briefly describe your Gig (up to 1,200 characters). Make sure
                you:
              </Typography>
              <ul>
                <li className="font-12 text-blueGray-300">
                  Include clear expectations about your tasks or deliverables
                </li>
                <li className="font-12 text-blueGray-300">
                  Are as detailed as possible so clients are able to determine
                  if your service meets their needs.
                </li>
                <li className="font-12 text-blueGray-300">
                  Never add contact information
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Box className="discribe-service-right">
              <TextField
                id="description"
                name="description"
                variant="outlined"
                placeholder='Already have a description? Paste it here!'
                value={formData.description}
                onChange={handleInputChange}
                className='onboarding-input-text'
                multiline  // Enable multiline
                rows={4}   // Set the number of rows to display
                style={{ minHeight: '80px' }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}>
              {validationErrors.description ? <Typography paddingLeft={"32px"} variant="body2" color="error">{validationErrors.description}</Typography> : <Typography paddingLeft={"32px"} color={'#94A3B8'} className='font-12'>  Enter at least 500 characters</Typography>}
              <Typography paddingRight={"24px"} color={'#94A3B8'} className='font-12'> {textCount} characters left</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="btn-content-div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className='go-back-btn'>
            <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
              Go Back
            </Button>
          </Box>
          <Box className="continue-btn">
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              className='btn-rounded btn-large'
              style={{ background: "var(--golden-gradient)" }}
              onClick={handleFormSubmit}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ServiceOverview;
