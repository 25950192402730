import React from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import { GetApiParam } from "../Api/Api";
import { PROJECT_STATUS } from "../constants";

// User Auth Section
import Register from "../Pages/Auth/Register";
import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import OtpVerification from "../Pages/Auth/OtpVerification";
import ResetPassword from "../Pages/Auth/ResetPassword";

// User profile section
import Layout from "../Layouts/Layout/Layout";
import LayoutServiceProposerDashboard from "../Layouts/Layout/LayoutServiceProposerDashBoard";
import LayoutServiceProviderDashboard from "../Layouts/Layout/LayoutServiceProviderDashBoard";
import LayoutServiceProposer from "../Layouts/Layout/LayoutServiceProposer";
import LayoutServiceProvider from "../Layouts/Layout/LayoutServiceProvider";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Projects from "../Pages/Projects/Projects";
import SavedDeals from "../Pages/SavedDeals/SavedDeals";
import TransactionsPage from "../Pages/Transactions/TransactionsPage";
import DocumentsPage from "../Pages/Documents/DocumentsPage";
import Setting from "../Pages/Settings/Setting";

// User KYC Section
// import KYCForm from "../Pages/KYC/KYCForm";

// Investor Section
import LayoutInvestor from "../Layouts/Layout/LayoutInvestor";
import ListingPage from "../Pages/Investor/ListingPage";
import PaymentPage from "../Pages/Investor/PaymentPage";
import UploadedDocs from "../Pages/Investor/SignDocuments";
import ProjectDetailsPage from "../ProjectDetails/ProjectDetailsPage";
import UploadAgreementDocuments from "../ProjectDetails/UploadAgreementDocuments.jsx";
import SeeAllPage from "../Pages/Search/SeeAllPage";
import ProjectList from "../Pages/Projects/ProjectList";

// Landing Pages
import Home from "../Pages/Home/Home";
import ProjectProposer from "../Pages/ProjectProposer/Dashboard";
import AssetCategoryPage from "../LandingPages/AssetCategoryPage";

// Propose Section
import LetsGetStarted from "../../src/Pages/ProjectProposer/Questionnaire/LetsGetStarted";
import Stepper from "../Pages/ProjectProposer/Stepper";

// Service Provider - new
import ServiceMarketplace from "../ServiceProvider/ServiceMarketplace/ServiceMarketplace";
import ServiceCatalog from "../ServiceProvider/ServiceCatalogPage/ServiceCatalog";
import CategoryPage from "../ServiceProvider/CategoryPage/CategoryPage";
import SearchServiceProviderResults from "../ServiceProvider/SearchServiceProviderResults/SearchServiceProviderResults";
import CompanyProfilePage from "../ServiceProvider/CompanyProfilePage/CompanyProfilePage";
import SearchProjectResults from "../ServiceProvider/SearchProjectResults/SearchProjectResults";
import FindServiceProvider from "../ServiceProvider/FindServiceProvider.jsx/FindServiceProvider";
import IndividualProfilePage from "../ServiceProvider/IndividualProfilePage/IndividualProfilePage";
import HomeServiceProvider from "../ServiceProvider/HomeServiceProvider/HomeServiceProvider";
import DashboardOverview from "../ServiceProvider/ClientDashboard/DashboardOverview.jsx";
import DashboardPostProject from "../ServiceProvider/ClientDashboard/DashboardPostProject.jsx";
import DashboardMyOrder from "../ServiceProvider/ClientDashboard/MyOrders/ManageOrders.jsx";
import IndividualJobActivityClient from "../ServiceProvider/ClientDashboard/MyOrders/IndividualOrder/IndividualJobActivity.jsx";
import BrowserRequest from "../ServiceProvider/BrowserRequest/BrowserRequest";
import DashboardWallet from "../ServiceProvider/ClientDashboard/DashboardWallet.jsx";
import ProjectPosting from "../ServiceProvider/ClientDashboard/ReviewProjectPosting/ProjectPosting";
import OnboardingSteps from "../ServiceProvider/OnboardingProfile/OnboardingSteps";
import OnboardingServiceLeft from "../ServiceProvider/OnboardingProfile/OnboardingProfileComponent/OnboardingServiceLeft";
import ServiceProviderOverview from "../ServiceProvider/ServiceProviderDashBoard/ServiceProviderOverview";
import OnboardingReviewPage from "../ServiceProvider/OnboardingReviewPage/OnboardingReviewPage";
import SavedRequests from "../ServiceProvider/ServiceProviderDashBoard/SavedRequests";
import SavedRequestDrawer from "../ServiceProvider/ServiceProviderDashBoard/SavedRequestDrawer";
import ServiceOverview from "../ServiceProvider/ServiceProviderDashBoard/ListService/ServiceOverview.jsx";
import Pricing from "../ServiceProvider/ServiceProviderDashBoard/ListService/Pricing.jsx";
import Requirements from "../ServiceProvider/ServiceProviderDashBoard/ListService/Requirements.jsx";
import Gallery from "../ServiceProvider/ServiceProviderDashBoard/ListService/Gallery.jsx";
import ReviewServicePost from "../ServiceProvider/ServiceProviderDashBoard/ListService/ReviewServicePost.jsx";
import PostNewContract from "../ServiceProvider/ClientDashboard/ProjectContract/NewProjectContract.jsx";
import ReviewNewContract from "../ServiceProvider/ClientDashboard/ProjectContract/ReviewProjectContract.jsx";
import ViewOfferContract from "../ServiceProvider/ServiceProviderDashBoard/MyJobs/ViewOfferContract.jsx";
import IndividualJobActivity from "../ServiceProvider/ServiceProviderDashBoard/MyJobs/IndividualJobActivity.jsx";
import ReviewProviderService from "../ServiceProvider/ReviewProviderService/ReviewService.jsx";
import Faqs from "../ServiceProvider/Faqs/Faq.jsx";
import ViewOfferContractClient from "../ServiceProvider/ClientDashboard/MyOrders/ViewOffer/ViewOfferContract.jsx";

// Routes For DIC
import { LayoutDic } from "../Layouts/Layout/LayoutDic";
import LoginDIC from "../DIC/AuthIC/LoginDIC";
import RegisterDIC from "../DIC/AuthIC/RegisterDIC";
import ForgotPasswordDIC from "../DIC/AuthIC/ForgotPasswordDIC";
import VotingPopup from "../Components/Modal/VotingPopup";
import Overview from "../DIC/Overview/Overview";
import WalletDic from "../DIC/WalletDic/WalletDic";
import ProjectDic from "../DIC/DICProjects/Project";
import DicProjectDetailsPage from "../DIC/DICProjectDetails/ProjectDetailsPage";
import ChatsPage from "../Pages/Chat/ChatsPage";
import ServiceProviderChatsPage from "../ServiceProvider/ChatsModule/ChatsPage";
import OverviewProjectList from "../DIC/Overview/OverviewProjectList";
import SavedDealDic from "../DIC/SaveDealDic/SaveDealDic";

// Extra pages
import InvestmentCommitteeDb from "../Pages/Extra/InvestmentCommittee";
import TermsAndConditions from "../Pages/Extra/TermsAndConditions";
import PrivacyPolicy from "../Pages/Extra/PrivacyPolicy";
import NotFound from "../Pages/Extra/NotFound";
import Dummy from "../Pages/Extra/Dummy";
import Guides from "../Pages/Extra/Guides";
import AboutRace from "../Pages/Extra/AboutRace";
import Team from "../Pages/Extra/Team";
import Feedback from "../Pages/Extra/Feedback";
import Cookies from "../Pages/Extra/Cookies";
import Faq from "../Pages/Extra/Faq";
import ViewSingleRequest from "../ServiceProvider/ViewSingleRequest/ViewSingleRequest.jsx";
import Popup from "../ServiceProvider/Popup/Popup.jsx";
import OnboardingOne from "../ServiceProvider/OnboardingOne/OnboardingOne.jsx";
import OnboardingTwo from "../ServiceProvider/OnboardingTwo/OnboardingTwo.jsx";
import MyprojectPosting from "../ServiceProvider/ClientDashboard/MyProjectPosting/MyprojectPosting";
import ListService from "../ServiceProvider/ServiceProviderDashBoard/ListService/ListService.jsx";
import ServiceProviderPostProject from "../ServiceProvider/ServiceProviderDashBoard/ServiceProviderProfile.jsx";
import MyJobs from "../ServiceProvider/ServiceProviderDashBoard/MyJobs/MyJobs.jsx";
import MyOffers from "../ServiceProvider/ServiceProviderDashBoard/MyOffers/MyOffers.jsx";
import SavedServices from "../ServiceProvider/ServiceProviderDashBoard/SavedServices/SavedServices.jsx";
import Wallet from "../ServiceProvider/ServiceProviderDashBoard/Settings/Settings.jsx";
import Documents from "../ServiceProvider/ServiceProviderDashBoard/Documents/Documents.jsx";
import Settings from "../ServiceProvider/ServiceProviderDashBoard/Settings/Settings.jsx";
// import Demo from "../ServiceProvider/Demo/Demo.jsx";
import PostViewProject from "../ServiceProvider/ClientDashboard/PostViewProjectComponent/PostViewProject.jsx";
import OnboardingOption from "../InvestorOnly/Component/OnboardingOption";
import InvestorHome from "../InvestorOnly/Component/InvestorHome";
import InvestorLogin from "../InvestorOnly/Component/InvestorLogin";
import InvestorRegister from "../InvestorOnly/Component/InvestorRegister";
import LayoutInvestorOnly from "../Layouts/Layout/LayoutInvestorOnly";
import ProtectedRouteDIC from "./ProtectedRouteDIC";
import TransactionsPageProposer from "../Pages/Transactions/TransactionsPageProposer";


// service provider propser login
import ServiceProviderProposerLogin from "../InvestorOnly/Component/ServiceProviderProposerLogin";
// service provider propser register
import ServiceProviderProposerRegister from "../InvestorOnly/Component/ServiceProviderProposerRegister";
// service provider propser verification otp
import OtpVarificationServiceProviderProposer from "../Pages/Auth/OtpVarificationServiceProviderProposer";
// Function to return data when page loaded (calling api to get asset Data and fetching on home page using useLoader)
// getting assetData using Api call
const assetData = async () => {
  try {
    const data = {
      status: PROJECT_STATUS.SUBMITTED,
      is_onboard: 1,
      is_publish: 1
    };
    const res = await GetApiParam("/proposer/getAssetsHomePage", data);
    return res?.data ? res?.data : [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

// Function to get TopLoans
const getTopLoan = async () => {
  try {
    const payLoadForTopLoan = {
      top_loan: true,
      is_onboard: 1,
      is_publish: 1
    };
    const res = await GetApiParam("/proposer/getAssetsHomePage", payLoadForTopLoan);
    return res?.data?.data ? res?.data?.data : [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

// Function to get TopInvestment
const getTopInvestments = async () => {
  try {
    const payloadForTopInvestment = {
      top_investment: true,
      is_onboard: 1,
      is_publish: 1
    };
    const res = await GetApiParam("/proposer/getAssetsHomePage", payloadForTopInvestment);
    return res?.data?.data ? res?.data?.data : [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

const isUserAuthenticated = () => {
  const user = localStorage.getItem("user_data");
  const login = localStorage.getItem("login");
  if (login && user) {
    return true;
  } else {
    return false;
  }
};
const isDICAuthenticated = () => {
  const user = localStorage.getItem("user_data");
  const dic_user = localStorage.getItem("user_data_dic");
  const login = localStorage.getItem("login");
  if (login && dic_user?.id == user?.id && dic_user?.first_name == user?.first_name) {
    return true;
  }
};
const router = createBrowserRouter([
  /**
   * DIC Routes
   */
  {
    path: "/dic",
    children: [
      {
        path: "",
        element: <LayoutDic />,
        loader: () => {
          const authenticated = isDICAuthenticated();
          // if user is not authenticated or logged in then redirect to home page
          if (!authenticated) {
            throw redirect("/");
          }
          return null;
        },
        children: [
          {
            index: true,
            path: "overview",
            element: <Overview />,
          },
          {
            path: "project",
            element: <ProjectDic />,
          },
          {
            path: "overviewprojectlist",
            element: <OverviewProjectList />,
          },
          {
            path: "chats",
            element: <ChatsPage />,
          },
          {
            path: "saveddealdic",
            element: <SavedDealDic />,
          },
          {
            path: "wallet",
            element: <WalletDic />,
          },
        ],
      },
      {
        path: "login",
        element: <LoginDIC />,
      },
      {
        path: "register",
        element: <RegisterDIC />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordDIC />,
      },
      {
        path: "project-details/:assetId",
        element: <DicProjectDetailsPage />,
      },
      // Additional route to viewOnly Details on overView Page
      {
        path: "project-details-view/:assetId",
        element: <DicProjectDetailsPage />,
      },
    ],
  },

  /**
   * Main Website Routes
   */
  {
    path: "/",
    element: <ProtectedRouteDIC><LayoutInvestorOnly /></ProtectedRouteDIC>,
    children: [
      {
        path: "/",
        // Setting Loader Data for the home page
        loader: async () => {
          let resAssetData = await assetData();
          // let resTopLon = await getTopLoan();
          let resTopInvestment = await getTopInvestments();
          return {
            assetData: resAssetData,
            // topLoan: resTopLon,
            topInvestment: resTopInvestment,
          };
        },
        element: <InvestorHome />,
      },
      {
        path: "investor-listing-invest",
        element: <ListingPage />,
      },
      {
        path: "investor-listing-loan",
        element: <ListingPage />,
      },
      {
        path: "project-details/:assetId",
        element: <ProjectDetailsPage />,
      },
      /**
       * Route for the  project Review Status check on proposer Side
       */
      {
        path: "project-details-inreview/:assetId",
        element: <ProjectDetailsPage />,
      },
      {
        path: "investor-payment/:listing",
        element: <PaymentPage />,
      },
      {
        path: "investor-uploaded-docs",
        element: <UploadedDocs />,
      },
      {
        path: "asset-category/:type",
        element: <AssetCategoryPage />,
      },
      {
        path: "project-proposer",
        element: <ProjectProposer />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },

      {
        path: "search",
        loader: async () => {
          let resAssetData = await assetData();
          return resAssetData;
        },
        element: <SeeAllPage />,
      },
    ],
  },

  /**
   * User Routes
   */
  {
    path: "/user",
    element: <Layout />,
    loader: () => {
      const authenticated = isUserAuthenticated();
      // if user is not authenticated or logged in then redirect to home page
      if (!authenticated) {
        throw redirect("/");
      }
      return null;
    },
    children: [
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "completeprojects",
        element: <ProjectList />,
      },
      {
        path: "portfolio",
        element: <Portfolio />,
      },
      {
        path: "saved-deals",
        element: <SavedDeals />,
      },
      {
        path: "transactions",
        element: <TransactionsPage />,
      },
      {
        path: "proposer-transactions",
        element: <TransactionsPageProposer />,
      },
      {
        path: "documents",
        element: <DocumentsPage />,
      },
      {
        path: "settings",
        element: <Setting />,
      },
      {
        path: "chats",
        element: <ChatsPage />,
      },
    ],
  },
  {
    path: "/questionnaire-get-started",
    element: <LetsGetStarted />,
  },
  {
    path: "/questionnaire-flow",
    element: <Stepper />,
  },
  {
    path: "/upload-agreement-documents",
    element: <UploadAgreementDocuments />,
  },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  {
    path: "/login",
    element: <InvestorLogin />,
  },
  {
    path: "/service-proposer-login",
    element: <ServiceProviderProposerLogin userTypeLocal={'client'}/>,
  },
  {
    path: "/service-provider-login",
    element: <ServiceProviderProposerLogin userTypeLocal={'spv'}/>,
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  {
    path: "/register",
    element: <InvestorRegister />,
  },
  {
    path: "/service-proposer-register",
    element: <ServiceProviderProposerRegister userTypeLocal={'client'}/>,
  },
  {
    path: "/service-provider-register",
    element: <ServiceProviderProposerRegister userTypeLocal={'spv'}/>,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/otp-verification",
    element: <OtpVerification />,
  },
  {
    path: "/otp-verification-service-provider",
    element: <OtpVarificationServiceProviderProposer userTypeLocal={'spv'}/>,
  },
  {
    path: "/otp-verification-service-proposer",
    element: <OtpVarificationServiceProviderProposer userTypeLocal={'client'}/>,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/modal/voting-popup",
    element: <VotingPopup />,
  },
  // {
  //   path: "/individual-pil",
  //   element: <KYCForm />,
  // },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/dummy",
    element: <Dummy />,
  },
  {
    path: "/guides",
    element: <Guides />,
  },
  {
    path: "faq",
    element: <Faq />,
  },
  {
    path: "about-race",
    element: <AboutRace />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/cookies",
    element: <Cookies />,
  },
  {
    path: "/investment-committee-dashboard",
    element: <InvestmentCommitteeDb />,
  },

  /**
   * Service proposer Module
   */
  {
    path: "/service-proposer",
    element: <LayoutServiceProposer />,
    loader: () => {
      const authenticated = isUserAuthenticated();
      // if user is not authenticated or logged in then redirect to home page
      if (!authenticated) {
        throw redirect("/");
      }
      return null;
    },
    children: [
      {
        index: true,
        element: <ServiceMarketplace />,
      },
      {
        path: "service-marketplace",
        element: <ServiceMarketplace />,
      },
      {
        path: "service-catalog",
        element: <ServiceCatalog />,
      },
      {
        path: "client-category/:id",
        element: <CategoryPage />,
      },
      {
        path: "client-category-results",
        element: <SearchProjectResults />,
      },
      {
        path: "find-a-service-provider",
        element: <FindServiceProvider />,
      },
      {
        path: "service-provider-results",
        element: <SearchServiceProviderResults />,
      },
      {
        path: "profile/company/:id",
        element: <CompanyProfilePage />,
      },
      {
        path: "profile/individual/:id",
        element: <IndividualProfilePage />,
      },
      {
        path: "provider-service/:id",
        element: <ReviewProviderService />,
      },
    ],
  },

  /**
   * ServiceProposer Dashboard Routes
   */
  {
    path: "/service-proposer-dashboard",
    element: <LayoutServiceProposerDashboard />,
    loader: () => {
      const authenticated = isUserAuthenticated();
      // if user is not authenticated or logged in then redirect to home page
      if (!authenticated) {
        throw redirect("/");
      }
      return null;
    },
    children: [
      {
        path: "overview",
        element: <DashboardOverview />,
      },
      {
        path: "postProject",
        element: <DashboardPostProject />,
      },
      {
        path: "postNewContract/:projectId/:spvId/:offerId",
        element: <PostNewContract />,
      },
      {
        path: "reviewNewContract",
        element: <ReviewNewContract />,
      },
      {
        path: "reviewproject",
        element: <ProjectPosting />,
      },
      {
        path: "projectposting",
        element: <MyprojectPosting />,
      },
      {
        path: "viewprojectposting/:id",
        element: <PostViewProject />,
      },
      {
        path: "myorder",
        element: <DashboardMyOrder />,
      },
      {
        path: "view-job/:jobId",
        element: <IndividualJobActivityClient/>
      },
      {
        path: "view-contract/:contractId",
        element: <ViewOfferContractClient />,
      },
      {
        path: "chats",
        element: <ServiceProviderChatsPage />,
      },
      {
        path: "saved-services",
        element: <SavedServices />,
      },
      {
        path: "wallet",
        element: <DashboardWallet />,
      },
      {
        path: "documents",
        element: <Documents />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "faq",
        element: <Faqs type="ServiceProposer"/>,
      },
    ],

  },

  /**
   * Service Provider Dashboard Routes
   */

  {
    path: "/service-provider-dashboard",
    element: <LayoutServiceProviderDashboard />,
    loader: () => {
      const authenticated = isUserAuthenticated();
      // if user is not authenticated or logged in then redirect to home page
      if (!authenticated) {
        throw redirect("/");
      }
      return null;
    },
    children: [
      {
        path: "listservice",
        element: <ListService />,
      },
      {
        path: "overview",
        element: <ServiceProviderOverview />,
      },
      {
        path: "profile",
        element: <ServiceProviderPostProject />,
      },
      {
        path: "savedrequest",
        element: <SavedRequests />,
      },
      {
        path: "chats",
        element: <ServiceProviderChatsPage />,
      },
      {
        path: "savedrequestdrawer",
        element: <SavedRequestDrawer />,
      },
      {
        path: "serviceoverview",
        element: <ServiceOverview />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "Requirements",
        element: <Requirements />,
      },
      {
        path: "Gallery",
        element: <Gallery />,
      },
      {
        path: "review-service-post/:id",
        element: <ReviewServicePost />,
      },
      {
        path: "my-jobs",
        element: <MyJobs />,
      },
      {
        path: "my-offers",
        element: <MyOffers />,
      },
      {
        path: "view-contract/:contractId",
        element: <ViewOfferContract />,
      },
      {
        path: "view-job/:jobId",
        element: <IndividualJobActivity />,
      },
      {
        path: "wallet",
        element: <DashboardWallet />
        // element: <Wallet />,
      },
      {
        path: "documents",
        element: <Documents />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "faq",
        element: <Faqs type="ServiceProvider"/>,
      },
    ],
  },

  /**
   * Service Provider Routes
   */
  {
    path: "/service-provider",
    element: <LayoutServiceProvider />,
    children: [
      {
        index: true,
        element: <HomeServiceProvider />,
      },
      {
        path: "browser-request",
        element: <BrowserRequest />,
      },
      {
        path: "view-single-request/:projectId",
        element: <ViewSingleRequest />,
      }
    ],
  },
  {
    path: "popup",
    element: <Popup />,
  },
  {
    path: "/service-provider/onbording1",
    element: <OnboardingOne />,
  },
  {
    path: "/service-provider/onbording2",
    element: <OnboardingTwo />,
  },
  {
    path: "/service-provider/onboarding",
    element: <OnboardingSteps />,
  },
  {
    path: "/service-provider/reviewprofile",
    element: <OnboardingReviewPage />,
  },

  // {
  //   path: '/service-provider/service-left',
  //   element: <OnboardingServiceLeft />
  // },

  // for investor only
  {
    path: "/onboarding",
    element: <OnboardingOption />,
  },
  {
    path: "/investor-login",
    element: <InvestorLogin />,
  },
  {
    path: "/investor-register",
    element: <InvestorRegister />,
  },
]);

export default router;
