import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  MenuItem,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import "./EditNamePopup.css";
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import { updateApi } from "../../../Api/Api";
import countryList from "react-select-country-list";

const EditNamePopup = ({ onOpen, handleClose, userDetails, getProfileData }) => {
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    firstName: userDetails?.first_name || "",
    lastName: userDetails?.last_name || "",
    location: userDetails?.address || "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors("");
  }, [handleClose]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    setFormData({
      ...formData,
      [name]: updatedValue,
    });

    // Clear the error message when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const initialCountryList = countryList().getData().map(country => ({
    label: `${country.label} (${country.value})`,
    value: country.value
  }));

  const sortedCountryList = initialCountryList.sort((a, b) => a.label.localeCompare(b.label));

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    // Validate first name
    if (!formData.firstName || formData.firstName.trim() === '') {
      errors.firstName = 'First name is required';
    }

    // Validate last name
    if (!formData.lastName || formData.lastName.trim() === '') {
      errors.lastName = 'Last name is required';
    }

    // Validate country of residence
    if (!formData.location || formData.location.trim() === '') {
      errors.location = 'Country of Residence is required.';
    }

    // Set errors state with validation results
    setErrors(errors);
    // If there are no errors, proceed with onSave and handleClose
    if (Object.keys(errors).length === 0) {

      let updateData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        address: formData.location, // Renamed from displayName to location
      }
      updateApi(`/services/updateProfile/${userDetails?.id}`, updateData).then((res) => {
        if (res?.status === 200) {
          showSnackbar("Updated Successfully", 'success');
          getProfileData();
          handleClose(); // Close the modal
        } else { // something went wrong user or backend side
          showSnackbar(res?.data?.message, 'error');
          handleClose(); // Close the modal
        }
      })
    }
  };

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
              <CloseIcon className="cross-icon-modal" />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit User Details
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box className="DatePicker SPAddEducation">
                    <TextField
                      id="first-name"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      placeholder='Enter your name'
                      className='onboarding-input-text'
                      value={formData.firstName}
                      onChange={handleInputChange}
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName}

                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="DatePicker SPAddEducation">
                    <TextField
                      id="last-name"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      placeholder='Enter your surname'
                      className='onboarding-input-text'
                      value={formData.lastName}
                      onChange={handleInputChange}
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Typography className="font-18" mt={2}>
              Country
            </Typography>
            <Box mt={2}>
              <TextField
                className='roles_select'
                InputLabelProps={{
                  style: { color: '#fff' }, // Set the label text color to white
                }}
                label="Country of Residence"
                id="outlined-select-country-native"
                select
                defaultValue=""
                name='location' // Renamed from displayName to location
                value={formData.location}
                onChange={handleInputChange}
                error={Boolean(errors.location)}
                helperText={errors.location?.message}
                fullWidth
                InputProps={{
                  style: { color: '#fff' }, // Set the input text color to white
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set maximum height for the dropdown
                      },
                    },
                  },
                }}
              >
                {sortedCountryList?.slice(0, 5).map((item, index) => (
                  <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                ))}
                {sortedCountryList?.slice(5).map((item, index) => (
                  <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                ))}
              </TextField>
            </Box>
            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{
                      background: "var(--golden-gradient)",
                      color: "var(--blueGray-900)",
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};


export default EditNamePopup;
