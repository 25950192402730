import { Box, Grid } from "@mui/material";
import React, { useMemo, useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import RaceProjectCard from "../RaceProjectCard/RaceProjectCard";


const SlickSlider = ({ projects, selectedProject, projectData }) => {
  const sliderRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const handleProjectId = (project) => {
    projectData(project);
  };

  useEffect(() => {
    if(projects && projects?.data?.length > 0 && selectedProject){
      // Find the index of the object with the specified id
      const idx = projects?.data.findIndex(item => item.id === selectedProject?.id);
      sliderRef.current.slickGoTo(idx);
      setFocusedIndex(idx);
    }
  }, [projects, selectedProject]);

  const settings =  useMemo(() => {
    let slidesToShow = 4;
    if(projects?.data && projects?.data && projects?.data?.length === 1)slidesToShow = 1;
    if(projects?.data && projects?.data && projects?.data?.length === 2)slidesToShow = 4;
    return {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          }
        }
      ],
    }
  },[projects])
  
  return (
    <Box>
      <div className="slider-container" style={{ marginRight: "10px", cursor: "pointer" }} >
        <Slider ref={sliderRef} {...settings}>
            {projects?.data?.length > 0 && projects?.data?.map((project, index) => (
                <div key={index} onClick={() => handleProjectId(project)}>
                  <RaceProjectCard project={project} focused={index === focusedIndex ? 'focused' : 'unfocused'} navigate={false}/>
                </div>
            ))}
            {projects?.data?.length === 2 && 
              <div key={2}>
                &nbsp;
              </div>
            }
        </Slider>
      </div>
    </Box>
  );
};

export default SlickSlider;
