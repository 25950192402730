import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Box, Paper, InputBase, TableRow, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select, Stack, Grid, Modal, IconButton } from '@mui/material';
import { ImageOutlined, RemoveRedEye, Edit, Download, SwapVert, Search, ViewComfy } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import FileViewer from "react-file-viewer";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { GetApiParam } from '../../../Api/Api';
import config from '../../../Config/Config';
import "../../../Pages/Transactions/Transactions.css"
import moment from 'moment';
import { getLoggedInUserType } from '../../../Services/localstorage.service';

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

const ContractDocument = () => {
    const [documentsData, setDocumentsData] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ itemsPerPage, setItemsPerPage ] = useState(5);
    const [ paginationData, setPaginationData ] = useState({
        "page": 1,
        "pageSize": 0,
        "totalCount": 0,
        "totalPages": 0
    });

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const loggedInUserType = getLoggedInUserType();

    /**
     * here we get documents list
     */
    useEffect(() => {
        let u = localStorage.getItem('user_data');
        let User = JSON.parse(u);
        const getContractDocument = async () => {
            let payload = {
                userType: loggedInUserType === 'spv' ? 'spv' : 'client',
                tab: `contract`,
                page: currentPage,
                rowperpage: itemsPerPage
            }
            let res = await GetApiParam(`/services/getDocuments/${User.id}`, payload);
            if (res?.data?.data) {
                const newData = [...res.data.data];
                setDocumentsData(newData);
                setPaginationData(res.data.pagination)
            }
        }
        getContractDocument();
    }, [currentPage, itemsPerPage])


    /**
     * open modal to display doc content
     * @param {Array} documentdata 
     * @returns {void}
     */
    const viewDoc = async (documentdata) => {
        setOpenModal(true);
        setView(true);
        if (documentdata?.path) {
            // const fileName = `${process.env.REACT_APP_IMAGE_URL}${documentdata.path ? documentdata.path : ""}`;
            // setFile(fileName);
            const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentdata.path ? documentdata.path : ""
                }`);

            const templateBuffer = await fileName.arrayBuffer();
            const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
            const templateUrl = URL.createObjectURL(templateBlob);
            setFile(templateUrl);
        }
        if (documentdata?.type === 'document' || documentdata?.type === 'doc') {
            setType('pdf');
        } else {
            setType(documentdata?.type);
        }
    };

    /**
     * handle modal close
     */
    const handleClose = () => { setOpenModal(false); setView(false) };

    // modal styles
    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <>
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Document Name</StyledTableCell>
                            <StyledTableCell align="left">Project Name</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.original_name}`}>
                                <StyledTableCell component="th" scope="row">{row.original_name}</StyledTableCell>
                                <StyledTableCell align="left">  {row.projectDetails?.title}</StyledTableCell>
                                <StyledTableCell align="left">{moment(row.createdAt).format('DD.MM.YYYY, HH:mm')}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Chip
                                        sx={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                            color: "#13202D",
                                            bgcolor: config?.CONTRACT_STATUS?.CONTRACT_STATUS_COLOR[config.CONTRACT_STATUS[row.projectDetails.status]],
                                            borderRadius: "8px",
                                        }}
                                        label={config?.CONTRACT_STATUS[row.projectDetails.status]}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        spacing={3}
                                    >
                                        <IconButton>
                                            <RemoveRedEye onClick={() => viewDoc(row)} style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                        <IconButton disabled={row.projectDetails.status === config.CONTRACT_STATUS[6] ? false : true}>
                                            <Edit style={{ color: row.projectDetails.status === config.CONTRACT_STATUS[6] ? "#FFFFFF" : "#053863"  }} />
                                        </IconButton>
                                        <IconButton>
                                            <Download style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {paginationData?.totalCount > 0 ?
                  <Box className="pagination-container">
                    <Pagination
                      count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Box>:  <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
                      Record not available
                </Typography> }
            </TableContainer>
            {/* modal code start from here */}
            <Grid>
                {view &&
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            <OverlayScrollbarsComponent defer
                                style={{ width: '100%', height: '100%' }}
                            >
                                {/* {<FileViewer fileType={type} filePath={file} />} */}
                                <object
                                    data={file}
                                    type="application/pdf"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <p style={{ textAlign: 'center' }}>
                                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                    </p>
                                </object>
                            </OverlayScrollbarsComponent>
                        </Box>
                    </Modal>
                }
            </Grid>
            {/* modal code end from here */}
        </>
    )
}

export default ContractDocument