// releasedAsset.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * Call the withdrawalAsset method to transfer the asset to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function releasedAsset(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        let Contract_Address = ""

        if (assetData.category_id === CATEGORY_TYPE_ID.WATCH && assetData.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            Contract_Address = CONTRACTS.WATCH_MARKETPLACE
        } else if (assetData.category_id === CATEGORY_TYPE_ID.ART && assetData.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            Contract_Address = CONTRACTS.ART_SALE_MARKETPLACE
        } else if (assetData.category_id === CATEGORY_TYPE_ID.ART && assetData.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            Contract_Address = CONTRACTS.ART_PARCEL
        } else if (assetData.category_id === CATEGORY_TYPE_ID.ASSET && assetData.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            Contract_Address = CONTRACTS.WATCH_MARKETPLACE
        } else if (assetData.category_id === CATEGORY_TYPE_ID.FUNDS && assetData.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            Contract_Address = CONTRACTS.FUNDS_SPACE_X_MARKETPLACE
        } else if (assetData.category_id === CATEGORY_TYPE_ID.FUNDS && assetData.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
            Contract_Address = CONTRACTS.FUNDS_MARKETPLACE
        }
        console.log('Contract_Address', Contract_Address, assetData);
        const { request: withdrawalAsset } = await prepareWriteContract({
            address: Contract_Address,
            abi: CONTRACT_ABI[Contract_Address],
            account: address,
            chainId: 6806,
            functionName: "releasedAsset",
            args: [
                assetData?.listing_id, // listing id 
            ]
        })

        const { hash } = await writeContract(withdrawalAsset)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw amount sale:', error);
        throw error; // propagate the error
    }
}

export { releasedAsset };