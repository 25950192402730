import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardHeader, Chip, Typography, Stack, Button, Divider, Tooltip } from '@mui/material'
import { User } from "../Components/Images/Images";
import { useNavigate } from 'react-router-dom';
import { GetApiParam, postApi } from '../Api/Api';
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS, STATUS_OF_PROJECT, SUB_CATEGORY_TYPE_ID } from '../constants';
import { formatNumber } from '../Components/Common/USFormat';
import OfferDetailsModal from './OfferDetailsModal';
import TransactionProgressModal from '../Components/SmartContract/TransactionProgressModal';
import TimerIcon from '@mui/icons-material/Timer';

export default function Offers({ asset_Id, is_direct_buy, is_parcel, is_parcel_sold, category_id }) {

    const navigate = useNavigate()

    // all states
    const [offersData, setOffersData] = useState([])
    const [modelOpen, setModelOpen] = useState(false);
    const [offerDetails, setOfferDetails] = useState('');
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [openModalCreateEMI, setOpenModalCreateEMI] = useState(false);
    const [openModalWithdrawalSale, setOpenModalWithdrawalSale] = useState(false);
    const [openModalWithdrawalFunds, setOpenModalWithdrawalFunds] = useState(false);
    const [propData, setPropData] = useState();
    const [assetData, setAssetData] = useState();
    const [restateData, setRestateData] = useState();
    const [fundsData, setFundsData] = useState();
    const [rejectReasons, setRejectReasons] = useState([]);
    const [equityWithdraw, setEquityWithdraw] = useState(false);
    const [seniorWithdraw, setSeniorWithdraw] = useState(false);
    const [juniorWithdraw, setJuniorWithdraw] = useState(false);
    const [classAWithdraw, setAWithdraw] = useState(false);
    const [classBWithdraw, setBWithdraw] = useState(false);
    const [withdrawLoan, setWithdrawLoan] = useState(false);
    const [isClaimAvailable, setIsClaimAvailable] = useState(false);
    const [isClaimAvailable1, setIsClaimAvailable1] = useState(false);
    const [isClaimAvailable2, setIsClaimAvailable2] = useState(false);
    const [isClaimAvailable3, setIsClaimAvailable3] = useState(false);
    const [isClaimAvailable4, setIsClaimAvailable4] = useState(false);
    const [totalFundedAmount, setTotalFundedAmount] = useState(0);
    const [challengePeriods, setChallengePeriods] = useState(0);
    const [isParcelWithdrawAvail, setIsParcelWithdrawAvail] = useState(true);
    const [transactionData, setTransactionData] = useState([]);
    const [noWithdrawAmt, setNoWithdrawParcelAmt] = useState(false);
    const [isRemainingPaymentDone, setIsRemainingPaymentDone] = useState(false);
    const [openSpaceXCloseFund, setOpenSpaceXCloseFund] = useState(false);
    const [spaceXISWithdraw, setSpaceXISWithdraw] = useState(false);

    // Function to fetch offers data
    const getOffers = async () => {
        try {
            let payload = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payload);
            setOffersData(res?.data?.data);
        } catch (error) {
            console.error(error)
        }
    }
    // Function to fetch asset data
    const getAssetData = async () => {
        try {
            let payload = { asset_id: asset_Id }
            const res = await postApi("/proposer/assetsDetails", payload);
            if (res?.data?.data) {
                setAssetData(res?.data?.data[0]);
            }
        } catch (error) {
            console.error(error)
        }
    }
    // Function to fetch asset data
    const getAssetDicConfig = async () => {
        try {
            const res = await GetApiParam("/proposer/getAssetDicConfig/", { assetId: asset_Id });
            if (res?.data?.data && res?.data.data?.challengePeriod) {
                setChallengePeriods(res.data.data?.challengePeriod);
            }
        } catch (error) {
            console.error(error)
        }
    }
    // Function to fetch asset data
    const getRestateInvestment = async () => {
        try {
            let payload = { asset_id: asset_Id, category_id: CATEGORY_TYPE_ID.REAL_ESTATE }
            const res = await GetApiParam("/transaction/getRestateInvestment", payload);
            if (res?.data?.data) {
                setRestateData(res?.data?.data);
            }
        } catch (error) {
            console.error(error)
        }
    }
    // Function to fetch asset data
    const getFundInvestment = async () => {
        try {
            let payload = { asset_id: asset_Id, category_id: CATEGORY_TYPE_ID.FUNDS }
            const res = await GetApiParam("/transaction/getRestateInvestment", payload);
            if (res?.data?.data) {
                setFundsData(res?.data?.data);
            }
        } catch (error) {
            console.error(error)
        }
    }
    // Function to fetch asset data
    const getRejectReasons = async () => {
        try {
            let payload = { asset_id: asset_Id }
            const res = await GetApiParam("committee/getRejectReason", payload);
            if (res?.data?.data) {
                setRejectReasons(res?.data?.data);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllTransaction = async (params) => {
        try {
            let res = await GetApiParam('/transaction/getTransactions', params);
            if (res?.data?.data) {
                setTransactionData(res?.data?.data);
            }
        } catch (error) {
            console.error(error)
        }
    }

    // useEffect to fetch offers data 
    useEffect(() => {
        if (transactionData?.length) {
            let ids = assetData?.parcelData?.filter(item => item.is_sold)?.map((item) => item.title.split('#')[1]);
            let compareId = []
            let data = transactionData?.filter((item) => item?.add_asset_owner_id != item?.user_id && item?.is_parcel_withdraw);
            for (let i = 0; i < data?.length; i++) {
                let parcelIds = data[i].parcel_ids.split(',');
                compareId = compareId.concat(parcelIds)
            }
            if (ids?.length == compareId.length) {
                setNoWithdrawParcelAmt(true)
            } else {
                setNoWithdrawParcelAmt(false)
            }
        }
    }, [transactionData])

    // useEffect to fetch offers data 
    useEffect(() => {
        const timer = setTimeout(() => {
            if (category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                getRestateInvestment();
            }
            if (category_id === CATEGORY_TYPE_ID.FUNDS) {
                getFundInvestment();
            }
            if (is_parcel) {
                getAllTransaction({ asset_id: asset_Id, category_id: category_id })
            }
            getOffers();
            getAssetData();
            getRejectReasons();
            getAssetDicConfig();
        }, 100);
        return () => clearTimeout(timer)
    }, [])

    // handle is withdraw for space x funds
    useEffect(() => {
        if (offersData?.length && assetData && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            if (offersData?.some((item) => item.status == OFFER_STATUS.COMPLETED)) {
                setSpaceXISWithdraw(true);
            }
        }
    }, [offersData, assetData])


    /**
     * Function to handle submitting an offer and open the modal
     * @param {Object} val 
     */
    const handleOfferSubmit = async (val) => {
        console.log(val)
        setModelOpen(true);
        setOfferDetails(val);
    }

    /**
    * Function to handle changing offer status (Accept or Reject)
    * @param {Object} val
    * @param {String} offer_status
    */
    const handleOfferStatusChange = async (offer_status, val) => {
        if (val.investment_type_id === INVESTMENT_TYPE_ID.LOAN || val.investment_type_id === INVESTMENT_TYPE_ID.SALE || val.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
            setPropData({ offer_status, val });
            if (offer_status === OFFER_STATUS.ACCEPTED) {
                navigate(`/investor-payment/${val.investment_type_id === INVESTMENT_TYPE_ID.LOAN ? "Loan" : val.investment_type_id === INVESTMENT_TYPE_ID.SALE ? "Sale" : 'Fractionalize'}`, { state: { from: 'offer-page', offer_status, blockchainOfferId: val?.blockchainOfferId, offerAmount: val?.offer_amount, val, offerId: val.id, assetId: asset_Id } })
            } else {
                setOpenTransactionModal(true);
            }
        }
        handleClose()
    }

    /**
     * Used for sale asset withdrawal and watch/gold , sale , parcel create emi
     */
    const handleClaimToken = () => {
        setPropData(assetData);
        if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            navigate('/investor-payment/Parcelling', { state: { from: 'parcel-buy', assetId: asset_Id, assetData } })
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE && is_direct_buy) {
            navigate('/investor-payment/Sale', { state: { from: 'direct-buy', assetId: asset_Id, assetData } })
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            setOpenModalWithdrawalSale(true);
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            setOpenModalWithdrawalFunds(true);
        } else {
            setOpenModalCreateEMI(true);
        }
    }

    /**
     * Used for sale asset withdrawal and watch/gold , sale , parcel create emi
     */
    const handleSpaceXCloseFund = () => {
        setPropData(assetData);
        setOpenSpaceXCloseFund(true);

    }

    /**
    * Used for sale asset withdrawal and watch/gold create emi
    */
    const handleWithDrawAmount = (item) => {
        setPropData(assetData);
        if (item === 'Equity') {
            navigate('/investor-payment/Fractionalize', { state: { from: 'equity-amount', capital_type_id: CAPITAL_TYPE_ID.EQUITY, assetId: asset_Id, assetData, type: 'Equity' } })
        } else if (item === 'Senior') {
            navigate('/investor-payment/Fractionalize', { state: { from: 'senior-amount', capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT, assetId: asset_Id, assetData } })
        } else if (item === 'Junior') {
            navigate('/investor-payment/Fractionalize', { state: { from: 'junior-amount', capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT, assetId: asset_Id, assetData } })
        } else if (item === 'ClassA') {
            navigate('/investor-payment/Fractionalize', { state: { from: 'class-a', class: 1, assetId: asset_Id, assetData } })
        } else if (item === 'ClassB') {
            navigate('/investor-payment/Fractionalize', { state: { from: 'class-b', class: 2, assetId: asset_Id, assetData } })
        }
    }

    /**
      * Function to handle modal close
      */
    const handleModalClose = () => {
        setOpenTransactionModal(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseCreateEMI = () => {
        setOpenModalCreateEMI(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseWithdrawalSale = () => {
        setOpenModalWithdrawalSale(false);
    }
    /**
    * Function to handle modal close
    */
    const handleModalCloseWithdrawalFunds = () => {
        setOpenModalWithdrawalFunds(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseSpaceXFunds = () => {
        setOpenSpaceXCloseFund(false);
    }


    /**
    * Function call after transaction confirm
    */
    const confirmStake = async ({ offer_status, val }) => {
        try {
            let payloadNew = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payloadNew);
            setOffersData(res?.data?.data);
        } catch (error) {
            console.error(error)
        }

    };

    /**
     * Function call after transaction confirm
     */
    const confirmCreateEMI = async () => {
        console.log('work done');
        try {
            let payloadNew = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payloadNew);
            setOffersData(res?.data?.data);
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * Function call after transaction confirm
    */
    const confirmWithdrawalSale = async () => {
        console.log('work done');
        try {
            let payloadNew = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payloadNew);
            setOffersData(res?.data?.data);
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * Function call after transaction confirm
    */
    const confirmWithdrawalFunds = async () => {
        console.log('work done');
        try {
            let payloadNew = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payloadNew);
            setOffersData(res?.data?.data);
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * Function call after transaction confirm
    */
    const confirmWithdrawalSpaceXFunds = async () => {
        console.log('work done');
        try {
            let payloadNew = { asset_id: asset_Id }
            const res = await GetApiParam("/invest/offer", payloadNew);
            setOffersData(res?.data?.data);
            await getAssetData();
        } catch (error) {
            console.error(error)
        }
    }


    // Function to close the modal
    const handleClose = () => {
        setModelOpen(false)
    }

    function Timer(props) {
        let challengePeriod = challengePeriods + 300;// in secs
        let claimPercentage = assetData?.claim_percentage ? parseFloat(parseFloat(assetData?.claim_percentage) / 100).toFixed(2) : 0 // in secs

        const formatTime = (seconds) => {
            const days = Math.floor(seconds / (24 * 60 * 60));
            const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
            const minutes = Math.floor((seconds % (60 * 60)) / 60);
            const secs = seconds % 60;
            return `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${secs}s`;
        };
        let timerData = 0;
        let totalAmount = 0;
        if (props?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            let amount = 0, amount1 = 0, amount2 = 0;
            let offersDataFiltered = offersData.filter(offer => offer.status === OFFER_STATUS.COMPLETED || offer.status === OFFER_STATUS.ACCEPTED);
            // Sort in ascending order
            offersDataFiltered.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });


            for (let i = 0; i < offersDataFiltered.length; i++) {
                let item = offersDataFiltered[i];
                // for 75 % and completed challenge
                if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && (item.status === OFFER_STATUS.COMPLETED)) {
                    amount += parseFloat(item.offer_amount)
                    amount1 += parseFloat(item.offer_amount)
                    amount2 += parseFloat(item.offer_amount)
                } // accepted and completed challenge
                else if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && (item.status === OFFER_STATUS.ACCEPTED)) {
                    amount1 += parseFloat(item.offer_amount)
                    amount2 += parseFloat(item.offer_amount)
                } else if ((Math.floor(new Date().getTime() / 1000) < Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && item.status === OFFER_STATUS.ACCEPTED && item.offer_accepted_time) {
                    timerData = Math.max(timerData, (Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000)));
                    amount2 += parseFloat(item.offer_amount)
                    break;
                }
            }
            if (amount >= parseFloat((assetData?.loan_amount) * claimPercentage)) {
                setIsClaimAvailable(true);
                if (amount1 > amount) {
                    timerData = 0;
                }
                totalAmount = amount
            } else if (amount1 >= parseFloat((assetData?.loan_amount) * claimPercentage)) {
                setIsClaimAvailable(true);
                totalAmount = Math.max(amount, amount1);
            } else if (amount2 >= parseFloat((assetData?.loan_amount) * claimPercentage) && timerData) {
                setIsClaimAvailable(false);
                totalAmount = amount2
            }
            // if (totalAmount < parseFloat((assetData?.loan_amount) * claimPercentage)) {
            //     timerData = 0;
            // }
        } else if (props?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let totalAmountReceived = 0, totalAmountReceived1 = 0, flag = 0;
            let restateDataFiltered = restateData?.filter(data => data.user_id !== data.add_asset_owner_id && data.add_capital_type_id === props?.type);
            let capital_info = assetData?.capital_info?.find(data => data?.capital_type?.id === props?.type)
            for (let i = 0; i < restateDataFiltered.length; i++) {
                let item = restateDataFiltered[i];
                if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item.createdAt).getTime() / 1000) + challengePeriod)) {
                    totalAmountReceived += parseFloat(item.amount)
                    totalAmountReceived1 += parseFloat(item.amount)
                } else if (item.is_equity_withdraw === false && (Math.floor(new Date().getTime() / 1000) < Math.floor(new Date(item.createdAt).getTime() / 1000) + challengePeriod)) {
                    timerData = Math.max(timerData, (Math.floor(new Date(item.createdAt).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000)));
                    totalAmountReceived1 += parseFloat(item.amount)
                    break;
                }
            }

            if (totalAmountReceived < capital_info?.total_raise * claimPercentage || totalAmountReceived1 < capital_info?.total_raise * claimPercentage || timerData || restateDataFiltered?.every((item => item.is_equity_withdraw))) {
                if (props?.type === CAPITAL_TYPE_ID.EQUITY) {
                    console.log(props.type)
                    setIsClaimAvailable(false)
                }
                else if (props?.type === CAPITAL_TYPE_ID.SENIOR_DEBT) {
                    setIsClaimAvailable1(false)
                }
                else if (props?.type === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
                    setIsClaimAvailable2(false)
                }
            } else {
                if (props?.type === CAPITAL_TYPE_ID.EQUITY) {
                    setIsClaimAvailable(true)
                }
                else if (props?.type === CAPITAL_TYPE_ID.SENIOR_DEBT) {
                    setIsClaimAvailable1(true)
                }
                else if (props?.type === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
                    setIsClaimAvailable2(true)
                }
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            let amount = 0, amount1 = 0, amount2 = 0;
            let offersDataFiltered = offersData.filter(offer => offer.status === OFFER_STATUS.COMPLETED || offer.status === OFFER_STATUS.ACCEPTED && offer.is_commitment == true);
            // Sort in ascending order
            offersDataFiltered.sort((a, b) => {
                return new Date(a.offer_accepted_time) - new Date(b.offer_accepted_time);
            });

            for (let i = 0; i < offersDataFiltered.length; i++) {
                let item = offersDataFiltered[i];
                // for 75 % and completed challenge
                if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && (item.status === OFFER_STATUS.COMPLETED)) {
                    amount += parseFloat(item.offer_amount)
                    amount1 += parseFloat(item.offer_amount)
                    amount2 += parseFloat(item.offer_amount)
                } // accepted and completed challenge
                else if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && (item.status === OFFER_STATUS.ACCEPTED)) {
                    amount1 += parseFloat(item.offer_amount)
                    amount2 += parseFloat(item.offer_amount)
                } else if ((Math.floor(new Date().getTime() / 1000) < Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod) && item.status === OFFER_STATUS.ACCEPTED && item.offer_accepted_time) {
                    timerData = Math.max(timerData, (Math.floor(new Date(item.offer_accepted_time).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000)));
                    amount2 += parseFloat(item.offer_amount)
                    break;
                }
            }

            if (amount >= parseFloat((assetData?.fractionalize_total_price)) * (claimPercentage) || amount1 >= parseFloat((assetData?.fractionalize_total_price)) * (claimPercentage)) {
                setIsClaimAvailable(true);
                totalAmount = Math.max(amount, amount1);
                if (amount1 > amount) {
                    timerData = 0
                }
            } else if (amount2 >= parseFloat((assetData?.fractionalize_total_price)) * (claimPercentage) && timerData) {
                setIsClaimAvailable(false);
                totalAmount = amount2
            }
            // if (totalAmount < parseFloat((assetData?.fractionalize_total_price) * claimPercentage)) {
            //     timerData = 0;
            // }
        } else if (props?.is_parcel_sold) {
            let parcelData = assetData?.parcelData;
            parcelData = parcelData?.filter((item) => item.sold_time && item.is_sold)
            parcelData?.sort((a, b) => {
                return new Date(a?.sold_time) - new Date(b?.sold_time);
            });

            // 
            let compareId = []
            let data = transactionData?.filter((item) => item?.add_asset_owner_id != item?.user_id && item?.is_parcel_withdraw);
            for (let i = 0; i < data?.length; i++) {
                let parcelIds = data[i].parcel_ids.split(',');
                compareId = compareId.concat(parcelIds)
            }
            let remainingForWithdraw = false;
            let alreadyWith = 0;
            for (let i = 0; i < parcelData?.length; i++) {
                let item = parcelData[i];
                if ((Math.floor(new Date().getTime() / 1000) >= Math.floor(new Date(item?.sold_time).getTime() / 1000) + challengePeriod) && (item?.is_sold) && item?.sold_time) {
                    totalAmount += parseFloat(item.price);
                    if (compareId?.includes(item.title.split('#')[1]) == false) {
                        remainingForWithdraw = true;
                    } else {
                        alreadyWith++;
                    }
                } else if ((Math.floor(new Date().getTime() / 1000) < Math.floor(new Date(item?.sold_time).getTime() / 1000) + challengePeriod) && (item?.is_sold) && item?.sold_time) {
                    timerData = Math.max(timerData, (Math.floor(new Date(item?.sold_time).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000)));
                    totalAmount += parseFloat(item.price);
                }
            }
            // if (alreadyWith === compareId?.length) {
            //     setNoWithdrawParcelAmt(true);
            // }
            if (remainingForWithdraw && (totalAmount >= parseFloat(assetData?.parcel_total_price) * claimPercentage)) {
                timerData = 0;
            }
        } else if (props?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            if (is_direct_buy) {
                setIsRemainingPaymentDone(true)
                timerData = (Math.floor(new Date(assetData?.direct_buy_time).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000))
            } else {
                let acceptedOffer = offersData?.find((item) => item?.full_payment);
                if (acceptedOffer?.full_payment) {
                    setIsRemainingPaymentDone(true)
                    timerData = (Math.floor(new Date(acceptedOffer?.sale_payment_time).getTime() / 1000) + challengePeriod - Math.floor(new Date().getTime() / 1000))
                }
            }
        }

        const [elapsedTime, setElapsedTime] = useState(timerData > 0 ? timerData : 0);
        useEffect(() => {
            if (elapsedTime <= 0) {
                if (props?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                    if ((totalAmount >= parseFloat((assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ? assetData?.fractionalize_total_price : assetData?.loan_amount) * claimPercentage))) {
                        setIsClaimAvailable(true);
                    } else {
                        setIsClaimAvailable(false);
                    }
                }

                if (props?.is_parcel_sold) {
                    if ((totalAmount >= parseFloat(assetData?.parcel_total_price) * claimPercentage)) {
                        props?.setState(false);
                    } else {
                        props?.setState(true);
                    }
                } else {
                    props?.setState(false);
                }
                return
            }; // Stop when challenge period over 

            const timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime - 1);
                props?.setState(true);
            }, 1000);

            return () => clearInterval(timer); // Cleanup on component unmount
        }, [elapsedTime]);

        return (
            <>
                {elapsedTime > 0 ?
                    <Box style={{ margin: '2px 0px 16px 0px' }}>
                        <Chip
                            avatar={<TimerIcon style={{ color: 'white' }} />}
                            label={`${formatTime(elapsedTime)}`}
                            style={{
                                backgroundColor: 'darkgray', // Choose your desired background color
                                color: 'white', // Adjust text color for better contrast
                                fontWeight: 'bold',
                                height: '30px'
                            }}
                        />
                    </Box>
                    : null
                }
            </>
        );
    }

    useEffect(() => {
        const getFundedAmount = async () => {
            const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": asset_Id })
            if (resp?.data?.code === 200 && resp?.data?.totalAmount) {
                setTotalFundedAmount(resp?.data?.totalAmount)
            }
        }
        if (asset_Id) {
            getFundedAmount()
        }
    }, [])
    return (
        <>
            {/* Fund Close Button*/}
            {assetData?.is_closed ?
                <Box className='asset-sold-status'>
                    <Chip label="Closed" style={{ background: '#dc2626', color: 'white' }} />
                </Box>
                :
                assetData?.is_onboard && !assetData?.is_closed && (offersData?.length == 0 && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS || (offersData && offersData?.length > 0 && offersData?.every((item) => item.status !== OFFER_STATUS.COMPLETED && item.status !== OFFER_STATUS.RETURNED && item?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS))) ?
                    <Box sx={{ textAlign: 'right' }}>
                        <Button style={{ marginBottom: '20px' }} className='btn-rounded btn close-space-x-fund' onClick={() => { handleSpaceXCloseFund() }}>Close Fund</Button>
                    </Box> :
                    null
            }
            {assetData?.project_status === STATUS_OF_PROJECT?.REJECTED ?
                <Box>
                    <Box>
                        <Chip className="pc-chip-capitalinfo rejected" label={"Rejected"} />
                        <Box className="pd-sidebar-Offers-wrap">
                            <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                {rejectReasons?.length ? <Typography className="title" component={'h4'}>Reject Reasons</Typography> : null}
                            </Stack>
                            {rejectReasons?.length ? <Divider /> : null}
                            {rejectReasons?.map((reason) =>
                                <>
                                    <Typography p={1} key={reason?.id}>{reason?.reject_reason}</Typography>
                                    <Divider />
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
                :
                is_parcel || category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                    is_parcel_sold ?
                        <Box textAlign={'right'}>
                            <Button disabled={Boolean(noWithdrawAmt || (isParcelWithdrawAvail === true ? true : false))} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleClaimToken() }}>Withdraw Amount</Button>
                            <Timer is_parcel_sold={true} setState={setIsParcelWithdrawAvail} />
                        </Box>
                        : category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                            <>
                                {restateData && restateData?.length && restateData?.some(item => item.add_capital_type_id === CAPITAL_TYPE_ID.EQUITY) ?
                                    <Box textAlign={'right'}>
                                        <Button disabled={Boolean(equityWithdraw || isClaimAvailable === false)} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleWithDrawAmount('Equity') }}>Withdraw Equity Amount</Button>
                                        <Timer category_id={CATEGORY_TYPE_ID.REAL_ESTATE} type={CAPITAL_TYPE_ID.EQUITY} setState={setEquityWithdraw} />
                                        {Boolean(equityWithdraw && isClaimAvailable === false) ? <Typography></Typography> : ''}
                                    </Box> : null
                                }
                                {restateData && restateData?.length && restateData?.some(item => item.add_capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT) ?
                                    <Box Box textAlign={'right'}>
                                        <Button disabled={Boolean(seniorWithdraw || isClaimAvailable1 === false)} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleWithDrawAmount('Senior') }}>Withdraw Senior Amount</Button>
                                        <Timer category_id={CATEGORY_TYPE_ID.REAL_ESTATE} type={CAPITAL_TYPE_ID.SENIOR_DEBT} setState={setSeniorWithdraw} />
                                    </Box> : null
                                }
                                {restateData && restateData?.length && restateData?.some(item => item.add_capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT) ?
                                    <Box Box textAlign={'right'}>
                                        <Button disabled={Boolean(juniorWithdraw || isClaimAvailable2 === false)} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleWithDrawAmount('Junior') }}>Withdraw Junior Amount</Button>
                                        <Timer category_id={CATEGORY_TYPE_ID.REAL_ESTATE} type={CAPITAL_TYPE_ID.JUNIOR_DEBT} setState={setJuniorWithdraw} />
                                    </Box> : null
                                }
                            </>
                            : category_id === CATEGORY_TYPE_ID.FUNDS ?
                                <>
                                    {fundsData && fundsData?.length && fundsData?.some(item => item.class == 1) ?
                                        <Box textAlign={'right'}>
                                            <Button disabled={Boolean(classAWithdraw || isClaimAvailable3 === false)} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleWithDrawAmount('ClassA') }}>Withdraw Class A Amount</Button>
                                            <Timer category_id={CATEGORY_TYPE_ID.FUNDS} type={'1'} setState={setAWithdraw} />
                                            {Boolean(equityWithdraw && isClaimAvailable === false) ? <Typography></Typography> : ''}
                                        </Box> : null
                                    }
                                    {fundsData && fundsData?.length && fundsData?.some(item => item.class == 2) ?
                                        <Box Box textAlign={'right'}>
                                            <Button disabled={Boolean(classBWithdraw || isClaimAvailable4 === false)} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleWithDrawAmount('ClassB') }}>Withdraw Class B Amount</Button>
                                            <Timer category_id={CATEGORY_TYPE_ID.FUNDS} type={'2'} setState={setBWithdraw} />
                                        </Box> : null
                                    }
                                </>
                                : null
                    :
                    <Box className="pd-sidebar-Offers-wrap">
                        {is_direct_buy ? <Box className='asset-sold-status'>
                            <Chip label="Sold" style={{ background: '#dc2626', color: 'white', marginBottom: '8px' }} />
                        </Box>
                            : null}
                        <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            {is_direct_buy ?
                                <></>
                                : <Typography className="title" component={'h4'}>{assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ? "Commitments" : "Offers"}</Typography>}
                            {(offersData && offersData?.length > 0 && (offersData?.some((val) => val?.status === OFFER_STATUS.ACCEPTED && ((assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE ? val?.full_payment : val?.is_commitment && (offersData && offersData?.length > 0 && offersData?.every((item) => item.status != OFFER_STATUS.COMPLETED))) || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN))) || is_direct_buy === 1) ?
                                <>
                                    <Button disabled={Boolean(((withdrawLoan || (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) && isClaimAvailable === false) || (isRemainingPaymentDone == false && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE)))} style={{ marginBottom: '10px' }} className='btn-rounded btn claim' onClick={() => { handleClaimToken() }}>Withdraw Amount</Button>
                                    {assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ?
                                        <Timer investment_type_id={assetData?.investment_type_id} category_id={assetData?.category_id} asset_sub_category={assetData?.asset_sub_category} setState={setWithdrawLoan} /> : null
                                    }
                                </>
                                : null}
                        </Stack>

                        {
                            (offersData && offersData?.length > 0) ? offersData?.map((val, ind) => {
                                {
                                    return (
                                        <Card className={`pd-action-card offer-btn-chip ${val?.status === OFFER_STATUS.PENDING ? "" : "not-pending"}`} key={ind}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar alt='User' src={User} aria-label="recipe" sx={{ width: '40px', height: '40px' }}></Avatar>
                                                }
                                                action={
                                                    (val.payment_method == 'fiat' && val?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && ((val?.investment_doc_tables?.length) && val?.investment_doc_tables[0].approval_status == 0)) ?
                                                        <Tooltip title={"Waiting for admin to approve the investment documents"} arrow>
                                                            <span>
                                                                <Button disabled={true} className="btn-rounded btn view-offer" sx={{ ml: 1.5 }}>
                                                                    View Commitment
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                        :
                                                        (val?.status === OFFER_STATUS.RETURNED || ((val?.status === OFFER_STATUS.PENDING || val?.status === OFFER_STATUS.ACCEPTED) && spaceXISWithdraw)) ? <Chip style={{ background: '#94A3B8' }} label="Returned" />
                                                            :
                                                            (val?.status === OFFER_STATUS.COMPLETED) ? <>
                                                                <Chip label="Completed" />
                                                                {/* <Button className='btn-rounded btn btn-green-400 claim'>Claimed</Button> */}
                                                            </>
                                                                :
                                                                (val?.status === OFFER_STATUS.ACCEPTED) ?
                                                                    <Chip label="Accepted" />
                                                                    :
                                                                    (val?.status === OFFER_STATUS.REJECTED || val?.status === OFFER_STATUS.ADMIN_REJECTED) ?
                                                                        <Chip style={{ background: '#94A3B8' }} label={val?.status === OFFER_STATUS.ADMIN_REJECTED ? "Rejected by Admin" : "Rejected"} />
                                                                        :
                                                                        (val?.status === OFFER_STATUS.CANCELLED) ? <Chip style={{ background: '#94A3B8' }} label="Cancelled" />
                                                                            : Boolean((val?.offer_amount > (assetData?.loan_amount - totalFundedAmount)) && assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) ?
                                                                                <Tooltip title={"Waiting for investor to update the offer price"} arrow>
                                                                                    <span>
                                                                                        <Button disabled={(val?.offer_amount > (assetData?.loan_amount - totalFundedAmount) && assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) ? true : false} className="btn-rounded btn view-offer" sx={{ ml: 1.5 }} onClick={() => { handleOfferSubmit(val) }} >
                                                                                            View {val?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Commitment" : "Offer"}
                                                                                        </Button>
                                                                                    </span>
                                                                                </Tooltip> :
                                                                                <Button disabled={val?.status === OFFER_STATUS.HOLD ? true : false} className="btn-rounded btn view-offer" sx={{ ml: 1.5 }} onClick={() => { handleOfferSubmit(val) }} >
                                                                                    View {val?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Commitment" : "Offer"}
                                                                                </Button>
                                                }
                                                title={`${val?.investor_details?.member_id} ${assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ? "committed" : "offered"}`}
                                                subheader={`$${formatNumber(val?.offer_amount ? val?.offer_amount : 0)}`}
                                            />
                                        </Card>
                                    )
                                }
                            })
                                : <Typography textAlign={'center'}>{is_direct_buy ? "" : `Currently, there are no available ${assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ? "investments." : 'offers.'}`}</Typography>
                        }
                    </Box >
            }
            {modelOpen && <OfferDetailsModal open={modelOpen} offerDetails={offerDetails} onClose={handleClose} handleOfferStatusChange={handleOfferStatusChange} />}

            {/* code for listing smart contract modal */}
            {openTransactionModal ? <TransactionProgressModal identity={'accept-reject-offer'} confirmStake={confirmStake} propData={propData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}

            {/* code for payment confirmation for offer loan smart contract modal */}
            {openModalCreateEMI ? <TransactionProgressModal identity={'create-emi'} confirmStake={confirmCreateEMI} propData={propData} handleModalClose={handleModalCloseCreateEMI} openTransactionModal={openModalCreateEMI} /> : null}

            {/* code for withdrawal of asset smart contract modal */}
            {openModalWithdrawalSale ? <TransactionProgressModal identity={'withdrawal-asset-sale'} confirmStake={confirmWithdrawalSale} propData={propData} handleModalClose={handleModalCloseWithdrawalSale} openTransactionModal={openModalWithdrawalSale} /> : null}

            {/* for funds */}
            {openModalWithdrawalFunds ? <TransactionProgressModal identity={'withdraw-funds'} confirmStake={confirmWithdrawalFunds} propData={propData} handleModalClose={handleModalCloseWithdrawalFunds} openTransactionModal={openModalWithdrawalFunds} /> : null}

            {/* close fund space x  */}
            {openSpaceXCloseFund ? <TransactionProgressModal identity={'close-space-x-fund'} confirmStake={confirmWithdrawalSpaceXFunds} propData={propData} handleModalClose={handleModalCloseSpaceXFunds} openTransactionModal={openSpaceXCloseFund} /> : null}
        </>

    )
}

Offers.propTypes = {
}
