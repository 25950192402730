import DealTerms from "../DealTerms";
import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import CheckReview from "../CheckReview";
import Templates from "../Templates";
import CapitalInfo from "../CapitalInfo";
import { SUB_CATEGORY_TYPE_ID } from "../../../constants";
import { formatNumber } from "../../../Components/Common/USFormat";

export default class Funds extends BaseDetailsAsset {

  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {
    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Funds data is required in Art Details class"
      );
    }


    this.offersData = [
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
    ];

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name,
      },
      // {
      //   title: "Property Type",
      //   value: _data.owner_information
      //     ? _data.asset_sub_category_info?.title
      //     : "",
      // },
      {
        title: "Fund Type",
        value: _data.asset_sub_category_info?.title ?? "",
      },
      {
        title: "Owned By",
        value: _data.owner_information
          ? _data.owner_information.owner_type
          : "",
      },
    ];

    this.checkReview = [
      {
        title: "All required documents uploaded",
        value: false,
      },
      {
        title: "Deal terms reviewed",
        value: false,
      },
      {
        title: "Asset Details provided are accurate",
        value: false,
      },
      {
        title: "Project Description and Title reviewed",
        value: false,
      },
      {
        title: "Images uploaded are clear and accurate",
        value: false,
      },
    ];

    this.dealData = [
      {
        title: 'Total Raise',
        value: `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
      },
      {
        title: `${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Initial Project Term" : 'Duration of Project'}`,
        value: `${_data?.fractionalize_duration_of_project
          } ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
      },
    ];
    // setting deal data for fund sub type
    if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
      this.dealData = [...this.dealData,
      {
        title: "No. of Share Classes",
        value: _data?.capital_type,
        num_of_class: _data?.number_of_shared
      }
      ]
    } else if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
      this.dealData = [...this.dealData,
      {
        title: 'Fund Raising Period',
        value: `${_data?.investment_duration
          } ${_data?.investment_duration > 1 ? "Days" : "Day"}`,
      },
      {
        title: 'Minimum Investment',
        value: `$${formatNumber(parseFloat(_data?.fractionalize_minimum_investment))}`,
      },
      {
        title: 'Management Fees',
        value: `${formatNumber(parseFloat(_data?.management_fees))}%`,
      },
      // {
      //   title: 'Per Year Fees',
      //   value: `${formatNumber(parseFloat(_data?.management_fees / _data?.fractionalize_duration_of_project))}%`,
      // },
      {
        title: 'Carried Interest',
        value: `${formatNumber(parseFloat(_data?.carried_interest))}%`,
      }
      ]
    }

    this.data = _data;
    this.blockchain_id = _data.blockchain_id
    this.fromClass = "fundDetails";
    this.docs = _data.asset_document;
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.location = _data?.asset_location?.split(",");
    this.galleryImages = _data?.asset_gallery;
    this.member_id = _data?.user_details?.member_id

    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.coverPhoto = _data?.asset_coverphoto;
    this.descriptionData = {
      title: _data?.asset_title,
      subtitle: _data?.asset_address,
      address: _data?.owner_information?.owner_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
    };

    if (this.isFraction) {
      if (_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
        this.prices = {
          'Total Raise': `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
          // 'Minimum Investment': `$${_data?.fractionalize_minimum_investment}`,
          'Initial Project Term': `${_data?.fractionalize_duration_of_project
            }  ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
        }
      }
      else {
        this.prices = {
          'Total Raise': `$${formatNumber(parseFloat(_data?.fractionalize_total_price))}`,
          // 'Minimum Investment': `$${_data?.fractionalize_minimum_investment}`,
          'Project Duration': `${_data?.fractionalize_duration_of_project
            }  ${_data.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year") : (_data?.fractionalize_duration_of_project > 1 ? "Years" : "Year")}`,
        }
      }
    }

    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverPhoto} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        address={this.descriptionData.address}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        userId={this.user_id}
        assetId={this.asset_Id}
      />
    );
  };

  setRightPanelComponents = () => {
    this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    this.rightPanel.push(<PriceOverview prices={this.prices} />);
    // if (this.isFraction && !this.isOwner ) this.rightPanel.push(<FractionView />)
    this.rightPanel.push(<CapitalInfo capitalInfo={this.capitalInfo} />)
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} />);
    this.rightPanel.push(<DealTerms dealTerms={this.dealData} fromPage={this.fromClass} editable={this.isOwner} />);
    this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Asset Documents" />);
    this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} assetId={this.asset_Id} ownerId={this.ownerInfo} editable={this.isOwner} section_title="Agreement Documents" />);
    this.rightPanel.push(<Templates selected_templates={this.selected_templates} />);
    this.rightPanel.push(<CheckReview data={this.data} blockchain_id={this.blockchain_id} assetId={this.asset_Id} details={this.checkReview} editable={this.isOwner} reviewList={this.reviewList} readOnly={this.readOnly} asset_signing_document={this.asset_signing_document} template_data={this.selected_templates} />);
  };
}
