import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {  LogoInvestor } from "../../Components/Images/Images";
import {
  Box,
  Button,
  Stack,
  Avatar,
} from "@mui/material";
import { GetApi } from "../../Api/Api";
import Launch from "../../InvestorOnly/LaunchPopUp/Launch";
import "./Header.css";
import Popup from "../../ServiceProvider/Popup/Popup";

const HeaderInvestorOnly = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [categoryData, setCategoryData] = useState();
  const [launchClick, setLaunchClick] = useState(false);
  const [serviceClick, setServiceClick] = useState(false);
  const [isServiceProviderModalPopup, setIsServiceProviderModalPopup] = useState(false);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
    console.log("called");
    setLaunchClick(true);
  };
  const handleClose1 = (id) => {
    setAnchorEl1(null);
    document.documentElement.scrollTop = 0;
  };

  const handleCancel = () => {
    setLaunchClick(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Api to get categoryData
    async function headergetCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    headergetCategoryData();
  }, []);

  // code for restricting Routes for the Categories
  const renderPath = (val) => {
    switch (val.id) {
      case 2:
        return `/asset-category/${val.url}`;
      case 9:
        return `/asset-category/${val.url}`;
      case 8:
        return `/asset-category/${val.url}`;
      case 7:
        return `/asset-category/${val.url}`;
      default:
        return "#";
    }
  };

  function scrollTop() {
    document.documentElement.scrollTop = 0;
  }

  const handleCloseServiceProviderModal = () => {
    setIsServiceProviderModalPopup(false);
  }
  return (
    <>
      <Box className="main-header-investor-only">
        <Box className="container">
          <Box className="investor-only-item">
            <Box display={"flex"} alignItems={"center"}>
              <Box className="investor-only-logo">
                <Link to="/" onClick={scrollTop}>
                  <Avatar variant="square" src={LogoInvestor} alt="Logo" />
                </Link>
              </Box>
              <Stack
                direction="row"
                spacing={4}
                className="headerLeft-menu"
                sx={{
                  display: {
                    xs: "flex",
                    md: "flex",
                    padding: "16px",
                    marginLeft: { xs: 0, md: "32px" },
                  },
                }}
              >
                {/* remove code before live */}
                <Box className="menu-item">
                  <Link to="/dic/login">
                    Decentralized Investment Committee
                  </Link>
                </Box>
                <Box className="menu-item">
                  <Link onClick={() => setIsServiceProviderModalPopup(true)}>Service Provider</Link>
                </Box>
              </Stack>
            </Box>
            <Button
              className="btn-rounded btn-golden"
              id="fade-button"
              onClick={handleClick1}
            >
              Launch App{" "}
            </Button>
          </Box>
          {isServiceProviderModalPopup ? <Popup open={isServiceProviderModalPopup} handleCloseModal={handleCloseServiceProviderModal} /> : null}
          {launchClick ? (
            <Launch handleCancel={handleCancel} openModal={true} />
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeaderInvestorOnly;
