import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Grid } from '@mui/material';
import { useTheme } from "@mui/material/styles"
import SwipeableViews from "react-swipeable-views"
import OffersPanel from './OffersPanel.jsx';
import LoansPanel from './LoansPanel.jsx';
import TransactionsPanel from './TransactionsPanel.jsx';
import EquityPanel from './EquityPanel.jsx';
import FundsPanel from './FundsPanel.jsx';
import { useLocation, useNavigate } from "react-router-dom";
import './Transactions.css';
import FundsDistributionPanel from './FundDistributionsPanel.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div >
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

export default function TransactionsPageProposer() {

  // state to handle the tabs value
  const theme = useTheme()
  const [value, setValue] = useState(0);
  const location = useLocation();
  const state = location.state;

  // useEffect(() => {
  //   // Check if state.from is "notification"
  //   if (state && state.from === "notification") {
  //     // Set the tab to "My Offers" (index 1)
  //     setValue(1);
  //   }
  // }, [state]);

  /**
   * Handle the change in tabs
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Handle the change in index
   * @param {*} index 
   */
  const handleChangeIndex = index => {
    setValue(index)
  }

  console.log("location.state", state)

  return (
    <>
      <Grid className='transactions-list-head investor-transaction-list-head'>
        <Box className='transactions-list-head-inside'>
          <Box>
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              {/* <Tab label="My Transactions" className={value === 0 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(0)} /> */}
              <Tab label="Loans" className={value === 0 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(1)} />
              {/* <Tab label="Equity" className={value === 3 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(3)} /> */}
              <Tab label="Distribution" className={value === 1 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(1)} />
            </Tabs>
          </Box>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {/* <TabPanel value={value} index={0} >
              <TransactionsPanel comingFrom={'investor'} />
            </TabPanel> */}
            <TabPanel value={value} index={0} >
              <LoansPanel setValue={setValue} />
            </TabPanel>
            {/* <TabPanel value={value} index={3} >
              <EquityPanel />
            </TabPanel> */}
            <TabPanel value={value} index={1} >
              <FundsDistributionPanel setValue={setValue} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Grid>
    </>
  );
}