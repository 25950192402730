// calculateMinimumPrepaymentAmount.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculateMinimumPrepaymentAmount(payEMIData, amount) {
    try {
        let contract_address = ''
        if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            contract_address = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
        } else if (payEMIData.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            contract_address = CONTRACTS.WATCH_REPAYMENT_ADDRESS
        }
        if (contract_address) {
            const getMinimumPrepaymentData = await readContract({
                address: contract_address,
                abi: CONTRACT_ABI[contract_address],
                functionName: "calculateMinimumPrepaymentAmount",
                chainId: 6806,
                watch: true,
                args: [
                    payEMIData.listing_id,
                    amount * 1e18
                ]
            });

            return getMinimumPrepaymentData
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculateMinimumPrepaymentAmount };
