// proposerApproveSuggestion.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * send the proposer accept or reject dic deal term status
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Boolean} proposerDecision approve or reject suggestion by proposer
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function proposerApproveSuggestion(address, proposalId, proposerDecision, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        let contract_address = CONTRACTS.CREATE_PROPOSAL;
        if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            contract_address = CONTRACTS.FUND_SPACE_X_CREATE_PROPOSAL
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            contract_address = CONTRACTS.FUND_CREATE_PROPOSAL
        }
        const { request: approvedSuggestion } = await prepareWriteContract({
            address: contract_address,
            abi: CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6806,
            functionName: "approvedSuggestion",
            args: [
                proposalId, // asset's blockchain id
                proposerDecision == true ? 1 : 2
            ]
        })

        const { hash } = await writeContract(approvedSuggestion)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                handleActiveStep(2);
                showSnackbar("Transaction Successful", 'success')
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { proposerApproveSuggestion };