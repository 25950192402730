import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import "./ListService.css";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import ReviewServicePostLeft from "./ReviewServicePostLeft";
import ReviewServicePostRight from "./ReviewServicePostRight";
import { postApi } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import { LoadingButton } from "@mui/lab";

const Publish = ({ onBack, sharedDataStepFour, previousData, sharedDataStepTwo, sharedDataStepThree, stepTwoPreviousData, stepThreePreviousData, sharedData }) => {

  const documentFiles = sharedDataStepThree?.document?.flat();

  const starterData = {
    "title": sharedDataStepTwo?.starterTitle,
    "description": sharedDataStepTwo?.starterDesc,
    "deliveryDays": sharedDataStepTwo?.starterDeliveryDays,
    "revisions": sharedDataStepTwo?.starterRevisions,
    "includedAdditionalService": sharedDataStepTwo?.starterIncAddService
  };

  const standardData = {
    "title": sharedDataStepTwo?.standardTitle,
    "description": sharedDataStepTwo?.standardDesc,
    "deliveryDays": sharedDataStepTwo?.standardDeliveryDays,
    "revisions": sharedDataStepTwo?.standardRevisions,
    "includedAdditionalService": sharedDataStepTwo?.standardIncAddService
  };

  const advancedData = {
    "title": sharedDataStepTwo?.advancedTitle,
    "description": sharedDataStepTwo?.advancedDesc,
    "deliveryDays": sharedDataStepTwo?.advancedDeliveryDays,
    "revisions": sharedDataStepTwo?.advancedRevisions,
    "includedAdditionalService": sharedDataStepTwo?.advancedIncAddService
  };
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const localData = getUserDataFromLocalStorage();
  const [loading, setLoading] = useState(false);
  /**
  * Function to handle form submission
  * Validates the OTP length and sends a request to verify the OTP
  * Displays success or error message based on the response
  */

  useEffect(() => {
    function scrollTopFunction() {
      document.documentElement.scrollTop = 0;
    }
    scrollTopFunction()
  }, []);

  const handleCreateService = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("user_id", localData ? localData?.id : "");
    formData.append("services_title", sharedData?.serviceTitle);
    formData.append("sp_category_id", sharedData?.selectedCategory);
    formData.append("sp_sub_category_id", 1);
    formData.append("starter_price_scope", JSON.stringify(starterData));
    formData.append("standard_price_scope", JSON.stringify(standardData));
    formData.append("advacend_price_scope", JSON.stringify(advancedData));
    formData.append("starter_service_price", sharedDataStepTwo?.starterPrice ? sharedDataStepTwo?.starterPrice : parseFloat(sharedDataStepTwo?.starterPrice));
    formData.append("standard_service_price", sharedDataStepTwo?.standardPrice ? sharedDataStepTwo?.standardPrice : 0);
    formData.append("advacend_service_price", sharedDataStepTwo?.advancedPrice ? sharedDataStepTwo?.advancedPrice : 0);
    formData.append("describe_services", sharedData?.description);
    formData.append("requirment_describe", sharedDataStepThree?.requirementDocument);

    documentFiles?.forEach((document) => {
      formData.append("document", document);
    });

    sharedDataStepFour?.galleryDetails?.forEach((gallery) => {
      formData.append("gallery", gallery.gallery_image);
    });
    const skillIds = sharedData?.selectedSkills?.map((skill) => skill.id);
    formData.append(
      "skill",
      sharedData?.selectedSkills?.length > 0
        ? JSON.stringify(skillIds)
        : sharedData?.selectedSkills
    );
    postApi(`/services/createService`, formData).then((res) => {
      if (res?.status === 200) {
        showSnackbar('Created Successfully', 'success');
        navigate("/service-provider-dashboard/profile");
        setLoading(false);
      } else { // something went wrong user or backend side
        setLoading(false);
        showSnackbar(res?.data?.message, 'error');
      }
    })
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Box className="review-service-post-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">Review Service Post</Typography>
        </Box>
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box className="go-back-btn">
              <Button startIcon={<ArrowBackIcon />} onClick={onBack}>Go Back</Button>
            </Box>
            <Box className="continue-btn">
              <LoadingButton
                onClick={handleCreateService}
                startIcon={<ArrowForwardIcon />}
                style={{ background: "var(--golden-gradient)" }}
                loading={loading}
                loadingPosition="start"
                variant="contained"
                type="submit"
                className="btn-rounded btn-large"
              >
                Create Service
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className="review-content">
        <Grid container spacing={2}>
          <ReviewServicePostLeft sharedDataStepFour={sharedDataStepFour} sharedDataStepThree={sharedDataStepThree} sharedData={sharedData} previousData={previousData} />
          <ReviewServicePostRight stepTwoPreviousData={stepTwoPreviousData} stepThreePreviousData={stepThreePreviousData} sharedDataStepTwo={sharedDataStepTwo} sharedDataStepThree={sharedDataStepThree} />
        </Grid>
      </Box>
    </Box>
  )
}

export default Publish;
