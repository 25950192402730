import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const Pricing = ({ onNext, onBack, onDataUpdate, stepTwoPreviousData, }) => {
  const [showInputs, setShowInputs] = useState(true);
  const [switchLabel, setSwitchLabel] = useState("3 tiers");
  const [value, setValue] = useState({
    starterTitle: '', standardTitle: '', advancedTitle: '',
    starterDesc: '', standardDesc: '', advancedDesc: '',
    starterDeliveryDays: '', standardDeliveryDays: '', advancedDeliveryDays: '',
    starterRevisions: '', standardRevisions: '', advancedRevisions: '',
    starterIncAddService: '', standardIncAddService: '', advancedIncAddService: '',
    starterPrice: '', standardPrice: '', advancedPrice: ''
  });
  const [starterTextCount, setStarterTextCount] = useState(30);
  const [standardTextCount, setStandardTextCount] = useState(30);
  const [advancedTextCount, setAdvancedTextCount] = useState(30);
  const [starterDescTextCount, setStarterDescTextCount] = useState(80);
  const [standardDescTextCount, setStandardDescTextCount] = useState(80);
  const [advancedDescTextCount, setAdvancedDescTextCount] = useState(80);
  const [starterIncAddServiceTextCount, setStarterIncAddServiceTextCount] = useState(80);
  const [standardIncAddServiceTextCount, setStandardIncAddServiceTextCount] = useState(80);
  const [advancedIncAddServiceTextCount, setAdvancedIncAddServiceTextCount] = useState(80);
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;
    let maxCharCount = 0;

    // Determine max character count based on input field name
    switch (name) {
      case 'starterTitle':
        maxCharCount = 30;
        break;
      case 'standardTitle':
        maxCharCount = 30;
        break;
      case 'advancedTitle':
        maxCharCount = 30;
        break;
      case 'starterDesc':
        maxCharCount = 80;
        break;
      case 'standardDesc':
        maxCharCount = 80;
        break;
      case 'advancedDesc':
        maxCharCount = 80;
        break;
      case 'starterIncAddService':
        maxCharCount = 80;
        break;
      case 'standardIncAddService':
        maxCharCount = 80;
        break;
      case 'advancedIncAddService':
        maxCharCount = 80;
        break;
      default:
        break;
    }
    // Check if value length is within the limit
    if (value.length > maxCharCount) {
      updatedValue = value.substring(0, maxCharCount); // Trim the value to the character limit
    }

    // Update character count
    switch (name) {
      case 'starterTitle':
        setStarterTextCount(maxCharCount - updatedValue.length);
        break;
      case 'standardTitle':
        setStandardTextCount(maxCharCount - updatedValue.length);
        break;
      case 'advancedTitle':
        setAdvancedTextCount(maxCharCount - updatedValue.length);
        break;
      case 'starterDesc':
        setStarterDescTextCount(maxCharCount - updatedValue.length);
        break;
      case 'standardDesc':
        setStandardDescTextCount(maxCharCount - updatedValue.length);
        break;
      case 'advancedDesc':
        setAdvancedDescTextCount(maxCharCount - updatedValue.length);
        break;
      case 'starterIncAddService':
        setStarterIncAddServiceTextCount(maxCharCount - updatedValue.length);
        break;
      case 'standardIncAddService':
        setStandardIncAddServiceTextCount(maxCharCount - updatedValue.length);
        break;
      case 'advancedIncAddService':
        setAdvancedIncAddServiceTextCount(maxCharCount - updatedValue.length);
        break;
      default:
        break;
    }

    // Update form data
    setValue(prevFormData => ({
      ...prevFormData,
      [name]: updatedValue
    }));
  }

  const handleDaysNoChange = (event) => {
    const { name, value } = event.target;
    // Check if the value is a valid number
    if (value === '' || /^\d*$/.test(value) && value >= 0 && value <= 5000) {
      setValue((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '', // Clear any previous error message
      }));
    }
    // else {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [name]: 'User input value wrong.',
    //   }));
    // }
  };

  const handlePricingNoChange = (event) => {
    const { name, value } = event.target;
    // Check if the value is a valid number
    if (/^\d*\.?\d*$/.test(value) && value >= 0 && value <= 9999999999) {
      setValue((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '', // Clear any previous error message
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'User input value wrong.',
      }));
    }
  };

  const handleClear = (name) => {

    setValue(prevFormData => ({
      ...prevFormData,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const errors = {};
    const regex = /^[0-9]+(\.[0-9]+)?$/;
    if (switchLabel === '1 tier') {
      // Tier 1 validation: Only check if input is empty
      if (!value.starterTitle) {
        errors.starterTitle = "Starter Title is required.";
      }
      // Standard Title
      if (!value.starterDesc) {
        errors.starterDesc = "Starter Custom Description is required.";
      }
      if (value.starterDeliveryDays === "") {
        errors.starterDeliveryDays = "Starter Delivery Days is required.";
      }
      if (value.starterDeliveryDays === "0") {
        errors.starterDeliveryDays = "Please enter correct value.";
      }
      if (!regex.test(value.starterDeliveryDays)) {
        errors.starterDeliveryDays = "User input value wrong.";
      }

      if (!value.starterRevisions) {
        errors.starterRevisions = "Starter Revisions is required.";
      } else if (!/^\d+$/.test(value.starterRevisions) || /[+\-.,]/.test(value.starterRevisions)) {
        errors.starterRevisions = "User input value wrong.";
      } else if (value.starterRevisions === "0") {
        errors.starterRevisions = "Please enter correct value.";
      }

      if (!value.starterIncAddService) {
        errors.starterIncAddService = "Starter Add Service is required.";
      }

      if (!value.starterPrice) {
        errors.starterPrice = "Starter Price is required.";
      }

      if (!regex.test(value.starterPrice)) {
        errors.starterPrice = "Please enter valid price.";
      }

    } else if (switchLabel === '3 tiers') {

      if (!value.starterTitle) {
        errors.starterTitle = "Starter Title is required.";
      }

      if (!value.starterDesc) {
        errors.starterDesc = "Starter Custom Description is required.";
      }

      if (value.starterDeliveryDays === "") {
        errors.starterDeliveryDays = "Starter Delivery Days is required.";
      }
      if (value.starterDeliveryDays === "0") {
        errors.starterDeliveryDays = "Please enter correct value.";
      }
      if (!regex.test(value.starterDeliveryDays)) {
        errors.starterDeliveryDays = "User input value wrong.";
      }

      if (!value.starterRevisions) {
        errors.starterRevisions = "Starter Revisions is required.";
      } else if (!/^\d+$/.test(value.starterRevisions) || /[+\-.,]/.test(value.starterRevisions)) {
        errors.starterRevisions = "User input value wrong.";
      } else if (value.starterRevisions === "0") {
        errors.starterRevisions = "Please enter correct value.";
      }

      if (!value.starterIncAddService) {
        errors.starterIncAddService = "Starter Add Service is required.";
      }

      if (!value.starterPrice) {
        errors.starterPrice = "Starter Price is required.";
      }

      if (!value.standardTitle) {
        errors.standardTitle = "Standard Title is required.";
      }
      // Standard Title
      if (!value.standardDesc) {
        errors.standardDesc = "Standard Custom Description is required.";
      }

      if (value.standardDeliveryDays === "") {
        errors.standardDeliveryDays = "Standard Delivery Days is required.";
      }
      if (value.standardDeliveryDays === "0") {
        errors.standardDeliveryDays = "Please enter correct value.";
      }
      if (!regex.test(value.standardDeliveryDays)) {
        errors.standardDeliveryDays = "User input value wrong.";
      }

      if (!value.standardRevisions) {
        errors.standardRevisions = "Standard Revisions is required.";
      } else if (!/^\d+$/.test(value.standardRevisions) || /[+\-.,]/.test(value.standardRevisions)) {
        errors.standardRevisions = "User input value wrong.";
      } else if (value.standardRevisions === "0") {
        errors.standardRevisions = "Please enter correct value.";
      }

      if (!value.standardIncAddService) {
        errors.standardIncAddService = "Standard Add Service is required.";
      }

      if (!value.standardPrice) {
        errors.standardPrice = "Standard Price is required.";
      }

      if (!value.advancedTitle) {
        errors.advancedTitle = "Advanced Title is required.";
      }
      if (!value.advancedDesc) {
        errors.advancedDesc = "Advanced Custom Description is required.";
      }

      if (value.advancedDeliveryDays === "") {
        errors.advancedDeliveryDays = "Advanced Delivery Days is required.";
      }
      if (value.advancedDeliveryDays === "0") {
        errors.advancedDeliveryDays = "Please enter correct value.";
      }
      if (!regex.test(value.advancedDeliveryDays)) {
        errors.advancedDeliveryDays = "User input value wrong.";
      }

      if (!value.advancedRevisions) {
        errors.advancedRevisions = "Advanced Revisions is required.";
      } else if (!/^\d+$/.test(value.advancedRevisions) || /[+\-.,]/.test(value.advancedRevisions)) {
        errors.advancedRevisions = "User input value wrong.";
      } else if (value.advancedRevisions === "0") {
        errors.advancedRevisions = "Please enter correct value.";
      }

      if (!value.advancedIncAddService) {
        errors.advancedIncAddService = "Advanced Add Service is required.";
      }

      if (!value.advancedPrice) {
        errors.advancedPrice = "Advanced Price is required.";
      }
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no validation errors
  };

  useEffect(() => {
    // Check for validation errors when form data changes
    if (finishButtonClicked) {
      validateForm();
    }
  }, [value, finishButtonClicked]);

  const handleFormSubmit = () => {
    setFinishButtonClicked(true);
    if (validateForm()) {
      const dataToSend = {
        starterTitle: value.starterTitle,
        standardTitle: value.standardTitle,
        advancedTitle: value.advancedTitle,
        starterDesc: value.starterDesc,
        standardDesc: value.standardDesc,
        advancedDesc: value.advancedDesc,
        starterDeliveryDays: value.starterDeliveryDays,
        standardDeliveryDays: value.standardDeliveryDays,
        advancedDeliveryDays: value.advancedDeliveryDays,
        starterRevisions: value.starterRevisions,
        standardRevisions: value.standardRevisions,
        advancedRevisions: value.advancedRevisions,
        starterIncAddService: value.starterIncAddService,
        standardIncAddService: value.standardIncAddService,
        advancedIncAddService: value.advancedIncAddService,
        starterPrice: value.starterPrice,
        standardPrice: value.standardPrice,
        advancedPrice: value.advancedPrice,
        switchLabel: switchLabel,
        showInputs: showInputs
      };
      // Pass data to parent component
      onDataUpdate(dataToSend);
      onNext();
    }
  };

  useEffect(() => {

    if (stepTwoPreviousData) {
      setSwitchLabel(stepTwoPreviousData.switchLabel || "3 tiers");
      setShowInputs(stepTwoPreviousData.switchLabel !== "1 tier");
      setValue({
        "starterTitle": stepTwoPreviousData.starterTitle,
        "starterDesc": stepTwoPreviousData.starterDesc,
        "starterDeliveryDays": stepTwoPreviousData.starterDeliveryDays,
        "starterRevisions": stepTwoPreviousData.starterRevisions,
        "starterIncAddService": stepTwoPreviousData.starterIncAddService,
        "starterPrice": stepTwoPreviousData.starterPrice,
        "standardTitle": stepTwoPreviousData.standardTitle,
        "advancedTitle": stepTwoPreviousData.advancedTitle,
        "standardDesc": stepTwoPreviousData.standardDesc,
        "advancedDesc": stepTwoPreviousData.advancedDesc,
        "standardDeliveryDays": stepTwoPreviousData.standardDeliveryDays,
        "advancedDeliveryDays": stepTwoPreviousData.advancedDeliveryDays,
        "standardRevisions": stepTwoPreviousData.standardRevisions,
        "advancedRevisions": stepTwoPreviousData.advancedRevisions,
        "standardIncAddService": stepTwoPreviousData.standardIncAddService,
        "advancedIncAddService": stepTwoPreviousData.advancedIncAddService,
        "standardPrice": stepTwoPreviousData.standardPrice,
        "advancedPrice": stepTwoPreviousData.advancedPrice,
        "switchLabel": stepTwoPreviousData.switchLabel
      });
    }
  }, [stepTwoPreviousData]);

  const toggleInputs = () => {
    const newShowInputs = !showInputs;
    setShowInputs(newShowInputs);
    setSwitchLabel(newShowInputs ? "3 tiers" : "1 tier");
    if (!newShowInputs) {
      setValue((prevValue) => ({
        ...prevValue,
        standardTitle: '', standardDesc: '', standardDeliveryDays: '', standardRevisions: '', standardIncAddService: '', standardPrice: '',
        advancedTitle: '', advancedDesc: '', advancedDeliveryDays: '', advancedRevisions: '', advancedIncAddService: '', advancedPrice: ''
      }));
      setValidationErrors({});
    }
  };

  return (
    <Box className="list-service-pricing-container">
      <Box className="top-view">
        <Box>
          <Typography className="font-36">Price & Scope</Typography>
        </Box>
        <Box>
          <FormControlLabel
            value="start"
            control={<IOSSwitch color="primary" checked={showInputs}
              onChange={toggleInputs} />}
            label={switchLabel}
            labelPlacement="start"
            sx={{ margin: 0 }}
          />
        </Box>
      </Box>
      <Typography className="font-18" mt={2.5}>
        Customize your service with 1 or 3 pricing tiers
      </Typography>
      <Box className={`pricing-container ${showInputs ? '' : 'half-contener'}`}>
        <Grid container spacing={2}>
          <Grid item md={showInputs ? 3 : 5} xs={12} sm={12}>
            <Box>
              <Typography>Custom Title</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={showInputs ? 9 : 7} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <label className="font-14 label-top">Starter</label>
                  <Box style={{ position: "relative" }}>
                    <TextField
                      id="outlined-multiline-static"
                      rows={1}
                      value={value.starterTitle}
                      name="starterTitle"
                      onChange={handleInputChange}
                      placeholder="Enter Starter title"
                    />
                  </Box>
                </Box>
                {validationErrors.starterTitle ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterTitle}</Typography> :
                  <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{starterTextCount}/30 characters</Typography>}
              </Grid>
              {showInputs && (
                <>
                  <Grid item xs={12} md={4} sm={6}>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <label className="font-14 label-top">Standard</label>
                      <Box style={{ position: "relative" }}>
                        <TextField
                          id="outlined-multiline-static"
                          rows={1}
                          value={value.standardTitle}
                          onChange={handleInputChange}
                          name="standardTitle"
                          placeholder="Enter Standard title"
                        />
                      </Box>
                    </Box>
                    {validationErrors.standardTitle ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardTitle}</Typography> :
                      <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{standardTextCount}/30 characters</Typography>}
                  </Grid>
                  <Grid item xs={12} md={4} sm={6}>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <label className="font-14 label-top">Advanced</label>
                      <Box style={{ position: "relative" }}>
                        <TextField
                          id="outlined-multiline-static"
                          rows={1}
                          value={value.advancedTitle}
                          onChange={handleInputChange}
                          name="advancedTitle"
                          placeholder="Enter Advanced title"

                        />
                      </Box>
                    </Box>
                    {validationErrors.advancedTitle ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedTitle}</Typography> :
                      <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{advancedTextCount}/30 characters</Typography>}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box className="" mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={showInputs ? 3 : 5} sm={6}>
              <Box>
                <Typography>Custom Description</Typography>
              </Box>
            </Grid>
            <Grid item md={showInputs ? 9 : 7} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box style={{ position: "relative" }}>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="starterDesc"
                        value={value.starterDesc}
                        onChange={handleInputChange}
                        placeholder="Enter Starter Descripton"
                        className="text-right-space"
                      />
                      {value && (
                        <IconButton
                          onClick={() => handleClear("starterDesc")}
                          style={{
                            position: "absolute",
                            right: "8px",
                            top: "20%",
                            transform: "translateY(-50%)",
                            color: "#64748B",
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  {validationErrors.starterDesc ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterDesc}</Typography> :
                    <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{starterDescTextCount}/80 characters</Typography>}
                </Grid>
                {showInputs && (
                  <>
                    <Grid item xs={12} md={4} sm={6}>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ position: "relative" }}>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            name="standardDesc"
                            value={value.standardDesc}
                            onChange={handleInputChange}
                            placeholder="Enter Standard Descripton"
                            className="text-right-space"
                          />
                          {value && (
                            <IconButton
                              onClick={() => handleClear("standardDesc")}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "20%",
                                transform: "translateY(-50%)",
                                color: "#64748B",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {validationErrors.standardDesc ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardDesc}</Typography> :
                        <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{standardDescTextCount}/80 characters</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ position: "relative" }}>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            value={value.advancedDesc}
                            name="advancedDesc"
                            onChange={handleInputChange}
                            placeholder="Enter Advanced Descripton"
                            className="text-right-space"
                          />
                          {value && (
                            <IconButton
                              onClick={() => handleClear("advancedDesc")}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "20%",
                                transform: "translateY(-50%)",
                                color: "#64748B",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {validationErrors.advancedDesc ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedDesc}</Typography> :
                        <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{advancedDescTextCount}/80 characters</Typography>}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={`pricing-container ${showInputs ? '' : 'half-contener'}`}>
        <Grid container spacing={2}>
          <Grid item md={showInputs ? 3 : 5} sm={12} xs={12}>
            <Box>
              <Typography>Delivery Days</Typography>
            </Box>
          </Grid>
          <Grid item md={showInputs ? 9 : 7} xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Starter Delivery Days"
                  value={value.starterDeliveryDays}
                  name="starterDeliveryDays"
                  onChange={handleDaysNoChange}
                  inputProps={{ min: 0, max: 5000 }}
                />
                {validationErrors.starterDeliveryDays && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterDeliveryDays}</Typography>}
              </Grid>
              {showInputs && (
                <>
                  <Grid item xs={12} md={4} sm={6}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      pattern="[0-9]*"
                      value={value.standardDeliveryDays}
                      name="standardDeliveryDays"
                      placeholder="Enter Standard Delivery Days"
                      onChange={handleDaysNoChange}
                      inputProps={{ min: "0", step: "1" }}
                    />
                    {validationErrors.standardDeliveryDays && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardDeliveryDays}</Typography>}
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      pattern="[0-9]*"
                      value={value.advancedDeliveryDays}
                      name="advancedDeliveryDays"
                      placeholder="Enter Advanced Delivery Days"
                      onChange={handleDaysNoChange}
                      inputProps={{ min: "0", step: "1" }}
                    />
                    {validationErrors.advancedDeliveryDays && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedDeliveryDays}</Typography>}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item md={showInputs ? 3 : 5} sm={12} xs={12}>
              <Box>
                <Typography className="font-14">Number of Revisions</Typography>
              </Box>
            </Grid>
            <Grid item md={showInputs ? 9 : 7} xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Starter Revisions"
                    value={value.starterRevisions}
                    name="starterRevisions"
                    onChange={handleDaysNoChange}
                    inputProps={{ min: "0", step: "1" }}
                  />
                  {validationErrors.starterRevisions && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterRevisions}</Typography>}
                </Grid>
                {showInputs && (
                  <>
                    <Grid item xs={12} md={4} sm={6}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Standard Revisions"
                        value={value.standardRevisions}
                        name="standardRevisions"
                        onChange={handleDaysNoChange}
                        inputProps={{ min: "0", step: "1" }}
                      />
                      {validationErrors.standardRevisions && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardRevisions}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Advanced Revisions"
                        value={value.advancedRevisions}
                        name="advancedRevisions"
                        onChange={handleDaysNoChange}
                        inputProps={{ min: "0", step: "1" }}
                      />
                      {validationErrors.advancedRevisions && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedRevisions}</Typography>}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={`pricing-container ${showInputs ? '' : 'half-contener'}`}>
        <Typography className="font-18">Service Tier Options</Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={showInputs ? 3 : 5} sm={12}>
              <Box>
                <Typography className="font-14">
                  Include Additional Services included in each tier
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={showInputs ? 9 : 7} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box style={{ position: "relative" }}>
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="starterIncAddService"
                        value={value.starterIncAddService}
                        onChange={handleInputChange}
                        placeholder="Enter Starter Service Tier"
                        className="text-right-space"
                      />
                      {value && (
                        <IconButton
                          onClick={() => handleClear("starterIncAddService")}
                          style={{
                            position: "absolute",
                            right: "8px",
                            top: "28%",
                            transform: "translateY(-50%)",
                            color: "#64748B",
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  {validationErrors.starterIncAddService ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterIncAddService}</Typography> :
                    <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{starterIncAddServiceTextCount}/80 characters</Typography>}
                </Grid>
                {showInputs && (
                  <>
                    <Grid item xs={12} md={4} sm={6}>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ position: "relative" }}>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            name="standardIncAddService"
                            value={value.standardIncAddService}
                            onChange={handleInputChange}
                            placeholder="Enter Standard Service Tier"
                            className="text-right-space"
                          />
                          {value && (
                            <IconButton
                              onClick={() => handleClear("standardIncAddService")}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "28%",
                                transform: "translateY(-50%)",
                                color: "#64748B",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {validationErrors.standardIncAddService ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardIncAddService}</Typography> :
                        <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{standardIncAddServiceTextCount}/80 characters</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ position: "relative" }}>
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            name="advancedIncAddService"
                            value={value.advancedIncAddService}
                            onChange={handleInputChange}
                            placeholder="Enter Advanced Service Tier"
                            className="text-right-space"
                          />
                          {value && (
                            <IconButton
                              onClick={() => handleClear("advancedIncAddService")}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "28%",
                                transform: "translateY(-50%)",
                                color: "#64748B",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      {validationErrors.advancedIncAddService ? <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedIncAddService}</Typography> :
                        <Typography paddingLeft={"14px"} color={'#94A3B8'} mt={1} className='font-12'>{advancedIncAddServiceTextCount}/80 characters</Typography>}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={`pricing-container ${showInputs ? '' : 'half-contener'}`}>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item md={showInputs ? 3 : 5} sm={12} xs={12}>
              <Box>
                <Typography className="font-18">Service Price</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={showInputs ? 9 : 7} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={showInputs ? 4 : 12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Starter Price"
                    variant="outlined"
                    name="starterPrice"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment className="label-text" position="start">$</InputAdornment>,
                    }}
                    value={value.starterPrice}
                    onChange={handlePricingNoChange}
                    placeholder="Enter Starter Price"
                  />
                  {validationErrors.starterPrice && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.starterPrice}</Typography>}
                </Grid>
                {showInputs && (
                  <>
                    <Grid item xs={12} md={4} sm={6}>
                      <TextField
                        id="outlined-basic"
                        label="Standard Price"
                        variant="outlined"
                        name="standardPrice"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment className="label-text" position="start">$</InputAdornment>,
                        }}
                        value={value.standardPrice}
                        onChange={handlePricingNoChange}
                        placeholder="Enter Standard Price"
                      />
                      {validationErrors.standardPrice && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.standardPrice}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                      <TextField
                        id="outlined-basic"
                        label="Advanced Price"
                        variant="outlined"
                        name="advancedPrice"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment className="label-text" position="start">$</InputAdornment>,
                        }}
                        value={value.advancedPrice}
                        onChange={handlePricingNoChange}
                        placeholder="Enter Advanced Price"
                      />
                      {validationErrors.advancedPrice && <Typography paddingLeft={"14px"} mt={1} className='font-12' color="error">{validationErrors.advancedPrice}</Typography>}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="btn-content-div">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className="go-back-btn">
            <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
              Go Back
            </Button>
          </Box>
          <Box className="continue-btn">
            <Button
              variant="contained"
              startIcon={<ArrowForwardIcon />}
              className="btn-rounded btn-large"
              style={{ background: "var(--golden-gradient)" }}
              onClick={handleFormSubmit}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Pricing;
