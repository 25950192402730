import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Chip, Typography, Grid, Tooltip } from "@mui/material";
import { GetApi } from '../../../Api/Api';
import imgBg from "../../../Assets/Images/service-provider/img-card.png";
import DownloadIcon from '@mui/icons-material/Download';
import { formatDate, fetchAndDownloadFile } from '../../../Utils/utils';

export default function DeliveryJob({ contractData }) {
  const [deliveryData, setDeliveryData] = useState([]);

  const fetchData = useCallback(() => {
    let url = `/services/getContractDelivery/${contractData?.id}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          let number = 1;
          const updatedData = res.data.data.map((item, index) => {
            if(index === 0){
              return {
                ...item,
                sno: number
              }
            }else{
              const updatedData = {
                ...item,
                sno: number
              }
              if(item?.status === 1)number = number + 1;
              return updatedData;
            }
          });
          setDeliveryData(updatedData);
        } else {
          setDeliveryData([]);
        }
      }
    })
  }, [contractData]);

  useEffect(() => {
    fetchData();
  }, [contractData]);

  return (
    <Box className="activity-container">
      {deliveryData && deliveryData.length > 0 ? deliveryData.map((row, index) => (
        <><Box className="date-btn">
          <Chip className="chip-grey" label={`Delivery #${row?.sno}`} />&nbsp;
          {row?.status === 0 && <Chip className="chip-pending" label={`Pending`} />}&nbsp;
          {row?.status === 1 && <Chip className="chip-accepted" label={`Accepted`} />}&nbsp;
          {row?.status === 2 && <Chip className="chip-rejected" label={`Rejected`} />}
        </Box><Box className="user-chat">
            <Box className="chat-left">
              <Avatar />
            </Box>
            <Box className="chat-right">
              <Typography className="font-16">{row?.serviceProviderData?.first_name} {row?.serviceProviderData?.last_name}</Typography>
              <Typography className="font-12 text-blueGray-300">
                {formatDate(row.createdAt, 'MMM dd, h:mm a')}
              </Typography>
              <Box className="msg-box">
                <Typography className="font-16">Message</Typography>
                <Typography className="font-12">
                  {row.message}
                </Typography>
              </Box>
              {row?.reject_reason && 
              <Box className="msg-box">
                <Typography className="font-16">Rejection Reason</Typography>
                <Typography className="font-12">
                  {row?.reject_reason}
                </Typography>
              </Box>}
              <Box mt={2}>
                <Grid container spacing={1} >
                  {row.documents && row.documents.length > 0 && row.documents.map((doc) => (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box className="download-img-card">
                        <Box className="download-img-wrapper">
                          <img src={imgBg} alt="" />
                        </Box>
                        <Box className="img-box-bottom">
                          <Box className="left-div">
                            <Typography className="font-16">{doc?.original_name}</Typography>
                            <Typography className="font-12 text-blueGray-300">
                              {doc?.size}
                            </Typography>
                          </Box>
                          <Box className="right-div">
                            {/* <img src={downloadIcon} alt="" /> */}
                            <Tooltip title={'download'} arrow>
                              <DownloadIcon style={{ color: '#10B981', cursor: 'pointer' }} onClick={()=>{
                                fetchAndDownloadFile(doc.path, doc.original_name);
                              }}/>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box></>
      )) : 
        <Typography className="font-12 text-blueGray-300" style={{height: '50vh', textAlign: 'center'}}>
          Delivery data not available
        </Typography>}
    </Box>
  )
}
