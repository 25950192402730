import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import { IndividualProfilePersona } from "../../../Components/Images/Images";
import StarsIcon from "@mui/icons-material/Stars";
import ReviewServiceSlider from "./ReviewServiceSlider";
import { GetApiParam } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import config from "../../../Config/Config";

const ReviewServicePostLeft = ({ sharedDataStepFour, sharedDataStepThree, sharedData, }) => {
    const [profileData, setProfileData] = useState([])
    const [language, setLanguage] = useState()
    const userData = getUserDataFromLocalStorage();
    const userId = userData.id;

    let avatarSrc = IndividualProfilePersona; // Default avatar image
    const image = profileData && profileData?.profilePicture?.length > 0 ? profileData?.profilePicture[0]?.path : IndividualProfilePersona;
    // Check if props.persona exists and is a file object
    if (image instanceof File) {
        // Create a URL for the file object
        avatarSrc = URL.createObjectURL(image);
    } else if (image) {
        // Use the URL provided in props.persona
        avatarSrc = `${process.env.REACT_APP_IMAGE_URL}${image}`;
    }

    useEffect(() => {
        async function getProfileData() {
            try {
                const res = await GetApiParam("/services/ProfileDetails", { user_id: userData.id ? userData.id : "" });
                setProfileData(res?.data?.data ? res?.data?.data[0] : []);
                const profileLanguage = res?.data?.data[0]?.language ? JSON.parse(res?.data?.data[0]?.language) : null;
                const languageSelected = profileLanguage && profileLanguage.map((val) => {
                    return `${val?.language}`;
                }).join(" | ");
                setLanguage(languageSelected);
            } catch (error) {
            }
        }
        if (userId) {
            getProfileData()
        }
    }, [])

    return (
        <>
            <Grid item xs={12} md={8}>
                <Box className="left-content">
                    <Typography className="font-32">
                        {sharedData?.serviceTitle}
                    </Typography>
                    <Box className="skills-box">
                        <Box className="pc-chip-wrap" >
                            <Stack direction="row" flexWrap={'wrap'} display={"flex"} gap={1}>
                                {sharedData?.selectedSkills?.map((item) => (
                                    <Chip className="pc-chip" label={item?.skill_name} />
                                ))}
                            </Stack>
                        </Box>

                        {/* <Box className="">
                            <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                <IconButton aria-label="View" size="large" className={"square-icon-btn"} >
                                    <BookmarkBorderIcon />
                                </IconButton>
                            </Stack>
                        </Box> */}
                    </Box>

                    <Box className="mr-3 publish-slider">
                        <ReviewServiceSlider images={sharedDataStepFour?.galleryDetails} />
                    </Box>
                    <Box>
                        <Typography className="font-24" mt={3}>About this service</Typography>
                        <Typography className="font-14" mt={2} style={{ whiteSpace: 'pre-line' }}>
                            {sharedData?.description}
                        </Typography>
                        <Typography className="font-24" mt={3}>Requirement</Typography>
                        <Typography className="font-14" mt={2} style={{ whiteSpace: 'pre-line' }}>
                            {sharedDataStepThree?.requirementDocument}
                        </Typography>
                    </Box>
                    <Box className="about-service-provider">
                        <Typography className="font-24">
                            About the Service Provider
                        </Typography>
                        <Box className="about-service-content">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <Box className="profile-persona-img-wrap">
                                        <Avatar src={avatarSrc} alt="" className='profile-persona-img user-img-divs' />
                                    </Box>
                                </Grid>
                                <Grid item  xs={12} md={10}>
                                    <Box className="right-div">
                                        <Typography className="font-22">
                                            {profileData?.first_name} {profileData?.last_name}
                                        </Typography>
                                        <Typography className="font-14 text-blueGray-300">
                                            {profileData?.title}
                                        </Typography>
                                        <Box className="profile-view">
                                            <Box className="left-view">
                                                <StarsIcon />
                                                <Typography ml={2} className="font-12">
                                                    {profileData?.rating ? profileData?.rating : 'NA'}
                                                </Typography>
                                            </Box>
                                            <Box className="top-rates-btn">
                                                <Stack spacing={2} direction="row">
                                                    {profileData?.rating >= config?.TOP_RATAED?.ratingGrater && profileData?.review >= config?.TOP_RATAED?.reviewGrater && <Button variant="contained" className="">
                                                        Top-Rated
                                                    </Button>}
                                                    <Button variant="contained" className="">
                                                        {profileData?.successRate ? `${profileData?.successRate}% Success` : 'Not Worked'}
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        {/* <Box className="contact-me-btn">
                                            <Button variant="outlined" className="">
                                                Contact Me
                                            </Button>
                                        </Box> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Box px={2} py={3}>
                                <Grid container spacing={2}>
                                    <Grid md={3} sm={3}>
                                        <Box>
                                            <Typography className="font-22">{profileData?.address}</Typography>
                                            <Typography className="font-12 text-blueGray-300">
                                                Country
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid md={3} sm={3}>
                                        <Box>
                                            <Typography className="font-22">{profileData?.avgResponseTime}</Typography>
                                            <Typography className="font-12 text-blueGray-300">
                                                Avg. response time
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid md={3} sm={3}>
                                        <Box>
                                            <Typography className="font-22">{profileData?.lastDelivery ? profileData?.lastDelivery : 'NA'}</Typography>
                                            <Typography className="font-12 text-blueGray-300">
                                                Last delivery
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid md={3} sm={3}>
                                        <Box>
                                            <Typography className="font-22">{language ? language : null}</Typography>
                                            <Typography className="font-12 text-blueGray-300">
                                                Languages
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Typography className="font-18">
                                    {profileData?.title ?? ''}
                                </Typography>
                                <Typography className="font-14 text-blueGray-300">
                                    {profileData?.description ?? ''}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default ReviewServicePostLeft;