import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, Chip, Typography, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../Contexts/SnackbarContext";
import { useSocket } from '../../../../Contexts/SocketContext';
import { GetApi } from '../../../../Api/Api';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import imgBg from "../../../../Assets/Images/service-provider/img-card.png";
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmModal from "../../../../Components/Modal/Confirm/Confirm";
import RatingModal from './RatingModal/RatingModal';
import DeliveryConfirmModal from './DeliveryConfirmModal/DeliveryConfirmModal'
import { updateApi } from "../../../../Api/Api";
import { formatDate, fetchAndDownloadFile } from '../../../../Utils/utils';

export default function DeliveryJob({ contractData, getContractData }) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const socket = useSocket();
  const [ openModal, setOpenModal ] = useState(false);
  const [ deliveryId, setDeliveryId ] = useState(null);
  const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
  const [ rejectReason, setRejectReason ] = useState(null);

  const [deliveryData, setDeliveryData] = useState([]);

  const fetchData = useCallback(() => {
    let url = `/services/getContractDelivery/${contractData?.id}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          let number = 1;
          const updatedData = res.data.data.map((item, index) => {
            if(index === 0){
              return {
                ...item,
                sno: number
              }
            }else{
              const updatedData = {
                ...item,
                sno: number
              }
              if(item?.status === 1)number = number + 1;
              return updatedData;
            }
          });
          setDeliveryData(updatedData);
        } else {
          setDeliveryData([]);
        }
      }
    })
  }, [contractData]);

  useEffect(() => {
    fetchData();
  }, [contractData]);

  const handleAction = (action) => {
    let payload;
    if(action === 'confirm'){
      payload = {
        status: 1,
        contractId: contractData.id,
      }
    }else{
      payload = {
        status: 2,
        contractId: contractData.id,
        deliveryCancel: true,
        reject_reason: rejectReason
      }
    }
    let url = `/services/updateContractDeliveryStatus/${deliveryId}`;
    console.log("url---",url);
    // if(contractData?.status === 5){
    //   if(action === 'confirm'){
    //     payload = {
    //       status: 6,
    //       deliveryId,
    //     }
    //   }else{
    //     payload = {
    //       status: 3,
    //       deliveryCancel: true,
    //       deliveryId
    //     }
    //   }
    //   url = `/services/contractStatusUpdate/${contractData.id}`;
    // }
    updateApi(url, payload).then((res) => {
      if (res?.status === 200) {
        if(res.data.code === 200){
          showSnackbar(res?.data?.message, 'success');
          setOpenModal(false);
          if(res?.data?.rating)setIsOpenRatingModal(true);
          else getContractData();
          // navigate(`/service-proposer-dashboard/myorder`);
        }else{
          showSnackbar(res?.data?.message, 'error');
        }
      }
    })
  }
  const handleCloseRatingModal = () => {
    setIsOpenRatingModal(false);
    getContractData();
  }
  return (
    <><Box className="activity-container">
      {deliveryData && deliveryData.length > 0 ? deliveryData.map((row, index) => (
      <><Box className="date-btn">
          <Chip className="chip-grey" label={`Delivery #${row?.sno}`} />&nbsp;
          {row?.status === 0 && <Chip className="chip-pending" label={`Pending`} />}&nbsp;
          {row?.status === 1 && <Chip className="chip-accepted" label={`Accepted`} />}&nbsp;
          {row?.status === 2 && <Chip className="chip-rejected" label={`Rejected`} />}
        </Box><Box className="user-chat">
            <Box className="chat-left">
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg" />
            </Box>
            <Box className="chat-right">
              <Typography className="font-16">{row?.serviceProviderData?.first_name} {row?.serviceProviderData?.last_name}</Typography>
              <Typography className="font-12 text-blueGray-300">
                {formatDate(row.createdAt, 'MMM dd, h:mm a')}
              </Typography>
              <Box className="msg-box">
                <Typography className="font-16">Message</Typography>
                <Typography className="font-12">
                  {row?.message}
                </Typography>
              </Box>
              {row?.reject_reason && 
              <Box className="msg-box">
                <Typography className="font-16">Rejection Reason</Typography>
                <Typography className="font-12">
                  {row?.reject_reason}
                </Typography>
              </Box>}
              <Box mt={2}>
                <Grid container spacing={1} >
                  {row.documents && row.documents.length > 0 && row.documents.map((doc) => (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box className="download-img-card">
                        <Box className="download-img-wrapper">
                          <img src={imgBg} alt="" />
                        </Box>
                        <Box className="img-box-bottom">
                          <Box className="left-div">
                            <Typography className="font-16">{doc?.original_name}</Typography>
                            <Typography className="font-12 text-blueGray-300">
                              {doc?.size}
                            </Typography>
                          </Box>
                          <Box className="right-div">
                            {/* <img src={downloadIcon} alt="" /> */}
                            <Tooltip title={'download'} arrow>
                              <DownloadIcon style={{ color: '#10B981', cursor: 'pointer' }} onClick={()=>{
                                fetchAndDownloadFile(doc.path, doc.original_name);
                              }}/>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {row?.status === 0 && (
                <><Box mt={2}>
                  <Button onClick={() => {setOpenModal(true); setDeliveryId(row?.id);}} variant="contained" startIcon={<DoneAllIcon />} className="race-btn-req-provider">
                    Mark Service As Complete
                  </Button>
                </Box><Box mt={2}>
                    <Typography className="font-12 text-blueGray-300">
                      Once service has been marked as completed, the funds will be released to the Service Provider. Please ensure you are happy with the deliverables before clicking this button.
                    </Typography>
                  </Box></>
              )}
            </Box>
          </Box></>
      )) : <Typography className="font-12 text-blueGray-300" style={{height: '50vh', textAlign: 'center'}}>
      Delivery data not available
    </Typography>}
    </Box>
    {/* <ConfirmModal title={'Confirmation'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} /> */}
    <RatingModal onOpen={isOpenRatingModal} handleClose={handleCloseRatingModal} contractId={contractData?.id} spvUserId={contractData?.serviceProviderData?.id} />
    <DeliveryConfirmModal 
      onOpen={openModal} 
      handleClose={setOpenModal} 
      rejectReason={rejectReason}
      setRejectReason={setRejectReason}
      handleAction={handleAction} />
    </>
  )
}
