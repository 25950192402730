import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import { GetApi } from "../../../Api/Api";
import "./Components/Questionnaire.css"
import { useSnackbar } from "../../../Contexts/SnackbarContext";


const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


export default function RealEstateFractionDetails(props) {
  const { showSnackbar } = useSnackbar()

  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;
  // list of capital type
  const [capitalTypeList, setCapitalTypeList] = useState(propData?.capital_type_list ? propData?.capital_type_list : []);

  // Get current proposal and progress data from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // handling capital type checked
  const [checked, setChecked] = useState(["off", "off", "off", "off", "off", "off", "off"]);
  const handleChange = (num) => (e) => {
    setChecked(prev => {
      let result = [...prev];
      result[num] = result[num] === "on" ? "off" : e.target.value;
      return result;
    })
  }

  // Initialize state variables 
  const [projectSize, setProjectSize] = useState(propData?.fractionalize_project_size ? propData?.fractionalize_project_size : 0)
  const [raise, setRaise] = useState(propData?.fractionalize_total_price ? propData?.fractionalize_total_price : 0)
  const [projectDuration, setProjectDuration] = useState(propData?.fractionalize_duration_of_project ? propData?.fractionalize_duration_of_project : 0);
  // Repay Date
  const [repayDay, setRepayDay] = useState(propData?.loan_repay_day ? propData?.loan_repay_day : 1);


  // state to hold max limit for project price
  const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
  const [maxLimit, setMaxLimit] = useState(0);
  /**
  * Function to increment or decrement values by $500
  * @param {String} type 
  * @param {Function} setFunction 
  */
  const handleChangeAmount = (type, setFunction) => {
    if (type === 'inc') {
      setFunction(prevCount => prevCount ? (parseFloat(prevCount) + 500) : 500);
    } else {
      setFunction(prevCount => (parseFloat(prevCount) >= 500 ? parseFloat(prevCount) - 500 : prevCount));
    }
  }

  /**
   * Function to increment or decrement 'projectDuration' by 1 
   * @param {String} type 
   */
  const handleProjectDuration = (type, setFunction, functionName) => {
    if (type === 'inc') {
      if (functionName === "setProjectDuration") { setFunction(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1) }
      else { setFunction((prevCount) => parseInt(prevCount) < 28 ? parseInt(prevCount) + 1 : 28) }
    } else {
      if (functionName === "setProjectDuration") { setFunction(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount)) }
      else { setFunction((prevCount) => parseInt(prevCount) >= 2 ? parseInt(prevCount) - 1 : prevCount); }
    }
  }

  /**
   * Handle "Next" button click 
   */
  const handleClick = () => {
    let checkedData = []
    let checkedData_ids = []
    checked?.forEach((check, index) => {
      if (check === "on") {
        checkedData.push(capitalTypeList[index]?.capital_name)
        checkedData_ids.push(capitalTypeList[index].id)
      }
    })
    propData.fractionalize_project_size = projectSize;
    propData.fractionalize_total_price = raise;
    propData.fractionalize_duration_of_project = projectDuration;
    propData.loan_repay_day = repayDay;
    propData.capital_type = checkedData;
    propData.capital_type_ids = checkedData_ids;
    propData.capital_type_list = capitalTypeList;
    if (propData?.capitalTypeDetails?.length) {
      // Use the filter method to keep only the objects with the specified key
      const filteredData = propData?.capitalTypeDetails?.filter(item => checkedData?.includes(Object.keys(item)[0]));
      propData.capitalTypeDetails = filteredData
    }
    updateProposalData(propData);
    handleNext()
  }

  /**
  *  Handle "Save and Exit" button click 
  */
  const SaveAndExit = () => {
    let checkedData = []
    let checkedData_ids = []
    checked?.forEach((check, index) => {
      if (check === "on") {
        checkedData.push(capitalTypeList[index]?.capital_name)
        checkedData_ids.push(capitalTypeList[index].id)
      }
    })
    propData.fractionalize_project_size = projectSize;
    propData.fractionalize_total_price = raise;
    propData.fractionalize_duration_of_project = projectDuration;
    propData.loan_repay_day = repayDay;
    propData.capital_type = checkedData;
    propData.capital_type_ids = checkedData_ids;
    propData.capital_type_list = capitalTypeList;
    if (propData?.capitalTypeDetails?.length) {
      // Use the filter method to keep only the objects with the specified key
      const filteredData = propData?.capitalTypeDetails?.filter(item => checkedData?.includes(Object.keys(item)[0]));
      propData.capitalTypeDetails = filteredData
    }
    updateProposalData(propData);
    saveProposal();
  }

  // Getting capital type data
  useEffect(() => {
    try {
      async function getCapitalTypes() {
        const res = await GetApi("/proposer/getTypeOfCapital");
        if (res?.data?.data?.length) {
          setCapitalTypeList(res?.data?.data);
          let newChecked = ["off", "off", "off", "off", "off", "off", "off"];
          // updating newChecked array based new data
          if (propData?.capital_type && propData?.capital_type?.length) {
            propData?.capital_type_list?.forEach((item, index) => {
              if (propData?.capital_type.includes(item?.capital_name)) {
                newChecked[index] = "on"
              }
            }
            )
          }
          setChecked(newChecked)
        }
      }
      getCapitalTypes()
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (raise) {
      // checking for max range
      let checkMaxLimitExceeded = true, checkMaxLimit = 0;
      let stackAmtData = propData?.stackAmtData;
      let projectAmt = parseFloat(raise)
      if (stackAmtData?.length) {
        stackAmtData?.forEach((dataItem) => {
          checkMaxLimit = dataItem?.max_amount
          if (dataItem?.min_amount <= projectAmt && projectAmt <= dataItem?.max_amount) {
            checkMaxLimitExceeded = false
          }
        })
        setIsMaxLimitExceeded(checkMaxLimitExceeded)
        setMaxLimit(checkMaxLimit)
      }
      // check for max range exceeded
      if (checkMaxLimitExceeded && checkMaxLimit > 0) {
        showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
      } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
        showSnackbar(`Range configuration is not set for the asset type`, 'error');
      }
    }
  }, [raise])


  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Now, set your price
                </Typography>
              </Box>
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit}
                    disabled={Boolean(parseFloat(projectSize) >= parseFloat(raise)) && (projectSize !== '' && raise !== "" && projectDuration !== "" && projectDuration > 0 && raise > 0 && projectSize > 0 && (parseInt(repayDay) >= 1 && parseInt(repayDay) <= 28) && !isMaxLimitExceeded &&
                      checked.some(element => element === 'on')) ? false : true}
                    className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box>
                  <Grid container spacing={{ xs: 1, md: 1, sm: 1 }} my={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography className="inputFieldTitle">Total Project Value</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('dec', setProjectSize) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                            }}
                            value={projectSize}
                            size="small"
                            helperText={"Total Project Value"}
                            onChange={(e) => setProjectSize(parseFloat(e.target.value))}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('inc', setProjectSize) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography className="inputFieldTitle">Total Raise</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('dec', setRaise) }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: NumericFormatCustomDollar,
                            }}
                            size="small"
                            helperText={"Total Raise"}
                            value={raise}
                            onChange={(e) => setRaise(parseFloat(e.target.value))}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleChangeAmount('inc', setRaise) }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>Duration of Project</Typography>
                      <Box className='NS-sale-details'>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('dec', setProjectDuration, "setProjectDuration") }} className="NS-sale-details-icon-btn">
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-number"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={projectDuration}
                            size="small"
                            helperText={projectDuration > 1 ? "Years" : 'Year'}
                            onChange={(e) => setProjectDuration(parseFloat(e.target.value))}
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            onKeyDown={(event) => {
                              if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box className='NS-sale-details-icon'>
                          <IconButton onClick={() => { handleProjectDuration('inc', setProjectDuration, "setProjectDuration") }} className="NS-sale-details-icon-btn">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    {checked && checked?.length && (checked[1] === "on" || checked[2] === "on") ?
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography className="inputFieldTitle" sx={{ marginBottom: '8px !important' }}>Payment Date</Typography>
                        <Box className='NS-sale-details'>
                          <Box className='NS-sale-details-icon'>
                            <IconButton onClick={() => { handleProjectDuration('dec', setRepayDay, "setRepayDay") }} className="NS-sale-details-icon-btn">
                              <RemoveIcon />
                            </IconButton>
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-number"
                              type="text"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repayDay}
                              size="small"
                              helperText={"Day in month"}
                              onKeyDown={(event) => {
                                if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => setRepayDay(parseInt(e.target.value))}
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                            />
                          </Box>
                          <Box className='NS-sale-details-icon'>
                            <IconButton onClick={() => { handleProjectDuration('inc', setRepayDay, "setRepayDay") }} className="NS-sale-details-icon-btn">
                              <AddIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                      : null}
                  </Grid>
                </Box>
                <Box mt={6} textAlign={"center"} >
                  <Typography className="inputFieldTitle">Type of Capital</Typography>
                  <Box>
                    <Grid container>
                      {capitalTypeList?.map((capital_item, index) => {
                        return (
                          <Grid item sx={6} md={4} key={index}>
                            <Box textAlign={"left"}>
                              <Checkbox {...label} onChange={handleChange(index)} defaultChecked={propData?.capital_type?.includes(capital_item?.capital_name) ? true : false} />
                              <Box component={"span"} color="black" fontWeight={"500"}>{capital_item?.capital_name}</Box>
                            </Box>
                          </Grid>
                        )
                      })
                      }
                    </Grid>
                  </Box>
                </Box>
                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>{Boolean(parseFloat(projectSize) < parseFloat(raise)) ? "Total raise can not be greater than total project value." : (parseInt(repayDay) < 1 || parseInt(repayDay) > 28) ? "Payment dates should be between the 1st and the 28th." : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : ""}</Typography>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    <Button disabled={Boolean(parseFloat(projectSize) >= parseFloat(raise)) && (projectSize !== '' && raise !== "" && projectDuration !== "" && projectDuration > 0 && raise > 0 && projectSize > 0 && (parseInt(repayDay) >= 1 && parseInt(repayDay) <= 28) && !isMaxLimitExceeded &&
                      checked.some(element => element === 'on')) ? false : true}
                      onClick={() => handleClick()} endIcon={<KeyboardArrowRightIcon />} className='btn-rounded btn-text-white btn-blue-600'>
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}




