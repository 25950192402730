import React from "react";
import { Box, Typography, Button, Modal, Grid, TextField } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./SendCustomeOfferPopup.css";

const SendCustomeOfferPopup = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "var(--blue-900)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    borderWidth: "0px",
    "@media (max-width: 600px)": {
      width: "350px", // Set a different height for small screens
      maxHeight: "700px",
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
      // Custom styles for devices between 601px and 900px width
      width: "100%",
      maxWidth: "580px",
      maxHeight: "650px",
      overflow: "auto",
    },
    "@media (min-width: 901px) and (max-width: 1200px)": {
      // Custom styles for devices between 901px and 1200px width
      width: "750px",
      maxHeight: "300px",
      overflow: "auto",
    },
    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
      maxWidth: "1043px",
      maxHeight: "525px",
      overflow: "auto",
    },
  };
  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-custom-offer-container"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title font-28" component="h2">
            Send a Custom Offer
          </Typography>
          <Box className="step1-container">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <Box className="left-div">
                  <Typography className="font-12 text-blueGray-300">
                    Step 1
                  </Typography>
                  <Typography className="font-22">
                    Confirm your deliverables
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    List down what you will work on and the files you will
                    deliver to the clients. Be as detailed as possible.
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="" md={6} sm={12}>
                <Box className="right-div">
                  <Typography className="font-16">
                    Describe what you will deliver
                  </Typography>
                  <Box mt={1}>
                    <TextField
                      id="outlined-multiline-flexible"
                      placeholder="What are you working on?"
                      multiline
                      maxRows={10}
                      rows={4}
                      value=""
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="step2-container">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <Box className="left-div">
                  <Typography className="font-12 text-blueGray-300">
                    Step 2
                  </Typography>
                  <Typography className="font-22">
                    Send your quotation
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    This is how much you’re charging for this service project.
                    If you have an hourly rate, estimate the total rate based on
                    the number of hours required for the service,
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="" md={6} sm={12}>
                <Box className="right-div">
                  <Typography className="font-16">Amount Charged</Typography>
                  <Box mt={1}>
                    <TextField
                      id="outlined-multiline-flexible"
                      placeholder="What are you working on?"
                      multiline
                      maxRows={10}
                      rows={4}
                      value=""
                    />
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} md={6}>
                        <Box className="hourly-rate-container">
                          <Typography className="font-14">
                            Hourly Rate
                          </Typography>
                          <Typography className="font-12">
                            Pay by hour
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={6}>
                        <Box className="hourly-rate-container">
                          <Typography className="font-14">
                            Project Budget
                          </Typography>
                          <Typography className="font-12">
                            Budget for the entire project
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={8} md={6}>
                        <Box className="estimated-hours">
                          <TextField
                            id="outlined-basic"
                            label="Estimated hours"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={6}>
                        <Box className="total-amount">
                          <TextField
                            id="outlined-basic"
                            label="Total Amount"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="send-offer">
            <Button variant="contained" startIcon={<RemoveRedEyeIcon />}>
              Send Offer
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SendCustomeOfferPopup;
