import React from 'react'
import { Box, FormControl, Grid, InputLabel, ListItemText, Select, Typography } from "@mui/material";
import { MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import './ServiceProviderFilter.css'
import { Assessment, Paid, SwapVert, ViewComfy  } from '@mui/icons-material';
import countryList from "react-select-country-list";

const Filter = ({ title , totalServices, filter, setFilter}) => {
    const priceRange = [{
        title: '0-20',
        id: '0-20'
    },{
        title: '20-40',
        id: '20-40'
    },{
        title: '40-60',
        id: '40-60'
    },{
        title: '60-80',
        id: '60-80'
    },{
        title: 'Above 80',
        id: '80-0'
    }];

    const rating = [{
        title: 'Not rated',
        id: 0
    },{
        title: '1 and above',
        id: 1
    },{
        title: '2 and above',
        id: 2
    },{
        title: '3 and above',
        id: 3
    },{
        title: '4 and above',
        id: 4
    }];

    const savedRequest = [{
        title: 'Saved Request',
        id: 'savedRequest'
    }];

    const initialCountryList = countryList().getData().map(country => ({
        title: `${country.label} (${country.value})`,
        id: `${country.label} (${country.value})`
    }));

    const sortedCountryList = initialCountryList.sort((a, b) => a.title.localeCompare(b.title));

    const sort = [{
        title: 'A to Z',
        id: 'ASC'
    },{
        title: 'Z to A',
        id: 'DESC'
    }];

    const handleChange = (value, field) => {
        let temp = { ...filter };
        if(field === 'priceRange'){
            //priceRangeStart, priceRangeEnd
            const priceRangeStart = value.split('-')[0];
            const priceRangeEnd = value.split('-')[1];
            setFilter({...temp, priceRangeStart, priceRangeEnd})
        }else{
            setFilter({...temp, [field]: value});
        }
    }

    return (
        <>
            <Box className='container'>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant='h4' className='text-white'>{title}</Typography>
                        {totalServices !== undefined && <Typography  className='text-white font-14'>{totalServices ? `${totalServices} Services ` : "No Data Found"} </Typography>}
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Stack direction="row" flexWrap={'wrap'} gap={1.5} justifyContent={'end'}>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '130px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Price Range
                                        <ViewComfy className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Price Range"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'priceRange')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {priceRange?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '120px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Locations
                                        <Assessment className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Locations"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'location')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {sortedCountryList?.slice(0, 5).map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                        {sortedCountryList?.slice(5).map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '100px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                        Ratings
                                        <Paid className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Ratings"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'rating')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {rating?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group">
                                <FormControl className="filter-select-rounded" sx={{ minWidth: '150px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Sort By: Name
                                        <SwapVert className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Sort By: Name"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'sort')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {sort?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="select-group ">
                                <FormControl className="filter-select-rounded " sx={{ minWidth: '210px' }} size="small">
                                    <InputLabel id="demo-controlled-open-select-label" style={{ color: '#fff' }}>
                                    Saved Service Providers
                                        <Assessment className='text-white' />
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        label="Saved Service Providers"
                                        variant='outlined'
                                        onChange={(e) => handleChange(e.target.value, 'savedRequest')}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {savedRequest?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}


export default Filter;
