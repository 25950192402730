import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton, } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import SliderCardProser from "../../../Components/Common/SliderCardProser";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomPercent from "../../../Components/Common/NumericFormatCustomPercent";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./Components/Questionnaire.css";
import { useSnackbar } from "../../../Contexts/SnackbarContext";

export default function SetLoanDetails(props) {
    const { showSnackbar } = useSnackbar()

    // Destructure props to obtain necessary functions and data
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, } = props;

    // Get current proposal and progress data from the parent component
    let propData = getProposalData();
    let currentProgress = getProgress();

    // Loan amount
    const [loanAmount, setLoanAmount] = useState(
        propData?.loan_amount ? propData?.loan_amount : 0
    );

    // Loan return rate
    const [returnRate, setReturnRate] = useState(
        propData?.loan_roi ? propData?.loan_roi : 0
    );

    // Loan duration in year
    const [durationMonth, setDurationMonth] = useState(
        propData?.loan_duration_month ? propData?.loan_duration_month : 1
    );

    // Minimum investment in months
    const [minimumIn, setMinimumIn] = useState(
        propData?.loan_minimum_investment ? propData?.loan_minimum_investment : 0
    );

    // Interest only period in months
    const [loanIOP, setLoanIOP] = useState(
        propData?.loan_iop ? propData?.loan_iop : 0
    );

    // Interest only period in months
    const [repayDay, setRepayDay] = useState(
        propData?.loan_repay_day ? propData?.loan_repay_day : 1
    );

    // state to hold max limit for project price
    const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
    const [maxLimit, setMaxLimit] = useState(0);


    /**
    * Function to handle change in duration loan (duration inc or dec by 1)
    * @param {String} type 
    * @param {Function} setFunction
    * @param {String} functionName
    */
    const handleChangeDuration = (type, setFunction, functionName) => {
        if (type === "inc") {
            if (functionName === "setDurationMonth") {
                setFunction((prevCount) => prevCount ? parseInt(prevCount) + 1 : 1);
            }
            else { setFunction((prevCount) => parseInt(prevCount) < 28 ? parseInt(prevCount) + 1 : 28) }
        } else {
            if (functionName === "setDurationMonth") {
                setFunction((prevCount) =>
                    parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount
                );
            }
            else { setFunction((prevCount) => parseInt(prevCount) >= 2 ? parseInt(prevCount) - 1 : prevCount); }
        }
    };

    /**
    * Function to handle change in interest rate loan + 0.25%, minimum interest rate can be 1%
    * @param {String} type 
    */
    const changeInterestRate = (type) => {
        if (type === "inc") {
            setReturnRate((prevCount) => prevCount ? parseFloat(prevCount) + 0.25 : 1);
        } else {
            setReturnRate((prevCount) =>
                parseFloat(prevCount) >= 1.25 ? parseFloat(prevCount) - 0.25 : prevCount
            );
        }
    };

    /**
    * Function to increment or decrement values by $500
    * @param {String} type 
    */
    const changeLoanAmount = (type, setFunction) => {
        if (type === "inc") {
            setFunction((prevCount) => prevCount ? parseFloat(prevCount) + 500 : 500);
        } else {
            setFunction((prevCount) => parseFloat(prevCount) >= 500 ? parseFloat(prevCount) - 500 : prevCount);
        }
    };

    /**
    * Function to handle "Next" button click
    */
    const handleClick = () => {
        propData.loan_amount = loanAmount;
        propData.loan_roi = returnRate;
        propData.loan_iop = loanIOP;
        propData.loan_minimum_investment = minimumIn;
        propData.loan_duration_month = durationMonth;
        propData.loan_repay_day = repayDay;
        updateProposalData(propData);
        handleNext();
    };

    /**
    * Function to handle "Save and Exit" button click
    */
    const SaveAndExit = () => {
        propData.loan_amount = loanAmount;
        propData.loan_roi = returnRate;
        propData.loan_iop = loanIOP;
        propData.loan_minimum_investment = minimumIn;
        propData.loan_duration_month = durationMonth;
        propData.loan_repay_day = repayDay;
        updateProposalData(propData);
        saveProposal();
    };

    // converting string to numeric
    // const estimatedValue = parseFloat(propData?.watchInformation?.watch_estimated ? propData?.watchInformation?.watch_estimated : propData?.goldInformation?.gold_estimated ? propData?.goldInformation?.gold_estimated : 0); // Parses the string as an float

    const estimatedValue = parseFloat(
        propData?.watchInformation?.watch_estimated
            ? propData?.watchInformation?.watch_estimated
            : propData?.goldInformation?.gold_estimated
                ? propData?.goldInformation?.gold_estimated
                : propData?.carInformation?.car_estimated ? propData?.carInformation?.car_estimated : 0  // Parses the string as an float
    );

    useEffect(() => {
        if (loanAmount) {
            // checking for max range
            let checkMaxLimitExceeded = true, checkMaxLimit = 0;
            let stackAmtData = propData?.stackAmtData;
            let projectAmt = parseFloat(loanAmount)
            if (stackAmtData?.length) {
                stackAmtData?.forEach((dataItem) => {
                    checkMaxLimit = dataItem?.max_amount
                    if (dataItem?.min_amount <= projectAmt && projectAmt <= dataItem?.max_amount) {
                        checkMaxLimitExceeded = false
                    }
                })
                setIsMaxLimitExceeded(checkMaxLimitExceeded)
                setMaxLimit(checkMaxLimit)
            }
            // check for max range exceeded
            if (checkMaxLimitExceeded && checkMaxLimit > 0) {
                showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
            } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
                showSnackbar(`Range configuration is not set for the asset type`, 'error');
            }
        }
    }, [loanAmount])


    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Now, set your loan details
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right  quests-right-contents-box">
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button
                                        onClick={SaveAndExit}
                                        className="btn-rounded btn-text-white btn-blue-600"
                                        disabled={
                                            (loanAmount != "" && loanAmount > 0 &&
                                                returnRate != "" && (parseFloat(returnRate) <= 100 && parseFloat(returnRate) >= 1) &&
                                                durationMonth != "" && durationMonth > 0 &&
                                                repayDay != "" && (loanIOP != "" || loanIOP == 0) &&
                                                minimumIn != "" &&
                                                Boolean(parseFloat(minimumIn) <= parseFloat(loanAmount)) &&
                                                Boolean(parseFloat(loanAmount) <= parseFloat(estimatedValue))
                                                && (parseInt(repayDay) >= 1 && parseInt(repayDay) <= 28)
                                                && Boolean(parseFloat(loanIOP) < parseFloat(durationMonth)) && !isMaxLimitExceeded
                                            ) ? false : true
                                        }
                                    >
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ padding: "inherit" }} className="qsn-middle-content">
                                <Box className="questionnaire5" sx={{ pt: 4, mb: 5 }}>
                                    <Box className="CategoryBox">
                                        <Grid container direction="row">
                                            {propData?.selectedListingType === "loan" ? <>
                                                <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid>
                                                <Grid item xs={10} sm={10} md={8} lg={7} className="priceDetailsImage">
                                                    <SliderCardProser
                                                        height={"100%"}
                                                        image={(propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view}
                                                        title={propData?.asset_title}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid></>
                                                :
                                                <Grid item xs={12} sm={12} md={12} className="priceDetailsImage">
                                                    <SliderCardProser
                                                        height={"80.76%"}
                                                        image={
                                                            propData?.selectedCategory === "nft" ||
                                                                propData?.asset_coverphoto_view?.startsWith(
                                                                    "proposer/uploads/"
                                                                )
                                                                ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}`
                                                                : propData?.asset_coverphoto_view
                                                        }
                                                        title={propData?.asset_title}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box>
                                    <Grid container spacing={{ xs: 1, md: 1, sm: 1 }} my={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 2.5 }}
                                            >
                                                Loan Amount
                                            </Typography>
                                            <Box className="NS-sale-details">
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeLoanAmount("dec", setLoanAmount);
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={loanAmount}
                                                        size="small"
                                                        onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustomDollar,
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeLoanAmount("inc", setLoanAmount);
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 2.5 }}
                                            >
                                                Minimum Investment
                                            </Typography>
                                            <Box className="NS-sale-details">
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeLoanAmount("dec", setMinimumIn);
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={minimumIn}
                                                        size="small"
                                                        onChange={(e) => setMinimumIn(parseFloat(e.target.value))}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustomDollar,
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeLoanAmount("inc", setMinimumIn);
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 2.5 }}
                                            >
                                               Annual Preferred Interest Rate
                                            </Typography>
                                            <Box className="NS-sale-details">
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeInterestRate("dec");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={returnRate}
                                                        onChange={(e) => setReturnRate(parseFloat(e.target.value))}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustomPercent,
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        size="small"
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            changeInterestRate("inc");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 1.75 }}
                                            >
                                                Loan Duration
                                            </Typography>
                                            <Box className="NS-sale-details" mb={2}>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("dec", setDurationMonth, "setDurationMonth");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={
                                                            {
                                                                inputComponent: NumericFormatCustom,
                                                            }}
                                                        value={durationMonth}
                                                        size="small"
                                                        onChange={(e) => setDurationMonth(parseInt(e.target.value))}
                                                        helperText={durationMonth > 1 ? "Months" : "Month"}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("inc", setDurationMonth, "setDurationMonth");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 1.75 }}
                                            >
                                                Interest Only Period
                                            </Typography>
                                            <Box className="NS-sale-details" mb={2}>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("dec", setLoanIOP, "setDurationMonth");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={
                                                            {
                                                                inputComponent: NumericFormatCustom,
                                                            }}
                                                        value={loanIOP}
                                                        size="small"
                                                        onChange={(e) => setLoanIOP(parseInt(e.target.value))}
                                                        helperText={loanIOP > 1 ? "Months" : "Month"}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("inc", setLoanIOP, "setDurationMonth");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Typography
                                                fontWeight={"500"}
                                                sx={{ color: "black", textAlign: "center", my: 1 }}
                                            >
                                                Payment Date
                                            </Typography>
                                            <Box className="NS-sale-details" mb={2}>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("dec", setRepayDay, "setRepayDay");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <TextField
                                                        id="outlined-number"
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={
                                                            {
                                                                inputComponent: NumericFormatCustom,
                                                            }}
                                                        value={repayDay}
                                                        size="small"
                                                        onChange={(e) => setRepayDay(parseInt(e.target.value))}
                                                        helperText={"Day in month"}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box className="NS-sale-details-icon">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleChangeDuration("inc", setRepayDay, "setRepayDay");
                                                        }}
                                                        className="NS-sale-details-icon-btn"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>{Boolean(parseFloat(loanAmount) > parseFloat(estimatedValue)) ? "Loan amount can not be greater than estimated value." : Boolean(parseFloat(minimumIn) > parseFloat(loanAmount)) ? "Minimum investment can not be greater than loan amount." : (parseInt(repayDay) < 1 || parseInt(repayDay) > 28) ? "Payment dates should be between the 1st and the 28th." : Boolean(parseFloat(loanIOP) >= parseFloat(durationMonth)) ? "The interest-only period must be less than the loan duration." : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : ""}</Typography>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress">
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button
                                            className="btn-rounded back-btn"
                                            onClick={handleback}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            disabled={
                                                (loanAmount != "" && loanAmount > 0 &&
                                                    returnRate != "" && (parseFloat(returnRate) <= 100 && parseFloat(returnRate) >= 1) &&
                                                    durationMonth != "" && durationMonth > 0 &&
                                                    repayDay != "" && (loanIOP != "" || loanIOP == 0) &&
                                                    minimumIn != "" &&
                                                    Boolean(parseFloat(minimumIn) <= parseFloat(loanAmount)) &&
                                                    Boolean(parseFloat(loanAmount) <= parseFloat(estimatedValue))
                                                    && (parseInt(repayDay) >= 1 && parseInt(repayDay) <= 28) &&
                                                    Boolean(parseFloat(loanIOP) < parseFloat(durationMonth)) && !isMaxLimitExceeded
                                                ) ? false : true
                                            }
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowRightIcon />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
