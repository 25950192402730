import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Box,
  styled,
  Drawer,
  IconButton,
  List,
  Badge,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import RoomServiceRoundedIcon from "@mui/icons-material/RoomServiceRounded";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSocket } from "../../../Contexts/SocketContext";
import {ReactComponent as Workspace} from "../../../Assets/Images/service-provider/work.svg";
import {ReactComponent as WorkspaceGradient} from "../../../Assets/Images/service-provider/work-hover.svg";
import {ReactComponent as MyProfile} from "../../../Assets/Images/service-provider/my-profile-icon.svg";
import {ReactComponent as MyProfileGradient} from "../../../Assets/Images/service-provider/my-profile-gradient.svg";
import {ReactComponent as MyJob} from "../../../Assets/Images/service-provider/my-job.svg";
import {ReactComponent as MyJobGradient} from "../../../Assets/Images/service-provider/my-job-gradient.svg";
import {ReactComponent as SavedRequest} from "../../../Assets/Images/service-provider/bookmarks.svg";
import {ReactComponent as SavedRequestGradient} from "../../../Assets/Images/service-provider/bookmarks-hover.svg";
import {ReactComponent as Wallet} from "../../../Assets/Images/service-provider/attach_money.svg";
import {ReactComponent as WalletGradient} from "../../../Assets/Images/service-provider/attach_money-hover.svg";
import {ReactComponent as MessageIcon} from "../../../Assets/Images/service-provider/message.svg";
import {ReactComponent as MessageGradient} from "../../../Assets/Images/service-provider/message-hover.svg";
import {ReactComponent as DocumentIcon} from "../../../Assets/Images/service-provider/assignment.svg";
import {ReactComponent as DocumentGradient} from "../../../Assets/Images/service-provider/assignment-hover.svg";
import {ReactComponent as SettingIcon} from "../../../Assets/Images/service-provider/settings.svg";
import {ReactComponent as SettingGradient} from "../../../Assets/Images/service-provider/settings-hover.svg";
import {ReactComponent as OfferIcon} from "../../../Assets/Images/service-provider/offer-icon.svg";
import {ReactComponent as OfferIconGradient} from "../../../Assets/Images/service-provider/offer-hover.svg";
import { removeServiceProviderFromLocalStorage, setServiceProposerInLocalStorage } from "../../../Services/localstorage.service";
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  padding: "12px",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  [theme.breakpoints.up("xs")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    marginTop: "64px",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#032744",
      borderRadius: "0px 16px 16px 0px",
    },
  }),
}));

const ServiceProviderSideBar = ({ handleSidebarChange }) => {
  const socket = useSocket();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const lastSegment = window.location.href.split("/").pop();

  const { totalUnreadCount } = useSelector((state) => state.serviceChat);

  const location = useLocation();

  useEffect(() => {
    if(/view-contract/.test(location?.pathname) ||
    /view-job/.test(location?.pathname)){
      setActiveMenuItem('My Jobs');
    }else{
      setActiveMenuItem(null);
    }
  }, [location])

  const handleDrawerOpen = () => {
    setOpen(true);
    handleSidebarChange(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleSidebarChange(false);
  };

  const handleProposeAProject = () => {
    // removeServiceProviderFromLocalStorage();
    // setServiceProposerInLocalStorage();
    // navigate("/service-proposer");
  };

  return (
    <CustomDrawer className="left-sidebar" variant="permanent" open={open}>
      {open ? (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton
            className="leftSidebar-toggle"
            onClick={handleDrawerClose}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      ) : (
        <Box className="leftSidebar-toggle-wrap">
          <IconButton className="leftSidebar-toggle" onClick={handleDrawerOpen}>
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box className="service-provider-sidebar-head">
        {open ? (
          <>
            <Box className="provide-service-btn" mb={2}>
              {/* <NavLink
                to="/service-provider/browser-request"
                exact
                className={`nav-link`}
              > */}
                <Button
                  variant="contained"
                  className="btn btn-rounded btn-golden btn-large"
                  onClick={handleProposeAProject}
                >
                  <RoomServiceRoundedIcon />
                  <span className="btn-text ">Propose a project</span>
                </Button>
              {/* </NavLink> */}
            </Box>
            <Divider className="divider" />
          </>
        ) : (
          <>
            <Divider className="divider-closed" />
          </>
        )}
      </Box>
      <List className={open ? "" : "menu-close"}>
        <Box className="service-provider-sidebar-menu">
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/overview"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Workspace" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <Workspace className="workspace-icon"/>
                <WorkspaceGradient className="workspace-gradient" />
                </Box>
              </Tooltip>
              {open && "Workspace"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/profile"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "My Profile" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <MyProfile className="workspace-icon"/>
                <MyProfileGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "My Profile"}
            </NavLink>
          </Box>

          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/my-jobs"
              exact
              className={`nav-link ${activeMenuItem === 'My Jobs' ? 'active' : ''}`}
            >
              <Tooltip
                title={open === false ? "My Jobs" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <MyJob className="workspace-icon"/>
                <MyJobGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "My Jobs"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/my-offers"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "My Offers" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <OfferIcon className="workspace-icon"/>
                <OfferIconGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "My Offers"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/savedrequest"
              exact
              className={`nav-link`}
            >
              <Tooltip
                title={open === false ? "Saved Requests" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <SavedRequest className="workspace-icon"/>
                <SavedRequestGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "Saved Requests"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="/service-provider-dashboard/wallet" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Wallet" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <Wallet className="workspace-icon"/>
                <WalletGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "Wallet"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink
              to="/service-provider-dashboard/chats"
              exact
              className={`nav-link`}
              style={{}}
            >
              <Tooltip
                title={open === false ? "Messages" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon" style={{}}>
                  {/* // forcefully 0 unread messages make badge  invisible when sidebar is expanded */}
                  <Badge
                    badgeContent={open ? 0 : totalUnreadCount}
                    color="error"
                  >
                  <MessageIcon className="workspace-icon"/>
                  <MessageGradient className="workspace-gradient"/>
                  </Badge>
                </Box>{" "}
              </Tooltip>
              {open && "Messages"}
              <Badge
                badgeContent={open ? totalUnreadCount : 0}
                color="error"
                sx={{
                  transition: "opacity 0.5s ease-in",
                  opacity: open ? 1 : 0,
                  float: "right",
                  mt: "3px",
                  right: "-84px",
                }}
              />
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="/service-provider-dashboard/documents" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Documents" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <DocumentIcon className="workspace-icon"/>
                <DocumentGradient className="workspace-gradient"/>
                </Box>{" "}
              </Tooltip>
              {open && "Documents"}
            </NavLink>
          </Box>
          <Box className={open ? "menu-item" : "menu-item menu-item-close"}>
            <NavLink to="/service-provider-dashboard/settings" exact className={`nav-link`}>
              <Tooltip
                title={open === false ? "Settings" : ""}
                arrow
                placement="right"
              >
                <Box component={"span"} className="icon">
                <SettingIcon className="workspace-icon" style={{ marginRight: open ? "10px" : "0px" }}/>
                <SettingGradient className="workspace-gradient" style={{ marginRight: open ? "10px" : "0px" }}/>
                </Box>{" "}
              </Tooltip>
              {open && "Settings"}
            </NavLink>
          </Box>
        </Box>
      </List>
    </CustomDrawer>
  );
};

export default ServiceProviderSideBar;
