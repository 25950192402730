import React, { useEffect } from 'react'
import { Box } from '@mui/system';
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@mui/material/styles"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Payment from "./Components/Payment"
import Contact from "./Components/Contact"
import Password from "./Components/Password"
import Deactivate from "./Components/Deactivate"
import Personal from './Components/Personal'
import Security from './Components/Security'
import './Setting.css'
import { GetApi } from '../../Api/Api';

/**
 * TabPanel functional component that conditionally renders its children based on the active tab.
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The content to be rendered within the TabPanel.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of the specific TabPanel.
 * @param {Object} other - Additional properties spread onto the component.
 * @returns {JSX.Element} - The rendered TabPanel component.
 */
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div >
    )
}

/**
 * Helper function to generate accessibility properties for tabs.
 * @param {number} index - The index of the tab.
 * @returns {Object} - Accessibility properties for the tab.
 */
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    }
}


const Setting = () => {
    // Importing 'useTheme' hook from the Material-UI library
    const theme = useTheme();

    // State to manage the current tab 
    const [value, setValue] = React.useState(0)

    // State to manage current user data
    const [userData, setUserData] = React.useState(null)

    /**
   * Function to handle changes in the tab, triggered by the TabPanel
   * @param {Number} newValue - index value of tab panel
   */
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    /**
  * Function to handle changes in the index, used for tab navigation
  * @param {Number} index 
  */
    const handleChangeIndex = index => {
        setValue(index)
    }

    // useEffect to get user data 
    useEffect(() => {
        let u = localStorage.getItem('user_data')
        let user = JSON.parse(u)
        const getUserData = async () => {
            //todo get user data
            try {
                const res = await GetApi('/user/getContactInformation/' + user.id)
                if (res.data.code === 200) {
                    setUserData(res.data.data)
                }
            } catch (error) { }
        }
        const timer = setTimeout(() => {
            getUserData()
        }, 100);
        return () => clearTimeout(timer);
    }, [value])

    return (
        <>
            <Box className='st-page'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    TabIndicatorProps={{
                        sx: {
                            height: '3px',
                            background: '#EDE4C5',
                            borderRadius: '3px 3px 0px 0px',
                        }
                    }}
                >
                    <Tab label="Personal Information" className={value === 0 ? 'st-active' : 'st-inactive'}  {...a11yProps(0)} />
                    <Tab label="Contact Information" className={value === 1 ? 'st-active' : 'st-inactive'}  {...a11yProps(1)} />
                    {/*<Tab label="Payment Methods" className={value === 2 ? 'st-active' : 'st-inactive'}  {...a11yProps(2)} /> */}
                    <Tab label="Security" className={value === 2 ? 'st-active' : 'st-inactive'} {...a11yProps(2)} />
                    <Tab label="Password" className={value === 3 ? 'st-active' : 'st-inactive'} {...a11yProps(3)} />
                    <Tab label="Deactivate Account" className={value === 4 ? 'st-active' : 'st-inactive'}  {...a11yProps(4)} />
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}  >
                        <Personal Data={userData} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Contact userData={userData} />
                    </TabPanel>
                    {/*<TabPanel value={value} index={2} dir={theme.direction}>
                        <Payment />
                </TabPanel> */}
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <Security />
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <Password />
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                        <Deactivate />
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </>
    )
}
export default Setting;