// withdrawAmountSale.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Call the withdrawalAsset method to transfer the asset to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function withdrawAmountSale(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        const { request: withdrawalAsset } = await prepareWriteContract({
            address: CONTRACTS.ART_SALE_MARKETPLACE,
            abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
            account: address,
            chainId: 6806,
            // functionName: "withdrawalAsset",
            functionName: "withdrawalAmount",
            args: [
                assetData?.listing_id, // listing id / ipfs code id Integer
            ]
        })

        const { hash } = await writeContract(withdrawalAsset)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw amount sale:', error);
        throw error; // propagate the error
    }
}

export { withdrawAmountSale };