// dicStake.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Pay stake amount by DIC member to start review process
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function dicStake(address, asset_id, user_id, proposalId, stakeAmt, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        // set active step to 2
        handleActiveStep(2)
        console.log(assetData);
        // checking for project/proposal id of blockchain
        if (!proposalId) {
            showSnackbar("Project ID not found on the blockchain.", 'error');
            handleModalClose();
            handleActiveStep(-1)
            return
        }

        let contract_address = CONTRACTS.STAKE_CONTRACT;
        if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            contract_address = CONTRACTS.FUND_SPACE_X_STAKE_CONTRACT
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            contract_address = CONTRACTS.FUND_STAKE_CONTRACT
        }
        const { request: stakeInPool } = await prepareWriteContract({
            address: contract_address,
            abi: CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6806,
            functionName: "stakeInPool",
            args: [
                Number(proposalId), // asset's blockchain id
                getCurrencyTypeAddress(assetData?.feeTokenType), // _feeToken
                getCurrencyTypeDecimal(assetData?.feeTokenType, stakeAmt)  // Stake amount
                // assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN ? getCurrencyTypeAddress(assetData?.feeTokenType) : CONTRACTS.TOKEN,// _feeToken
                // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(stakeAmt) : convertToDecimal(stakeAmt), // Stake amount
            ]
        })

        const { hash } = await writeContract(stakeInPool);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, stakeAmt, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling dic stake method:', error);
        throw error; // propagate the error
    }
}

export { dicStake };