import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Close, Done, EditSharp, Visibility } from '@mui/icons-material';
import { Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography, Avatar, Modal, TextField, TableCell } from '@mui/material';
import { postApi } from '../../Api/Api';
import { CAPITAL_TYPE_ID } from '../../constants';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { NewLogo } from "../../Components/Images/Images";
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal';
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

const EquityPanel = () => {
    const [calculateEmiAmount, setCalculateEmiAmount] = useState('');
    const [loanTableData, setLoanTableData] = useState([])
    const [openMakePayment, setOpenMakePayment] = useState(false)
    const [makePaymentData, setMakePaymentData] = useState('')
    const [ownership, setOwnership] = useState({ equity: 0, total_raise: 1 });

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    useEffect(() => {
        /**
        * Api to get Calculate EMI data
        */
        const getCalculateEmiLoanTable = async () => {
            try {
                let res = await postApi('/proposer/getCalculateEquityAmount', { proposer_id: User.id });
                if (res?.data?.data) {
                    let ans = res.data.data;
                    if (ans?.length) {
                        setCalculateEmiAmount(ans);
                        if (ans[0] && ans[0].asset_id) {
                            const newAssetData = await postApi("/proposer/assetsDetails", { "asset_id": ans[0].asset_id });
                            if (newAssetData?.data?.data && newAssetData?.data?.data[0]) {
                                let data = newAssetData?.data?.data[0];
                                let capital_info = data?.capital_info?.find((item) => item.capital_type.id === CAPITAL_TYPE_ID.EQUITY)
                                if (capital_info) {
                                    setOwnership({ equity: capital_info?.equity, total_raise: capital_info?.total_raise });
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        getCalculateEmiLoanTable();
    }, [])

    useEffect(() => {
        if (calculateEmiAmount && calculateEmiAmount.length > 0) {
            let table = [];
            calculateEmiAmount.forEach((details) => {
                //create a single table row i.e an loan emiData
                let tableRow = {
                    project_name: details?.asset_title,
                    capital_type_id: CAPITAL_TYPE_ID.EQUITY,
                    total_amount: details?.balance_sum,
                    ...details,
                    listing_id: details?.listingId,
                    category_name: details?.category_name,
                    category_id: details?.category_id,
                    asset_id: details?.asset_id
                    // user_id: details?.user_id,
                };
                table.push(tableRow);
            });
            setLoanTableData(table);
        } else {
            setLoanTableData([]);
        }
    }, [calculateEmiAmount])

    /**
     * opens the payment modal for making payment
     * @param {Object}  
     */
    const handleOpenMakePayment = (row, index) => {
        setMakePaymentData(row);
        setOpenMakePayment(true)
    }

    /**
     * closes the payment review modal
     */
    const handleCloseMakePayment = () => {
        setOpenMakePayment(false)
    }

    return (
        <>

            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Equity Received
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Asset Type</StyledTableCell>
                            <StyledTableCell align="left">Total Amount</StyledTableCell>
                            <StyledTableCell align="left">Equity</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanTableData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.name}${i}`}>
                                <StyledTableCell component="th" scope="row"><Link to={`/project-details/${row?.asset_id}`} >{row?.project_name}</Link></StyledTableCell>
                                <StyledTableCell align="left">{row?.category_name}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.total_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color'>{parseFloat((row?.balance_sum ? row?.balance_sum : 0) / (ownership?.total_raise ? ownership?.total_raise : 1) * ownership?.equity).toFixed(2) + '%'}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {
                                        row?.dueStatus === DUE_STATUS.PAID ?
                                            <Button onClick={() => { }} startIcon={<Visibility />} className='status-button'>View Transaction</Button>
                                            :
                                            <Button
                                                // disabled={true}
                                                onClick={() => handleOpenMakePayment(row, i)}
                                                disableFocusRipple
                                                disableRipple
                                                startIcon={<EditSharp />} className='status-button'>
                                                Make Payment
                                            </Button>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {openMakePayment
                ? <MakePayment payEMIData={makePaymentData} openMakePayment={openMakePayment} handleCloseMakePayment={handleCloseMakePayment} />
                : null
            }

        </>
    )
}

export default EquityPanel

function MakePayment({ payEMIData, openMakePayment, handleCloseMakePayment }) {
    const style = { position: "relative", boxShadow: 24, borderRadius: "24px", width: "1043px", };
    const rowTextStyles = { color: "burlywood", fontWeight: '500', fontSize: '16px' }

    const [transactionModal, setTransactionModal] = useState({
        open: false,
        title: "Repayment",
        message: `is done successfully, For transaction see ` + <span style={{ textDecoration: 'underline' }}><Link to="/user/transactions" target="_blank" >My Transactions</Link></span> + ` page.`,
    })
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [openPrepayment, setOpenPrepayment] = useState(false);
    const [propData, setPropData] = useState();
    const [payableAmount, setPayableAmount] = useState(payEMIData?.emi_amount ? payEMIData?.emi_amount : 0);
    const [selectedOption, setSelectedOption] = useState('Pay Dividend');
    const [payDividendAmt, setDividendAmt] = useState(0);

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    useEffect(() => {
        if (payDividendAmt) {
            setPayableAmount(parseInt(payDividendAmt));
        } else {
            if (!payDividendAmt) {
                setPayableAmount(0)
            }
        }
    }, [payDividendAmt])

    /**
     * handle pay button
     */
    const handleMakePayment = async () => {
        try {
            setOpenPrepayment(true);
            setPropData({ ...payEMIData, amount: payableAmount })
        } catch (error) {
            showSnackbar(error?.message, 'error')
        }
    }

    /**
    * Function to handle prepayment modal close
    */
    const handleClosePrepayment = () => {
        setOpenPrepayment(false);
    }

    /**
    * Function call after transaction confirm
    */
    const confirmStake = async (data) => {
        handleCloseMakePayment();
        setTransactionModal({ ...transactionModal, open: true, title: "Dividend" })
    };

    return (
        <>
            {/* modal for pay emi, prepayment and minimum prepayment */}
            {openMakePayment ?
                <Modal
                    open={openMakePayment}
                    onClose={handleCloseMakePayment}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90, objectFit: "contain" }}
                                />
                            </Box>
                        </Box>
                        <Box className="modalContentDone" >
                            <Box className="main-content loan " >
                                <Box className="votingContent">
                                    <Typography component="h1" mt={2} px={4} className="headText" textAlign={"center"}>{selectedOption}</Typography >
                                    {
                                        <Box className='minimum-prepayment'>
                                            <Typography>Enter Dividend Amount: </Typography>
                                            <TextField
                                                className='minimum-prepayment-text'
                                                style={{ width: '200px' }}
                                                placeholder='Enter Amount'
                                                onChange={(e) => setDividendAmt(parseInt(e.target.value))}
                                                InputProps={{
                                                    inputComponent: NumericFormatCustomDollar,
                                                    style: {
                                                        color: 'white'
                                                    }
                                                }}
                                                value={payDividendAmt}
                                                onKeyDown={(event) => {
                                                    if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />

                                        </Box>
                                    }
                                    <Box className='vote-btn-box' mt={2}>
                                        <Button className="wallet-button appr-btn" disabled={payableAmount && parseInt(payableAmount) > 0 ? false : true} onClick={() => handleMakePayment(payableAmount)}> <Box className="btn-icon" ><Done />Pay ${payableAmount}</Box></Button>
                                        <Button className="wallet-button rjct-btn" onClick={handleCloseMakePayment}><Box className="btn-icon" ><Close />Cancel</Box></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                : null}

            {/* Transaction successful status modal */}
            {transactionModal.open ?
                <Modal
                    open={transactionModal.open}
                    onClose={() => { setTransactionModal({ ...transactionModal, open: false }) }}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90, objectFit: "contain" }}
                                />
                            </Box>
                            <Close className="close-btn" onClick={() => { setTransactionModal({ ...transactionModal, open: false }) }} />
                        </Box>
                        <Box className="modalContentDone" >
                            <Box className="main-content" >
                                <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                                <Typography component="h1" className="headText">Payment Successful</Typography >
                                <Typography component="p" className="sub-headText">{transactionModal?.title} {transactionModal?.message}</Typography >
                                <Button onClick={() => { navigate('/user/transactions'); setTransactionModal({ ...transactionModal, open: false }); }} className="wallet-button">Return to Dashboard</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                : null}

            {openPrepayment
                ? <TransactionProgressModal identity={'pay-dividend'} confirmStake={confirmStake} propData={propData} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepayment} />
                : null
            }
        </>
    )

}