import React, { useState } from 'react';
import {
    Box,
    Chip,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Modal,
    IconButton,
    Grid,
    TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../../Config/Config';
import { formatDate, validAmountCheck } from '../../../Utils/utils';
import { updateApi } from "../../../Api/Api";
import ConfirmModal from "../../../Components/Modal/Confirm/Confirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#053863",
      color: "#fff",
      borderBottom: "0px",
      "&:first-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
      "&:last-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#fff',
      borderBottom: "0px",
    },
}));

const offerModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--blue-900)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderWidth: "0px",
  borderRadius: "16px",
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
    maxHeight: "300px",
    overflow: "auto",
  },
  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "525px",
    overflow: "auto",
  },
};

export default function OfferList({ fetchData, offerList, paginationData, currentPage, setCurrentPage }) {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [ openModal, setOpenModal ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ offerId, setOfferId ] = useState(null);
    const [ quoteAmount, setQuoteAmount ] = useState(null);
    const [quoteAmountError, setQuoteAmountError] = useState('');

    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
    const editOffer = (offerData) => {
      setOfferId(offerData?.id);
      setQuoteAmount(offerData?.offer_amount);
      setOpenModal(true);
    }
    const updateOffer = () => {
      const err = validAmountCheck(quoteAmount);
      if(err){
        setQuoteAmountError(err);
        return;
      }
      const payload = {
        offer_amount: quoteAmount,
        status: 1
      }
      setLoading(true);
      updateApi(`/services/spvOffer/${offerId}`, payload).then((res) => {
        if (res?.status === 200) {
          if(res?.data?.code === 200){
            setLoading(false);
            showSnackbar("Update Successfully", "success");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
            fetchData();
          }else if(res?.data?.code === 201){
            setLoading(false);
            showSnackbar(res?.data?.message, "warning");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }else{
            setLoading(false);
            showSnackbar(res?.data?.message, "error");
            setOpenModal(false);
            setQuoteAmount('');
            setQuoteAmountError('');
          }
        } else { // something went wrong user or backend side
          setLoading(false);
          showSnackbar("Something went wrong", "error");
        }
      })
    }
    return (
        <>
            <Box>
                <TableContainer component={Paper} className="TableContainer">
                    <Typography className="font-22 text-white" mb={2}>
                      My Offers
                    </Typography>
                    <Table aria-label="customized table">
                    <TableHead className="TableHeader">
                      <TableRow>
                        <StyledTableCell>Project</StyledTableCell>
                        <StyledTableCell>Offer Date</StyledTableCell>
                        <StyledTableCell>Offer Amount</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {offerList && offerList.length > 0 ? offerList.map((row) => (
                        <TableRow
                          key={row.id}
                          className='cursor-pointer'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" sx={{ color: '#fff', borderBottom: 'none', cursor: 'pointer' }}>
                            <Link to={`/service-provider/view-single-request/${row?.project_id}`}>
                              {row?.projectInfo?.project_title}
                            </Link>
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                            {row?.createdAt ? formatDate(row.createdAt, 'MMM dd, h:mm a') : ''}
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                            ${row.offer_amount}
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none', cursor: "pointer"}}>
                            <Chip
                              sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                color: "#fff",
                                bgcolor: config.OFFER_STATUS.CONTRACT_STATUS_COLOR[row.status],
                                borderRadius: "8px",
                              }}
                              label={ config.OFFER_STATUS[row.status] }
                            />
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                              {row.status === 1 && 
                                <Tooltip title="Update offer amount" arrow>
                                  <EditIcon onClick={() => editOffer(row)}/>
                                </Tooltip>
                              }
                              {row.status === 2 && 
                                <Tooltip title="" arrow>
                                  <EditOffIcon />
                                </Tooltip>
                              }
                              {row.status === 3 && 
                                <Tooltip title="Update offer amount" arrow>
                                  <EditIcon onClick={() => editOffer(row)}/>
                                </Tooltip>
                              }
                          </TableCell>
                        </TableRow>
                      )) : (null)}
                    </TableBody>
                  </Table>
                  {paginationData?.totalCount > 0 ?
                  <Box className="pagination-container">
                    <Pagination
                      count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Box>:  <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
                      Offers not available
                    </Typography> }
                </TableContainer>
            </Box>
            <Modal
              open={openModal}
              onClose={()=>{
                setOpenModal(false);
                setQuoteAmount('');
                setQuoteAmountError('');
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="send-custom-offer-container send-offer"
            >
              <Box sx={offerModalStyle}>
                <Box className="headContent cross-view-single">
                  <IconButton className='cross-icon-modal' onClick={()=>{
                    setOpenModal(false);
                    setQuoteAmount('');
                    setQuoteAmountError('');
                  }}>
                      <CloseIcon />
                  </IconButton>
                </Box>
                <Typography id="modal-modal-title" component="h2" mb={4}>
                  Send a Custom Offer
                </Typography>
                <Box className="step1-container ">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12}>
                      <Box className="left-div">
                        <Box className="input-box" mb={3}>
                          <TextField
                            id="outlined-basic"
                            label="Quotation"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            placeholder="Quotation"
                            value={"$" + quoteAmount}
                            onChange={(e) => {
                              setQuoteAmount(
                                e.target.value.substring(1)
                              )
                              const err = validAmountCheck(e.target.value.substring(1));
                              if(err)setQuoteAmountError(err);
                              else setQuoteAmountError('');
                            }
                            }
                          />
                          {quoteAmountError && <p style={{color: 'red'}}>{quoteAmountError}</p>}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="send-offer" textAlign={'center'}>
                  <LoadingButton
                      onClick={updateOffer}
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                      type="submit"
                      className="submit-btn"
                    >
                      Update Offer
                    </LoadingButton>
                </Box>
              </Box>
            </Modal>
        </>
    )
}
