import React, { useEffect, useState, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Tooltip, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import "./PostViewProject.css";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams, useNavigate } from "react-router-dom";
import { GetApiParam, updateApi, deleteApi } from '../../../Api/Api';
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import RightPostViewSection from './RightPostViewSection';
import { getUserDataFromLocalStorage } from '../../../Services/localstorage.service';
import ConfirmModal from '../../../Components/Modal/Confirm/Confirm';

const theme = createTheme({
    palette: {
      close: {
        main: '#94a3b8',
      },
    },
  });

const PostViewProject = (data) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [individualProject, setIndividualProject] = useState([]);
    const [ openModal, setOpenModal ] = useState(false);
    const [ openModalDelete, setOpenModalDelete ] = useState(false);
    const userData = getUserDataFromLocalStorage();
    // const individualProject = location?.state?.servicedetail;
    const fetchData = useCallback(() => {
        const payload = {
            id: id,
            lastViewDateTime: new Date(),
            login_user_id: userData.id,
        }
        GetApiParam(`/services/sp_projectRequirement`, payload).then((res) => {
            if (res?.status === 200) {
                if (res.data && res.data.data && res.data.data.length > 0) {
                    setIndividualProject(res.data.data[0]);
                }
            }
        })
    }, [id]);

    const handleAction = async (action) => {
        try {
            if(action === 'confirm'){
                const res = await updateApi('/services/projectStatusUpdate/' + id, { status: individualProject?.status === 2 ? 1 : 2 });// status 2 for close the project
                if (res?.data?.code === 200) {
                    showSnackbar(res?.data?.message, 'success');
                    fetchData();
                } else if (res?.data?.code === 201) {
                    showSnackbar(res?.data?.message, 'error');
                }
                else {
                    showSnackbar(res?.data?.message, 'error');
                }
                setOpenModal(false);
            }
        } catch (error) {
            showSnackbar('Something went wrong', 'error');
            setOpenModal(false);
        }
    }

    const handleActionDelete = async (action) => {
        try {
            if(action === 'confirm'){
                const res = await deleteApi('/services/sp_projectRequirement/' + id);// status 2 for close the project
                if (res?.data?.code === 200) {
                    showSnackbar(res?.data?.message, 'success');
                    navigate("/service-proposer-dashboard/projectposting")
                } else if (res?.data?.code === 201) {
                    showSnackbar(res?.data?.message, 'error');
                }
                else {
                    showSnackbar(res?.data?.message, 'error');
                }
                setOpenModalDelete(false);
            }
        } catch (error) {
            showSnackbar('Something went wrong', 'error');
            setOpenModalDelete(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <> <Grid container spacing={2}>
            <Grid item sm={12} md={8}>
                <Box className="post-view-project-main">
                    <Box>
                        <Typography component="h4" className='title font-22'>Project Details</Typography>
                    </Box>
                    <Box className="project-title">
                        <Typography component="h4" className='title font-16'>{individualProject?.project_title}</Typography>
                        <Typography component="h5" className='sub-title font-12'>{individualProject?.describe_services}</Typography>

                    </Box>
                    <Box className="post-project-category">
                        <Typography component="h4" className='title font-16'>Category</Typography>
                        <Typography component="h5" className='sub-title font-12'>{individualProject?.categoryDetails?.title}</Typography>
                    </Box>
                    <Box className="post-project-skill">
                        <Typography component="h4" className='title font-16'>Skills</Typography>
                        <Box className="button-div">
                            {individualProject?.skillData?.map((skill) => {
                                return <Button variant="outlined" className='btn btn-rounded' startIcon={<AddIcon />}>
                                    {skill?.skill_name}
                                </Button>
                            })}
                        </Box>
                    </Box>
                    <Box className="post-project-scope">
                        <Typography component="h4" className='title font-16'>Scope</Typography>
                        <Typography component="h5" className='sub-title font-12'>{individualProject?.project_size}, {individualProject?.work_time} months, {individualProject?.level_of_experience}</Typography>
                    </Box>
                    <Box className="post-project-budget">
                        <Typography component="h4" className='title font-16'>Budget</Typography>
                        {individualProject?.HourlyRateFrom ?
                            <Typography component="h5" className='sub-title font-12'>${individualProject?.HourlyRateFrom} - ${individualProject?.HourlyRateTo} /hr</Typography>
                            :
                            <Typography component="h5" className='sub-title font-12'>${individualProject?.budget_from} - ${individualProject?.budget_to}</Typography>
                        }
                    </Box>
                    <Box className="d-flex">
                        {individualProject?.buttonCheck?.delete &&
                            <Box>
                                <Button className='post-project-delButton' variant="contained" color="info" startIcon={<DeleteIcon />} onClick={() => setOpenModalDelete(true)} >Delete Project</Button>&nbsp;&nbsp;
                            </Box>
                        }
                        {individualProject?.buttonCheck?.close &&
                            <Box>
                                <ThemeProvider theme={theme}>
                                    {individualProject?.status === 2 ?
                                    <Tooltip title='If your project close' placement='top' arrow>
                                        <Button className='post-project-delButton' variant="contained" color="close" startIcon={<InfoOutlinedIcon />} onClick={() => setOpenModal(true)} >Open</Button> 
                                    </Tooltip>
                                    :
                                    <Tooltip title='If your project has been completed' placement='top' arrow>
                                        <Button className='post-project-delButton' variant="contained" color="close"  startIcon={<InfoOutlinedIcon />} onClick={() => setOpenModal(true)} >Close</Button>
                                    </Tooltip>}
                                </ThemeProvider>
                            </Box>
                        }
                        <ConfirmModal title={individualProject?.status === 2 ? 'Open' : 'Close'} description={`Are You Sure Want To ${individualProject?.status === 2 ? 'Open' : 'Close'} It!`} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction}/>
                        <ConfirmModal title={'Delete'} description={'Are You Sure Want To Delete It!'} isOpen={openModalDelete} setIsOpenModal={setOpenModalDelete} handleAction={handleActionDelete}/>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={12} md={4}>
                <Box>
                    <RightPostViewSection individualProject={individualProject} />
                </Box>
            </Grid>
        </Grid>
        </>
    )
}

export default PostViewProject