import React, { useEffect, useState } from "react";
import { Chip, Box, Avatar, Typography, Badge, Rating, Stack, } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { SaveDeal } from "../../../Components/Images/Images";
import { BookmarkBorder, Bookmarks } from "@mui/icons-material";
import { LocalFireDepartment } from "@mui/icons-material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';
import { NavLink } from "react-router-dom";
import { postApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import "./MainCard.css";
import config from "../../../Config/Config";

const MainCard = (props) => {
  const [ isBookmark, setIsBookmark ] = useState(props?.is_bookmark == 1 ? true : false);
  const image = props?.gallery && props?.gallery?.length > 0 && props?.gallery[0]?.path ? props.gallery[0]?.path : '';
  const isImage = /\.(jpeg|jpg|png)$/i.test(image);
  const isVideo = /\.(mp4)$/i.test(image);
  const localData = getUserDataFromLocalStorage();

  useEffect(() => {
    setIsBookmark(props?.is_bookmark == 1 ? true : false);
  }, [props?.is_bookmark]);

  const handleClickBookmark = async (item) => {
    const payloadData = {
      spv_service_id: props?.id,
      user_id: localData.id,
      is_bookmark: isBookmark ? 0 : 1,
    };

    if (localData.id && props?.id) {
      const bookmarkResponce = await postApi(
        "/services/isBookmarkServices",
        payloadData
      );
      if (bookmarkResponce?.data?.code) {
        setIsBookmark(!isBookmark);
        if(props.getServicesData)props.getServicesData();
      }
    }
  };
  return (
    <>
      <Box className="product-commonCard" sx={{ background: props?.bgColor ? props?.bgColor : '' }}>
        <Box className="pc-card-body" position={"relative"}>
          <Box className="pc-bookmark" display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {isBookmark ? (
                <Bookmarks
                  className="bookmark-icon cursor-pointer"
                  onClick={() =>
                    handleClickBookmark(props)
                  }
                />
            ) : (
                <BookmarkBorder
                  className="bookmark-icon cursor-pointer"
                  onClick={() =>
                    handleClickBookmark(props)
                  }
                />
            )}
          </Box>
          <NavLink to={`/service-proposer/provider-service/${props.id}`}>
            <Box className='type-chip-icon'>
            {props?.userData?.rating >= config?.TOP_RATAED?.ratingGrater && props?.userData?.review >= config?.TOP_RATAED?.reviewGrater ?
              <Chip icon={<LocalFireDepartment />} label={"Popular"} /> : 
              <Chip icon={<LocalFireDepartment />} label={"New"} />}
            </Box>
            <Box className="pc-thumbnail-wrap">
              <Box className="pc-slide" sx={{ cursor: "pointer" }}>
                {isImage && (
                  <img src={`${process.env.REACT_APP_IMAGE_URL}${image}`} alt="Slide Image" />
                )}
                {isVideo && (
                  <div className="video-thumbnail">
                    <VideoThumbnail
                      videoUrl={`${process.env.REACT_APP_IMAGE_URL}${image}`} // Ensure the correct URL is used for videos
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <div className="play-icon-overlay">
                      <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                  </div>
                )}
              </Box>
            </Box>
            <Typography component={"h5"} className="pc-title text-white">
              {props.services_title ? props.services_title : "No Title"}
            </Typography>
            <Typography className="pc-subtitle text-white">
              {props.starter_service_price ? `From $${props.starter_service_price}` : "No Range"}
            </Typography>

            <Box className="pc-chip-wrap" >
              <Stack direction="row" flexWrap={'wrap'} display={"flex"} spacing={1}>
                <Chip className="pc-chip" label={props?.Skills.length ? props?.Skills[0]?.skill_name : null} />
                <Chip className="pc-chip" label={props?.serviceCategory?.title} />
              </Stack>
            </Box>
          </NavLink>
          <NavLink to={`/service-proposer/profile/individual/${props?.userData?.id}`}>
            <Box className="pc-user-wrap" display={"flex"} alignItems={"center"} mt={3}>
              <Box className="user-thumbnail" display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Badge badgeContent={<DoneIcon />} anchorOrigin={{ vertical: "bottom", horizontal: "right", }}>
                  <Avatar className="user-avatar" alt={props?.userData?.first_name[0]} src={props?.userData?.profilePicture?.path ? props?.userData?.profilePicture?.path : SaveDeal} />
                </Badge>
              </Box>
              <Box className="user-details" ml={1.8}>
                <Typography className="user-title text-white">
                  {props?.userData ? `${props?.userData?.first_name}  ${props?.userData?.last_name}` : null}
                </Typography>
                <Typography className="user-subtitle">
                  {props?.userData?.rating >= config?.TOP_RATAED?.ratingGrater && props?.userData?.review >= config?.TOP_RATAED?.reviewGrater ? "Top Rated Service Provider" : props?.userData?.rating ? 'Average Rated' : props?.userData?.rating === 0 ? 'Not Rated' : ''}
                </Typography>
              </Box>
            </Box>
            <Stack direction="row" flexWrap="wrap" spacing={1} className='rating-wrap text-golden'>
              <Box className="value" component={'span'}>{props?.userData?.rating ? props?.userData?.rating : 'NA'}</Box>
              <Rating name="read-only" value={props?.userData?.rating} readOnly precision={0.5} />
              <Box className="review" component={'span'}>{`${props?.userData?.review ? props?.userData?.review : ''}`} Reviews</Box>
            </Stack>
          </NavLink>
        </Box>
      </Box>
    </>
  );
};
export default MainCard;
