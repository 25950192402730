import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Pagination,
  Grid,
  Stack,
  Button, // Import Stack component from Material-UI
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; // Updated import for RemoveRedEyeIcon
import EditIcon from "@mui/icons-material/Edit";
import ProfileDescription from "../Components/ProfileDescription/ProfileDescription";
import WorkHistorySection from "../Components/WorkHistorySection/WorkHistorySection";
import SkillChip from "../Components/Chips/SkillChip/SkillChip";
import RightProjectProfileSection from "../CompanyProfilePage/RightProjectProfileSection";
import { IndividualProfilePersona } from "../../Components/Images/Images";
import { GetApiParam } from "../../Api/Api";
import ServiceProviderCard from "../Components/ServiceProviderCard/ServiceProviderCard";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ProfilePortfolioCard from "../Components/ProfilePortfolioCard/ProfilePortfolioCard";
import AddPortfolioPopup from '../../ServiceProvider/OnboardingProfile/AddPortfolioPopup/AddPortfolioPopup';
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import { useDispatch } from "react-redux";
import { updateProfileData } from '../../features/auth/serviceProviderSlice';
import EditSkillPopup from "../../ServiceProvider/EditProfile/EditSkillPopup/EditSkillPopup"

// Define CustomTabPanel before using it in the JSX
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box  sx={{
          pl: 0, 
          pr: { xs: 0, md: 3 },
          pt: 3, 
          pb: 3
        }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ServiceProviderProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopupOpenEditSkill, setIsPopupOpenEditSkill] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceCurrentPage, setServiceCurrentPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const [serviceData, setServiceData] = useState([]);
  const [isPopupOpenEditPortfolio, setIsPopupOpenEditPortfolio] = useState(false);
  const [portfolioDetails, setPortfolioDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 3; // Number of items per page
  const itemsServicePerPage = 3; // Number of items per page

  const localData = getUserDataFromLocalStorage();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleServiceProviderPageChange = (event, page) => {
    setServiceCurrentPage(page);
  };
  const getProfileData = async () => {
    try {
      setIsLoading(true)
      const res = await GetApiParam("/services/ProfileDetails", {
        user_id: localData?.id ? localData?.id : "",
      });
      setProfileData(res?.data?.data ? res?.data?.data[0] : []);
      setIsLoading(false)
      if (res?.data && res?.data?.data?.length > 0) {
        dispatch(updateProfileData({
          userProfilePicture: res?.data?.data[0]?.profilePicture?.length > 0 ? res?.data?.data[0]?.profilePicture[0]?.path : '',
          first_name: `${res?.data?.data[0].first_name}`,
          last_name: `${res?.data?.data[0].last_name}`,
        }));
      }
    } catch (error) { }
  }
  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    async function getServiceProviderData() {
      try {
        const res = await GetApiParam("/services/getServices", {
          user_id: localData?.id ? localData?.id : "",
        });
        setServiceData(res?.data?.data ? res?.data?.data : []);
      } catch (error) { }
    }
    getServiceProviderData();
  }, []);

  const handleClick = () => {
    navigate("/service-provider-dashboard/listservice");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditPortfolio = (item) => {
    setPortfolioDetails(item)
    handleOpenPopupEdit()
  }

/**
  * Function to open Edit Skill Modal
  */
  const handleCloseSkillPopup = () => {
    setIsPopupOpenEditSkill(false);
  }

  /**
  * Function to open Edit Portfolio Modal
  */
  const handleOpenPopupEdit = () => {
    setIsPopupOpenEditPortfolio(true);
  };

  /**
* Function to close Edit Portfolio Modal
*/
  const handleClosePopupEdit = () => {
    setIsPopupOpenEditPortfolio(false);
  };


  function SingleSection({ children, sectionTitle }) {
    return (
      <Box p={"24px"}>
        <Typography
          variant="subtitle1"
          className="font-18 font-readexpro"
          ml={"16px"}
          mb={3}
        >
          {sectionTitle}
        </Typography>
        {children}
      </Box>
    );
  }

  return (
    <>
      {isLoading ?
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <CircularProgress disableShrink />
        </Box>
        :
        <Box className="service-provider-my-profile-container">
          <Box mt={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <Box className="top-view">
                  <Box className="tab-view">
                    <Box>
                      <Typography className="font-28">Your Profile</Typography>
                    </Box>
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="tabs-btn"
                      >
                        <Tab
                          label="Preview Profile"
                          icon={<RemoveRedEyeIcon />}
                          className="tab-btn"
                          sx={{
                            borderRadius: value === 0 ? "50px" : "0", // Apply border radius only to the first tab
                            bgcolor: value === 0 ? "#398CD1" : "inherit", // Apply background color conditionally
                            height: "15px",
                            color: value === 0 ? "#ffffff" : "#398CD1",
                          }}
                        />
                        <Tab
                          label="All contracts"
                          style={{ color: "#398CD1" }}
                          onClick={() => navigate('/service-provider-dashboard/my-jobs')}
                        />
                      </Tabs>
                    </Box>
                  </Box>
                </Box>
                <Box className="left-container" mt={2}>
                  <Box className="pt-0">
                    <CustomTabPanel value={value} index={0}>
                      <Box className="my-profile-box tab1">
                        <ProfileDescription
                          id={profileData?.id}
                          first_name={profileData && profileData?.first_name}
                          last_name={profileData && profileData?.last_name}
                          name={profileData ? `${profileData?.first_name ? profileData?.first_name : null} ${profileData?.last_name ? profileData?.last_name : null}` : null}
                          address={profileData?.address ? profileData?.address : null}
                          title={profileData && profileData?.title}
                          description={profileData && profileData?.description}
                          persona={profileData?.profilePicture?.length ? profileData?.profilePicture[0]?.path : IndividualProfilePersona}
                          coverPicture={profileData?.coverPicture?.length ? profileData?.coverPicture[0]?.path : null}
                          hourlyrate={profileData && profileData?.hourly_rate}
                          from="serviceProviderProfile"
                          getProfileData={getProfileData}
                        />
                      </Box>
                      <Box className="comapany-profile-skills">
                        <h4 className="title">Work History</h4>
                        <WorkHistorySection userId={profileData?.user_id} />
                      </Box>

                      {/* Portfolio section*/}
                      <Box className="comapany-profile-skills">
                        <Box className="top-view">
                          <Box>
                            <Typography variant="subtitle1" className="font-18 font-readexpro" mb={3} >
                              Portfolio
                            </Typography>
                          </Box>
                          {/* <Box className="edit-cover-image"> */}

                        </Box>
                        <Box mx={{ xs: 0, sm: 2, md: 3, lg: 4 }}
                        >
                          <Grid container spacing={1}>
                            {profileData?.portfolioData && profileData?.portfolioData?.length > 0 ? profileData?.portfolioData
                              ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                              .map((item, index) => (
                                <Grid item key={index} md={4} xs={12} sm={12}>
                                  <ProfilePortfolioCard
                                    image={item}
                                    title={item.portfolio_title}
                                    description={item.portfolio_description}
                                    link={item.portfolio_link}
                                    profileClick={() => handleEditPortfolio(item)}
                                  />
                                </Grid>
                              )) : (
                              <Grid item key={0} md={12} >
                                <Typography className="font-14 font-readexpro" textAlign={'center'} color={'#5f89a3'}>Portfolio not available</Typography>
                              </Grid>
                            )}
                            {isPopupOpenEditPortfolio && <AddPortfolioPopup editPortfolio='edit-protfolio' open={isPopupOpenEditPortfolio} handleClose={handleClosePopupEdit} portfolioDetails={portfolioDetails} getProfileData={getProfileData} />}
                          </Grid>
                        </Box>
                        {profileData?.portfolioData && profileData?.portfolioData?.length > 0 &&
                          <Box className="pagination-container">
                            <Pagination
                              count={Math.ceil(profileData?.portfolioData?.length / itemsPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              variant="outlined"
                              shape="rounded"
                              showFirstButton
                              showLastButton
                            />
                          </Box>
                        }
                      </Box>

                      {/* Service Provider section*/}
                      <Box className="comapany-profile-skills">
                        <Typography variant="subtitle1" className="font-18 font-readexpro" mb={3} >
                          Services
                        </Typography>
                        <Box mx={{md:"3",xs:"0"}}>
                          <Grid container spacing={1}>
                            {serviceData && serviceData.length > 0 ?
                              <>
                                {serviceData?.slice((serviceCurrentPage - 1) * itemsServicePerPage, serviceCurrentPage * itemsServicePerPage)?.map((item, index) => (
                                  <Grid item key={index} md={4} xs={12} sm={6}>
                                    <ServiceProviderCard
                                      image={item?.gallery?.length ? item?.gallery[0]?.path : IndividualProfilePersona}
                                      profileImage={item?.gallery?.length ? item?.gallery[0]?.path : IndividualProfilePersona}
                                      title={item?.services_title}
                                      description={item}
                                      link={`/service-provider-dashboard/review-service-post/${item.id}`}
                                    />
                                  </Grid>
                                ))}
                              </> :
                              <>
                                <Grid item key={0} md={12} >
                                  <Typography className="font-14 font-readexpro" textAlign={'center'} color={'#5f89a3'}>Services not available</Typography>
                                </Grid>
                              </>
                            }
                          </Grid>
                        </Box>
                        {serviceData && serviceData.length > 0 &&
                          <Box className="pagination-container">
                            <Pagination
                              count={Math.ceil(serviceData?.length / itemsServicePerPage)}
                              page={serviceCurrentPage}
                              onChange={handleServiceProviderPageChange}
                              variant="outlined"
                              shape="rounded"
                              showFirstButton
                              showLastButton
                            />
                          </Box>
                        }
                      </Box>

                      {/* Skills Section Individual */}
                      <Box className="comapany-profile-skills">
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <h4 className="title">Skills</h4>
                          <Box className="edit-grey-icon">
                            <EditIcon onClick={() => setIsPopupOpenEditSkill(true)}/>
                          </Box>
                        </Stack>
                        <br />
                        <Box>
                          <Stack direction="row" spacing={2} className="skill-list-chips">
                            {profileData && profileData?.skillData?.map((item, index) => {
                              return <SkillChip key={index} label={item.skill_name} />;
                            })}
                          </Stack>
                        </Box>
                      </Box>
                      {isPopupOpenEditSkill && <EditSkillPopup open={isPopupOpenEditSkill} handleClose={handleCloseSkillPopup} profileData={profileData} getProfileData={getProfileData} />}
                    </CustomTabPanel>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Box textAlign={'end'}>
                      <Button className="btn btn-blue-primary" startIcon={<AddIcon />} onClick={() => handleClick()}>
                        <span className="btn-span">List a service</span>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="right-container" mt={3}>
                  <RightProjectProfileSection profileData={profileData} getProfileData={getProfileData} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>}
    </>
  );
};

export default ServiceProviderProfile;
