import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Link } from "react-router-dom";
import { EditSharp, ViewComfy, PaymentsOutlined, SwapVert, Search } from '@mui/icons-material';
import { Chip, Button, Box, Paper, InputBase, TableRow, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select } from '@mui/material';
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS } from '../../constants.js'
import { GetApi, GetApiParam, updateApi } from '../../Api/Api.js';
import OfferEditCancelModel from './OfferEditCancelModal.jsx';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import './Transactions.css'
import SelectCurrencyType from './SelectCurrencyType.jsx';
import { formatNumber } from '../../Components/Common/USFormat.js';

const sortByOptionsOffers = ['Project Name', 'Status', 'Amount'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));


/**
 * @param {string} date  Date String
 * @returns  {string} Formatted Date String in MM/DD/YYYY format
 */
const formatDate = (date) => {
    let d = new Date(date);
    let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    return formattedDate;
}

const OffersPanel = () => {

    const navigate = useNavigate();
    const [offersData, setOffersData] = useState([])
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenCurrency, setModalOpenCurrency] = useState(false);
    const [offerDetails, setOfferDetails] = useState(false);
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);
    const [remainingPaymentData, setRemainingPaymentData] = useState({
        feeTokenType: 'USDT'
    });

    const [feeTokenType, setFeeTokenType] = useState('USDT');

    // for transaction modal
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [openModalRemainingPayment, setOpenModalRemainingPayment] = useState(false);

    const [propData, setPropData] = useState();

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    /**
     * function to get offers
     */
    const getOffers = async (param) => {
        try {
            let res = await GetApiParam('/invest/offer', param);
            if (res?.data?.data) {
                let data = res.data.data
                data = data?.filter((data) => data?.asset_data?.category_id != CATEGORY_TYPE_ID.FUNDS)
                setOffersData(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getOffers({ investor_id: User.id });
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData()
    }, [])

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        if (toSearch.length >= 2) {
            if (filterCategoryId > 0) {
                setOffersData(offersData.filter(o => o.asset_data?.asset_title.toLowerCase().includes(toSearch.toLowerCase()) && o.asset_data?.category_id === filterCategoryId))
            } else {
                setOffersData(offersData.filter(o => o.asset_data?.asset_title.toLowerCase().includes(toSearch.toLowerCase())))
            }

        }
        else if (toSearch.length < 2) {
            if (filterCategoryId > 0) {
                getOffers({ investor_id: User.id, category_id: filterCategoryId });
            } else {
                getOffers({ investor_id: User.id });
            }
        }
    }

    /**
    * Handle change in category filter
    * @param {Event} event 
    */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };

    // Getting filter data on category id change
    useEffect(() => {
        if (filterCategoryId > 0) {
            getOffers({ investor_id: User.id, category_id: filterCategoryId });
        } else {
            getOffers({ investor_id: User.id });
        }
    }, [filterCategoryId])
    /**
     * function to sort data 
     * @param {event} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Status') {
            setOffersData([...offersData].sort((a, b) => a.status - b.status))
        }
        else if (value === 'Project Name') {
            setOffersData([...offersData].sort((a, b) => a.asset_data?.asset_title.localeCompare(b.asset_data?.asset_title)))
        }
        else if (value === 'Amount') {
            setOffersData([...offersData].sort((a, b) => parseFloat(a.offer_amount) - parseFloat(b.offer_amount)))
        }
        else {
            setOffersData(offersData)
        }
    }

    /**
     * function handle make payment button click
     */
    const handleCompletePayment = (assetId, listingType, offerAmount, loan_duration, loan_roi, offerId, capital_type_id, blockchainOfferId) => {
        // the state.from is used to check if the user has clicked complete payment on an offer
        // by this we will directly render show sign docs on the payment page and complete the payment
        navigate(`/investor-payment/${listingType}`, { state: { assetId: assetId, from: 'offer_accept', offerAmount: offerAmount, loan_duration: loan_duration, loan_roi: loan_roi, offerId: offerId, capital_type_id: capital_type_id, blockchainOfferId } })
    }

    /**
    * function handle make payment button click
    */
    const handlePayRemainingPayment = async (assetId, investment_type_id, listing_id, deposit_amount, offerAmount, blockchainOfferId) => {
        if (investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
            setPropData({ assetId, investment_type_id, listing_id, category_id: CATEGORY_TYPE_ID.FUNDS, remaining_amount: parseFloat(offerAmount), blockchainOfferId })
            setOpenModalRemainingPayment(true)
        } else {
            if (investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setModalOpenCurrency(true)
                setRemainingPaymentData({ assetId, investment_type_id, listing_id, feeTokenType, remaining_amount: parseFloat(offerAmount) - parseFloat(deposit_amount) })
            } else {
                setPropData({ assetId, investment_type_id, listing_id, feeTokenType, remaining_amount: parseFloat(offerAmount) - parseFloat(deposit_amount) })
                setOpenModalRemainingPayment(true)
            }
        }
    }

    // handle modal close
    const handleClose = () => {
        setModalOpen(false)
    }

    // handle modal close
    const handleCloseCurrency = () => {
        setModalOpenCurrency(false)
    }

    // handle user response and edited offer data(via Edit/Cancel Offer modal)
    const saveChanges = async (assetId, editedData, responseType, offer_id, investment_type_id, offerDetails, category_id) => {

        if (investment_type_id === INVESTMENT_TYPE_ID.LOAN || investment_type_id === INVESTMENT_TYPE_ID.SALE || category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            if (category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                setPropData({ assetId, editedData, responseType, offer_id, offerDetails, investment_type_id, category_id, capital_type_id: offerDetails?.capital_type_id });
            } else {
                if (responseType === 'Edited') {
                    if (investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                        navigate(`/investor-payment/${offerDetails?.asset_data?.investment_type?.investment_name}`, { state: { assetId: assetId, from: 'edit-offer', offerAmount: editedData[0]?.value, loan_duration: editedData[1]?.value, loan_roi: editedData[2]?.value, offerId: offer_id, editedData, responseType, investment_type_id, offerDetails, category_id, feeTokenType } })
                    } else {
                        navigate(`/investor-payment/${offerDetails?.asset_data?.investment_type?.investment_name}`, { state: { assetId: assetId, from: 'edit-offer', offerAmount: editedData[0]?.value, loan_duration: editedData[1]?.value, loan_roi: editedData[2]?.value, offerId: offer_id, editedData, responseType, investment_type_id, offerDetails, category_id, feeTokenType } })
                    }
                } else {
                    setPropData({ assetId, editedData, responseType, offer_id, offerDetails, investment_type_id, category_id });
                }
            }
            setOpenTransactionModal(true);
        } else {
            // for offer edited
            if (responseType === 'Edited') {
                let payload = {}
                editedData?.forEach(item => {
                    payload[item.key] = item.value;
                });
                try {
                    const res = await updateApi(`/invest/offer/${offer_id}`, payload);
                    if (res?.data?.code === 200) {
                        handleClose();
                        getOffers({ investor_id: User.id });

                    }
                } catch (error) {
                    console.error(error)
                }
            }// for offer cancelled
            else {
                let payload = {
                    "status": 7,
                    'asset_id': assetId
                }
                try {
                    const res = await updateApi(`/invest/offerCancelled/${offer_id}`, payload);
                    if (res?.data?.code === 200) {
                        handleClose();
                        getOffers({ investor_id: User.id });
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
    }

    /**
    * Function to handle modal close
    */
    const handleModalClose = () => {
        setOpenTransactionModal(false);
    }


    /**
    * Function to handle modal close
    */
    const handleModalCloseRemainingPayment = (data) => {
        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalRemainingPayment(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmRemainingPayment = () => {
        handleCloseCurrency()
        try {
            getOffers({ investor_id: User.id });
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * Function call after transaction confirm
    */
    const confirmStake = async ({ assetId, editedData, responseType, offer_id }) => {
        try {
            getOffers({ investor_id: User.id });
        } catch (error) {
            console.error(error)
        }
    };

    /**
     * function to handle status
     * @param {Object} row 
     * @param {function} handleCompletePayment 
     * @returns 
     */
    const getStatusComponent = (row, handleCompletePayment) => {
        const getStatusProperties = (status) => {
            switch (status) {
                case OFFER_STATUS.PENDING:
                    return {
                        label: 'Pending',
                        sx: { color: 'black', bgcolor: '#F6CFB7', borderRadius: '8px' },
                    };
                case OFFER_STATUS.ACCEPTED:
                    if (row?.investment_type_id === INVESTMENT_TYPE_ID.SALE || row?.class) {
                        return {
                            label: 'Complete Payment',
                            sx: { color: 'black', bgcolor: '#F6CFB7', borderRadius: '8px', width: '180px' },
                            onClick: () =>
                                handlePayRemainingPayment(
                                    row.asset_data?.id,
                                    row.investment_type_id,
                                    row.listing_id,
                                    row.deposit_amount,
                                    row.offer_amount,
                                    row.blockchainOfferId
                                ),
                            startIcon: <PaymentsOutlined />,
                        }
                    } else {
                        return {
                            // label: 'Complete Payment',
                            // sx: { color: 'black', bgcolor: '#F6CFB7', borderRadius: '8px', width: '180px' },
                            // onClick: () =>
                            //     handleCompletePayment(
                            //         row.asset_data?.id,
                            //         row.asset_data?.investment_type?.investment_name,
                            //         row.offer_amount,
                            //         row.loan_duration,
                            //         row.loan_roi,
                            //         row.id,
                            //         row.capital_type_id,
                            //         row.blockchainOfferId
                            //     ),
                            // startIcon: <PaymentsOutlined />,
                            label: 'Accepted',
                            sx: { color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' },
                        }
                    }
                case OFFER_STATUS.REJECTED:
                    return {
                        label: 'Rejected',
                        sx: { color: 'white', bgcolor: '#EF4444', borderRadius: '8px' },
                    };
                case OFFER_STATUS.COMPLETED:
                    return {
                        label: 'Completed',
                        sx: { color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' },
                    };
                case OFFER_STATUS.EXPIRED:
                    return {
                        label: 'Expired',
                        sx: { color: 'white', bgcolor: 'darkgray', borderRadius: '8px' },
                    };
                case OFFER_STATUS.CANCELLED:
                    return {
                        label: 'Cancelled',
                        sx: { color: 'black', bgcolor: '#888888', borderRadius: '8px' },
                    };
                default:
                    return {
                        label: 'Hold',
                        sx: { color: 'black', bgcolor: '#FFFFED', borderRadius: '8px' },
                    };
            }
        };

        const statusProperties = getStatusProperties((row?.investment_type_id === INVESTMENT_TYPE_ID.SALE && row?.full_payment || (row?.class && row?.is_commitment == true)) ? OFFER_STATUS.COMPLETED : row.status);

        if (statusProperties.onClick) {
            return (
                <Button
                    className="status-button"
                    onClick={statusProperties.onClick}
                    startIcon={statusProperties.startIcon}
                    sx={statusProperties.sx}
                >
                    {statusProperties.label}
                </Button>
            );
        } else {
            return <Chip label={statusProperties.label} sx={statusProperties.sx} />;
        }
    };

    const handleFeeTokenType = (currencyType) => {
        if (remainingPaymentData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            navigate(`/investor-payment/${remainingPaymentData?.offerDetails?.asset_data?.investment_type?.investment_name}`, { state: { ...remainingPaymentData, feeTokenType: currencyType } })
        } else {
            setPropData({ ...remainingPaymentData, feeTokenType: currencyType });
            setOpenModalRemainingPayment(true);
            handleCloseCurrency(true);
        }
    }
    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            My Offers
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
                            <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search in offers"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    onChange={handleChangeCategory}
                                    value={filterCategoryId}
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        categoryData?.length && categoryData?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>


                        {/* Sorting  */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>

            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Offer Amount</StyledTableCell>
                            <StyledTableCell align="left">Loan Duration</StyledTableCell>
                            <StyledTableCell align="left">Interest Rate</StyledTableCell>
                            <StyledTableCell align="center">Created Date</StyledTableCell>
                            {/* <StyledTableCell align="center">Updated Date</StyledTableCell> */}
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offersData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_data?.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">
                                    <Link to={`/project-details/${row.asset_data.id}`}>{row.asset_data?.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">${formatNumber(parseFloat(row.offer_amount))}</StyledTableCell>
                                <StyledTableCell align="left">{row.loan_duration ? `${row.loan_duration} months` : '-'}</StyledTableCell>
                                <StyledTableCell align="left">{row.loan_roi ? `${row.loan_roi}%` : (row.fractional_ror && row.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) ? `${row.fractional_ror}%` : '-'}</StyledTableCell>
                                <StyledTableCell align="center">{formatDate(row.createdAt)}</StyledTableCell>
                                {/* <StyledTableCell align="center">{formatDate(row.createdAt)}</StyledTableCell> */}
                                <StyledTableCell align="center">{
                                    getStatusComponent(row, handleCompletePayment)
                                }</StyledTableCell>

                                <StyledTableCell align="center">{
                                    <Button
                                        startIcon={row.status === OFFER_STATUS.PENDING ? <EditSharp /> : ''}

                                        disabled={((row.status !== OFFER_STATUS.PENDING) && (row.status === OFFER_STATUS.ACCEPTED || row.status === OFFER_STATUS.COMPLETED || row.status === OFFER_STATUS.HOLD || row.status === OFFER_STATUS.REJECTED || row.status === OFFER_STATUS.CANCELLED) || row.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) ? true : false}

                                        className={((row.status !== OFFER_STATUS.PENDING && (row.status === OFFER_STATUS.ACCEPTED || row.status === OFFER_STATUS.COMPLETED || row.status === OFFER_STATUS.HOLD || row.status === OFFER_STATUS.REJECTED || row.status === OFFER_STATUS.CANCELLED)) || row.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) ? 'status-button-action' : 'status-button'}

                                        onClick={() => {
                                            setModalOpen(true);
                                            setOfferDetails(row);
                                        }}>{row.status === OFFER_STATUS.PENDING ? "Edit/Cancel Offer" : "Cancel Offer"}</Button>}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {modalOpen && <OfferEditCancelModel open={modalOpen} onClose={handleClose} offerDetails={offerDetails} saveChanges={saveChanges} setFeeTokenType={setFeeTokenType} />}

            {modalOpenCurrency && <SelectCurrencyType open={modalOpenCurrency} onClose={handleCloseCurrency} handleFeeTokenType={handleFeeTokenType} />}

            {/* code for listing smart contract modal */}
            {propData?.responseType === 'Cancelled' && openTransactionModal ?
                <TransactionProgressModal identity={'cancel-offer'} confirmStake={confirmStake} propData={propData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null
            }
            {propData?.responseType === 'Edited' && openTransactionModal ?
                <TransactionProgressModal identity={'changing-offer'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: feeTokenType }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null
            }

            {/* code for pay remaining payment smart contract modal */}
            {openModalRemainingPayment ? <TransactionProgressModal identity={'remaining-payment'} confirmStake={confirmRemainingPayment} propData={propData} handleModalClose={handleModalCloseRemainingPayment} openTransactionModal={openModalRemainingPayment} /> : null}
        </>
    )
}

export default OffersPanel


