import React, { useMemo, useState } from 'react'
import countryList from 'react-select-country-list';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Typography, Box, MenuItem, styled, Paper, TextField, Button } from '@mui/material';
import { useSnackbar } from '../../../Contexts/SnackbarContext';
import { updateApi } from '../../../Api/Api';
import './Contact.css'
import CommonBackdropLoader from '../../../Components/Common/CommonBackdropLoader';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#05131B',
        borderRadius: '8px',
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: '#05131B',
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: '#05131B',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
    },
    '& .MuiInputBase-input.Mui-focused': {
        backgroundColor: '#05131B'
    },
    '& .MuiFilledInput-underline:after': {
        border: '1px solid #C5E4FF'
    },
    '& .MuiFilledInput-underline:hover:after': {
        border: '1px solid #C5E4FF'
    },
    '& label.MuiFormLabel-root': {
        color: '#64748B',
    },

    '& p.MuiFormHelperText-root:not(.Mui-error)': {
        color: '#ffffff',
    },

}));

const CustomTextFieldOutlined = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
        color: '#ffffff',
    },
    '& .MuiInputBase-input.Mui-focused': {
        backgroundColor: '#05131B'
    },
    '& .MuiFilledInput-underline:after': {
        border: '1px solid #C5E4FF'
    },
    '& .MuiFilledInput-underline:hover:after': {
        border: '1px solid #C5E4FF'
    },
    '& label.MuiFormLabel-root': {
        color: '#94a3b8',
    },
    '& p.MuiFormHelperText-root:not(.Mui-error)': {
        color: '#ffffff',
    },
    '& .MuiInputBase-root .MuiSvgIcon-root': {
        color: '#ffffff !important'
    }

}));

const Contact = ({ userData }) => {
    // Getting country name
    const countriesRawData = useMemo(() => countryList().getData(), []);
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const {
        register: registerSecondaryContact,
        handleSubmit: handleSecondaryContactSubmit,
        formState: { errors: secondaryContactErrors },
    } = useForm({
        defaultValues: {
            s_email: "",
            c_password: ""
        },
    });

    const {
        register: registerMailing,
        control: controlMailing,
        handleSubmit: handleMailingSubmit,
        formState: { errors: mailingErrors },
    } = useForm({
        defaultValues: {
            address: userData?.address,
            suit_floor: userData?.floor,
            city: userData?.city,
            postal: userData?.zip,
            state: userData?.state,
            country: userData?.country
        },
    });

    /**
     * updates secondary email with current password check
     * @param {object} values 
     * @param {*} actions 
     */
    const onSubmitContact = async (values, actions) => {
        let payload = {
            secondry_email: values.s_email,
            password: values.c_password
        }
        try {
            let u = localStorage.getItem('user_data')
            let user = JSON.parse(u);
            let id = userData?.userInformation?.id ? userData?.userInformation?.id : user?.id;

            const res = await updateApi('/user/updateSecondryEmail/' + id, payload)
            if (res.data.code === 200) {
                showSnackbar("Secondary email updated successfully", "success")
            } else {
                showSnackbar(res.data.message, "error")
            }
        } catch (error) {
            showSnackbar("Something went wrong", "error")
        }
    };

    /**
     * updates mailing address
     * @param {object} values 
     * @param {*} actions 
     */
    const onSubmitMailing = async (values, actions) => {
        let payload = {
            address: values.address,
            floor: values.suit_floor,
            city: values.city,
            zip: values.postal,
            state: values.state,
            country: values.country
        }
        try {
            setLoading(true)
            const res = await updateApi('/user/updateContactInformation/' + userData?.userInformation?.id, payload)
            if (res.data.code === 200) {
                showSnackbar("Mailing address updated successfully", "success")
            } else {
                showSnackbar("Some error occurred", "error")
            }
            setLoading(false)
        } catch (error) {
            showSnackbar("Some error occurred", "error")
            console.error('some error occurred')
            setLoading(false)
        }
    }

    return (
        <Box className='stPi-tab' mb={3}>
            <Grid container>
                <Grid item xs={12} sm={12} md={7}>
                    <Paper className='stPi-card'>
                        <Box mb={3}>
                            <Typography variant='h6'>
                                Contact Information
                            </Typography>
                        </Box>
                        {/* <Box component={'form'} onSubmit={handleSecondaryContactSubmit(onSubmitContact)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        label="Secondary Email Address"
                                        variant="filled"
                                        autoComplete='email'
                                        name='s_email'
                                        {
                                        ...registerSecondaryContact("s_email", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                message: "Invalid email address"
                                            },
                                        })
                                        }
                                        error={Boolean(secondaryContactErrors.s_email)}
                                        helperText={secondaryContactErrors.s_email ? secondaryContactErrors.s_email.message
                                            : `current secondary email: ${userData?.secondry_email ?? 'None'}`
                                        }

                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomTextField
                                        label="Current Password"
                                        variant="filled"
                                        autoComplete='current-password'
                                        name='c_password'
                                        {
                                        ...registerSecondaryContact("c_password", {
                                            required: "Please enter your current password",
                                        })
                                        }
                                        error={Boolean(secondaryContactErrors.c_password)}
                                        helperText={secondaryContactErrors.c_password && secondaryContactErrors.c_password.message}
                                        className='stIf-input'
                                        sx={{ input: { color: '#FFFFFF !important' } }} />
                                </Grid>

                            </Grid>
                            <Button sx={{ marginTop: 2 }} type='submit' className='btn-rounded btn-teal-100' >
                                Save changes
                            </Button>
                        </Box> */}

                        {/* mailing Address */}
                        <Box mb={3} mt={4}>
                            <Typography variant="body4" component="p" style={{ fontWeight: 'bold' }}>
                                Mailing Address
                            </Typography>
                        </Box>
                        <Box component={'form'} onSubmit={handleMailingSubmit(onSubmitMailing)}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} md={12} >
                                    <CustomTextFieldOutlined
                                        InputLabelProps={{ shrink: true }}
                                        label="Address"
                                        variant="outlined"
                                        autoComplete='address-line1'
                                        name='address'
                                        {
                                        ...registerMailing("address", {
                                            required: "This field is required.",
                                            // minLength: {
                                            //     value: 8,
                                            //     message: "Address must be at least 8 characters"
                                            // },
                                        })
                                        }
                                        error={Boolean(mailingErrors.address)}
                                        helperText={mailingErrors.address && mailingErrors.address.message}
                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTextFieldOutlined
                                        InputLabelProps={{ shrink: true }}
                                        label="Suite/Floor"
                                        variant="outlined"
                                        autoComplete='address-line2'
                                        name='suit_floor'
                                        {
                                        ...registerMailing("suit_floor", {
                                            required: "This field is required.",
                                        })
                                        }
                                        error={Boolean(mailingErrors.suit_floor)}
                                        helperText={mailingErrors.suit_floor && mailingErrors.suit_floor.message}
                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <CustomTextFieldOutlined

                                        label="City"
                                        variant="outlined"
                                        autoComplete='off'
                                        InputLabelProps={{ shrink: true }}
                                        name='city'
                                        {
                                        ...registerMailing("city", {
                                            required: "This field is required.",
                                        })
                                        }
                                        error={Boolean(mailingErrors.city)}
                                        helperText={mailingErrors.city && mailingErrors.city.message}
                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <CustomTextFieldOutlined
                                        InputLabelProps={{ shrink: true }}

                                        label="State/Province"
                                        variant="outlined"
                                        autoComplete='on'
                                        name='state'
                                        {
                                        ...registerMailing("state", {
                                            required: "This field is required.",
                                        })
                                        }
                                        error={Boolean(mailingErrors.state)}
                                        helperText={mailingErrors.state && mailingErrors.state.message}
                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <CustomTextFieldOutlined
                                        InputLabelProps={{ shrink: true }}

                                        label="ZIP/Postal"
                                        variant="outlined"
                                        autoComplete='postal-code'
                                        name='postal'
                                        {
                                        ...registerMailing("postal", {
                                            required: "This field is required.",
                                        })
                                        }
                                        error={Boolean(mailingErrors.postal)}
                                        helperText={mailingErrors.postal && mailingErrors.postal.message}
                                        onInput={(event) => {
                                            event.target.value = event.target.value
                                                .replace(/\D/g, "")
                                                .slice(0, 7);
                                        }}
                                        className='stIf-input'
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller name="country" control={controlMailing}
                                        rules={{
                                            required: "Country is required.",
                                        }}
                                        render={({ field }) => (
                                            <CustomTextFieldOutlined
                                                fullWidth
                                                label="Country"
                                                select
                                                {...field}
                                                error={Boolean(mailingErrors.country)}
                                                helperText={mailingErrors.country?.message}
                                                className='country-list-icons'
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {countriesRawData.map((option) => (
                                                    <MenuItem
                                                        key={`Topic${option?.label}`}
                                                        value={option?.label}
                                                    >
                                                        {option?.label}
                                                    </MenuItem>
                                                ))}
                                            </CustomTextFieldOutlined>
                                        )}


                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button className='btn-rounded btn-teal-100' type='submit'>Save changes </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <CommonBackdropLoader loading={loading} />

        </Box>
    )
}

export default Contact;