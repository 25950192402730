import React, { useState } from 'react';
import {
    Box,
    Chip,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import "./MyOrders.css";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from '../../../Config/Config';
import ConfirmModal from "../../../Components/Modal/Confirm/Confirm";
import { updateApi } from "../../../Api/Api";
import { formatDate } from '../../../Utils/utils';
import { getUserDataFromLocalStorage } from '../../../Services/localstorage.service';
import ModifyContractOfferModal from './ModifyContractOfferModal/ModifyContractOfferModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#053863",
      color: "#fff",
      borderBottom: "0px",
      "&:first-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
      "&:last-of-type": {
        borderRadius: "0px 0px 0px 0px",
      },
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#fff',
      borderBottom: "0px",
    },
}));

export default function JobList({ Title, fetchData, jobList, paginationData, currentPage, setCurrentPage, setItemsPerPage }) {
  const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [ openModal, setOpenModal ] = useState(false);
    const [ contractId, setContractId ] = useState('');
    const [ openModifyOfferModal, setOpenModifyOfferModal ] = useState(false);
    const [ modifyOfferReqData, setModifyOfferReqData ] = useState(null);
    const [ milestoneData, setMilestoneData ] = useState([]);
    const userData = getUserDataFromLocalStorage();

    const handleAction = (action) => {
      let status = '';
      if(action === 'confirm'){
        status = 3;
      }else{
        status = 7;
      }
      updateApi(`/services/contractStatusUpdate/${contractId}`, { status, client_id: userData.id }).then((res) => {
        if (res?.status === 200) {
          if(res.data.code === 200){
            showSnackbar(res?.data?.message, 'success');
            fetchData();
            setOpenModal(false);
          }else{
            showSnackbar(res?.data?.message, 'error');
          }
        }
      })
    }

    const handleActionModifyOffer = (action) => {
      let status = 0;
      if(action === 'confirm'){
        status = 1;
      }
      const payload = {
        status,
        id: modifyOfferReqData?.id, 
        requested_amount:  modifyOfferReqData?.requested_amount,
        milestoneData
      }
      updateApi(`/services/accRejRequestForContractOfferAmount/${contractId}`, payload).then((res) => {
        if (res?.status === 200) {
          if(res.data.code === 200){
            showSnackbar(res?.data?.message, 'success');
            setOpenModifyOfferModal(false);
          }else{
            showSnackbar(res?.data?.message, 'error');
          }
        }
        fetchData();
      })
    }

    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };

    const handelRowClick = (status, id) => {
      if(status === 3 || status === 4 || status === 5 || status === 6 || status === 7 ){
        navigate(`/service-proposer-dashboard/view-job/${id}`,{state: id})
      }
      if(status === 1 || status === 2){
        navigate(`/service-proposer-dashboard/view-contract/${id}`,{state: id})
      }
    }

    return (
        <>
            <Box>
                <TableContainer component={Paper} className="TableContainer">
                    <Typography className="font-22 text-white" mb={2}>
                        {Title}
                    </Typography>
                    <Table aria-label="customized table">
                    <TableHead className="TableHeader">
                      <TableRow>
                        <StyledTableCell>Project</StyledTableCell>
                        <StyledTableCell >

                        </StyledTableCell>
                        <StyledTableCell >
                          Order Date
                        </StyledTableCell>
                        <StyledTableCell>Due On</StyledTableCell>
                        <StyledTableCell>Total</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        {/* <StyledTableCell>Action</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobList && jobList.length > 0 ? jobList.map((row) => (
                        <TableRow
                          key={row.id}
                          className='cursor-pointer'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row"
                            sx={{ color: '#fff', borderBottom: 'none' }}
                            onClick={()=>handelRowClick(row.status, row.id)}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }} 
                            onClick={()=>handelRowClick(row.status, row.id)}>
                            <Chip
                              sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                color: "#fff",
                                bgcolor: "#1771BD",
                                borderRadius: "8px",
                              }}
                              label="Custom Order"
                            />
                          </TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }} onClick={()=>handelRowClick(row.status, row.id)}>{row?.createdAt ? formatDate(row.createdAt, 'MMM dd, h:mm a') : 'NA'}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }} onClick={()=>handelRowClick(row.status, row.id)}>{row?.delivery_date ? formatDate(row.delivery_date, 'MMM d, yyyy') : 'NA'}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }} onClick={()=>handelRowClick(row.status, row.id)}>${row.total_fees}</TableCell>
                          <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                            {row.status === 2 && (
                              <Button className='confirmOrderButton' onClick={() => {
                                setContractId(row.id);
                                setOpenModal(true);
                              }} variant="contained">
                                  Confirm Order
                              </Button>
                            )}
                            {row.status === 1 && row?.modifyOfferReqData?.requested_amount && (
                              <Button className='confirmOrderButton' onClick={() => {
                                setModifyOfferReqData(row?.modifyOfferReqData);
                                setMilestoneData(row?.milestoneData);
                                setContractId(row.id);
                                setOpenModifyOfferModal(true);
                              }} variant="contained">
                                  Confirm Modify Request
                              </Button>
                            )}
                            { row.status !== 2 && !row.modifyOfferReqData?.requested_amount && (
                              <Chip
                                sx={{
                                  marginTop: 2,
                                  marginBottom: 2,
                                  color: "#fff",
                                  bgcolor: config.CONTRACT_STATUS.CONTRACT_STATUS_COLOR[config.CONTRACT_STATUS[row.status]],
                                  borderRadius: "8px",
                                  cursor: "pointer"
                                }}
                                label={ config.CONTRACT_STATUS[row.status] }
                                onClick={()=>handelRowClick(row.status, row.id)}
                              />
                            )}
                            
                          </TableCell>
                          {/* <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                            <RuleFolderIcon sx={{marginRight: "15px"}} onClick={()=>{
                                if(row.status === 1 || row.status === 2){
                                    navigate(`/service-proposer-dashboard/ViewOfferContract/${row.id}`)
                                }
                            }}/>
                            <TimerOutlinedIcon sx={{marginLeft: "15px"}}/>
                          </TableCell> */}
                        </TableRow>
                      )) : (null)}
                    </TableBody>
                  </Table>
                  {paginationData?.totalCount > 0  ?
                  <Box className="pagination-container">
                    <Pagination
                      count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Box>:  <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
                      Record not available
                    </Typography> }
                </TableContainer>
            </Box>
          <ConfirmModal title={'Confirm Order'} description={'Are You Sure Want To Confirm It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction}/>
          {/* <ConfirmModal title={'Confirm Request'} description={`Request Of Modify Offer Amount ($${modifyOfferReqData?.requested_amount}), Do you want to confitm IT.`} isOpen={openModifyOfferModal} setIsOpenModal={setOpenModifyOfferModal} handleAction={handleActionModifyOffer}/> */}
          <ModifyContractOfferModal 
            isOpen={openModifyOfferModal} 
            setIsOpenModal={setOpenModifyOfferModal} 
            requested_amount={modifyOfferReqData?.requested_amount} 
            milestoneData={milestoneData}
            setMilestoneData={setMilestoneData}
            handleAction={handleActionModifyOffer}
          />
        </>
    )
}
