// makeOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call makeOffer method to create offer on asset
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function makeOffer(address, assetData, offerAmount, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        if (assetData?.payment_method == 'fiat') { } else {
            handleActiveStep(2)
        }

        if (!assetData?.listing_id) {
            showSnackbar("Listing ID is required.", 'error')
            handleModalClose();
            handleActiveStep(-1)
            return
        }
        let makeOfferArgs = {};
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
            makeOfferArgs = {
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "investOnDebt",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                ]
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
            makeOfferArgs = {
                address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "investOnEquity",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                ]
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            if (assetData?.payment_method == 'fiat') {
                makeOfferArgs = {
                    address: CONTRACTS.FUNDS_SPACE_X_INVESTMENT,
                    abi: CONTRACT_ABI[CONTRACTS.FUNDS_SPACE_X_INVESTMENT],
                    account: address,
                    chainId: 6806,
                    functionName: "investUsingFiat",
                    args: [
                        assetData?.listing_id, // listing id / ipfs code id Integer
                        convertToDecimal(offerAmount)
                    ]
                }
            } else {
                makeOfferArgs = {
                    address: CONTRACTS.FUNDS_SPACE_X_INVESTMENT,
                    abi: CONTRACT_ABI[CONTRACTS.FUNDS_SPACE_X_INVESTMENT],
                    account: address,
                    chainId: 6806,
                    functionName: "investOnFund",
                    args: [
                        assetData?.listing_id, // listing id / ipfs code id Integer
                        getCurrencyTypeAddress(assetData?.feeTokenType),
                        getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)
                    ]
                }
            }
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            makeOfferArgs = {
                address: CONTRACTS.FUNDS_INVESTMENT,
                abi: CONTRACT_ABI[CONTRACTS.FUNDS_INVESTMENT],
                account: address,
                chainId: 6806,
                functionName: "investOnFund",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    [assetData?.class == 1 ? 0 : 1], // debtTypeId
                    getCurrencyTypeAddress(assetData?.feeTokenType),
                    // [convertToDecimal(offerAmount)], // offer amount / offeredAmount
                    [getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)]
                ]
            }
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            makeOfferArgs = {
                address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS],
                account: address,
                chainId: 6806,
                functionName: "makeOffer",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(offerAmount) : convertToDecimal(offerAmount), // offer amount / offeredAmount
                    getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount),
                    Number(Math.round(assetData?.loan_roi * 100)), // offer rate of interest / offeredPercentage
                    Number(assetData?.loan_duration), // offer loan duration / offeredDuration
                    getCurrencyTypeAddress(assetData?.feeTokenType), // fee token
                ]
            }
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            makeOfferArgs = {
                address: CONTRACTS.ART_SALE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "makeOffer",
                args: [
                    assetData?.listing_id, // listing id / ipfs code id Integer
                    // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(offerAmount) : convertToDecimal(offerAmount), // offer amount / offeredAmount
                    getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount), // offer amount / offeredAmount
                    getCurrencyTypeAddress(assetData?.feeTokenType), // fee token
                    // (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC') ? convertToSixDecimal(assetData?.depositAmount) : convertToDecimal(assetData?.depositAmount), // offer deposit amount / depositAmount
                    getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.depositAmount), // offer deposit amount / depositAmount
                ]
            }
        }
        // make offer by investor
        const { request: makeOffer } = await prepareWriteContract(makeOfferArgs)

        const { hash } = await writeContract(makeOffer)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE ? assetData?.depositAmount : offerAmount, assetData?.asset_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { makeOffer };