import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Icon2 from "./../../Assets/Images/service-provider/Icon2.png";
import Icon3 from "./../../Assets/Images/service-provider/Icon3.png";
import { Delete , LeftArrow, RightArrow } from "../../Components/Images/Images";
import { GetApi } from "../../Api/Api";
import CategoryCard from "../Components/CategoryCard/CategoryCard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./ClientDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DashboardOverview = () => {
  const [categoryData, setCategoryData] = useState(null);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const NextArrow = ({ onClick }) => {
    return <img src={LeftArrow} alt="LeftArrow" className="arrow-left" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };
  
  const PrevArrow = ({ onClick }) => {
    return <img src={RightArrow} alt="RightArrow" className="arrow-right" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const settings = {
    slidesToShow: 1,
    rows: 1,
    slidesPerRow: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          slidesPerRow: 1
        }
      }
    ]
  };

  useEffect(() => {
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/services/spCategory");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClick = () => {
    navigate("/service-proposer-dashboard/postProject");
  };

  return (
    <Box className="dashboard-overview-right service-proposer-dashboard-overview">
      <Box className="dashboard-overview-right-topview">
        <Box className="dashboard-overview-topview-left">
          <Box className="dob-overview-title-left">
            <Typography className="your-workspace-title" mt={0.6}>
              Your Workspace
            </Typography>
          </Box>
          <Box className="dob-tabs-main">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab 
                  onClick={() => navigate('/service-proposer-dashboard/projectposting')}
                  label="All project posts" {...a11yProps(0)} />
                  <Tab 
                  onClick={() => navigate('/service-proposer-dashboard/myorder')}
                  label="All contracts" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="dashboard-overview-topview-right">
          <Box className="post-project-btn">
            <Button
              className="btn"
              startIcon={<AddIcon />}
              onClick={() => handleClick()}
            >
              <span className="btn-span">Post a project</span>
            </Button>
          </Box>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box className="dasboard-overview-card-main">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Box className="dashboard-overview-card">
                <Box className="dob-top">
                  <Box className="dob-left">
                    <Typography component="h5" className="title">
                      Job Title
                    </Typography>
                    <Typography component="p" className="sub-title">
                      Job description
                    </Typography>
                  </Box>
                  <Box className="dop-right">
                    <img src={Delete} alt="" />
                  </Box>
                </Box>
                <Box className="draft-project-btn">
                  <Button variant="contained">Draft project post</Button>
                </Box>
                <Box className="card-discription">
                  <Typography component="p" className="text">
                    Add details to your draft
                  </Typography>
                </Box>
                <Box className="fill-draft-btn">
                  <Button
                    variant="outlined"
                    sx={{ borderColor: "var(--golden-gradient)" }}
                  >
                    Fill in draft
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box className="dashboard-overview-card">
                <Box className="dop-card2">
                  <Typography component="p" className="title">
                    Required to hire
                  </Typography>
                  <Typography component="h3" className="sub-title">
                    <span>Connect a wallet.</span> There’s <br />
                    no cost until you hire.
                  </Typography>
                </Box>

                <Box className="fill-draft-btn connect-wallet">
                  <Button variant="outlined">Connect Wallet</Button>
                </Box>
                <Box className="account-bal-icon">
                  <img src={Icon2} className="icon" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box className="dashboard-overview-card">
                <Box className="dop-card2">
                  <Typography component="p" className="title">
                    Required to hire
                  </Typography>
                  <Typography component="h3" className="sub-title">
                    <span>Verify your email address.</span>
                  </Typography>
                </Box>

                <Box className="fill-draft-btn verify-now">
                  <Button variant="outlined">Verified</Button>
                </Box>
                <Box className="account-bal-icon">
                  <img src={Icon3} className="icon" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <Box className="dasboard-overview-card-main">
          <Box>
            <Typography>All Contracts</Typography>
          </Box>
        </Box>
      </CustomTabPanel> */}
      <Box className="find-service-container">
        <Box className="find-service-title">
          <Typography component="h4" className="title font-22">
            Find service providers by category
          </Typography>
        </Box>
        {/* Browse Services by Category */}
        <Box className="categoryCard" py={3}>
        <Box className="cat-slider">
              <Grid >
                <Slider {...settings}>
              {categoryData?.map((category, index) => (
                <Grid item key={index} md={3} sm={6} xs={12}>
                  <CategoryCard
                    key={index} // It's good practice to provide a unique key for each element in the array
                    Icon={category?.categoryIcon?.path}
                    Heading={category.title}
                    SubHeading={`${category.totalSkill} Skill`}
                    url={category.id}
                  />
                </Grid>
              ))}
              </Slider>
              </Grid>
            </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardOverview;
