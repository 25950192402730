import React, { useState } from "react";
import { Chip, Box, Avatar, Typography, Slider, Stack, IconButton, Button, Backdrop, CircularProgress, Modal } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import WatchIcon from "@mui/icons-material/Watch";
import PaidIcon from "@mui/icons-material/Paid";
import BalanceIcon from "@mui/icons-material/Balance";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { Bookmark } from "@mui/icons-material";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApiParam, postApi } from "../../Api/Api";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE } from "../../constants";
import { YellowWatchIcon, noImageAvailable } from "../Images/Images";
import EditIcon from "@mui/icons-material/Edit";
import TransactionProgressModal from "../SmartContract/TransactionProgressModal";
import ConfirmReleaseAssetModal from "../Common/ConfirmRAModal";
import "./Card.css";

const Cards = (props) => {

  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openReleasedAssetModal, setOpenReleasedAssetModal] = useState(false);
  const [openConfirmRAModal, setOpenConfirmRAModal] = useState(false);
  const [listingPayLoadData, setListingPayLoadData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  let asset_id = props?.id;
  /**
   * Generating Props for sending full data
   */
  const projectData = { project: props, };

  /**
   * Generating State for the inReview Projects
   */
  const projectState = { proState: props.state, };

  const DraftEditData = { category_id: props.category_id, id: props.id, EditMode: true, investment_type_id: props.investment_type_id ? props.investment_type_id : "", };

  // Rendering Icons According to condition
  const iconPack = {
    "Real Estate": <HouseRoundedIcon />,
    Art: <ColorLensIcon />,
    NFT: <PhotoSizeSelectActualIcon />,
    Energy: <LightModeRoundedIcon />,
    Collection: <WatchIcon />,
    Watch: <WatchIcon />,
    Funds: <PaidIcon />,
    LLC: <BalanceIcon />,
    Gold: <ViewWeekIcon />,
  };

  // Onclick function for Editing Draft
  const handleClickDraft = (dataForDraft) => {
    navigate("/questionnaire-flow", {
      state: {
        category_id: dataForDraft?.category_id,
        id: dataForDraft?.id,
        EditMode: true,
      },
    });
  };
  const [isClock, setIsClock] = useState(props.isClock);

  /**
   * Function to handle onboard process 
   */
  const handleOnboard = async () => {
    // creating ipfs data if asset id exist
    setLoader(true);
    setLoading(false);
    try {
      const ipfsData = await GetApiParam("/proposer/getIpfsData/", { asset_id: asset_id });
      let parcelNFTs = []
      if (props.investType === INVESTMENT_TYPE.PARCEL) {
        parcelNFTs = await GetApiParam("/proposer/getParcelNFTs/", { asset_id: asset_id });
      }
      const assetDicConfig = await GetApiParam("/proposer/getAssetDicConfig/", { assetId: asset_id });
      const dicSuggestedData = await GetApiParam("/committee/getSuggestReview", { "asset_id": asset_id });
      const assetData = await postApi("/proposer/assetsDetails", { "asset_id": asset_id });

      if (ipfsData?.data?.data && ipfsData?.data?.data?.length && dicSuggestedData?.data?.data && dicSuggestedData?.data?.data?.length && assetDicConfig?.data?.data && (props.investType === INVESTMENT_TYPE.PARCEL ? (parcelNFTs?.data?.data && parcelNFTs?.data?.data?.length > 0) : true)) {
        setListingPayLoadData({ ipfsData: ipfsData?.data?.data[0], dicSuggestedData: dicSuggestedData?.data?.data[0], assetDicConfig: assetDicConfig.data.data, suggestion: true, investType: props?.investType, assetDetails: assetData?.data?.data[0], parcelNFTs: parcelNFTs?.data?.data });
        setTimeout(() => {
          setOpenTransactionModal(true)
          setLoader(false);
        }, 1000);
      } else if (ipfsData?.data?.data && ipfsData?.data?.data?.length && assetData?.data?.data && assetData?.data?.data?.length && assetDicConfig?.data?.data && (props.investType === INVESTMENT_TYPE.PARCEL ? parcelNFTs?.data?.data && parcelNFTs?.data?.data?.length > 0 : true)) {
        setListingPayLoadData({ ipfsData: ipfsData?.data?.data[0], dicSuggestedData: dicSuggestedData?.data?.data[0], assetDicConfig: assetDicConfig.data.data, assetDetails: assetData?.data?.data[0], investType: props?.investType, parcelNFTs: parcelNFTs?.data?.data });
        setTimeout(() => {
          setOpenTransactionModal(true)
          setLoader(false);
        }, 1000);
      } else {
        setLoader(false);
        showSnackbar('There was some error', 'error')
      }
    } catch (error) {
      setLoader(false);
      showSnackbar('There was some error', 'error')
    }
  }

  /**
 * Function to handle onboard process 
 */
  const handleReleasedAsset = async () => {
    setOpenReleasedAssetModal(true);
    setOpenConfirmRAModal(false);
  }

  /**
   * Function to handle modal close
   */
  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }

  /**
   * Function call after transaction confirm
   */
  const confirmStake = () => {
    window.location.reload(true);
  }

  /**
   * Function to handle modal close
   */
  const handleModalCloseRA = () => {
    setOpenReleasedAssetModal(false);
  }

  /**
   * Function to handle modal close
   */
  const handleCloseRAModal = () => {
    setOpenConfirmRAModal(false);
  }

  /**
   * Function call after transaction confirm
   */
  const confirmStakeRA = () => {
    setOpenReleasedAssetModal(false);
    window.location.reload(true);
  }


  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Box className="product-commonCard">
        <Box className="pc-card-body" position={"relative"}>
          {/* Edit mode for the draft project */}
          {props.isDraft && (
            <>
              <Box>
                <IconButton
                  className="rc-asset-card-save-button"
                  onClick={() => handleClickDraft(props)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </>
          )}
          {props.isSaved || props.bookMarkStatus ? (
            <Box
              className="pc-bookmark"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Bookmark />
            </Box>
          ) : (
            ""
          )}
          <Box className="pc-thumbnail-wrap">
            {props.imgSrc.length > 0 ? (
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                {(props.imgSrc || []).map((img, ind) =>
                  props.id ? (
                    <Link
                      to={props.isDraft ? "/questionnaire-flow" : props.link}
                      state={
                        props.isDraft
                          ? DraftEditData
                          : projectState.proState
                            ? projectState
                            : projectData
                      }
                      key={ind}
                    >
                      <Box className="pc-slide" sx={{ cursor: "pointer" }}>
                        <img src={img} alt="Slide Image" />
                      </Box>
                    </Link>
                  ) : (
                    <Link
                      to={props.isDraft ? "/questionnaire-flow" : props.link}
                      state={
                        props.isDraft
                          ? DraftEditData
                          : projectState.proState
                            ? projectState
                            : projectData
                      }
                      key={ind}
                    >
                      <Box className="pc-slide 1" sx={{ cursor: "pointer" }}>
                        <img src={img} alt="Slide Image" />
                      </Box>
                    </Link>
                  )
                )}
              </Carousel>
            ) : (
              <Link
                to={props.isDraft ? "/questionnaire-flow" : props.link}
                state={
                  props.isDraft
                    ? DraftEditData
                    : projectState.proState
                      ? projectState
                      : projectData
                }
              >
                <Box className="pc-slide 1" sx={{ cursor: "pointer" }}>
                  <img src={noImageAvailable} alt="Slide Image" />
                </Box>
              </Link>
            )}
          </Box>
          <Box className="pc-content" position={"relative"}>
            {isClock && props.investment_expiry_time ? (
              <ExpiryTimer
                investmentExpiryTime={props.investment_expiry_time} setIsClock={setIsClock}
              />
            ) : (
              ""
            )}

            {/* code for live status on card */}
            {props.is_voted ? (
              props.is_publish && props.status === 2 && (!props.is_onboard) && (props?.investType === INVESTMENT_TYPE.LOAN || props?.investType === INVESTMENT_TYPE.SALE || props?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || props?.category_id === CATEGORY_TYPE_ID.FUNDS || props?.investType === INVESTMENT_TYPE.PARCEL) ?
                <Button className={"btn-rounded"} onClick={() => {
                  if (props.is_publish && props.status === 2) { handleOnboard() }
                }}>
                  Go Publish
                </Button> :
                <>
                  <Chip
                    className={
                      props.status === 2
                        ? "pc-chip-status"
                        : "pc-chip-status status-rejected"
                    }
                    label={
                      props.is_publish && props.status === 2
                        ? "live"
                        : !props.is_publish && props.status === 2
                          ? "in-vote"
                          : "rejected"
                    }
                  />
                  {/* {props.is_released ?
                    <Box className='card-released-asset'>
                      <Button className={"btn-rounded"} onClick={() => setOpenConfirmRAModal(true)}>
                        Release Asset
                      </Button>
                    </Box>
                    : null} */}
                </>
            ) : (
              ""
            )}
            <Typography component={"h5"} className="pc-title">
              {props.title ? props.title : "No Title"}
            </Typography>

            {/* Adding Watch model instead of address for the Watch Category and car category */}

            <Typography className="pc-subtitle">
              {props?.category_id !== CATEGORY_TYPE_ID.WATCH && props?.category_id !== CATEGORY_TYPE_ID.ASSET
                ? props.subTitle || props.address || ""
                : props.assetDataDetail?.watch_brand || props.assetDataDetail?.assetInformation?.car_brand || ""}
            </Typography>
            <Box className="pc-chip-wrap" mt={1}>
              {/* <Tooltip title={props.investType} arrow> */}
              <Chip className="pc-chip" label={props.investType} />
              {/* </Tooltip> */}
              {
                props.loanType && (
                  // <Tooltip title={props.loanType} arrow>
                  <Chip className="pc-chip" label={props.loanType} />
                )
                // </Tooltip>
              }
              {/* <Tooltip title={props.isLoan} arrow> */}
              <Chip
                className="pc-chip with-icon"
                avatar={
                  <Avatar aria-label="recipe">
                    {iconPack[props.isLoan] ?? iconPack["Real Estate"]}
                  </Avatar>
                }
                label={props.isLoan === "Asset" ? "Luxury Cars" : props.isLoan}
              />
              {/* </Tooltip> */}
            </Box>
            {props.isDraft ? (
              <Stack
                className="pc-progreesSlider"
                direction="row"
                alignItems={"center"}
                spacing={2}
                width={"100%"}
                mt={2.5}
              >
                <Box className="value">
                  {props.projectProgress ? `${props.projectProgress}%` : "0%"}
                </Box>
                <Box width={"100%"}>
                  <Slider
                    style={{ maxWidth: 500 }}
                    value={props.projectProgress}
                    disabled
                    step={1}
                    max={100}
                    valueLabelDisplay="auto"
                    aria-labelledby="input-slider"
                    aria-label="Small"
                  />
                </Box>
              </Stack>
            ) : props.isSaveDeal ? (
              <Box mb={'10px'}></Box>
              // <Box
              //   className="pc-user-wrap"
              //   display={"flex"}
              //   alignItems={"center"}
              //   mt={3}
              // >
              //   <Box
              //     className="user-thumbnail"
              //     display={"flex"}
              //     alignItems={"center"}
              //     justifyContent={"center"}
              //   >
              //     <Badge
              //       badgeContent={<DoneIcon />}
              //       anchorOrigin={{
              //         vertical: "bottom",
              //         horizontal: "right",
              //       }}
              //     >
              //       <Avatar
              //         className="user-avatar"
              //         alt="User"
              //         src={props.ownerImage ?? SaveDeal}
              //       />
              //     </Badge>
              //   </Box>
              //   <Box className="user-details" ml={1.8}>
              //     <Typography className="user-title">
              //       {props.ownerName}
              //     </Typography>
              //     <Typography className="user-subtitle">
              //       Project Proposer
              //     </Typography>
              //   </Box>
              // </Box>
            ) : (
              <Stack
                className="pc-productStatus"
                direction="row"
                alignItems={"center"}
                spacing={1.5}
                width={"100%"}
                mt={2.5}
              >
              </Stack>
            )}
          </Box>
        </Box>
      </Box>

      {/* code for listing smart contract modal */}
      {openTransactionModal ? <TransactionProgressModal identity={'listing'} confirmStake={confirmStake} propData={listingPayLoadData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}

      {openReleasedAssetModal ? <TransactionProgressModal identity={'released-asset'} confirmStake={confirmStakeRA} propData={props?.assetDataDetail} handleModalClose={handleModalCloseRA} openTransactionModal={openReleasedAssetModal} /> : null}

      {openConfirmRAModal ? <ConfirmReleaseAssetModal open={openConfirmRAModal} onClose={handleCloseRAModal} handleResponse={handleReleasedAsset} /> : ""}

      <WaitingForSign openModal={loading} />
      <IpfsDataWaitingModal openModal={loader} />
      {/* </ThemeProvider> */}
    </>
  );
};
export default Cards;

function ExpiryTimer({ investmentExpiryTime, setIsClock }) {
  //  Setting Functionality for the Clock Timer
  const [countdownTimer, setCountdownTimer] = useState("");
  // Function for getting values  of hrs, minute and second
  // const timerSetting = (data) => {
  //   var expiryDate = new Date(investmentExpiryTime);
  //   var currentTime = new Date(data.detail);
  //   let expirationDate = new Date(expiryDate);
  //   var formattedcurrentTime = currentTime.toISOString().split('T')[0];

  //   if(investmentExpiryTime >= formattedcurrentTime){
  //   var remainingDayTime = expiryDate - currentTime;
  //   var totalDays = Math.ceil(remainingDayTime / (1000 * 60 * 60 * 24));
  //   var totalHours = Math.floor(Math.abs(expiryDate - currentTime) / 36e5);
  //   var totalMinutes = 60 - currentTime.getMinutes();
  //   var totalSeconds = 60 - currentTime.getSeconds();

  //   if (totalDays >= 1) {

  //     setCountdownTimer(totalDays + " days");
  //     // Removing Event Listner for more then 1 day(24 hrs)
  //     document.removeEventListener("clocktime", timerSetting);
  //   } else {
  //     if (totalSeconds < 10) {
  //       totalSeconds = "0" + totalSeconds;
  //     }
  //     if (totalMinutes < 10) {
  //       totalMinutes = "0" + totalMinutes;
  //     }
  //     if (totalSeconds === 60) {
  //       totalSeconds = "00";
  //     }
  //     if (totalMinutes === 60) {
  //       totalMinutes = "00";
  //     }
  //     setCountdownTimer(totalHours + ":" + totalMinutes + ":" + totalSeconds);
  //   }
  // }
  // else{
  //   setIsClock(false)
  // }
  // };
  const timerSetting = (data) => {
    // Assuming data.detail is a valid date string or timestamp
    var currentTime = new Date(data.detail);
    // Assuming investmentExpiryTime is a valid date string or timestamp
    var expiryDate = new Date(investmentExpiryTime);
    expiryDate.setDate(expiryDate.getDate() + 1);
    expiryDate.setHours(0);
    expiryDate.setMinutes(0);
    expiryDate.setSeconds(0);
    const timeDifference = expiryDate - currentTime;
    // Check if the countdown has reached zero
    if (timeDifference <= 0) {
      setIsClock(false);
    } else {
      const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      const hours = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);
      if (days >= 1) {
        setCountdownTimer(days + " days");
      } else {
        setCountdownTimer(`${hours}:${minutes}:${seconds}`);
      }
    }
  }


  //  Getting Dispatch value from project page and  assigning to the function
  // if any listener is already there should be removed first
  document.removeEventListener("clocktime", timerSetting);
  // then allocate next listener
  document.addEventListener("clocktime", timerSetting);



  return (
    <Box className="pc-timer">
      <Chip
        avatar={
          <Avatar component={"square"} alt="Icon" src={YellowWatchIcon} />
        }
        label={countdownTimer}
      />
    </Box>
  );
}

function WaitingForSign(props) {
  return (
    <>
      {/* Stepper modal for transaction flow for abi*/}
      <Modal
        open={props?.openModal}
        className="kyc-modal wallet-transaction-flow"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        BackdropComponent={Backdrop}
        BackdropProps={{ open: false }}
        sx={{ backdropFilter: 'blur(2px)' }}
      >
        <Box className="set-stack-rts common-modal-design" >
          <Box className='waiting-for-ipfs'>
            <CircularProgress />
            <Box className='text'>Waiting for Docusign Signature</Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function IpfsDataWaitingModal(props) {
  return (
    <>
      {/* Stepper modal for transaction flow for abi*/}
      <Modal
        open={props?.openModal}
        className="kyc-modal wallet-transaction-flow"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        BackdropComponent={Backdrop}
        BackdropProps={{ open: false }}
        sx={{ backdropFilter: 'blur(2px)' }}
      >
        <Box className="set-stack-rts common-modal-design" style={{ width: '180px' }} >
          <Box className='waiting-for-ipfs-center'>
            <CircularProgress />
            {/* <Box className='text'>Waiting for IPFS data</Box> */}
          </Box>
        </Box>
      </Modal >
    </>
  );
}
