// variable to switch between testnet and polygon
var isMainnetProduction = false;
/**
* @file overview This file contains the contract addresses and ABI's
*/

/**
* @constant {object} CONTRACTS - The contract addresses
*/
export const CONTRACTS = {
    // Token RACE
    TOKEN: isMainnetProduction ? "0xdc66010a5558473Fb91b21666B56E4D13Fd7254A" : '0xd020b1566d4E351D0cF6F3f8111602F337C94dD2',

    // Token USDT
    USDT: isMainnetProduction ? '0x05d032ac25d322df992303dca074ee7392c117b9' : '0x84ff23aad7520F2970F43EB1B567e9A1e0B5a902',

    // Token USDC
    USDC: isMainnetProduction ? '0xb62f35b9546a908d11c5803ecbba735abc3e3eae' : '0xa06019820a5FC8799Ff37AA5c73D4564C88fd4fA',

    // Token USDC
    DAI: isMainnetProduction ? '0x6c851f501a3f24e29a8e39a29591cddf09369080' : '0x9c7e9B3BEa668a22ed4c80240bCc81892aFcAa87',

    // NFT
    NFT_ADDRESS: isMainnetProduction ? '0x93779819c6CaC7Cc625B619B614F639433c9C615' : "0xcAdD888d1D6eC948BFE46F556523C584686Ee1E3",

    // NFT Balance Of
    BLUPRINT: isMainnetProduction ? "" : "0xB90D691383b303e9e1ba40611C5fF04447b45b32", 

    // Staking
    STAKE_CONTRACT: isMainnetProduction ? "0x2b66610961111bee3ad2417787AA95DCb5EF6C59" : "0xF6092c69CA4b0Ac838fBf012d3D3356d6B28bEdE",

    // Staking (For Funds)
    FUND_STAKE_CONTRACT: isMainnetProduction ? "" : "0xF3EB2c8CcCb344575E073E923524Dd2b52dE2629",

    // Staking (For Funds space-x)
    FUND_SPACE_X_STAKE_CONTRACT: isMainnetProduction ? "" : "0xE1F5a3d91f063aE3e1AcE05288A48C8f27A333aE",

    // Admin, User Management and DIC Management (Only used at admin side)
    USER_MANAGE: isMainnetProduction ? "0xCf6A32dB8b3313b3d439CE6909511c2c3415fa32" : '0x94c263D4f58611547f6E195A2cd1D7504bd10a08',
    DIC_MANAGE: isMainnetProduction ? "0xf54B2BAEF894cfF5511A5722Acaac0409F2F2d89" : '0x33af4F67eD502632BE6EcD19aFE471E3B14FdCa6',
    ADMIN: isMainnetProduction ? "0x0f33D824d74180598311b3025095727BeA61f219" : '0xCb4da06F4AdBb5a4EdA7e30de8D5dAB5e78286e4',

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions(For Watch, Gold, Car and Art)
    CREATE_PROPOSAL: isMainnetProduction ? "0x17d13c7Fb85795825dF97BE3cF4eE0317911e309" : '0xBDf37F747076732Bc5Cf966e3eB6dE1971e30938',
    DIC_PROPOSAL: isMainnetProduction ? "0xFA440a952EAc1bfabb38272f8cD88ABE6C0985eE" : '0x07de65c9c2F506171b91c6110023b274AADfB856',

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions(For Funds)
    FUND_CREATE_PROPOSAL: isMainnetProduction ? "" : '0x40a83779c805e63B3f78ec4204c45aF826833bED',
    FUND_DIC_PROPOSAL: isMainnetProduction ? "" : '0x0302ac262A7bC28451ABc536400673E1B16DEB45',

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions(For Funds Space-x)
    FUND_SPACE_X_CREATE_PROPOSAL: isMainnetProduction ? "" : '0xD0e5e518493f77760f540Ba49843d7a2e5d1179d',
    FUND_SPACE_X_DIC_PROPOSAL: isMainnetProduction ? "" : '0xae0cddcA3010b06B7C3414768ea4f7C79b013BF3',

    // Watch, GOLD, ASSET
    WATCH_MARKETPLACE: isMainnetProduction ? '0xC5e2063FBb77977a50703fCFFb54f06B4645e6cf' : "0x5f33be93cbf6734e0A3b43E7B0e16f3f0b485120",
    WATCH_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0xBFE15ae2D2a468c320d0075B79d7Bf1e5f9163BE' : "0x86505603a505AbABe05a0233174d944380907d53",
    WATCH_REPAYMENT_ADDRESS: isMainnetProduction ? '0x36eF8d1C6b536C08095ABaAE8a4D3EFCD3653825' : "0xf791415b9fFbceEC1C3030827B1b75973dec08D4",

    // Art - Sale
    ART_SALE_MARKETPLACE: isMainnetProduction ? '0x0eeb25c56F44a854F3cA9Ffe06272194c2069A19' : "0xCCbfbe628CDd1a46c23df3526CDE978c6ed7Edb4",

    // Art - Parcel
    ART_PARCEL: isMainnetProduction ? '0x1483879E808b01F56AFb8C0E23fAe991FEE32dAd' : "0x0433f57Ca2E688e70Cf43B9Adc714881D39989bF",

    // SPV
    SPV_MARKETPLACE: isMainnetProduction ? '0xa81Ec35dF8DB8AebcB56228803Fa278dd7d7155F' : "0xA5C38ED1D4c90d097cBdD39bD9a15Cfb5Caa5bf9",
    SPV_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0x4C40EeC05dD43078E7419f21537dA3C9A0BE0CDD' : "0x97191FBCd916f554C2530FC056494119Bb26C02e",
    SPV_DEBT_REPAYMENT_ADDRESS: isMainnetProduction ? '0x004905e36FC9E194a14493F5AE183f867fe19da0' : "0x1E712fE86f32889B34D6A107636535fD37B487F8",
    SPV_EQUITY_OFFER_ADDRESS: isMainnetProduction ? '0xa66012bDb82585C5697DCff9EFde5662116A2bf1' : "0xD3E808de1CB16D727C0213A0d0cc592df50DA3b9",
    SPV_EQUITY_REPAYMENT_ADDRESS: isMainnetProduction ? '0x66F51B60b8bF6A11B9F27266ac2a35131E61D5Ae' : "0x4a32407Ab6939216ac8A11Fdc5C3Ee88D7b54147",

    // Funds
    FUNDS_MARKETPLACE: isMainnetProduction ? '0x81C0a590450a64409e6651eb13A0fC7ec8FD4B89' : '0x6864086b8318a886185aE2b11a5331Eac7B0c2AE',
    FUNDS_INVESTMENT: isMainnetProduction ? '0xA08ce7651404d48ad068ba057E1bFD97Bb96163E' : '0x9E857b229DABa2055556c42fFA6F9eC33179a5E4',
    FUNDS_DISTRIBUTION: isMainnetProduction ? '0xB8e7d039706bd5415d0509e0e6E40A0F3C655643' : '0xF485D2255a8F994c739680330651ccf468084cdB',

    // Funds (space-x)
    FUNDS_SPACE_X_MARKETPLACE: isMainnetProduction ? '' : '0x53aAA17e64985C2a2CC44fc11492a23787aA8a42',
    FUNDS_SPACE_X_INVESTMENT: isMainnetProduction ? '' : '0x84451a125a6f5314E8ABC33f0EC67705A456CF54',
    FUNDS_SPACE_X_DISTRIBUTION: isMainnetProduction ? '' : '0xdF6dDFD6604c9fA7F46aF5b5D25aB48724C94964',

    // Helper module and calculate emi
    HELPER_MODULE: isMainnetProduction ? '0xB9b8858eD6898e68b55cC1E9766674C9D9802675' : "0x96D6623386668325E1A4723972B88392ce11B17a",
    CALCULATE_EMI: isMainnetProduction ? '0xACC3FA34498373eaE9Dc92409D2C67E04F876619' : "0xE0b5bc71B07a102736395462b7a48aF3949D3d0a",
}


/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const CONTRACT_ABI = {
    [CONTRACTS.TOKEN]: isMainnetProduction ? require("./abi/TokenRT.json") : require("./abi/Token.json"),
    [CONTRACTS.BLUPRINT]: isMainnetProduction ? require("./abi/BluprintNft.json") : require("./abi/BluprintNftRT.json"),
    [CONTRACTS.USDT]: isMainnetProduction ? require("./abi/TokenUSDTRT.json") : require("./abi/TokenUSDT.json"),
    [CONTRACTS.USDC]: isMainnetProduction ? require("./abi/TokenUSDCRT.json") : require("./abi/TokenUSDC.json"),
    [CONTRACTS.DAI]: isMainnetProduction ? require("./abi/TokenDIART.json") : require("./abi/TokenDIA.json"),
    [CONTRACTS.NFT_ADDRESS]: isMainnetProduction ? require("./abi/NFTRT.json") : require("./abi/NFT.json"),
    [CONTRACTS.USER_MANAGE]: isMainnetProduction ? require("./abi/UserRT.json") : require("./abi/User.json"),
    [CONTRACTS.DIC_MANAGE]: isMainnetProduction ? require("./abi/DicManageRT.json") : require("./abi/DicManage.json"),
    [CONTRACTS.ADMIN]: isMainnetProduction ? require("./abi/AdminRT.json") : require("./abi/Admin.json"),
    [CONTRACTS.STAKE_CONTRACT]: isMainnetProduction ? require("./abi/StakeContractRT.json") : require("./abi/StakeContract.json"),
    [CONTRACTS.DIC_PROPOSAL]: isMainnetProduction ? require("./abi/DicProposalRT.json") : require("./abi/DicProposal.json"),
    [CONTRACTS.CREATE_PROPOSAL]: isMainnetProduction ? require("./abi/CreateProposalRT.json") : require("./abi/CreateProposal.json"),
    [CONTRACTS.WATCH_MARKETPLACE]: isMainnetProduction ? require("./abi/WatchMarketplaceRT.json") : require("./abi/WatchMarketplace.json"),
    [CONTRACTS.WATCH_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanOfferRT.json") : require("./abi/WatchLoanOffer.json"),
    [CONTRACTS.WATCH_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanRepaymentRT.json") : require("./abi/WatchLoanRepayment.json"),
    [CONTRACTS.ART_SALE_MARKETPLACE]: isMainnetProduction ? require("./abi/ArtSaleMarketplaceRT.json") : require("./abi/ArtSaleMarketplace.json"),
    [CONTRACTS.ART_PARCEL]: isMainnetProduction ? require("./abi/ArtParcelMarketplaceRT.json") : require("./abi/ArtParcelMarketplace.json"),
    [CONTRACTS.SPV_MARKETPLACE]: isMainnetProduction ? require("./abi/SPVMarketplaceRT.json") : require("./abi/SPVMarketplace.json"),
    [CONTRACTS.SPV_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtOfferRT.json") : require("./abi/SPVDebtOffer.json"),
    [CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtRepaymentRT.json") : require("./abi/SPVDebtRepayment.json"),
    [CONTRACTS.SPV_EQUITY_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityInvestmentRT.json") : require("./abi/SPVEquityInvestment.json"),
    [CONTRACTS.SPV_EQUITY_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityRepaymentRT.json") : require("./abi/SPVEquityRepayment.json"),
    // For Funds
    [CONTRACTS.FUNDS_MARKETPLACE]: isMainnetProduction ? require("./abi/FundsMarketplaceRT.json") : require("./abi/FundsMarketplace.json"),
    [CONTRACTS.FUNDS_INVESTMENT]: isMainnetProduction ? require("./abi/FundsInvestmentRT.json") : require("./abi/FundsInvestment.json"),
    [CONTRACTS.FUNDS_DISTRIBUTION]: isMainnetProduction ? require("./abi/FundsDistributionRT.json") : require("./abi/FundsDistribution.json"),
    // For Funds space-x
    [CONTRACTS.FUNDS_SPACE_X_MARKETPLACE]: isMainnetProduction ? require("./abi/FundSpaceXMarketplaceRT.json") : require("./abi/FundSpaceXMarketplace.json"),
    [CONTRACTS.FUNDS_SPACE_X_INVESTMENT]: isMainnetProduction ? require("./abi/FundSpaceXInvestmentRT.json") : require("./abi/FundSpaceXInvestment.json"),
    [CONTRACTS.FUNDS_SPACE_X_DISTRIBUTION]: isMainnetProduction ? require("./abi/FundSpaceXDistributionRT.json") : require("./abi/FundSpaceXDistribution.json"),

    [CONTRACTS.HELPER_MODULE]: isMainnetProduction ? require("./abi/HelperModuleRT.json") : require("./abi/HelperModule.json"),
    [CONTRACTS.CALCULATE_EMI]: isMainnetProduction ? require("./abi/CalculateEMIRT.json") : require("./abi/CalculateEMI.json"),

    // For Funds
    [CONTRACTS.FUND_STAKE_CONTRACT]: isMainnetProduction ? require("./abi/FundStakingContractRT.json") : require("./abi/FundStakingContract.json"),
    [CONTRACTS.FUND_CREATE_PROPOSAL]: isMainnetProduction ? require("./abi/FundCreateProposalRT.json") : require("./abi/FundCreateProposal.json"),
    [CONTRACTS.FUND_DIC_PROPOSAL]: isMainnetProduction ? require("./abi/FundDicProposalRT.json") : require("./abi/FundDicProposal.json"),

    // For Funds space-x
    [CONTRACTS.FUND_SPACE_X_STAKE_CONTRACT]: isMainnetProduction ? require("./abi/FundSpaceXStakingContractRT.json") : require("./abi/FundSpaceXStakingContract.json"),
    [CONTRACTS.FUND_SPACE_X_CREATE_PROPOSAL]: isMainnetProduction ? require("./abi/FundSpaceXCreateProposalRT.json") : require("./abi/FundSpaceXCreateProposal.json"),
    [CONTRACTS.FUND_SPACE_X_DIC_PROPOSAL]: isMainnetProduction ? require("./abi/FundSpaceXDicProposalRT.json") : require("./abi/FundSpaceXDicProposal.json"),


    [CONTRACTS['SPACE_X_FUND_TOKEN']]: isMainnetProduction ? require("./abi/SpaceXFundToken.json") : require("./abi/SpaceXFundToken.json"),


}