import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Switch,
  TextField,
  Radio,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import UploadIcon from "@mui/icons-material/Upload";
import imgBg from "../../../Assets/Images/service-provider/img-card.png";
import EditIcon from "@mui/icons-material/Edit";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendCustomeOfferPopup from "../SendCustomeOfferPopup/SendCustomeOfferPopup";

const TimeTracking = ({ contactData }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [selectedValue, setSelectedValue] = React.useState("a");

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <Box className="time-tracking-container">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12}>
          <Box className="time-tracking-left-section">
            <Grid container spacing={2}>
              <Grid item md={12} lg={5} sm={12}>
                <Box className="top-left-section">
                  <Typography className="font-16 fw-500">
                    {contactData?.projectDetails?.project_title}
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                     Delivery date <b> Apr 12, 9:45 PM </b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} lg={7} sm={12}>
                <Box className="top-right-section">
                  <Box className="top-sub-left">
                    <Box className="long-switch-btn">
                      <IOSSwitch color="primary"
                        {...label}
                        defaultChecked
                        sx={{ color: "var(--golden-gradient)" }}
                      />
                    </Box>
                    <Box pl={2}>
                      <Typography className="font-14 fw-500 text-blueGray-300">
                        CURRENT SESSION
                      </Typography>
                      <Typography className="font-22 text-golden">
                        6hrs 46m
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="top-sub-right">
                    <Typography className="font-14 fw-500 text-blueGray-300">
                      TOTAL TRACKED TIME
                    </Typography>
                    <Typography className="font-22 text-golden">
                      6hrs 46m
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={2} >
              <Grid container spacing={2}>
                <Grid item md={7} sm={6}>
                  <Box className="top-left-section">
                    <Typography className="font-16 fw-500">5:40 hrs</Typography>
                    <Typography className="font-12 text-blueGray-300">
                      Today (Tue UTC)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={5} sm={6}>
                  <Box pl={0}>
                    <Typography className="font-16 fw-500 ">
                      5:40 hrs of 40 hrs
                    </Typography>
                    <Typography className="font-12 text-blueGray-300">
                      This week (UTC)
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="memo-box" mt={2}>
              <Typography className="font-16">Memo</Typography>
              <Box mt={2}>
                <TextField
                  id="outlined-multiline-flexible"
                  placeholder="What are you working on?"
                  multiline
                  maxRows={10}
                  rows={4}
                  value=""
                />
              </Box>
              <Box mt={2} className="upload-btn">
                <Button variant="contained" startIcon={<UploadIcon />}>
                  Upload Screen Capture
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="total-time-tracking-box" mt={4}>
            <Grid container spacing={2}>
              <Grid md={6} sm={12}>
                <Typography className="font-16 fw-500 ">Total Tracked Time</Typography>
                <Typography className="font-12 text-blueGray-300">
                  All your tracked time will be detailed below.
                </Typography>
              </Grid>
              <Grid md={6} sm={12}>
                <Box className="add-manu-time-btn">
                  <Button variant="contained" endIcon={<AvTimerIcon />}>
                    Add Manual Time
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box className="time-tracking-main">
              <Grid container spacing={2}>
                <Grid item md={1} sm={12}>
                  <Box>
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={handleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Box>
                    <Typography className="font-16 fw-500 ">
                      12:00 am - 12:10 am (0:10 hrs)
                    </Typography>
                    <Typography className="font-12 text-blueGray-300">
                      Memo missing. Add it to let clients know what you worked
                      on.
                    </Typography>
                    <Box className="img-container">
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12}>
                  <Box className="right-div">
                    <Box className="add-memo-btn">
                      <Button variant="contained" startIcon={<EditIcon />}>
                        Add Memo
                      </Button>
                    </Box>
                    <Box className="more-data">
                      <MoreHorizIcon />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="time-tracking-main">
              <Grid container spacing={2}>
                <Grid item md={1} sm={12}>
                  <Box>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={handleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Box>
                    <Typography className="font-16 fw-500 ">
                      12:00 am - 12:10 am (0:10 hrs)
                    </Typography>
                    <Typography className="font-12 text-blueGray-300">
                      Memo missing. Add it to let clients know what you worked
                      on.
                    </Typography>
                    <Box className="img-container">
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12}>
                  <Box className="right-div">
                    <Box className="add-memo-btn">
                      <Button variant="contained" startIcon={<EditIcon />}>
                        Add Memo
                      </Button>
                    </Box>
                    <Box className="more-data">
                      <MoreHorizIcon />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="time-tracking-main">
              <Grid container spacing={2}>
                <Grid item md={1} sm={12}>
                  <Box>
                    <Radio
                      checked={selectedValue === "c"}
                      onChange={handleChange}
                      value="c"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "C" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Box>
                    <Typography className="font-16 fw-500 ">
                      12:00 am - 12:10 am (0:10 hrs)
                    </Typography>
                    <Typography className="font-12 text-blueGray-300">
                      Memo missing. Add it to let clients know what you worked
                      on.
                    </Typography>
                    <Box className="img-container">
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                      <Box className="img-box">
                        <img src={imgBg} alt="" />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12}>
                  <Box className="right-div">
                    <Box className="add-memo-btn">
                      <Button variant="contained" startIcon={<EditIcon />}>
                        Add Memo
                      </Button>
                    </Box>
                    <Box className="more-data">
                      <MoreHorizIcon />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SendCustomeOfferPopup />
    </Box>
  );
};

export default TimeTracking;
