import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  TextField,
  Grid,
  IconButton
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import "./EditRatePopup.css";
import { GetApi, updateApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import config from "../../../Config/Config";

const EditRatePopup = ({ open, handleClose, profileData, getProfileData }) => {
  const { showSnackbar } = useSnackbar();
  const [serviceFee, setServiceFee] = useState({});
  const [formData, setFormData] = useState({
    hourlyrate: profileData?.hourlyrate || "",
    serviceFee: "",
    youWillGet: ""
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [errors, setErrors] = useState({});
  const localData = getUserDataFromLocalStorage();

/**
     * getting the service fee
     */
useEffect(() => {
  const getServiceFeeConfig = async() => {
   try {
     const res = await GetApi("/services/serviceProviderConfig");
     if(res?.data && res?.data?.data?.spProfileServiceFee)setServiceFee(res?.data?.data?.spProfileServiceFee);
     else setServiceFee(config?.SERVICE_FEE?.SERVICE_PROVIDER_PROFILE);
   } catch (error) {
     console.log(error);
   }
 }
 getServiceFeeConfig();
}, []);

  useEffect(() => {
    setErrors("");
  }, [handleClose]);

  /**
    * Error Msg Function
    * @param {*} fieldName 
    * @returns 
    */
  const renderErrorMessage = (fieldName) => {
    return validationErrors[fieldName] && (
      <Typography variant="body2" color="error">{validationErrors[fieldName]}</Typography>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Disable both start and end dates of previous entry
    if (formData.hourlyrate === "" || formData.hourlyrate === undefined) {
      errors.hourlyrate = 'Hourly Rate is required.';
    }
    // Set errors state with validation results
    setValidationErrors(errors);
    // If there are no errors, proceed with onSave and handleClose
    if (Object.keys(errors).length === 0) {
      // Add user_id to formData

      let updateData = new FormData();
      updateData.append("hourly_rate", formData?.hourlyrate);
      updateApi(`/services/updateProfile/${profileData?.id}`, updateData).then((res) => {
        if (res?.status === 200) {
          showSnackbar("Updated Successfully", 'success');
          getProfileData()
          handleClose(); // Close the modal
        } else { // something went wrong user or backend side
          showSnackbar(res?.data?.message, 'error');
          handleClose(); // Close the modal
        }
      })
    }
  };

  /**
  * Onchange function for the step 3 input fields
  * @param {*} e 
  */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear validation error for the field being updated
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));

    // Clear validation errors for service fee and you will get fields when hourly rate is entered
    if (name === 'hourlyrate') {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        serviceFee: '',
        youWillGet: ''
      }));
    }

  };

  useEffect(() => {
    if (formData.hourlyrate) {
      // Update mainServices in formData
      setFormData(prevFormData => ({
        ...prevFormData,
        serviceFee: (formData.hourlyrate / 100) * Number(serviceFee),
        youWillGet: `${formData?.hourlyrate - (formData.hourlyrate / 100) * Number(serviceFee)}`
      }));
    }
  }, [formData.hourlyrate, serviceFee])

  return (
    <Box className="add-education-popup-main">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-main"
      >
        <Box
          id="education-scroller-wrapper"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            borderRadius: "24px",
            width: "900px",
            padding: "52px",

            background: "var(--blueGray-900)",
            boxShadow:
              "0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)",
            // Media query for small devices
            "@media (max-width: 600px)": {
              width: "350px", // Set a different height for small screens
            },
            // Additional media queries for other screen sizes if needed
            "@media (min-width: 601px) and (max-width: 900px)": {
              // Custom styles for devices between 601px and 900px width
              width: "580px",
            },
            "@media (min-width: 901px) and (max-width: 1200px)": {
              // Custom styles for devices between 901px and 1200px width
              width: "750px",
              maxHeight: "300px",
              overflow: "auto",
            },
            // Specify the maximum width for large screens
            "@media (min-width: 1201px)": {
              maxWidth: "1043px",
              maxHeight: "525px",
              overflow: "auto",
            },
          }}
        >
          <Box className="close-icon-main">
            <IconButton className='close-btn' onClick={handleClose}>
              <CloseIcon className="cross-icon-modal"/>
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mt={2}
            className="heading font-36"
            sx={{ textAlign: "center" }}
          >
            Edit Hourly Rate
          </Typography>
          <form onSubmit={handleSubmit}>

            <Box className="proff-info-box" mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={12}>
                  <Typography className='font-22 title'>
                    Hourly Rate
                  </Typography>
                  <Typography className='font-12 sub-title'>Clients will see the rate on your profile once you publish it. You can adjust it anytime in your settings. You can also customize your rate every time you submit a proposal</Typography>
                </Grid>
                <Grid item xs={12} md={9} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={9} sm={12}>
                      <Typography className='font-18 title'>
                        Hourly rate
                      </Typography>
                      <Typography className='font-14 sub-title'>Total amount the client will see</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {/* Input field for service fee */}
                      <TextField
                        id="hourlyRate"
                        name="hourlyrate"
                        label="$ / hour"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder='0.00'
                        value={formData?.hourlyrate}
                        onChange={handleInputChange}
                        error={!!validationErrors.hourlyrate}
                      />
                      {renderErrorMessage('hourlyrate')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} md={9} sm={12}>
                      <Typography className='font-18 title'>
                        Service fee
                      </Typography>
                      <Typography className='font-14 sub-title'>This helps us run the platform and provide services like customer support</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="serviceFee"
                        name="serviceFee"
                        label="$ / hour"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder='0.00'
                        disabled
                        value={formData.serviceFee}
                        onChange={handleInputChange}
                        style={{ color: 'blue' }}
                      // error={!!validationErrors.serviceFee}
                      />
                      {/* {renderErrorMessage('serviceFee')} */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} md={9} sm={12}>
                      <Typography className='font-18 title'>
                        You’ll get
                      </Typography>
                      <Typography className='font-14 sub-title'>The estimated amount you’ll receive after service fees</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="youWillGet"
                        name="youWillGet"
                        label="$ / hour"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder='0.00'
                        value={formData.youWillGet}
                        onChange={handleInputChange}
                      // error={!!validationErrors.youWillGet}
                      />
                      {/* {renderErrorMessage('youWillGet')} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3} className="btn-container">
              <Stack spacing={2} direction="row" justifyContent="center">
                {/* <Button variant="outlined" className='cancel-btn' onClick={handleClose}>Cancel</Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{
                      background: "var(--golden-gradient)",
                      color: "var(--blueGray-900)",
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditRatePopup;
