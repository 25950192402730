import React from 'react'
import { Box, Grid } from '@mui/material'
import "./OnboardingProfile.css"
import OnboardingProfessionalLeft from './OnboardingProfileComponent/OnboardingProfessionalLeft';
import OnboardingProfileRight from './OnboardingProfileComponent/OnboardingProfileRight';

const OnboardingProfessional = ({ onNext,sharedData, commonData, onBack, handleDataProfessional, previousData, ProfileImage ,stepTwoPreviousData}) => {

    // const [sharedData, setSharedData] = useState({}); // State to hold shared data

    // Function to handle data update from the left component
    const handleDataUpdate = (newData) => {
        // setSharedData(newData);
        handleDataProfessional(newData)
    };

    return (
        <Box className='onboarding-professional-info-container'>

            <Box className="onboarding-professional-main">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sm={12}>
                        <OnboardingProfessionalLeft onDataUpdate={handleDataUpdate} onNext={onNext} onBack={onBack}  stepTwoPreviousData={stepTwoPreviousData} commonData={commonData}/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                        <Box>
                            <OnboardingProfileRight personalInfoData={sharedData} profileImage={ProfileImage} previousData={previousData} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default OnboardingProfessional