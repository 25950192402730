import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, IconButton, Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { validAmountCheck } from '../../../../Utils/utils';
import './ModifyContractOfferModal.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "var(--blue-900)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderWidth: "0px",
    "@media (max-width: 600px)": {
        width: "350px", // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    "@media (min-width: 601px) and (max-width: 900px)": {
        // Custom styles for devices between 601px and 900px width
        width: "580px",
    },
    "@media (min-width: 901px) and (max-width: 1200px)": {
        // Custom styles for devices between 901px and 1200px width
        width: "750px",
        maxHeight: "300px",
        overflow: "auto",
    },
    // Specify the maximum width for large screens
    "@media (min-width: 1201px)": {
        maxWidth: "1043px",
        maxHeight: "525px",
        overflow: "auto",
    },
};

const ModifyContractOfferModal = ({ isOpen, setIsOpenModal, requested_amount, milestoneData, setMilestoneData, handleAction }) => {
    const [openModal, setOpenModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [totalMilestoneAmount, setTotalMilestoneAmount] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setOpenModal(true);
            setIsOpenModal(true);
        } else {
            setOpenModal(false);
            setIsOpenModal(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if(milestoneData.length > 0){
            let sum = 0;
            const err = milestoneData.map((i) => {
                sum = sum + Number(i.Amount);
                let amtErr = validAmountCheck(i.Amount);
                let dscErr = '';
                if(i.description){
                    if(i.description && i.description.trim()){
                    if(i.description && i.description.length > 80){
                        dscErr = 'Please enter 80 characters max';
                    }
                    }else{
                    dscErr = 'Please enter valid description';
                    }
                }
                return { ...i, descriptionError: dscErr, amountError: amtErr };
            });
            setTotalMilestoneAmount(sum);
            setErrors(err);
        }
    },[milestoneData]);

    const handleMilestonechange = (key, value, index) => {
        let tempArr = [...milestoneData];
        if(tempArr && tempArr.length > 0){
            tempArr = tempArr.map((milestone, idx)=>{
                if(index === idx){
                return {
                    ...milestone,
                    [key]: value,
                }
                }
                return milestone;
            })
            let sum = 0;
            tempArr.map((itm) => {
                if(itm.Amount === '' || isNaN(itm.Amount))return itm;
                sum = sum + Number(itm.Amount);
                return itm;
            });
            setTotalMilestoneAmount(sum);
            setMilestoneData(tempArr);
        }
    }
    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setIsOpenModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="send-custom-offer-container alert-modal"
            >
                <Box sx={{...style, width: milestoneData.length ? 800 : 500}}>
                    <Box className="header">
                        <Box className="alert-icon">
                            <ErrorOutlineIcon className='font-14'/>
                        </Box>
                        <Typography id="modal-modal-title font-28" component="h2">
                            Confirm Request
                        </Typography>
                        <IconButton className='close-btn' onClick={() => {setOpenModal(false); setIsOpenModal(false);}}>
                            <CloseIcon className="cross-icon-modal"/>
                        </IconButton>
                    </Box>
                    <Box className="body">
                        <Box textAlign={'center'}>
                            <Typography component="p" className="description">
                                {`Request Of Modify Contract Offer Amount ($${requested_amount}), Do you want to confitm IT.`}
                            </Typography>
                        </Box>
                        {milestoneData.length > 0 && (
                            <>
                                <Box textAlign={'center'}>
                                    <Typography component="p" className="description">
                                        {`Note - If you want to confirm it, please update the milestone amount's accordingly`}
                                    </Typography>
                                </Box>
                                {Number(totalMilestoneAmount) !== Number(requested_amount) && <Box textAlign={'center'}>
                                    <Typography component="p" className='warning'>
                                        {`Total milestone amount should be $${requested_amount}`}
                                    </Typography>
                                </Box>}
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        {milestoneData.map((milestone, index) => (
                                            <><Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                <Grid item xs={2} sm={4} md={4}>
                                                    <Box className="input-box">
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Milestone Description"
                                                            InputLabelProps={{ shrink: true }}
                                                            variant="outlined"
                                                            value={milestone.description}
                                                            onChange={(e) => handleMilestonechange('description',e.target.value, index)} />
                                                        {errors?.length > 0 && errors[index].descriptionError && <Typography variant="body2" color="error">{errors[index].descriptionError}</Typography>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2} sm={4} md={4}>
                                                    <Box className="input-box DatePicker SPAddEducation">
                                                        <DatePicker
                                                            id="outlined-basic"
                                                            label="Due Date(optional)"
                                                            placeholder="MM/DD/YYYY"
                                                            variant="outlined"
                                                            value={milestone.dueDate}
                                                            onChange={(e)=> handleMilestonechange('dueDate', e, index)}
                                                            disablePast
                                                            renderInput={(params) => (
                                                                <TextField {...params} required={false} />
                                                            )} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2} sm={3} md={3}>
                                                    <Box className="input-box">
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Amount"
                                                            InputLabelProps={{ shrink: true }}
                                                            variant="outlined"
                                                            placeholder="$"
                                                            value={"$" + milestone.Amount}
                                                            onChange={(e) => handleMilestonechange('Amount',e.target.value.substring(1), index)} />
                                                        {errors.length > 0 && errors[index].amountError && <Typography variant="body2" color="error">{errors[index].amountError}</Typography>}
                                                    </Box>
                                                </Grid>
                                            </Grid><br /></>
                                        ))}
                                    </LocalizationProvider>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box className="footer">
                        <Button 
                            onClick={() => {
                                if(milestoneData.length > 0){
                                    if(errors?.filter((itm)=> itm.descriptionError || itm.amountError).length === 0){
                                        if(Number(totalMilestoneAmount) === Number(requested_amount)){
                                            handleAction('confirm');
                                        }
                                    }
                                }else{
                                    handleAction('confirm');
                                }
                            }} 
                            variant="contained" 
                            startIcon={<CheckIcon />}
                        >
                            Confirm
                        </Button>&nbsp;
                        <Button onClick={() => {
                            setOpenModal(false);
                            setIsOpenModal(false);
                            handleAction('cancel');
                        }} variant="contained" startIcon={<CloseIcon />}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ModifyContractOfferModal;