import React, { useState, useCallback, useEffect } from 'react'
import './WorkComponent.css'
import { Box, Stack, Typography } from '@mui/material';
import RatingCompo from '../RatingCompo/RatingCompo';
import PropTypes from 'prop-types';
import { formatDateRange, formatDate } from '../../../Utils/utils';

// locale date options for date formatting e.g Aug 3, 2021
const dateLocaleOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
}

function WorkComponent({ workHistory }) {
    const formattedDateRange = workHistory?.createdAt && workHistory?.delivery_date ? formatDateRange(workHistory?.createdAt, workHistory?.delivery_date): formatDate(workHistory?.createdAt, 'MMM d, yyyy');
    return (
        <Box>
            <Box display={'flex'} spa alignItems={'center'}  sx={{ 
                flexDirection: { xs: 'column-reverse', md: 'row' } 
              }}>
                <RatingCompo rating={workHistory?.rating ? workHistory?.rating : 'NA'} />
                <Typography ml={2} variant='subtitle2' sx={{ color: '#9CA3AF' }}>
                    {formattedDateRange}
                </Typography>
            </Box>
            <Box >
                <Typography variant='h6' sx={{ fontWeight: 400, color: '#fff' }}>{workHistory?.title}</Typography>
                <Typography sx={{ mt: 1, color: '#9CA3AF' }}>{workHistory?.describe_project}</Typography>
            </Box>
            <Stack spacing={3} ml={1} mt={2} direction="row" >
                <Typography variant='subtitle2' sx={{ color: '#fff' }} >${workHistory?.total_fees}</Typography>
                {workHistory?.project_term === 'project' && <Typography variant='subtitle2' sx={{ color: '#fff' }} >Fixed</Typography>}
                {workHistory?.project_term === 'hourly' && 
                    <><Typography variant='subtitle2' sx={{ color: '#fff' }} >${workHistory?.project_budget}/hr</Typography>
                    <Typography variant='subtitle2' sx={{ color: '#fff' }} >{workHistory?.workPrice} hours</Typography></>
                }
                {workHistory?.project_term === 'milestone' && 
                    <><Typography variant='subtitle2' sx={{ color: '#fff' }} >${workHistory?.project_budget}/hr</Typography>
                    <Typography variant='subtitle2' sx={{ color: '#fff' }} >{workHistory?.workPrice} hours</Typography></>
                }
            </Stack>
        </Box>
    )
}

export default WorkComponent

//prop types
WorkComponent.propTypes = {
    workTitle: PropTypes.string.isRequired,
    workDescription: PropTypes.string.isRequired,
    workRating: PropTypes.number.isRequired,
    workReview: PropTypes.number,
    workPrice: PropTypes.number.isRequired,
    workHours: PropTypes.number.isRequired,
    workStartDate: PropTypes.instanceOf(Date),
    workEndDate: PropTypes.instanceOf(Date),
}

//default props
WorkComponent.defaultProps = {
    workTitle: 'Fixes and new functions of the web app',
    workDescription: '“Outstanding experience. The service provided exceeded my expectations. Quick delivery and excellent communication. Highly recommended!"',
    workRating: 3.6,
    workReview: 632,
    workPrice: 3.50,
    workHours: 440,
    workStartDate: new Date(),
    workEndDate: null
}