
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton, } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomPercent from "../../../Components/Common/NumericFormatCustomPercent";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import "./Components/Questionnaire.css";

export default function FundsCapitalDetails(props) {
    // Destructuring props to extract specific functions
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Get current proposal and progress data
    let propData = getProposalData();
    let currentProgress = getProgress();

    // Define a state variable 'sharedClasses' using useState, which is initialized with the 'shared_data' from 'propData' if it exists,
    // otherwise, it's initialized with an array of objects with default values.

    let sharedLength = (propData?.shared_data && propData?.shared_data?.length) ? propData?.shared_data?.length : 0
    let newClasses = sharedLength ? [...propData?.shared_data, ...Array(propData?.number_of_shared - propData?.shared_data?.length).fill({
        total_raise: 0,
        minimum_investment: 0,
        hurdle_rate: 0
    })] : Array(propData?.number_of_shared - sharedLength).fill({
        total_raise: 0,
        minimum_investment: 0,
        hurdle_rate: 0
    })

    // set default total raise for a class if only one class selected
    if (sharedLength === 0 && propData?.number_of_shared === 1) {
        newClasses = [{ ...newClasses[0], total_raise: propData?.fractionalize_total_price }]
    }
    const [sharedClasses, setSharedClasses] = useState(newClasses)

    /**
     * Function to change the minimum investment for a shared class.
     * @param {String} type - The type of change, either 'inc' for increment or 'dec' for decrement.
     * @param {Number} i - The index of the shared class in the array.
     */
    const changeRaise = (type, i) => {
        if (type === "inc") {
            // Increment the minimum investment by 500, or set it to 500 if it is undefined
            let val = sharedClasses[i].total_raise ? 500 + sharedClasses[i].total_raise : 500;
            changeSharedClassData(val, i);
        } else {
            // Decrement the minimum investment by 500 if it is greater than or equal to 500, otherwise, keep it unchanged
            let val = sharedClasses[i].total_raise >= 500 ? sharedClasses[i].total_raise - 500 : sharedClasses[i].total_raise;
            changeSharedClassData(val, i);
        }
    };

    /**
     * Function to change the minimum investment for a shared class.
     * @param {String} type - The type of change, either 'inc' for increment or 'dec' for decrement.
     * @param {Number} i - The index of the shared class in the array.
     */
    const changeMinimum = (type, i) => {
        if (type === "inc") {
            // Increment the minimum investment by 500, or set it to 500 if it is undefined
            let val = sharedClasses[i].minimum_investment ? 500 + sharedClasses[i].minimum_investment : 500;
            changeSharedClassDataMini(val, i);
        } else {
            // Decrement the minimum investment by 500 if it is greater than or equal to 500, otherwise, keep it unchanged
            let val = sharedClasses[i].minimum_investment >= 500 ? sharedClasses[i].minimum_investment - 500 : sharedClasses[i].minimum_investment;
            changeSharedClassDataMini(val, i);
        }
    };

    /**
     * Function to change the hurdle rate for a shared class.
     * @param {String} type - The type of change, either 'inc' for increment or 'dec' for decrement.
     * @param {Number} i - The index of the shared class in the array.
     */
    const changeInterestRate = (type, i) => {
        if (type === "inc") {
            // Increment the hurdle rate by 0.25, or set it to 0.25 if it is undefined
            let val = sharedClasses[i].hurdle_rate ? 0.25 + sharedClasses[i].hurdle_rate : 0.25;
            changeSharedClassDataHurdle(val, i);
        } else {
            // Decrement the hurdle rate by 0.25 if it is greater than or equal to 0.25, otherwise, keep it unchanged
            let val = sharedClasses[i].hurdle_rate >= 0.25 ? sharedClasses[i].hurdle_rate - 0.25 : sharedClasses[i].hurdle_rate;
            changeSharedClassDataHurdle(val, i);
        }
    };

    /**
     * Function to handle a click event, updating 'shared_data' in 'propData', updating the proposal data, and moving to the next step.
     */
    const handleClick = () => {
        propData.shared_data = sharedClasses;
        updateProposalData(propData);
        handleNext();
    };

    /**
     * Function to save the current shared class data, update the proposal data, proceed to the next step, and save the proposal.
     */
    const SaveAndExit = () => {
        propData.shared_data = sharedClasses;
        updateProposalData(propData);
        saveProposal();
    };


    /**
     * Function to update the sharedClasses state with a new minimum investment value for a specific shared class.
     * @param {Number} val - The new minimum investment value.
     * @param {Number} i - The index of the shared class in the sharedClasses array.
     */
    const changeSharedClassData = (val, i) => {
        // Create a copy of the sharedClasses array
        const updatedSharedClasses = [...sharedClasses];

        // Update the specific item in the copied array
        updatedSharedClasses[i] = {
            ...updatedSharedClasses[i],
            total_raise: parseFloat(val)
        };

        // Set the updated state
        setSharedClasses(updatedSharedClasses);
    }

    /**
    * Function to update the sharedClasses state with a new minimum investment value for a specific shared class.
    * @param {Number} val - The new minimum investment value.
    * @param {Number} i - The index of the shared class in the sharedClasses array.
    */
    const changeSharedClassDataMini = (val, i) => {
        // Create a copy of the sharedClasses array
        const updatedSharedClasses = [...sharedClasses];

        // Update the specific item in the copied array
        updatedSharedClasses[i] = {
            ...updatedSharedClasses[i],
            minimum_investment: parseFloat(val)
        };

        // Set the updated state
        setSharedClasses(updatedSharedClasses);
    }

    /**
     * Function to update the sharedClasses state with a new hurdle rate value for a specific shared class.
     * @param {Number} val - The new hurdle rate value.
     * @param {Number} i - The index of the shared class in the sharedClasses array.
     */
    const changeSharedClassDataHurdle = (val, i) => {
        // Create a copy of the sharedClasses array
        const updatedSharedClasses = [...sharedClasses];

        // Update the specific item in the copied array
        updatedSharedClasses[i] = {
            ...updatedSharedClasses[i],
            hurdle_rate: parseFloat(val)
        };

        // Set the updated state
        setSharedClasses(updatedSharedClasses);
    }

    /**
     * Function to calculate the sum of minimum investments for all shared classes.
     * @returns {Number} - The sum of minimum investments.
     */
    const sumTotalRaise = () => {
        let newTotal = 0;
        sharedClasses?.forEach((values) => newTotal += parseFloat(values?.total_raise ? values?.total_raise : 0.0));
        return newTotal;
    }

    /**
    * Function to calculate the sum of minimum investments for all shared classes.
    * @returns {Number} - The sum of minimum investments.
    */
    const checkMinimumInvestment = () => {
        if (sharedClasses?.some((values) => parseFloat(values?.minimum_investment ? values?.minimum_investment : 0.0) > parseFloat(values?.total_raise ? values?.total_raise : 0.0))) {
            return true;
        }
        return false;
    }

    /**
     * Function to check if all hurdle rates for shared classes are less than or equal to 100.
     * @returns {Boolean} - True if all hurdle rates are valid, false otherwise.
     */
    const checkHurdleRate = () => {
        let checkHurdle = sharedClasses?.every((elm) => parseFloat(elm.hurdle_rate ? elm.hurdle_rate : 0) <= 100);
        return checkHurdle;
    }

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                {propData && propData?.number_of_shared <= 1 ?
                                    <Typography component="h1" className="title">
                                        Provide details for the type of capital selected
                                    </Typography>
                                    :
                                    <Typography component="h1" className="title">
                                        Provide details for the type(s) of capital selected
                                    </Typography>
                                }
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right  quests-right-contents-box">
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button
                                        onClick={SaveAndExit}
                                        className="btn-rounded btn-text-white btn-blue-600"
                                        disabled={
                                            (propData?.selectedListingType === "fraction" && Boolean(sumTotalRaise() == parseFloat(propData?.fractionalize_total_price)) && Boolean(checkMinimumInvestment() == false) && checkHurdleRate() === true &&
                                                propData.sharedClassesType != "" && sharedClasses.every((elm) => elm.minimum_investment && elm.hurdle_rate && elm.total_raise)) ? false : true
                                        }
                                    >
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="qsn-middle-content" mb={3}>
                                <OverlayScrollbarsComponent defer
                                    style={{ width: '100%', minHeight: '350px', maxHeight: '60vh', padding: '22px' }}
                                >
                                    <Box >
                                        {
                                            sharedClasses && sharedClasses.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Typography
                                                            fontWeight={"500"}
                                                            sx={{ color: "black", textAlign: "center", my: 2.5 }}
                                                        >
                                                            Shared Class {index === 0 ? 'A' : 'B'}
                                                        </Typography>
                                                        {propData?.selectedListingType === "fraction" && (

                                                            <Box>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={13} md={6} lg={6}>
                                                                        <Box className="NS-sale-details">
                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeRaise("dec", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <RemoveIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    id="outlined-number"
                                                                                    type="text"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    // disabled={propData?.number_of_shared === 1 ? true : false}
                                                                                    value={item.total_raise}
                                                                                    onChange={(e) => changeSharedClassData(e.target.value, index)}
                                                                                    InputProps={{
                                                                                        inputComponent: NumericFormatCustomDollar,
                                                                                    }}
                                                                                    onKeyDown={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    helperText={"Total Raise"}
                                                                                />
                                                                            </Box>

                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeRaise("inc", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={13} md={6} lg={6}>
                                                                        <Box className="NS-sale-details">
                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeMinimum("dec", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <RemoveIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    id="outlined-number"
                                                                                    type="text"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    value={item.minimum_investment}
                                                                                    onChange={(e) => changeSharedClassDataMini(e.target.value, index)}
                                                                                    InputProps={{
                                                                                        inputComponent: NumericFormatCustomDollar,
                                                                                    }}
                                                                                    onKeyDown={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    helperText={"Minimum Investment"}
                                                                                />
                                                                            </Box>

                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeMinimum("inc", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} lg={6}>
                                                                        <Box className="NS-sale-details">
                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeInterestRate("dec", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <RemoveIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    id="outlined-number"
                                                                                    type="text"
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    value={item.hurdle_rate}
                                                                                    onChange={(e) => changeSharedClassDataHurdle(e.target.value, index)}
                                                                                    InputProps={{
                                                                                        inputComponent: NumericFormatCustomPercent,
                                                                                    }}
                                                                                    onKeyDown={(event) => {
                                                                                        if (event?.key === '-' || event?.key === '+') {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                    helperText={"Hurdle Rate"}
                                                                                />
                                                                            </Box>
                                                                            <Box className="NS-sale-details-icon">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        changeInterestRate("inc", index);
                                                                                    }}
                                                                                    className="NS-sale-details-icon-btn"
                                                                                >
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>

                                                        )
                                                        }
                                                    </React.Fragment>)
                                            })

                                        }
                                    </Box>
                                </OverlayScrollbarsComponent>
                                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>{Boolean(checkMinimumInvestment()) ? "The minimum investment cannot exceed the total raise for each class." : Boolean(sharedClasses?.some((item)=>item?.total_raise > 0) && sumTotalRaise() != parseFloat(propData?.fractionalize_total_price)) ? (propData?.number_of_shared == 1 ? "The class raise amounts must be equal to total raise value" : "The sum of all class raise amounts must be equal to total raise value.") : checkHurdleRate() != true ? "Hurdle rate can not be greater 100." : ''}</Typography>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress">
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button
                                            className="btn-rounded back-btn"
                                            onClick={handleback}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            disabled={
                                                (propData?.selectedListingType === "fraction"
                                                    && Boolean(sumTotalRaise() == parseFloat(propData?.fractionalize_total_price)) && Boolean(checkMinimumInvestment() == false) && checkHurdleRate() === true && propData.sharedClassesType != "" && sharedClasses.every((elm) => elm.minimum_investment && elm.hurdle_rate && elm.total_raise)) ? false : true
                                            }
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowRightIcon />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
